import { INSURERS_RECEIVED } from '../constants';

export default function positionReducer(state = [], action) {
  switch (action.type) {
    case INSURERS_RECEIVED:
      return action.payload.value.results;
    default:
      return state;
  }
}
