import { DAPI_HOST } from '../../config';

const getEligibilityByInsuranceProfileId = (insuranceProfileId) =>
  `${DAPI_HOST}/v1/eligibility?insurance_profile_id=${insuranceProfileId}`;

const getFaceSheetUrl = (eligibilityCheckId) =>
  `${DAPI_HOST}/v1/eligibility/${eligibilityCheckId}/details`;

const getInsuranceDetailsUrl = (eligibilityCheckId, insuranceProfileId, serviceCode) =>
  `${DAPI_HOST}/v2/eligibility/${eligibilityCheckId}/details` +
  `?insurance_profile_id=${insuranceProfileId}&service_code=${serviceCode}`;

const getEligibilityFeedbackUrl = (eligibilityId) =>
  `${DAPI_HOST}/v1/eligibility/${eligibilityId}/feedback`;

export {
  getEligibilityByInsuranceProfileId,
  getEligibilityFeedbackUrl,
  getInsuranceDetailsUrl,
  getFaceSheetUrl,
};
