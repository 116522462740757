const CARDS_RECEIVED = 'payments/CARDS_RECEIVED';
const CARDS_ERROR = 'payments/CARDS_ERROR';

export const cardsReceived = value => ({
  type: CARDS_RECEIVED,
  payload: { value },
});

export const cardsError = value => ({
  type: CARDS_ERROR,
  payload: { value },
});

export default (state = {}, action) => {
  switch (action.type) {
    case CARDS_RECEIVED:
      return {
        ...state,
        cards: action.payload.value,
      };
    case CARDS_ERROR:
      return {
        ...state,
        cards: null,
        error: action.payload.value,
      };
    default:
      return state;
  }
};
