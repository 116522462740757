import { DAPI_HOST } from '../../config';

const getConversationsByLocationIdUrl = (locationId, page = 1) =>
  `${DAPI_HOST}/v1/conversations?location_id=${locationId}&page=${page}` +
  '&order_by=most_recent_message_timestamp%20DESC';

const getConversationByIdUrl = (conversationId, locationId) =>
  `${DAPI_HOST}/v1/conversations/${conversationId}?location_id=${locationId}`;

const getMessagesByConversationIdUrl = (conversationId, locationId) =>
  `${DAPI_HOST}/v1/messages?conversation_id=${conversationId}&location_id=${locationId}`;

const sendMessageUrl = () => `${DAPI_HOST}/v1/messages`;

const updateLastViewedTimestampUrl = (conversationId, participantId) =>
  `${DAPI_HOST}/v1/conversations/${conversationId}/last_viewed_timestamp/${participantId}`;

const getConversationByParticipantsUrl = (accountId, locationId) =>
  `${DAPI_HOST}/v1/conversation-by-participants?account_id_list=${accountId}&` +
  `location_id_list=${locationId}&requesting_as_location_id=${locationId}`;

const getUpdatePatientSmsConsentStatusUrl = participantId =>
  `${DAPI_HOST}/v1/conversation-participants/${participantId}/consent-status`;

export {
  getConversationsByLocationIdUrl,
  getConversationByIdUrl,
  getMessagesByConversationIdUrl,
  sendMessageUrl,
  updateLastViewedTimestampUrl,
  getConversationByParticipantsUrl,
  getUpdatePatientSmsConsentStatusUrl,
};
