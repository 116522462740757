/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* jscs:disable maximumLineLength */
import { DAPI_CLOUDFRONT_HOST, DAPI_HOST } from '../../config';
import { getTimestampRangeParam, getCalendarDateRangeParam } from './util';
import { isEmptyArray } from '../util/array';
import { getClinicAccountId } from '../auth';

// builds url for /v3/bookings/reports
const getV3BookingsReportsUrl = () => `${DAPI_CLOUDFRONT_HOST}/v3/bookings/reports`;

const getReviewsSummaryUrl = (locationIds) =>
  `${DAPI_CLOUDFRONT_HOST}/v1/solv-ratings/summary?location_ids=${locationIds.join(',')}`;

const getOptionalQSArrayParams = (paramName, paramArray) =>
  !isEmptyArray(paramArray) ? `&${paramName}=${paramArray.join(',')}` : '';

const getReviewsUrl = (locationIds, providerIds, filters, page = 1) => {
  const providerIdsParam = getOptionalQSArrayParams('provider_ids', providerIds);
  const locationIdsParam = getOptionalQSArrayParams('location_ids', locationIds);
  let filtersString =
    `page=${page}` +
    `${locationIdsParam}` +
    `${providerIdsParam}` +
    `&rating_source=${filters.source}` +
    `&rating_date=${getTimestampRangeParam(filters.from, 'from')}` +
    `_to_${getTimestampRangeParam(filters.to, 'to')}` +
    `&star_rating=${filters.starRating}`;

  if (filters.isContacted != null) {
    filtersString += `&is_contacted=${filters.isContacted}`;
  }

  if (isEmptyArray(locationIds) && !isEmptyArray(providerIds)) {
    return `${DAPI_CLOUDFRONT_HOST}/v1/providers/reviews?${filtersString}`;
  }

  return `${DAPI_CLOUDFRONT_HOST}/v2/locations/reviews?${filtersString}`;
};

const getBookingsGraphsUrl = (locationIds, from, to, graphType) =>
  `${DAPI_CLOUDFRONT_HOST}/v3/bookings/graphs` +
  `?location_ids=${locationIds.join(',')}` +
  `&from_=${getCalendarDateRangeParam(from, 'from')}` +
  `&to_=${getCalendarDateRangeParam(to, 'to')}` +
  `&graph_type=${graphType}`.replace(/\+/g, '%2B');

const getPaperlessReportsGraphUrl = (locationIds, from, to, graphType) =>
  `${DAPI_CLOUDFRONT_HOST}/v1/paperwork-reports/graphs` +
  `?location_ids=${locationIds.join(',')}` +
  `&from_=${getCalendarDateRangeParam(from, 'from')}` +
  `&to_=${getCalendarDateRangeParam(to, 'to')}` +
  `&graph_type=${graphType}`.replace(/\+/g, '%2B');

const getTelemedReportsGraphUrl = (locationId, from, to, graphType) =>
  `${DAPI_CLOUDFRONT_HOST}/v1/telemed-reports/graphs` +
  `?location_id=${locationId}` +
  `&from_=${getCalendarDateRangeParam(from, 'from')}` +
  `&to_=${getCalendarDateRangeParam(to, 'to')}` +
  `&graph_type=${graphType}`.replace(/\+/g, '%2B');

const getReportRedirectDownloadUrl = (reportId) =>
  `${DAPI_HOST}/v1/clinic-download-reports/${reportId}/download?author=${getClinicAccountId()}`;

const getClinicDownloadReportsUrl = (limit) =>
  `${DAPI_HOST}/v1/clinic-download-reports?limit=${limit}&author=${getClinicAccountId()}`;

export {
  getBookingsGraphsUrl,
  getV3BookingsReportsUrl,
  getReviewsSummaryUrl,
  getReviewsUrl,
  getPaperlessReportsGraphUrl,
  getTelemedReportsGraphUrl,
  getReportRedirectDownloadUrl,
  getClinicDownloadReportsUrl,
};
