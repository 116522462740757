import { getUrlParameterByNameForUrlString } from '../../core/url';
import React from 'react';
import { getCookie } from '../../core/util/cookie';
import { WELCOME_PAGE_COOKIE_NAME } from '../../config';

export const PRESENTATION_MODE_PARAM_KEY = 'mode';

export const PRESENTATION_MODES = {
  contactless_br: 'contactless_br',
  contactless_qr: 'contactless_qr',
  public_kiosk: 'public_kiosk',
};

export const getPresentationMode = () => {
  const modeUrlParam = getUrlParameterByNameForUrlString(
    getCookie(WELCOME_PAGE_COOKIE_NAME),
    PRESENTATION_MODE_PARAM_KEY
  );

  for (const key of Object.values(PRESENTATION_MODES)) {
    if (PRESENTATION_MODES[key] === modeUrlParam) {
      return modeUrlParam;
    }
  }

  return PRESENTATION_MODES.public_kiosk;
};

export const isKioskModeContactless = () => {
  const mode = getPresentationMode();
  return mode === PRESENTATION_MODES.contactless_br || mode === PRESENTATION_MODES.contactless_qr;
};

// Higher-Order Component that renders wrapped component only if we're in public_kiosk mode
export const guardPublicKioskMode = (WrappedComponent) => {
  return guardPublicKioskModeChild;
  function guardPublicKioskModeChild(props) {
    const presentationMode = getPresentationMode();
    if (presentationMode === PRESENTATION_MODES.public_kiosk) {
      return <WrappedComponent {...props} />;
    }
    return null;
  }
};
