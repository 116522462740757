import {
  ADMIN_BOOKINGS_SET_TAB,
  ADMIN_BOOKINGS_ASSIGN,
  ADMIN_BOOKINGS_SEARCH_RESULTS_RECEIVED,
  ADMIN_BOOKINGS_SEARCH_RESULTS_ERROR,
  ADMIN_BOOKINGS_CLEAR_SEARCH_RESULTS,
  ADMIN_BOOKINGS_SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX,
  ADMIN_BOOKINGS_SET_SEARCH_FILTERS,
  ADMIN_CALL_CENTER_SEARCH_PARAMS,
} from '../constants';

const setTab = (value) => ({
  type: ADMIN_BOOKINGS_SET_TAB,
  payload: { value },
});

const assignPatient = (tab, value) => ({
  type: ADMIN_BOOKINGS_ASSIGN,
  payload: { tab, value },
});

const resultsReceived = (value) => ({
  type: ADMIN_BOOKINGS_SEARCH_RESULTS_RECEIVED,
  payload: { value },
});

const resultsError = (value) => ({
  type: ADMIN_BOOKINGS_SEARCH_RESULTS_ERROR,
  payload: { value },
});

const clearResults = () => ({ type: ADMIN_BOOKINGS_CLEAR_SEARCH_RESULTS });

const setAssignLocationModalSelectedIndex = (value) => ({
  type: ADMIN_BOOKINGS_SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX,
  payload: { value },
});

const setSearchFilters = (value) => ({
  type: ADMIN_BOOKINGS_SET_SEARCH_FILTERS,
  payload: { value },
});

const setCallCenterSearchParams = (value) => ({
  type: ADMIN_CALL_CENTER_SEARCH_PARAMS,
  payload: { value },
});

export {
  setTab,
  assignPatient,
  resultsReceived,
  resultsError,
  clearResults,
  setSearchFilters,
  setAssignLocationModalSelectedIndex,
  setCallCenterSearchParams,
};
