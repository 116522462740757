import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet, apiPatch, apiPost } from '../../core/dapi';
import {
  createContactUrl,
  getContactsCollectionUrl,
  getContactUrl,
} from '../../core/dapi/contacts';
import {
  contactsCreateError,
  contactsCreateLoading,
  contactsCollectionError,
  contactsCollectionLoading,
  contactsCollectionReceived,
} from '../../ducks/contacts';

export class ContactsSagas {
  static CREATE_CONTACT = 'saga/CREATE_CONTACT';
  static EDIT_CONTACT = 'saga/EDIT_CONTACT';
  static FETCH_CONTACTS = 'saga/FETCH_CONTACTS';
}

export function* createContact({ postData }) {
  yield put(contactsCreateLoading(true));
  yield put(contactsCreateError(false));

  try {
    yield call(apiPost, createContactUrl(), postData);
  } catch (e) {
    yield put(contactsCreateError(e));
  } finally {
    yield put(contactsCreateLoading(false));
  }
}

export function* editContact({ contactId, postData }) {
  yield put(contactsCreateLoading(true));
  yield put(contactsCreateError(false));

  try {
    yield call(apiPatch, getContactUrl(contactId), postData);
  } catch (e) {
    yield put(contactsCreateError(e));
  } finally {
    yield put(contactsCreateLoading(false));
  }
}

export function* fetchContacts({ userProfileId }) {
  yield put(contactsCollectionLoading(true));

  try {
    const response = yield call(apiGet, getContactsCollectionUrl(userProfileId));
    yield put(contactsCollectionReceived(response));
  } catch (e) {
    yield put(contactsCollectionError(e));
  } finally {
    yield put(contactsCollectionLoading(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(ContactsSagas.CREATE_CONTACT, createContact);
  yield takeEvery(ContactsSagas.EDIT_CONTACT, editContact);
  yield takeEvery(ContactsSagas.FETCH_CONTACTS, fetchContacts);
}
