import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetJson } from '../../core/dapi';
import { getInsuranceProfileUrl } from '../../core/dapi/insuranceProfiles';
import {
  getCurrentPatientDentalInsuranceProfileSuccess,
  getCurrentPatientInsuranceProfileSuccess,
  getCurrentPatientInsuranceProfileError,
} from '../../actions/currentPatient';

import { InsuranceTypes, InsuranceCardSide } from '../../constants';
import { fetchPresignedAwsUrl } from './images';
import { log } from '../../core/logger/log';

export class InsuranceSagas {
  static FETCH_INSURANCE_PROFILE = 'insuranceSagas/FETCH_INSURANCE_PROFILE';
  static FETCH_DENTAL_INSURANCE_IMAGE_CARD = 'insuranceSagas/FETCH_DENTAL_INSURANCE_IMAGE_CARD';
  static INSURANCE_CARD_FRONT = 'front';
  static INSURANCE_CARD_BACK = 'back';
}

export function* saveInsuranceProfileInformation(insuranceType, responseData = null) {
  if (insuranceType === InsuranceTypes.DENTAL) {
    yield put(getCurrentPatientDentalInsuranceProfileSuccess(responseData));
  } else if (insuranceType === InsuranceTypes.MEDICAL) {
    yield put(getCurrentPatientInsuranceProfileSuccess(responseData));
  }
}

export function* fetchInsuranceProfile({
  insuranceProfileId,
  insuranceType = InsuranceTypes.MEDICAL,
}) {
  if (!insuranceProfileId) {
    yield call(saveInsuranceProfileInformation, insuranceType);
  } else {
    const url = getInsuranceProfileUrl(insuranceProfileId);
    const response = yield call(apiGetJson, url);

    if (response.error) {
      yield call(getCurrentPatientInsuranceProfileError(response.error));
    }

    if (response.data) {
      yield call(saveInsuranceProfileInformation, insuranceType, response.data);
      try {
        yield call(
          fetchPresignedAwsUrl,
          response.data.card_front_id,
          InsuranceCardSide.INSURANCE_CARD_FRONT,
          insuranceType
        );
      } catch (e) {
        log.info(e);
      }
      try {
        yield call(
          fetchPresignedAwsUrl,
          response.data.card_back_id,
          InsuranceCardSide.INSURANCE_CARD_BACK,
          insuranceType
        );
      } catch (e) {
        log.info(e);
      }
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(InsuranceSagas.FETCH_INSURANCE_PROFILE, fetchInsuranceProfile);
}
