import {
  ACTIVATION_CLEAR_ERRORS,
  ACTIVATION_CREATE_ERROR,
  ACTIVATION_CREATE_SUCCESS,
  ACTIVATION_SET_CLINIC_ACCOUNT,
  ACTIVATION_SET_ENCRYPTED_VALUE,
  ACTIVATION_SET_LAST_NAME,
  ACTIVATION_SET_NONCE,
  ACTIVATION_VERIFY_ERROR,
  CLINIC_RESET_PASSWORD,
} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIVATION_CLEAR_ERRORS:
      return {
        ...state,
        createError: null,
        verifyError: null,
      };
    case ACTIVATION_CREATE_ERROR:
      return {
        ...state,
        createError: action.payload.value,
      };
    case ACTIVATION_CREATE_SUCCESS:
      return {
        ...state,
        isCreateSuccessful: true,
        isResetSuccessful: null,
      };
    case ACTIVATION_SET_CLINIC_ACCOUNT:
      return {
        ...state,
        clinicAccount: action.payload.value,
      };
    case ACTIVATION_SET_ENCRYPTED_VALUE:
      return {
        ...state,
        encryptedValue: action.payload.value,
      };
    case ACTIVATION_SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload.value,
      };
    case ACTIVATION_SET_NONCE:
      return {
        ...state,
        nonce: action.payload.value,
      };
    case ACTIVATION_VERIFY_ERROR:
      return {
        ...state,
        verifyError: action.payload.value,
      };
    case CLINIC_RESET_PASSWORD:
      return {
        ...state,
        isResetSuccessful: true,
        isCreateSuccessful: true,
      };
    default:
      return state;
  }
};
