import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'antd';
import MaskedInput from 'react-text-mask';
import { EMPTY_OBJECT } from '../../core/util/object';

export const phoneValidator = (opts = EMPTY_OBJECT) => (rule, value, callback) => {
  const phoneNumber = value.phone;

  if (!opts.isRequired && (!phoneNumber || !phoneNumber.length)) {
    callback();
    return;
  }

  if (!phoneNumber) {
    callback('Please enter a phone number');
    return;
  }

  if (phoneNumber.length !== 10) {
    callback('Phone number must be 10 digits long');
    return;
  }

  callback();
};

export default class MobilePhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = { phone: this.props.value.phone || null };
    this.removeNonNumericCharacters = this.removeNonNumericCharacters.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      const phone = nextProps.value;
      this.setState(phone);
    }
  }

  handleValue = e => {
    const phone = e.target.value.replace(/[^0-9]/g, '');
    const changedValue = { phone };
    this.setState(changedValue);
    return changedValue;
  };

  removeNonNumericCharacters(e) {
    const changedValue = this.handleValue(e);
    if (this.props.onChange) {
      this.props.onChange(Object.assign({}, this.state, changedValue), e);
    }
  }

  handleOnBlur = e => {
    const changedValue = this.handleValue(e);
    if (this.props.onBlur) {
      this.props.onBlur(Object.assign({}, this.state, changedValue));
    }
  };

  renderMaskedInput = () => (
    <MaskedInput
      mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u00A0'}
      disabled={this.props.disabled}
      className="ant-input"
      onChange={this.removeNonNumericCharacters}
      autoFocus={this.props.autoFocus}
      onBlur={this.handleOnBlur}
      type="tel"
      value={this.state.phone}
      data-testid="addPatientMobilePhone"
    />
  );

  renderUnMaskedInput = () => (
    <Input
      autoFocus={this.props.autoFocus}
      disabled={this.props.disabled}
      onChange={this.removeNonNumericCharacters}
      onBlur={this.handleOnBlur}
      value={this.state.phone}
      type="tel"
    />
  );

  render() {
    return this.props.enableMask ? this.renderMaskedInput() : this.renderUnMaskedInput();
  }
}

MobilePhoneInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  enableMask: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

MobilePhoneInput.defaultProps = {
  enableMask: false,
};
