import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getLocationConsentFormsUrl,
  getLocationConsentFormUrl,
} from '../../core/dapi/locationConsentForms';
import { apiPatch, apiPost } from '../../core/dapi';
import { apiGetAllPages } from './base';
import {
  createLocationConsentFormSuccess,
  receiveLocationConsentForms,
  setIsLocationConsentFormUpdating,
  unsetIsLocationConsentFormUpdating,
  updateLocationConsentFormSuccess,
} from '../../ducks/locationConsentForms';
import { emptyFunction } from '../../core/util/function';
import { log } from '../../core/logger/log';

export class LocationConsentFormsSagas {
  static CREATE_FORM = 'sagas/CREATE_LOCATION_CONSENT_FORM';
  static LIST_FORMS = 'sagas/LIST_LOCATION_CONSENT_FORMS';
  static UPDATE_FORM = 'saga/UPDATE_LOCATION_CONSENT_FORM';
}

function* createLocationConsentForm({
  displayName,
  locationId,
  groupId,
  awsBucketName,
  awsPath,
  onSuccess,
  onFailure,
}) {
  try {
    const response = yield call(apiPost, getLocationConsentFormsUrl(), {
      display_name: displayName,
      location_id: locationId,
      group_id: groupId,
      aws_bucket_name: awsBucketName,
      aws_path: awsPath,
    });
    if (onSuccess) onSuccess();
    yield put(createLocationConsentFormSuccess(response));
  } catch (e) {
    log.error(e);
    if (onFailure) onFailure();
  }
}

function* listLocationConsentForms({ activeOnly, locationId }) {
  try {
    const url = getLocationConsentFormsUrl(activeOnly, locationId);
    const response = yield call(apiGetAllPages, { url });
    yield put(receiveLocationConsentForms(response.results));
  } catch (e) {
    log.error(e);
  }
}

function* updateLocationConsentForm({ locationConsentFormId, patchData, onError = emptyFunction }) {
  yield put(setIsLocationConsentFormUpdating(locationConsentFormId));

  try {
    const url = getLocationConsentFormUrl(locationConsentFormId);
    const response = yield call(apiPatch, url, patchData);
    yield put(updateLocationConsentFormSuccess(response));
  } catch (e) {
    onError(e);
  }

  yield put(unsetIsLocationConsentFormUpdating(locationConsentFormId));
}

export default function* rootSaga() {
  yield takeEvery(LocationConsentFormsSagas.CREATE_FORM, createLocationConsentForm);
  yield takeEvery(LocationConsentFormsSagas.LIST_FORMS, listLocationConsentForms);
  yield takeEvery(LocationConsentFormsSagas.UPDATE_FORM, updateLocationConsentForm);
}
