import {
  IMAGE_DATA_CLEAR,
  IMAGE_DATA_SET,
  IMAGE_DATA_SET_FRONT,
  IMAGE_DATA_SET_BACK,
  BOOKING_RESPONSE_RECEIVED,
} from '../constants';

const getInitialState = () => ({
  frontImageUrl: null,
  backImageUrl: null,
  data: null,
});

export default function imageReducer(state = getInitialState(), action) {
  switch (action.type) {
    case IMAGE_DATA_SET:
      return {
        ...state,
        data: action.payload.value,
      };
    case IMAGE_DATA_SET_FRONT:
      return {
        ...state,
        frontImageUrl: action.payload.value,
      };
    case IMAGE_DATA_SET_BACK:
      return {
        ...state,
        backImageUrl: action.payload.value,
      };
    case BOOKING_RESPONSE_RECEIVED:
    case IMAGE_DATA_CLEAR:
      return {};
    default:
      return state;
  }
}
