import { EMPTY_OBJECT } from '../core/util/object';
import cloneDeep from 'lodash/cloneDeep';
import { getPaginationValues } from '../core/dapi';

const RECEIVE_CONVERSATIONS = 'conversations/receiveConversations';
const RECEIVE_CONVERSATION = 'conversations/receiveConversation';
const RECEIVE_CONVERSATIONS_ERROR = 'conversations/receiveConversationsError';
const FETCHING_CONVERSATIONS = 'conversations/isFetchingConversations';

const RECEIVE_MESSAGES = 'conversations/receiveMessages';
const RECEIVE_MESSAGES_ERROR = 'conversations/receiveMessagesError';
const IS_FETCHING_MESSAGES = 'conversations/isFetchingMessages';
const SENDING_MESSAGE = 'conversations/sendingMessage';
const SEND_MESSAGE_SUCCESS = 'conversations/sendMessageSuccess';
const SEND_MESSAGE_ERROR = 'conversations/sendMessageError';

const SET_CURRENT_CONVERSATION = 'conversations/setCurrentConversation';
const CLEAR_CURRENT_CONVERSATION = 'conversations/clearCurrentConversation';
const SET_ALLOW_TEXT_MESSAGE_BOOKINGS = 'conversations/setAllowTextMessageBookings';

const CHAT_MODAL_TOGGLE = 'conversations/toggleChatModal';
const CANNED_MESSAGES_MODAL_TOGGLE = 'conversations/toggleCannedMessagesModal';

const SET_MODAL_OPENED_FROM = 'conversations/setModalOpenedFrom';

const UPDATE_PATIENT_CONSENT_STATUS_ERROR = 'conversations/updatePatientConsentStatusError';
const UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS = 'conversations/updatePatientConsentStatusSuccess';

const isFetchingConversations = () => ({
  type: FETCHING_CONVERSATIONS,
});

const receiveConversations = (value, opts = {}) => {
  const { currentPage, hasMorePages } = getPaginationValues(value);

  return {
    type: RECEIVE_CONVERSATIONS,
    payload: {
      value,
      currentPage,
      hasMorePages,
      ...opts,
    },
  };
};

const receiveConversation = (value, opts = {}) => ({
  type: RECEIVE_CONVERSATION,
  payload: { value, ...opts },
});

const receiveConversationsError = (value) => ({
  type: RECEIVE_CONVERSATIONS_ERROR,
  payload: { value },
});

const receiveMessages = (conversationId) => (value) => ({
  type: RECEIVE_MESSAGES,
  payload: { conversationId, value },
});

const isFetchingMessages = () => ({
  type: IS_FETCHING_MESSAGES,
});

const receiveMessagesError = (value) => ({
  type: RECEIVE_MESSAGES_ERROR,
  payload: { value },
});

const sendingMessage = () => ({
  type: SENDING_MESSAGE,
});

const sendMessageSuccess = (value, opts = {}) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { value, ...opts },
});

const sendMessageError = (value) => ({
  type: SEND_MESSAGE_ERROR,
  payload: { value },
});

const setCurrentConversation = (value) => ({
  type: SET_CURRENT_CONVERSATION,
  payload: { value },
});

const setAllowTextMessageBookings = (value) => ({
  type: SET_ALLOW_TEXT_MESSAGE_BOOKINGS,
  payload: { value },
});

const clearCurrentConversation = () => ({
  type: CLEAR_CURRENT_CONVERSATION,
});

const toggleChatModal = (value) => ({
  type: CHAT_MODAL_TOGGLE,
  payload: { value },
});

const toggleCannedMessagesModal = (value) => ({
  type: CANNED_MESSAGES_MODAL_TOGGLE,
  payload: { value },
});

const setModalOpenedFromValue = (value) => ({
  type: SET_MODAL_OPENED_FROM,
  payload: { value },
});

const updatePatientConsentStatusError = (value) => ({
  type: UPDATE_PATIENT_CONSENT_STATUS_ERROR,
  payload: { value },
});

const updatePatienSmsConsentStatusSuccess = (data, opts = {}) => ({
  type: UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS,
  payload: { data, ...opts },
});

const getInitialState = () => ({
  conversations: {
    conversations: EMPTY_OBJECT,
    currentPage: null,
    hasMorePages: null,
  },
  messagesByConversationId: EMPTY_OBJECT,
  unreadCount: 0,
});

const getConversationWithUpdatedConsent = (conversation, consentData) => {
  const conversationCopy = cloneDeep(conversation);

  for (let i = 0; i < conversationCopy.participants.length; i++) {
    const participant = conversationCopy.participants[i];
    if (participant.account_id === consentData.account_id) {
      participant.is_consented_patient = consentData.is_consented_patient;
    }
  }

  return conversationCopy;
};

const reducer = (state = getInitialState(), action = EMPTY_OBJECT) => {
  switch (action.type) {
    case FETCHING_CONVERSATIONS:
      return {
        ...state,
        isFetchingConversations: true,
      };
    case RECEIVE_CONVERSATIONS: {
      return {
        ...state,
        isFetchingConversations: false,
      };
    }
    case RECEIVE_CONVERSATION: {
      return {
        ...state,
        isFetchingConversations: false,
      };
    }
    case RECEIVE_CONVERSATIONS_ERROR:
      return {
        ...state,
        error: action.payload.value,
        isFetchingConversations: false,
      };
    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.payload.value,
      };
    case SET_ALLOW_TEXT_MESSAGE_BOOKINGS:
      return {
        ...state,
        allowedTextMessageBookings: action.payload.value,
      };
    case CLEAR_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: {},
      };
    case IS_FETCHING_MESSAGES:
      return {
        ...state,
        isFetchingMessages: true,
      };
    case RECEIVE_MESSAGES: {
      const newMessagesByConversationId = { ...state.messagesByConversationId };
      newMessagesByConversationId[action.payload.conversationId] = action.payload.value.results;

      return {
        ...state,
        messagesByConversationId: newMessagesByConversationId,
        isFetchingMessages: false,
      };
    }

    case RECEIVE_MESSAGES_ERROR:
      return {
        ...state,
        error: action.payload.value?.toString() ?? 'Something went wrong.',
        isFetchingMessages: false,
      };
    case SENDING_MESSAGE:
      return {
        ...state,
        error: undefined,
        sendError: undefined,
        sending: true,
      };
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        sending: false,
        sendError: undefined,
        error: undefined,
      };
    }
    case SEND_MESSAGE_ERROR: {
      return {
        ...state,
        sending: false,
        sendError: action.payload?.value?.toString() ?? 'Something went wrong.',
      };
    }
    case CHAT_MODAL_TOGGLE:
      return {
        ...state,
        chatModalVisible: action.payload.value,
        error: null,
      };
    case CANNED_MESSAGES_MODAL_TOGGLE:
      return {
        ...state,
        cannedMessagesModalVisible: action.payload.value,
      };

    case SET_MODAL_OPENED_FROM:
      return {
        ...state,
        modalOpenedFrom: action.payload.value,
      };

    case UPDATE_PATIENT_CONSENT_STATUS_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS:
      return {
        ...state,
        currentConversation: getConversationWithUpdatedConsent(
          state.currentConversation,
          action.payload.data
        ),
      };

    default:
      return state;
  }
};

export {
  reducer as default,
  receiveConversations,
  receiveConversation,
  receiveConversationsError,
  isFetchingConversations,
  isFetchingMessages,
  receiveMessages,
  receiveMessagesError,
  sendingMessage,
  sendMessageSuccess,
  sendMessageError,
  setCurrentConversation,
  clearCurrentConversation,
  toggleChatModal,
  toggleCannedMessagesModal,
  setModalOpenedFromValue,
  updatePatientConsentStatusError,
  updatePatienSmsConsentStatusSuccess,
  setAllowTextMessageBookings,
  RECEIVE_CONVERSATIONS,
  RECEIVE_CONVERSATION,
  SEND_MESSAGE_SUCCESS,
  UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS,
  getConversationWithUpdatedConsent,
};
