import { CHARGES_ERROR, CHARGES_RECEIVED, CHARGES_SUBMITTING } from '../constants';

const chargesReceived = value => ({
  type: CHARGES_RECEIVED,
  payload: { value },
});

const chargesError = value => ({
  type: CHARGES_ERROR,
  payload: { value },
});

const chargesSubmitting = () => ({
  type: CHARGES_SUBMITTING,
});

export { chargesReceived, chargesError, chargesSubmitting };
