import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Icon, Popover } from 'antd';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { getCookie, setCookie } from '../../core/util/cookie';

const StyledPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.grannySmith};
  margin-right: 5px;
`;

const StyledDismissPopoverButton = styled(Button)`
  width: 65px;
`;

const StyledIconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    color: ${colors.blueMonday};
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export default class OneTimeDismissablePopover extends PureComponent {
  state = {
    shouldShowPopover: false,
  };

  componentDidMount = () => {
    if (this.hasNeverBeenDisplayed()) {
      this.setState({ shouldShowPopover: true });
    }
  };

  getPopoverContent = () => (
    <StyledPopoverContainer>
      <StyledIconTextContainer>
        <StyledIcon type="exclamation-circle-o" />
        <span>{this.props.popoverText}</span>
      </StyledIconTextContainer>
      <StyledButtonContainer>
        <StyledDismissPopoverButton type="primary" onClick={this.handleDismissPopover}>
          Okay
        </StyledDismissPopoverButton>
      </StyledButtonContainer>
    </StyledPopoverContainer>
  );

  setHasBeenDisplayedCookie = () => setCookie(this.props.popoverId, true, 365);

  hasNeverBeenDisplayed = () => typeof getCookie(this.props.popoverId) === 'undefined';

  handleDismissPopover = () => {
    this.setState({ shouldShowPopover: false });
    this.setHasBeenDisplayedCookie();
  };

  render = () => (
    <Popover
      children={this.props.children}
      content={this.getPopoverContent()}
      placement={this.props.placement}
      title={this.props.title}
      visible={this.state.shouldShowPopover}
    />
  );
}

OneTimeDismissablePopover.defaultProps = {
  placement: 'top',
};

OneTimeDismissablePopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  popoverId: PropTypes.string.isRequired,
  popoverText: PropTypes.string.isRequired,
  title: PropTypes.string,
  placement: PropTypes.string,
  popoverHasBeenDismissed: PropTypes.bool,
};
