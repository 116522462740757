import {
  LOGIN_CLINICS_RECEIVED,
  LOGIN_ACCOUNT_RECEIVED,
  PUBNUB_AUTH_RECEIVED,
  PUBNUB_AUTH_ERROR,
  REHYDRATE,
  PASSWORD_RESET_SUBMITTED,
  PASSWORD_RESET_CLEAR_ERRORS,
  PASSWORD_RESET_REQUESTED,
  LAST_COMPLETED_NPS_UPDATED,
  CLINIC_ACCOUNTS_LOCATION_PATCHED,
  CLINIC_ACCOUNTS_LOCATION_PATCH_ERROR,
} from '../../src/constants';
import { produce } from 'immer';
import { ReduxMigration, RUN_MIGRATION } from '../store/useReduxMigrate';
import { LocationFormatters } from '../core/data/mappers';

export default function loginReducer(state = {}, action) {
  switch (action.type) {
    case RUN_MIGRATION: {
      return produce(state, (current) => {
        const migration = action.payload;
        switch (migration) {
          case ReduxMigration.AddLocationType: {
            current.account.locations = LocationFormatters.formatAccountLocations(
              current.account.locations
            );
            return current;
          }
        }
        return current;
      });
    }
    case LOGIN_CLINICS_RECEIVED:
      return {
        ...state,
        locations: action.payload.value,
      };
    case LOGIN_ACCOUNT_RECEIVED:
      return {
        ...state,
        account: {
          ...action.payload.value,
        },
        accountError: null,
      };
    case PUBNUB_AUTH_RECEIVED: {
      const newState = {
        ...state,
        pubNub: { ...state.pubNub },
        pubNubError: null,
      };
      newState.pubNub[action.payload.locationId] = action.payload.value;
      return newState;
    }

    case PUBNUB_AUTH_ERROR:
      return {
        ...state,
        pubNubError: action.payload.value,
      };
    case REHYDRATE: {
      return state;
    }

    case PASSWORD_RESET_SUBMITTED:
      return {
        ...state,
        passwordResetSubmitting: true,
      };

    case PASSWORD_RESET_REQUESTED:
      return {
        ...state,
        passwordResetSubmitting: false,
        passwordResetRequested: true,
      };

    case PASSWORD_RESET_CLEAR_ERRORS:
      return {
        ...state,
        passwordResetRequested: false,
        passwordResetSubmitting: false,
      };

    case LAST_COMPLETED_NPS_UPDATED:
      return {
        ...state,
        account: {
          ...state.account,
          last_completed_nps: action.payload.value,
        },
      };

    case CLINIC_ACCOUNTS_LOCATION_PATCHED:
      return {
        ...state,
        account: {
          ...state.account,
          locations: state.account.locations.map((location) => {
            if (location.location_id === action.payload.value.location_id) {
              return action.payload.value;
            }
            return location;
          }),
        },
      };

    case CLINIC_ACCOUNTS_LOCATION_PATCH_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };

    default:
      return state;
  }
}
