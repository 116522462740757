export function isNumber(number: any) {
  return typeof number === 'number' && number !== null;
}

export function isNumberBetween(
  number: number | string | undefined,
  min: number,
  max: number
): boolean {
  if (typeof number === 'string') {
    number = parseInt(number);
  }
  if (typeof number !== 'number') {
    return false;
  }
  return /^[0-9]+$/.test(number.toString()) && number >= min && number <= max;
}

export function isWholeNumber(number: number | undefined): boolean {
  return typeof number !== 'undefined' && number % 1 === 0;
}

export function roundFloat(
  number: string | number | undefined,
  decimalPlaces: number
): string | undefined {
  if (!number) {
    return;
  }
  if (typeof number !== 'string') {
    number = number.toString();
  }
  return parseFloat(number).toFixed(decimalPlaces);
}

/** *
 * Takes an integer and rounds it to the nearest `roundTo`.
 * @param integer Number to round
 * @param roundTo What to round to, default 10
 * @returns {number}
 */
export function roundInt(integer: number, roundTo = 10): number {
  return Math.ceil(integer / roundTo) * roundTo;
}

export function formatNumberWithCommas(price: number | string): string {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Formats a number (in dollars) as currency
 * @param dollars The number to format, in dollars
 * @param options Options for the number formatting
 * @returns The formatted number
 */
export function formatDollars(dollars?: number, options: Intl.NumberFormatOptions = {}): string {
  if (!dollars) {
    return '$0';
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(dollars);
}

/**
 * Formats a number (in cents) as currency
 * @param cents The number to format, in cents
 * @param options Options for the number formatting
 * @returns The formatted number
 */
export function formatCents(
  cents: number | undefined,
  options: Intl.NumberFormatOptions = {}
): string {
  if (!cents) {
    return '$0';
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(cents / 100);
}

export function centsToDollars(cents: number | undefined): number | undefined {
  if (cents === undefined) return undefined;
  return cents / 100;
}
