import {
  SET_RUNTIME_VARIABLE,
  UPDATE_BOOKING_SUCCESS,
  CREATE_BOOKING_SUCCESS,
  BOOKINGS_SUMMARY_RECEIVED,
  RECEIVE_REVIEWS_SUMMARY,
  RECEIVE_REVIEWS,
  RECEIVE_ACCOUNTS_SEARCH,
  ACCOUNTS_SEARCH_ERROR,
  RECEIVE_BOOKING_HISTORY,
  BOOKING_HISTORY_ERROR,
  RECEIVE_PAST_BOOKINGS,
  PAST_BOOKING_ERROR,
  CLEAR_BOOKING_ERROR,
  SETTINGS_RECEIVE_LOCATIONS,
  SETTINGS_SPECIAL_HOURS_SUCCESS,
  SETTINGS_SPECIAL_HOURS_ERROR,
  PULSES_RECEIVE_DATA,
  SETTINGS_RESERVATION_HOURS_SUCCESS,
  SETTINGS_RESERVATION_HOURS_ERROR,
  PARTIAL_UPDATE_EXISTING_BOOKING,
  GET_BOOKING_SUCCESS,
  BOOKINGS_COUNT_RECEIVED,
} from '../constants';
import { EMPTY_OBJECT } from '../core/util/object';

export class RuntimeAttributes {
  static IS_CREATING_HANDOFF_CODE = 'isCreatingHandoffCode';
  static IS_FETCHING_UCL_BRANDS = 'isFetchingUclBrands';
  static IS_FETCHING_CONSENT_FORM_SIGNATURES = 'isFetchingConsentFormSignatures';
  static IS_FETCHING_INSURERS = 'isFetchingInsurers';
  static IS_FETCHING_PAPERWORK_DATA = 'isFetchingPaperworkData';
  static IS_FETCHING_SERVICES = 'isFetchingServices';
  static IS_FETCHING_SPECIALTIES = 'isFetchingSpecialties';
  static IS_MATCHING_LOCATION_FOUND_MODAL_VISIBLE = 'isMatchingLocationFoundModalVisible';
  static IS_FETCHING_CATEGORIES = 'isFetchingCategories';
  static IS_PHYSICAL_LOCATION_ASSOCIATION_MODAL_VISIBLE =
    'isPhysicalLocationAssociationModalVisible';
}

export const createInitialRuntimeState = () => ({
  currentTime: null,
  facesheetEditMode: false,
  initialNow: Date.now(),
  isModifyingSpecialHours: false,
  isFetchingAccounts: false,
  isFetchingLocations: false,
  isGettingBookingHistory: false,
  isGettingPastBookings: false,
  isResendingInvitationEmail: EMPTY_OBJECT,
  reservedTodayCountMayNeedRefresh: false,
  route: null,
  locationsImportToolWetRunId: '',
  showAddPatientModal: false,
  showFollowUpBookingModal: false,
  showAssignPatientModal: false,
  showFacesheetModal: false,
  showReviewsModal: false,
  showWaitlistModal: false,
  showEmojiPickerModal: false,
  showLocationsImportToolCompleteModal: false,
  submittingAddEditPatient: false,
  submittingNotes: false,
  [RuntimeAttributes.IS_CREATING_HANDOFF_CODE]: false,
  [RuntimeAttributes.IS_FETCHING_CONSENT_FORM_SIGNATURES]: false,
  [RuntimeAttributes.IS_FETCHING_INSURERS]: false,
  [RuntimeAttributes.IS_FETCHING_PAPERWORK_DATA]: false,
  [RuntimeAttributes.IS_FETCHING_SERVICES]: false,
  [RuntimeAttributes.IS_FETCHING_SPECIALTIES]: false,
  [RuntimeAttributes.IS_FETCHING_UCL_BRANDS]: false,
  [RuntimeAttributes.IS_MATCHING_LOCATION_FOUND_MODAL_VISIBLE]: false,
  [RuntimeAttributes.IS_PHYSICAL_LOCATION_ASSOCIATION_MODAL_VISIBLE]: false,
  persistentStateLoaded: false,
});

export default function runtime(state = createInitialRuntimeState(), action) {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        persistentStateLoaded: true,
      };
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        facesheetEditMode: false,
        reservedTodayCountMayNeedRefresh: true,
      };
    case PARTIAL_UPDATE_EXISTING_BOOKING:
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        reservedTodayCountMayNeedRefresh: true,
      };
    case BOOKINGS_COUNT_RECEIVED:
      return {
        ...state,
        reservedTodayCountMayNeedRefresh: false,
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        showAddPatientModal: false,
      };
    case BOOKINGS_SUMMARY_RECEIVED:
      return {
        ...state,
        gettingSummaryData: false,
      };
    case RECEIVE_REVIEWS_SUMMARY:
      return {
        ...state,
        gettingReviewsSummaryData: false,
      };
    case RECEIVE_REVIEWS:
      return {
        ...state,
        isGettingReviewsData: false,
      };
    case RECEIVE_ACCOUNTS_SEARCH:
    case ACCOUNTS_SEARCH_ERROR:
      return {
        ...state,
        isFetchingAccounts: false,
      };
    case RECEIVE_BOOKING_HISTORY:
    case BOOKING_HISTORY_ERROR:
      return {
        ...state,
        isGettingBookingHistory: false,
      };
    case RECEIVE_PAST_BOOKINGS:
    case PAST_BOOKING_ERROR:
      return {
        ...state,
        isGettingPastBookings: false,
      };
    case CLEAR_BOOKING_ERROR:
      return {
        ...state,
        submittingAddEditPatient: false,
      };
    case SETTINGS_RECEIVE_LOCATIONS:
      return {
        ...state,
        isFetchingLocations: false,
      };
    case SETTINGS_SPECIAL_HOURS_SUCCESS:
    case SETTINGS_SPECIAL_HOURS_ERROR:
      return {
        ...state,
        isModifyingSpecialHours: false,
      };
    case PULSES_RECEIVE_DATA:
      return {
        ...state,
        pulseRefreshing: false,
      };
    case SETTINGS_RESERVATION_HOURS_SUCCESS:
    case SETTINGS_RESERVATION_HOURS_ERROR:
      return {
        ...state,
        isCreatingReservationHours: false,
      };
    default:
      return state;
  }
}
