import { call, put, takeEvery } from 'redux-saga/effects';
import { apiPatch, apiPost } from '../../core/dapi';
import {
  getProviderLocationsUrl,
  syncProviderLocationsUrl,
  updateProviderLocationUrl,
} from '../../core/dapi/providerLocations';
import {
  providerLocationsError,
  providerLocationsLoading,
  providerLocationsReceived,
  providerLocationsSyncing,
  providerLocationUpdated,
  providerLocationUpdating,
} from '../../ducks/providerLocations';
import { apiGetAllPages } from './base';

export class ProviderLocationsSagas {
  static FETCH_PROVIDER_LOCATIONS = 'saga/FETCH_PROVIDER_LOCATIONS';
  static UPDATE_PROVIDER_LOCATION = 'saga/UPDATE_PROVIDER_LOCATION';
  static SYNC_PROVIDER_LOCATIONS = 'saga/SYNC_PROVIDER_LOCATIONS';
}

export function* fetchProviderLocations({ filterActive, locationId }) {
  yield put(providerLocationsLoading(true));

  try {
    const response = yield call(apiGetAllPages, {
      url: getProviderLocationsUrl(filterActive, locationId),
    });
    yield put(providerLocationsReceived(response.results));
  } catch (e) {
    yield put(providerLocationsError(e));
  } finally {
    yield put(providerLocationsLoading(false));
  }
}

export function* updateProviderLocation({ onSuccess, patchData, providerLocationId }) {
  yield put(providerLocationUpdating(true));

  try {
    const response = yield call(apiPatch, updateProviderLocationUrl(providerLocationId), patchData);
    yield put(providerLocationUpdated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(providerLocationsError(e));
  } finally {
    yield put(providerLocationUpdating(false));
  }
}

export function* syncProviderLocations({ onTimeout, postData }) {
  yield put(providerLocationsSyncing(true));

  try {
    const response = yield call(apiPost, syncProviderLocationsUrl(), postData);
    if (response?.status === 'in_progress') {
      onTimeout();
    } else {
      yield call(fetchProviderLocations, { filterActive: false, locationId: postData.location_id });
    }
  } catch (e) {
    yield put(providerLocationsError(e));
  } finally {
    yield put(providerLocationsSyncing(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(ProviderLocationsSagas.FETCH_PROVIDER_LOCATIONS, fetchProviderLocations);
  yield takeEvery(ProviderLocationsSagas.UPDATE_PROVIDER_LOCATION, updateProviderLocation);
  yield takeEvery(ProviderLocationsSagas.SYNC_PROVIDER_LOCATIONS, syncProviderLocations);
}
