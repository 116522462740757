import { call, put, takeEvery } from 'redux-saga/effects';
import { setRuntimeVariable } from '../../actions/runtime';
import { RuntimeAttributes } from '../../reducers/runtime';
import { apiPost } from '../../core/dapi';
import { getCreateHandoffUrl } from '../../core/dapi/handoffs';
import {
  handoffCodeError,
  receiveHandoffCode,
  togglePopoverVisibility,
} from '../../ducks/handoffs';

export class HandoffsSagas {
  static TOGGLE_POPOVER_VISIBILITY = 'sagas/TOGGLE_POPOVER_VISIBILITY';
  static CREATE_HANDOFF_CODE = 'sagas/CREATE_HANDOF_CODE';
}

function* togglePopover({ isVisible, handoffType }) {
  yield put(togglePopoverVisibility(isVisible, handoffType));
}

function* createHandoffCode({ bookingId, handoffType }) {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_CREATING_HANDOFF_CODE, value: true }));

  try {
    yield put(
      receiveHandoffCode(
        yield call(apiPost, getCreateHandoffUrl(), {
          booking_id: bookingId,
          handoff_type: handoffType,
        }),
        handoffType
      )
    );
  } catch (e) {
    yield put(handoffCodeError(e, handoffType));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_CREATING_HANDOFF_CODE, value: false }));

  yield call(togglePopover, { isVisible: true, handoffType });
}

export default function* rootSaga() {
  yield takeEvery(HandoffsSagas.TOGGLE_POPOVER_VISIBILITY, togglePopover);
  yield takeEvery(HandoffsSagas.CREATE_HANDOFF_CODE, createHandoffCode);
}
