import RequestManager from '../dapi/requestManager';
import { assertNoError } from '../dapi/response';
import { DAPI_AUTH_ENABLED, DAPI_TOKEN } from '../../config';
import { getDapiAuthHeaders } from '../auth';

export interface RequestOptions {
  ignoreAuth?: boolean;
  camelCase?: boolean;
  raw?: boolean;
  usePublicDapiToken?: boolean;
  headers?: Record<string, string>;
  signal?: AbortSignal | null;
}

export interface HttpClient {
  getJson<T>(url: string, opts?: RequestOptions): Promise<T>;
  postJson<T>(url: string, postBody: any, opts?: RequestOptions): Promise<T>;
  patchJson<T>(url: string, patchBody: any, opts?: RequestOptions): Promise<T>;
  deleteJson<T>(url: string, deleteBody: any, opts?: RequestOptions): Promise<T>;
}

async function buildRequestOptions(opts?: RequestOptions): Promise<RequestInit> {
  let headers: Record<string, string> = {};
  if (opts?.camelCase) {
    headers['X-JSON-FORMAT'] = 'json_camelcase';
  }
  if (opts?.raw) {
    headers['X-JSON-FORMAT'] = 'raw';
  }
  if (!opts?.usePublicDapiToken && DAPI_AUTH_ENABLED && !opts?.ignoreAuth) {
    headers = { ...headers, ...getDapiAuthHeaders() };
  }
  if (opts?.usePublicDapiToken) {
    headers = { ...headers, ...{ Authorization: 'Bearer ' + DAPI_TOKEN } };
  }
  if (opts?.headers) {
    headers = {
      ...headers,
      ...opts.headers,
    };
  }
  return {
    headers,
    signal: opts?.signal ?? null,
  };
}

export async function httpGetJson<T>(url: string, opts?: RequestOptions): Promise<T> {
  const options = await buildRequestOptions(opts);
  return RequestManager.makeRequest(async () => {
    try {
      const result = await fetch(url, options);
      const body = await result.text();
      if (!result.ok) {
        throw new HttpError(result, body);
      }
      return parseResponse(result, body);
    } catch (ex) {
      throwError(url, ex);
    }
  });
}

export async function httpPostJson<T>(
  url: string,
  postBody: any,
  opts?: RequestOptions
): Promise<T> {
  const options = await buildRequestOptions(opts);
  return RequestManager.makeRequest(async () => {
    try {
      const result = await fetch(url, {
        body: JSON.stringify(postBody),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers,
        },
      });
      const body = await result.text();
      if (!result.ok) {
        throw new HttpError(result, body);
      }
      return parseResponse(result, body);
    } catch (ex) {
      throwError(url, ex);
    }
  });
}

export async function httpPatchJson<T>(
  url: string,
  putBody: any,
  opts?: RequestOptions
): Promise<T> {
  const options = await buildRequestOptions(opts);
  return RequestManager.makeRequest(async () => {
    try {
      const result = await fetch(url, {
        body: JSON.stringify(putBody),
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers,
        },
      });
      const body = await result.text();
      if (!result.ok) {
        throw new HttpError(result, body);
      }
      return parseResponse(result, body);
    } catch (ex) {
      throwError(url, ex);
    }
  });
}

export async function httpDeleteJson<T>(
  url: string,
  deleteBody: any,
  opts?: RequestOptions
): Promise<T> {
  const options = await buildRequestOptions(opts);
  return RequestManager.makeRequest(async () => {
    try {
      const result = await fetch(url, {
        body: JSON.stringify(deleteBody),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers,
        },
      });
      const body = await result.text();
      if (!result.ok) {
        throw new HttpError(result, body);
      }
      return parseResponse(result, body);
    } catch (ex) {
      return throwError(url, ex);
    }
  });
}

export class HttpError extends Error {
  private status: number | undefined;
  private url: string | undefined;

  constructor(result: Pick<Response, 'url' | 'status'>, body: string, error?: string) {
    const parsed = tryJsonParse<any>(body);

    if (parsed?.errors) {
      const first = parsed.errors[0];
      error = first?.description ?? first?.message ?? error;
    }

    super(
      JSON.stringify({
        error: error || `${result.url} failed with status code ${result.status}`,
        body,
        status: result.status,
        url: result.url,
      })
    );

    this.status = result.status;
    this.url = result.url;
  }

  toString() {
    return tryJsonParse<any>(this.message)?.error ?? this.message;
  }

  getStatusCode() {
    return this.status;
  }

  getUrl() {
    return this.url;
  }

  formatted() {
    return this.toString();
  }
}

function parseResponse<T>(response: Response, body: string): T {
  try {
    if (response.status == 204 && !body) {
      return undefined as unknown as T;
    }
    const parsed = JSON.parse(body);
    assertNoError(parsed);
    return parsed;
  } catch (ex: unknown) {
    throw new HttpError(response, body, ex instanceof Error ? ex.message : '');
  }
}

function tryJsonParse<T>(body: string): T | undefined {
  try {
    return JSON.parse(body);
  } catch (ex: unknown) {
    return undefined;
  }
}

function throwError(url: string, ex: any) {
  if (ex instanceof HttpError) {
    throw ex;
  }
  if (ex instanceof Error) {
    throw new HttpError(
      {
        url,
        status: 500,
      },
      ex.message
    );
  }
  throw new HttpError(
    {
      url,
      status: 500,
    },
    ex as any
  );
}
