import omit from 'lodash/omit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { paperworkResponseError, receivePaperworkResponse } from '../actions/paperworkResponses';
import { setRuntimeVariable } from '../actions/runtime';
import { setSubmitting } from '../actions/settings';
import { PaperworkTypes } from '../constants';
import { apiGet, apiPatch, apiPost, apiPostJson } from '../core/dapi';
import {
  getLocationPaperworkFieldsUrl,
  getPostLocationPaperworkFieldsUrl,
  getReplicateLocationPaperworkUrl,
  getUpsertManyLocationPaperworkFieldsUrl,
} from '../core/dapi/locationPaperworkFields';
import { getAllPaperworkFieldsUrl, getPaperworkFieldsV2Url } from '../core/dapi/paperworkFields';
import {
  getCreatePaperworkResponsesUrl,
  getPaperworkResponsesUrl,
} from '../core/dapi/paperworkResponses';
import { emptyFunction } from '../core/util/function';
import {
  createPaperworkFieldSuccess,
  getAllPaperworkFieldsError,
  locationPaperworkFieldsError,
  patchPaperworkFieldSuccess,
  receiveAllPaperworkFields,
  receiveLocationPaperworkFields,
  receiveUpdatedLocationPaperworkField,
  refreshPaperworkFields as refreshPaperworkFieldsAction,
  refreshPaperworkFieldsSuccess,
  selectors,
  softDeletePaperworkFieldSuccess,
  updateLocationPaperworkFieldError,
  updateLocationPaperworkFieldSuccess,
  upsertLocationPaperworkFields as upsertLocationPaperworkFieldsAction,
} from '../ducks/paperwork';
import { RuntimeAttributes } from '../reducers/runtime';
import { apiGetAllPages } from './core/base';
import { log } from '../core/logger/log';

export class PaperworkSagas {
  static FETCH_ALL_PAPERWORK_FIELDS = 'saga/paperwork/FETCH_ALL_PAPERWORK_FIELDS';
  static REFRESH_PAPERWORK_FIELDS = 'saga/paperwork/REFRESH_PAPERWORK_FIELDS';
  static FETCH_LOCATION_PAPERWORK_FIELDS = 'saga/paperwork/FETCH_LOCATION_PAPERWORK_FIELDS';
  static FETCH_PAPERWORK_DATA = 'saga/paperwork/FETCH_PAPERWORK_DATA';
  static POST_LOCATION_PAPERWORK_FIELDS = 'saga/paperwork/POST_LOCATION_PAPERWORK_FIELDS';
  static CREATE_PAPERWORK_FIELD = 'saga/paperwork/CREATE_PAPERWORK_FIELD';
  static PATCH_PAPERWORK_FIELD = 'saga/paperwork/PATCH_PAPERWORK_FIELD';
  static SOFT_DELETE_PAPERWORK_FIELD = 'saga/paperwork/SOFT_DELETE_PAPERWORK_FIELD';
  static UPSERT_LOCATION_PAPERWORK_FIELDS = 'saga/paperwork/UPSERT_LOCATION_PAPERWORK_FIELDS';
  static REPLICATE_PAPERWORK_OF_LOCATION = 'saga/paperwork/replicate_paperwork_of_location';
  static SUBMIT_PAPERWORK_RESPONSES = 'saga/paperwork/SUBMIT_PAPERWORK_RESPONSES';
}

function* fetchAllPaperworkFields({ filters, orderBy }) {
  try {
    const url = getAllPaperworkFieldsUrl(filters, orderBy);
    const response = yield call(apiGetAllPages, { url });
    yield put(receiveAllPaperworkFields(response));
  } catch (e) {
    yield put(getAllPaperworkFieldsError(e));
  }
}

function* refreshPaperworkFields({ paperworkFieldIds }) {
  const validPfIds = (yield select((state) =>
    selectors.getPaperworkFieldsById(state, paperworkFieldIds)
  ))
    .map((pf) => pf?.id)
    .filter((pf) => !!pf);

  if (validPfIds.length === 0) {
    return;
  }

  try {
    const url = getAllPaperworkFieldsUrl({ id: validPfIds });
    const response = yield call(apiGetAllPages, { url });
    yield put(
      refreshPaperworkFieldsSuccess({
        paperworkFields: response?.results ?? [],
      })
    );
  } catch (e) {
    log.error(e);
  }
}

export function* fetchLocationPaperworkFields({
  locationId,
  fieldsType,
  activeOnly,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onComplete = emptyFunction,
  cacheBust = false,
}) {
  try {
    const url = getLocationPaperworkFieldsUrl(locationId, fieldsType, {
      cacheBust,
      ...(activeOnly != null && { activeOnly }),
    });
    const response = yield call(apiGet, url);
    yield put(receiveLocationPaperworkFields(response, fieldsType));
    onSuccess();
  } catch (e) {
    yield put(locationPaperworkFieldsError(e));
    onError();
  } finally {
    onComplete();
  }
}

function* postLocationPaperworkFields({
  locationId,
  postData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmitting(true));

  try {
    const postLocationPaperworkFieldsUrl = getPostLocationPaperworkFieldsUrl(locationId);
    const response = yield call(apiPostJson, postLocationPaperworkFieldsUrl, postData);
    yield put(receiveUpdatedLocationPaperworkField(response.data));
    yield put(updateLocationPaperworkFieldSuccess(true));
    onSuccess();
  } catch (e) {
    yield put(updateLocationPaperworkFieldError(e));
    onError();
  }

  yield put(setSubmitting(false));
}

function* fetchPaperworkData({ bookingId, locationId }) {
  yield put(
    setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_PAPERWORK_DATA, value: true })
  );

  yield call(fetchLocationPaperworkFields, { locationId });

  try {
    const responsesResponse = yield call(apiGet, getPaperworkResponsesUrl(bookingId));
    yield put(receivePaperworkResponse(responsesResponse));
  } catch (e) {
    yield put(paperworkResponseError(e));
  }

  yield put(
    setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_PAPERWORK_DATA, value: false })
  );
}

function* createPaperworkField({
  fieldProps,
  locationsToAssign,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onComplete = emptyFunction,
}) {
  let fireEventHandlers = true;
  if (Array.isArray(fieldProps.response_options)) {
    // endpoint expects a comma delimited string, or null
    // eslint-disable-next-line no-param-reassign
    fieldProps = {
      ...fieldProps,
      response_options: fieldProps.response_options.join(',') || null,
    };
  }
  try {
    const response = yield call(apiPost, getAllPaperworkFieldsUrl(), fieldProps);
    yield put(createPaperworkFieldSuccess(response));
    if (Array.isArray(locationsToAssign) && locationsToAssign.length > 0 && response.id) {
      yield put(
        upsertLocationPaperworkFieldsAction(
          locationsToAssign.map((locationId) => ({
            location_id: locationId,
            paperwork_field_id: response.id,
            active: true,
            is_required_field: false,
          })),
          {
            onSuccess,
            onError,
            onComplete,
          }
        )
      );
      /**
       * We passed along our event handlers to upsertLocationPaperworkFields,
       * so we no longer need to fire them ourselves.
       */
      fireEventHandlers = false;
    }
    if (fireEventHandlers) {
      onSuccess();
    }
  } catch (e) {
    log.error(e);
    if (fireEventHandlers) {
      onError();
    }
  } finally {
    if (fireEventHandlers) {
      onComplete();
    }
  }
}

function* patchPaperworkField({
  fieldId,
  fieldProps,
  locationsToAssign,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onComplete = emptyFunction,
}) {
  let fireEventHandlers = true;
  try {
    const response = yield call(
      apiPatch,
      getPaperworkFieldsV2Url(fieldId),
      omit(fieldProps, ['id'])
    );
    yield put(patchPaperworkFieldSuccess(response));
    if (Array.isArray(locationsToAssign) && locationsToAssign.length > 0 && fieldProps.id) {
      yield put(
        upsertLocationPaperworkFieldsAction(
          locationsToAssign.map((locationId) => ({
            location_id: locationId,
            paperwork_field_id: response.id,
            active: true,
            is_required_field: false,
          })),
          {
            onSuccess,
            onError,
            onComplete,
          }
        )
      );
      /**
       * We passed along our event handlers to upsertLocationPaperworkFields,
       * so we no longer need to fire them ourselves.
       */
      fireEventHandlers = false;
    }
    if (fireEventHandlers) {
      onSuccess();
    }
  } catch (e) {
    log.error(e);
    if (fireEventHandlers) {
      onError();
    }
  } finally {
    if (fireEventHandlers) {
      onComplete();
    }
  }
}

function* softDeletePaperworkField({ id }) {
  if (!id) return;
  try {
    const response = yield call(apiPatch, getPaperworkFieldsV2Url(id), { active: false });
    yield put(softDeletePaperworkFieldSuccess(response));
  } catch (e) {
    log.error(e);
  }
}

/**
 * @param {ReturnType<upsertLocationPaperworkFieldsAction>} action
 */
function* upsertLocationPaperworkFields({
  locationPaperworkFields,
  onSuccess,
  onError,
  onComplete,
}) {
  try {
    const updatedLpfs = yield call(
      apiPost,
      getUpsertManyLocationPaperworkFieldsUrl(),
      locationPaperworkFields
    );
    // Update relevant PFs with new location counts
    yield put(
      refreshPaperworkFieldsAction({
        // eslint-disable-next-line camelcase
        paperworkFieldIds:
          updatedLpfs?.map((lpf) => lpf?.paperwork_field_id).filter((x) => !!x) ?? [],
      })
    );
    onSuccess();
  } catch (e) {
    log.error(e);
    onError();
  } finally {
    onComplete();
  }
}

/**
 * @param {ReturnType<typeof import('../ducks/paperwork').replicatePaperworkOfLocation)>} param0
 */
function* doReplicatePaperworkOfLocation({
  locationId,
  targetLocationIds,
  onSuccess,
  onError,
  onComplete,
}) {
  try {
    yield call(apiPostJson, getReplicateLocationPaperworkUrl(), {
      location_id: locationId,
      target_location_ids: targetLocationIds,
    });
    onSuccess();
  } catch (e) {
    log.error(e);
    onError();
  } finally {
    onComplete();
  }
}

function* submitPaperworkResponses({
  responseFields,
  bookingId,
  paperworkType = PaperworkTypes.REGISTRATION,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onComplete = emptyFunction,
}) {
  try {
    const data = {
      response_fields: responseFields,
      booking_id: bookingId,
      type_: paperworkType,
    };
    yield call(apiPost, getCreatePaperworkResponsesUrl(), data);
    onSuccess();
  } catch (e) {
    log.error(e);
    onError();
  } finally {
    onComplete();
  }
}

export default function* rootSaga() {
  yield takeEvery(PaperworkSagas.FETCH_ALL_PAPERWORK_FIELDS, fetchAllPaperworkFields);
  yield takeEvery(PaperworkSagas.FETCH_LOCATION_PAPERWORK_FIELDS, fetchLocationPaperworkFields);
  yield takeEvery(PaperworkSagas.FETCH_PAPERWORK_DATA, fetchPaperworkData);
  yield takeEvery(PaperworkSagas.POST_LOCATION_PAPERWORK_FIELDS, postLocationPaperworkFields);
  yield takeEvery(PaperworkSagas.CREATE_PAPERWORK_FIELD, createPaperworkField);
  yield takeEvery(PaperworkSagas.PATCH_PAPERWORK_FIELD, patchPaperworkField);
  yield takeEvery(PaperworkSagas.SOFT_DELETE_PAPERWORK_FIELD, softDeletePaperworkField);
  yield takeEvery(PaperworkSagas.UPSERT_LOCATION_PAPERWORK_FIELDS, upsertLocationPaperworkFields);
  yield takeEvery(PaperworkSagas.REFRESH_PAPERWORK_FIELDS, refreshPaperworkFields);
  yield takeEvery(PaperworkSagas.REPLICATE_PAPERWORK_OF_LOCATION, doReplicatePaperworkOfLocation);
  yield takeEvery(PaperworkSagas.SUBMIT_PAPERWORK_RESPONSES, submitPaperworkResponses);
}
