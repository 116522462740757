import { DAPI_HOST } from '../../config';
import { sanitizeDateString } from '../../components/Form/BirthDateInput';
import { apiPostJsonDispatchableWithCallbacks } from './index';
import { patchBookingDispatchable } from './bookings';
import { noOp } from '../../actions';
import { getCurrentPatientInsuranceProfileSuccess } from '../../actions/currentPatient';

export const createInsuranceProfileUrl = () => `${DAPI_HOST}/v1/insurance-profiles/`;

export const getInsuranceProfileUrl = insuranceProfileId =>
  `${DAPI_HOST}/v1/insurance-profiles/${insuranceProfileId}`;

export const updateBookingInsurance = (dispatch, insuranceInfo, bookingInfo) => {
  let birthDate = bookingInfo.birth_date || insuranceInfo.dateOfBirth || null;
  if (birthDate) {
    birthDate = sanitizeDateString(birthDate);
  }

  const insuranceProfileData = {
    account_id: bookingInfo.account_id,
    birth_date: birthDate,
    card_front_id: insuranceInfo.cardFrontId,
    first_name: insuranceInfo.firstName,
    insurer_code: insuranceInfo.insurerCode,
    insurer_name: insuranceInfo.insurerName,
    insurer_type: insuranceInfo.insurerType,
    last_name: insuranceInfo.lastName,
    member_code: insuranceInfo.memberCode,
  };

  const handleNewInsuranceProfileSuccess = response => {
    const patchData = { insurance_profile_id: response.id };
    patchBookingDispatchable(dispatch, bookingInfo.id, patchData, noOp, noOp);
  };

  dispatch(
    apiPostJsonDispatchableWithCallbacks(
      createInsuranceProfileUrl(),
      insuranceProfileData,
      getCurrentPatientInsuranceProfileSuccess,
      noOp,
      handleNewInsuranceProfileSuccess
    )
  );
};
