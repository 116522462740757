import { call, takeEvery, put, all } from 'redux-saga/effects';
import { apiPostJson } from '../core/dapi/index';
import {
  bulkAccountCreationError,
  bulkAccountCreationSuccess,
  clearBulkAccountCreation,
  isCreatingBulkAccounts,
} from '../ducks/bulkClinicAccountActivation';
import { getClinicAccountsUrl } from '../core/dapi/clinicAccounts';

export const BULK_ACCOUNT_CREATION = 'BULK_ACCOUNT_CREATION';
export const BULK_ACCOUNT_CLEAR = 'BULK_ACCOUNT_CLEAR';

const getPostData = (account) => {
  const postData = {
    ...account,
    api_account_id: 1,
  };

  if (account.send_activation_email.toLowerCase() === 'true') {
    postData.should_send_invite_email = true;
  } else {
    postData.should_send_invite_email = false;
  }

  delete postData.send_activation_email;

  if (account.roles.includes('manager')) {
    postData.daily_renew_email_enabled = true;
    postData.weekly_review_email_enabled = true;
  }

  if (account.roles.includes('billing')) {
    postData.payments_email_enabled = true;
  }

  return postData;
};

export function* createClinicAccount({ accounts }) {
  yield put(isCreatingBulkAccounts(true));
  const errors = [];
  const successes = [];
  const responses = yield all(
    accounts.map((account) => {
      const postData = getPostData(account);
      return call(function* () {
        const response = yield call(apiPostJson, getClinicAccountsUrl(), postData);
        return { response, postData };
      });
    })
  );

  responses.forEach(({ response, postData }) => {
    if ('errors' in response) {
      errors.push({ ...postData, errors: response.errors?.[0]?.description });
    } else {
      successes.push(response.data);
    }
  });

  yield put(bulkAccountCreationSuccess(successes));
  yield put(bulkAccountCreationError(errors));
  yield put(isCreatingBulkAccounts(false));
}

export function* clearBulkAccounts() {
  yield put(clearBulkAccountCreation());
}

export default function* rootSaga() {
  yield takeEvery(BULK_ACCOUNT_CREATION, createClinicAccount);
  yield takeEvery(BULK_ACCOUNT_CLEAR, clearBulkAccounts);
}
