import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { showCopySuccessMessage } from '../CopyToClipboard/util';
import { isEmpty } from '../../../core/util/empty';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Icon, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { getLogin } from '../../../selectors/login';

const StyledCopyIcon = styled.img`
  margin: 1px 0 0 3px;
  cursor: pointer;
`;

const StyledInfoIcon = styled(Icon)`
  color: ${colors.greyBae};
  margin-left: 3px;
`;

const StyledEditIcon = styled(Icon)`
  color: ${colors.cherryPie};
  margin-left: 3px;
`;

const StyledLabelContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.notQuiteBlack};
  font-size: 13px;
`;

const Optional = styled.span`
  margin-left: 3px;
  color: ${colors.lanaDelGrey} !important;
`;

const Label = ({
  label,
  showCopyIcon,
  location: propsLocation,
  fieldInfoTooltipDescription,
  copyValue,
  showEditedIcon,
  showIsOptional,
}) => {
  const login = useSelector(getLogin);
  const location = useSelector(state => propsLocation || state.location);
  const shouldShowCopyIcon = showCopyIcon && !isEmpty(copyValue);

  if (shouldShowCopyIcon) {
    return (
      <CopyToClipboard
        text={copyValue}
        onCopy={() => showCopySuccessMessage(label, login, location)}
      >
        <StyledLabelContainer>
          {label}
          {showIsOptional && <Optional>(optional)</Optional>}
          <StyledCopyIcon src="/images/icons/copy.svg" alt="copy" />
          {fieldInfoTooltipDescription && (
            <Tooltip title={fieldInfoTooltipDescription}>
              <StyledInfoIcon type="exclamation-circle-o" />
            </Tooltip>
          )}
          {showEditedIcon && <StyledEditIcon type="edit" />}
        </StyledLabelContainer>
      </CopyToClipboard>
    );
  }

  return (
    <StyledLabelContainer>
      {label}
      {showIsOptional && <Optional>(optional)</Optional>}
      {fieldInfoTooltipDescription && (
        <Tooltip title={fieldInfoTooltipDescription}>
          <StyledInfoIcon type="exclamation-circle-o" />
        </Tooltip>
      )}
      {showEditedIcon && <StyledEditIcon type="edit" />}
    </StyledLabelContainer>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  showCopyIcon: PropTypes.bool,
  location: PropTypes.object,
  fieldInfoTooltipDescription: PropTypes.string,
  copyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showEditedIcon: PropTypes.bool,
  showIsOptional: PropTypes.bool,
};

Label.defaultProps = {
  showEditedIcon: false,
  showIsOptional: false,
};

export default Label;
