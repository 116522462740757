import {
  ADMIN_BOOKINGS_SET_TAB,
  ADMIN_BOOKINGS_ASSIGN,
  ADMIN_BOOKINGS_SEARCH_RESULTS_RECEIVED,
  ADMIN_BOOKINGS_SEARCH_RESULTS_ERROR,
  ADMIN_BOOKINGS_CLEAR_SEARCH_RESULTS,
  ADMIN_BOOKINGS_SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX,
  ADMIN_BOOKINGS_SET_SEARCH_FILTERS,
  ADMIN_CALL_CENTER_SEARCH_PARAMS,
} from '../constants';
import { locationResponseFormatter } from './formatters/location';

export default function adminReducer(state = {}, action) {
  switch (action.type) {
    case ADMIN_BOOKINGS_SET_TAB: {
      return {
        ...state,
        currentTab: action.payload.value,
      };
    }

    case ADMIN_BOOKINGS_ASSIGN: {
      return {
        ...state,
        currentTab: action.payload.tab,
        patient: action.payload.value,
      };
    }

    case ADMIN_BOOKINGS_SEARCH_RESULTS_RECEIVED: {
      return {
        ...state,
        searchResults: action.payload.value.results.map(locationResponseFormatter),
        searchResultsError: null,
        searchResultsFetched: true,
      };
    }

    case ADMIN_BOOKINGS_SEARCH_RESULTS_ERROR: {
      return {
        ...state,
        searchResults: [],
        searchResultsError: action.payload.value,
        searchResultsFetched: true,
      };
    }

    case ADMIN_BOOKINGS_CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: [],
        searchResultsError: null,
        searchResultsFetched: false,
      };
    }

    case ADMIN_BOOKINGS_SET_ASSIGN_LOCATION_MODAL_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: action.payload.value,
      };
    }

    case ADMIN_BOOKINGS_SET_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload.value,
      };
    }

    case ADMIN_CALL_CENTER_SEARCH_PARAMS: {
      return {
        ...state,
        callCenterSearchParams: action.payload.value,
      };
    }

    default:
      return state;
  }
}
