import { call, put, takeEvery } from 'redux-saga/effects';
import {
  providerReceived,
  providersError,
  providersLoading,
  providersReceived,
} from '../../ducks/providers';
import { getProvidersUrl, getProviderUrl } from '../../core/dapi/providers';
import { apiGetAllPages } from './base';
import { apiGet, apiPatch } from '../../core/dapi';

export class ProvidersSagas {
  static FETCH_PROVIDER = 'saga/FETCH_PROVIDER';
  static SEARCH_PROVIDERS = 'saga/SEARCH_PROVIDERS';
  static UPDATE_PROVIDER = 'saga/UPDATE_PROVIDER';
}

export function* fetchProvider({ providerId }) {
  yield put(providersLoading(true));

  try {
    const response = yield call(apiGet, getProviderUrl(providerId));
    yield put(providerReceived(response));
  } catch (e) {
    yield put(providersError(e));
  } finally {
    yield put(providersLoading(false));
  }
}

export function* searchProviders({ searchParams }) {
  yield put(providersLoading(true));

  try {
    const response = yield call(apiGetAllPages, { url: getProvidersUrl(searchParams) });
    yield put(providersReceived(response.results));
  } catch (e) {
    yield put(providersError(e));
  } finally {
    yield put(providersLoading(false));
  }
}

export function* updateProvider({ onSuccess, patchData, providerId }) {
  yield put(providersLoading(true));

  try {
    const response = yield call(apiPatch, getProviderUrl(providerId), patchData);
    yield put(providerReceived(response));
    onSuccess();
  } catch (e) {
    yield put(providersError(e));
  } finally {
    yield put(providersLoading(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(ProvidersSagas.FETCH_PROVIDER, fetchProvider);
  yield takeEvery(ProvidersSagas.SEARCH_PROVIDERS, searchProviders);
  yield takeEvery(ProvidersSagas.UPDATE_PROVIDER, updateProvider);
}
