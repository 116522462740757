import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet, apiPatch, apiPost } from '../../core/dapi';
import {
  createServiceLocationUrl,
  getServiceLocationsUrl,
  updateServiceLocationUrl,
} from '../../core/dapi/serviceLocations';
import {
  serviceLocationsCreated,
  serviceLocationsCreating,
  serviceLocationsError,
  serviceLocationsLoading,
  serviceLocationsReceived,
  serviceLocationUpdated,
  serviceLocationUpdating,
} from '../../ducks/serviceLocations';

export class ServiceLocationsSagas {
  static CREATE_SERVICE_LOCATION = 'saga/CREATE_SERVICE_LOCATION';
  static FETCH_SERVICE_LOCATIONS = 'saga/FETCH_SERVICE_LOCATIONS';
  static UPDATE_SERVICE_LOCATION = 'saga/UPDATE_SERVICE_LOCATION';
}

export function* createServiceLocation({ onSuccess, postData }) {
  yield put(serviceLocationsCreating(true));

  try {
    const response = yield call(apiPost, createServiceLocationUrl(), postData);
    yield put(serviceLocationsCreated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(serviceLocationsError(e));
  } finally {
    yield put(serviceLocationsCreating(false));
  }
}

export function* fetchServiceLocations({ locationId }) {
  yield put(serviceLocationsLoading(true));

  try {
    const response = yield call(apiGet, getServiceLocationsUrl(locationId));
    yield put(serviceLocationsReceived(response));
  } catch (e) {
    yield put(serviceLocationsError(e));
  } finally {
    yield put(serviceLocationsLoading(false));
  }
}

export function* updateServiceLocation({ onSuccess, patchData, serviceLocationId }) {
  yield put(serviceLocationUpdating(true));

  try {
    const response = yield call(apiPatch, updateServiceLocationUrl(serviceLocationId), patchData);
    yield put(serviceLocationUpdated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(serviceLocationsError(e));
  } finally {
    yield put(serviceLocationUpdating(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(ServiceLocationsSagas.CREATE_SERVICE_LOCATION, createServiceLocation);
  yield takeEvery(ServiceLocationsSagas.FETCH_SERVICE_LOCATIONS, fetchServiceLocations);
  yield takeEvery(ServiceLocationsSagas.UPDATE_SERVICE_LOCATION, updateServiceLocation);
}
