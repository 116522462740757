import { isAnalyticsEnabled } from './coreAnalyticsTrackEvent';
import Cookies from 'js-cookie';
import { AJS_USER_ID_COOKIE_NAME } from '../../config';

export const analyticsReset = () => {
  if (isAnalyticsEnabled()) {
    const ajsUserId = Cookies.get(AJS_USER_ID_COOKIE_NAME);
    if (ajsUserId) {
      // subsequent "identify" calls will merge the adjacent identities unless reset is called
      window.analytics?.reset?.();
      window.mixpanel?.reset?.(); // Segment `reset` does not reset Mixpanel, so we need to call it directly
    }
  }
};
