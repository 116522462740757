import { DAPI_CALENDAR_DATE_RANGE_FILTER_FORMAT, DAPI_DATE_RANGE_FILTER_FORMAT } from './index';
import { log } from '../logger/log';
import * as Sentry from '@sentry/nextjs';

const handleErrors = (dispatch, errors, onErrorAction, onErrorCallback = null) => {
  if (onErrorCallback) {
    onErrorCallback();
  }

  return dispatch(onErrorAction(errors[0].description));
};

const handleNoDataObject = (dispatch, onErrorAction, onErrorCallback = null) => {
  if (onErrorCallback) {
    onErrorCallback();
  }

  return dispatch(onErrorAction('Invalid response: no data.'));
};

export const onValidResponse = (json, dispatch, onSuccess, onError) => {
  if (json && json.errors) {
    return handleErrors(dispatch, json.errors, onError);
  }

  if (json && !json.data) {
    return handleNoDataObject(dispatch, onError);
  }

  return dispatch(onSuccess(json.data));
};

export const handleResponse = (json) => {
  if (json && json.errors) {
    const error = new Error(json.errors[0].description, { cause: json.errors[0] });
    Sentry.captureException(error, { extra: { cause: json.errors[0] } });
    throw error;
  }

  if (json && !json.data) {
    throw new Error('No data');
  }

  return json.data;
};

export const onValidResponseFull = (json, dispatch, onSuccess, onError) => {
  if (json && json.errors && json.errors.length) {
    return dispatch(onError(json.errors[0].description));
  }

  if (!json) {
    return dispatch(onError('Invalid response.'));
  }

  return dispatch(onSuccess(json));
};

export const onValidResponseWithCallbacks = (
  json,
  dispatch,
  onSuccessAction,
  onErrorAction,
  onSuccessCallback,
  onErrorCallback
) => {
  if (json && json.errors) {
    return handleErrors(dispatch, json.errors, onErrorAction, onErrorCallback);
  }

  if (json && !json.data) {
    return handleNoDataObject(dispatch, onErrorAction, onErrorCallback);
  }

  onSuccessCallback(json.data);
  return dispatch(onSuccessAction(json.data));
};

export const onException = (e, dispatch, onError) => {
  log.error(e);
  dispatch(onError(e.message));
};

export const onExceptionWithCallback = (e, dispatch, onErrorAction, onErrorCallback) => {
  onErrorCallback();
  onException(e, dispatch, onErrorAction);
};

export const getTimestampRangeParam = (date, type, format = DAPI_DATE_RANGE_FILTER_FORMAT) => {
  if (type === 'from') {
    return date.utc().format(format);
  } else if (type === 'to') {
    return date.utc().format(format);
  }
  return null;
};

export const getCalendarDateRangeParam = (
  date,
  type,
  format = DAPI_CALENDAR_DATE_RANGE_FILTER_FORMAT
) => {
  if (type === 'from') {
    return date.format(format);
  } else if (type === 'to') {
    return date.format(format);
  }
  return null;
};
