import {
  INSURANCE_FORM_SUBMISSION,
  INSURANCE_FORM_SUBMITTED,
  INSURANCE_FORM_NOT_SUBMITTED,
  INSURANCE_OCR_DATA,
  INSURANCE_AUTOCOMPLETE,
  INSURANCE_CHECK_SET_REQUEST,
  INSURANCE_CHECK_SUBMITTING,
  INSURANCE_CHECK_RESPONSE_RECEIVED,
  INSURANCE_CHECK_ERROR,
  INSURANCE_TOGGLE_TREE,
  INSURANCE_RESET_FORM,
} from '../constants';

const insuranceFormSubmitted = value => ({
  type: INSURANCE_FORM_SUBMITTED,
  payload: { value },
});

const insuranceFormNotSubmitted = value => ({
  type: INSURANCE_FORM_NOT_SUBMITTED,
  payload: { value },
});

const insuranceFormSubmission = value => ({
  type: INSURANCE_FORM_SUBMISSION,
  payload: { value },
});

const insuranceOcrData = value => ({
  type: INSURANCE_OCR_DATA,
  payload: { value },
});

const insuranceAutocomplete = value => ({
  type: INSURANCE_AUTOCOMPLETE,
  payload: { value },
});

const insuranceCheckSetRequestData = value => ({
  type: INSURANCE_CHECK_SET_REQUEST,
  payload: { value },
});

const insuranceCheckSubmitting = value => ({
  type: INSURANCE_CHECK_SUBMITTING,
  payload: { value },
});

const insuranceCheckResponseReceived = value => ({
  type: INSURANCE_CHECK_RESPONSE_RECEIVED,
  payload: { value },
});

const insuranceCheckError = value => ({
  type: INSURANCE_CHECK_ERROR,
  payload: { value },
});

const insuranceToggleTree = value => ({
  type: INSURANCE_TOGGLE_TREE,
  payload: { value },
});

const insuranceResetForm = value => ({
  type: INSURANCE_RESET_FORM,
  payload: { value },
});

export {
  insuranceFormSubmitted,
  insuranceFormNotSubmitted,
  insuranceFormSubmission,
  insuranceOcrData,
  insuranceAutocomplete,
  insuranceCheckSetRequestData,
  insuranceCheckSubmitting,
  insuranceCheckResponseReceived,
  insuranceCheckError,
  insuranceToggleTree,
  insuranceResetForm,
};
