import MobileDetect from 'mobile-detect';

export const getUserAgent = () =>
  typeof global.navigator === 'undefined' ? '' : global.navigator.userAgent;

export const getOs = () => {
  const ua = getUserAgent();
  let uaindex;
  let userOS = 'unknown';
  let userOSver = 'unknown';

  // determine OS
  if (ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/iPhone/i)) {
    userOS = 'iOS';
    uaindex = ua.indexOf('OS ');
  } else if (ua.match(/Android/i)) {
    userOS = 'Android';
    uaindex = ua.indexOf('Android ');
  }

  // determine version
  if (userOS === 'iOS' && uaindex > -1) {
    userOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
  } else if (userOS === 'Android' && uaindex > -1) {
    userOSver = ua.substr(uaindex + 8, 3);
  }

  return { userOS, userOSver };
};

// SMS protocol are different between Android and iOS, and
// even between different iOS versions. Reference:
// http://stackoverflow.com/questions/39529099/trying-to-send-sms-in-ios-10-is-sms-protocol-broken
export const getSmsSeparator = () => {
  const { userOS, userOSver } = getOs();
  let separator = '?';
  if (userOS === 'iOS' && userOSver < 7) {
    separator = ';';
  } else if (userOS === 'iOS' && userOSver >= 7) {
    separator = '&';
  }

  return separator;
};

export const isPhone = () => (getUserAgent() ? new MobileDetect(getUserAgent()).phone() : false);

/**
 * Better type checking for the isPhone function.
 * returns a boolean value if it can find the user agent, and undefined if it can't
 */
export const isPhoneOrUndefined = () =>
  getUserAgent() ? Boolean(new MobileDetect(getUserAgent()).mobile()) : undefined;

export const isTablet = () => (getUserAgent() ? new MobileDetect(getUserAgent()).tablet() : false);

export const isMobile = () => {
  if (isPhoneOrUndefined() && !isTablet()) {
    return true;
  }

  if (isPhoneOrUndefined() === false || isTablet()) {
    return false;
  }

  return null;
};

export const isAndroidOS = () =>
  getUserAgent() ? new MobileDetect(getUserAgent()).is('AndroidOS') : false;

export const isIOS = () => (getUserAgent() ? new MobileDetect(getUserAgent()).is('iOS') : false);

export const getPhoneNoun = () => (isIOS() ? 'iPhone' : 'phone');

export const isIE = () => {
  const ua = getUserAgent();
  const msie = ua.indexOf('MSIE ');

  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};
