import { produce } from 'immer';
import {
  SETTINGS_CHANGE_TAB,
  SETTINGS_LOCATIONS_ERROR,
  SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM,
  SETTINGS_RECEIVE_LOCATIONS,
  SETTINGS_FETCHING_LOCATIONS_GROUPS,
  SETTINGS_RECEIVE_LOCATIONS_GROUPS,
  SETTINGS_ADD_LOCATIONS_GROUPS,
  SETTINGS_RESET_LOCATIONS_GROUPS,
  SETTINGS_SPECIAL_HOURS_SUCCESS,
  SETTINGS_SPECIAL_HOURS_ERROR,
  SETTINGS_RECEIVE_UPCOMING,
  SETTINGS_UPCOMING_ERROR,
  SETTINGS_UPDATE_LOCATION_SUCCESS,
  SETTINGS_UPDATE_LOCATION_ERROR,
  SETTINGS_TAB_LOCATIONS,
  SETTINGS_RESERVATION_HOURS_SUCCESS,
  SETTINGS_RESERVATION_HOURS_ERROR,
  SETTINGS_RECEIVE_RESERVATION_HOURS,
  SETTINGS_RECEIVE_RESERVATION_HOURS_ERROR,
  SETTINGS_UPDATE_PAYMENTS_ENABLED,
  SETTINGS_UPDATE_AUTORESEND_ENABLED,
  SETTINGS_UPDATE_POS_ENABLED,
  SETTINGS_PAYMENTS_ENABLED_SUCCESS,
  SETTINGS_PAYMENTS_ENABLED_ERROR,
  SETTINGS_POS_ENABLED_SUCCESS,
  SETTINGS_POS_ENABLED_ERROR,
  SETTINGS_FETCH_SOLVPAY_STATUS,
  SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS,
  SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_ERROR,
  SETTINGS_CREATE_NEW_ACCOUNT_FETCH_ERROR,
  SETTINGS_RESET_CREATE_ERROR_STATE,
  SETTINGS_FETCH_SERVICES_LIST_SUCCESS,
  SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_SUCCESS,
  SETTINGS_CLEAR_LOCATION_RESULTS,
  SETTINGS_EMPLOYEE_SEARCH_RETURNS_EMPTY,
  SETTINGS_EMPLOYEE_SEARCH_RESET,
  SETTINGS_FETCH_OPERATING_HOURS_SUCCESS,
  SETTINGS_FETCH_OPERATING_HOURS_ERROR,
  SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_ERROR,
  SETTINGS_FETCH_SERVICES_LIST_ERROR,
  SETTINGS_PATCH_OPERATING_HOURS_SUCCESS,
  SETTINGS_PATCH_OPERATING_HOURS_ERROR,
  SETTINGS_ADDING_LOCATION,
  SETTINGS_ADD_LOCATION_SUCCESS,
  SETTINGS_ADD_LOCATION_ERROR,
  SETTINGS_CLEAR_ADD_LOCATION_ERROR,
  SETTINGS_RESERVATION_HOURS_FETCHING,
  SETTINGS_RESERVATION_HOURS_SUBMITTING,
  SETTINGS_SUBMITTING,
  SETTINGS_AVAILABILITY_RECOMMENDATIONS_FETCHING,
  SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS,
  SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS_ERROR,
  SETTINGS_RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_RECEIVE_LAB_TEST_CONFIG,
  SETTINGS_RECEIVE_LAB_TEST_CONFIG_DEFAULT,
  SETTINGS_RECEIVE_PRACTICES,
  SETTINGS_RECEIVE_GROUPS,
  SETTINGS_GROUPS_ERROR,
  SETTINGS_GROUPS_LOADING,
  SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS,
  SETTINGS_SET_SELECTED_PRACTICE_ID,
  SETTINGS_RECEIVE_PRACTICE,
  SETTINGS_PRACTICES_ERROR,
  SETTINGS_PRACTICES_LOADING,
  SETTINGS_PROVIDER_GROUP_LOCATIONS_ERROR,
  SETTINGS_PROVIDER_GROUP_LOCATIONS_LOADING,
  SETTINGS_SET_SEARCH_FILTERS,
  SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED,
  SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED,
  SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM,
} from '../constants';
import { isEmptyObject, safeGet } from '../core/util/object';
import { EMPTY_ARRAY, isEmptyArray, mergeArraysOfObjects } from '../core/util/array';

const editPaymentsTabInitialState = {
  enablePos: { success: [], error: [], isEnabled: null },
  enablePayments: { success: [], error: [], isEnabled: null },
  enableAutoResend: { success: [], error: [], isEnabled: null },
  enablePaymentsDuringBooking: { success: [], error: [], isEnabled: null },
  requirePaymentsDuringBooking: { success: [], error: [], isEnabled: null },
  fetchError: [],
  createNewAccount: { success: [], error: [], fetchError: [] },
};

const createInitialState = () => ({
  currentTab: SETTINGS_TAB_LOCATIONS,
  reservationHours: { error: null, hours: [], isLoading: true },
  operatingHours: { error: null, operatingHours: [] },
  editPaymentsTab: editPaymentsTabInitialState,
  createEmployeeSearch: null,
  upcoming: EMPTY_ARRAY,
  practiceSettings: {},
});

const handleSpecialHoursSuccess = (payload, locations) => {
  const newSpecialHours = payload.value;
  const locationsCopy = locations.slice();

  locationsCopy.forEach((location, i) => {
    if (location.id === newSpecialHours.location_id) {
      if (payload.event === 'create') {
        locationsCopy[i].special_operating_hours = locationsCopy[i].special_operating_hours.concat([
          newSpecialHours,
        ]);
      } else if (payload.event === 'update') {
        locationsCopy[i].special_operating_hours = locationsCopy[i].special_operating_hours
          .filter(
            (hours) =>
              hours.operating_hours_special_id !== newSpecialHours.operating_hours_special_id
          )
          .concat([newSpecialHours]);
      }
    }
  });

  return locationsCopy;
};

const handleUpdateLocationSuccess = (payload, locations) => {
  const updatedLocation = payload.value;
  const locationsCopy = locations.slice();

  locationsCopy.forEach((location, i) => {
    if (location.id === updatedLocation.id) {
      locationsCopy[i] = { ...updatedLocation };
    }
  });

  return locationsCopy;
};

export default (state = createInitialState(), action) => {
  switch (action.type) {
    case SETTINGS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload.value,
      };
    case SETTINGS_CHANGE_TAB:
      return {
        ...state,
        currentTab: action.payload.value,
      };
    case SETTINGS_LOCATIONS_ERROR:
      return {
        ...state,
        locations: {
          error: action.payload.value,
        },
      };

    case SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM:
      return {
        ...state,
        locationsForGroupEditForm: action.payload.value.results,
      };

    case SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM:
      return { ...state, nonPartnerLocationsForGroupEditForm: action.payload.value.results };

    case SETTINGS_RECEIVE_LOCATIONS: {
      const results = state.locations ? state.locations.results : [] || [];

      return {
        ...state,
        locations: {
          results: mergeArraysOfObjects(results, action.payload.value.results, 'id'),
        },
      };
    }
    case SETTINGS_CLEAR_LOCATION_RESULTS:
      return {
        ...state,
        locations: {
          results: [],
        },
        editPaymentsTab: editPaymentsTabInitialState,
      };
    case SETTINGS_FETCHING_LOCATIONS_GROUPS:
      return {
        ...state,
        locationsGroups: {
          ...state.locationsGroups,
          fetching: true,
        },
      };
    case SETTINGS_ADD_LOCATIONS_GROUPS:
    case SETTINGS_RECEIVE_LOCATIONS_GROUPS:
      return {
        ...state,
        locationsGroups: {
          results: action.payload.value.results,
          fetching: false,
        },
      };
    case SETTINGS_RESET_LOCATIONS_GROUPS:
      return {
        ...state,
        locationsGroups: [],
      };
    case SETTINGS_SPECIAL_HOURS_SUCCESS: {
      const newState = {
        ...state,
        specialHours: {
          error: null,
        },
      };

      const safeState = safeGet(state);

      if (safeState('locations.results')) {
        newState.locations = {
          results: handleSpecialHoursSuccess(action.payload, state.locations.results),
        };
      }

      return newState;
    }
    case SETTINGS_SPECIAL_HOURS_ERROR:
      return {
        ...state,
        specialHours: {
          error: action.payload.value,
        },
      };
    case SETTINGS_RECEIVE_UPCOMING: {
      const newState = {
        ...state,
        upcoming: action.payload.value.results,
      };

      if (!isEmptyObject(state.locations) && !isEmptyArray(state.locations.results)) {
        const results = [...state.locations.results];
        results.forEach((location, i) => {
          if (location.id === action.payload.locationId) {
            results[i].special_operating_hours = action.payload.value.results;
          }
        });

        newState.locations = { results };
      }

      return newState;
    }

    case SETTINGS_UPCOMING_ERROR:
      return {
        ...state,
        upcoming: [],
      };

    case SETTINGS_RESERVATION_HOURS_FETCHING:
      return {
        ...state,
        reservationHours: {
          ...state.reservationHours,
          isLoading: action.payload.value,
        },
      };

    case SETTINGS_RESERVATION_HOURS_SUBMITTING:
      return {
        ...state,
        reservationHours: {
          ...state.reservationHours,
          isSubmitting: action.payload.value,
        },
      };

    case SETTINGS_RESERVATION_HOURS_SUCCESS: {
      const newState = { ...state };
      const newReservationHours = { ...newState.reservationHours };
      const [...newReservationHoursArray] = newState.reservationHours.reservationHours;

      const updatedDay = newReservationHoursArray.find(
        (hours) => hours.week_day === action.payload.value.week_day
      );
      Object.assign(updatedDay, action.payload.value);

      newReservationHours.reservationHours = newReservationHoursArray;
      newState.reservationHours = newReservationHours;
      return newState;
    }

    case SETTINGS_RESERVATION_HOURS_ERROR:
      return {
        ...state,
        reservationHours: {
          error: action.payload.value,
        },
      };

    case SETTINGS_RECEIVE_RESERVATION_HOURS: {
      return {
        ...state,
        reservationHours: {
          ...state.reservationHours,
          hours: action.payload.value.results,
        },
      };
    }

    case SETTINGS_RECEIVE_RESERVATION_HOURS_ERROR:
      return {
        ...state,
        reservationHours: {
          error: action.payload.value,
          hours: [],
        },
      };

    case SETTINGS_AVAILABILITY_RECOMMENDATIONS_FETCHING:
      return {
        ...state,
        availabilityRecommendations: {
          ...state.availabilityRecommendations,
          loading: action.payload.value,
        },
      };

    case SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        availabilityRecommendations: action.payload.value,
      };

    case SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS_ERROR:
      return {
        ...state,
        availabilityRecommendations: {
          ...state.availabilityRecommendations,
          error: action.payload.value,
        },
      };

    case SETTINGS_FETCH_OPERATING_HOURS_SUCCESS:
      return {
        ...state,
        operatingHours: {
          hours: action.payload.value.hours,
        },
      };

    case SETTINGS_FETCH_OPERATING_HOURS_ERROR:
      return {
        ...state,
        operatingHours: {
          error: action.payload.value,
          hours: [],
        },
      };

    case SETTINGS_UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: {
          editLocationError: null,
          results: handleUpdateLocationSuccess(action.payload, state.locations.results),
        },
      };

    case SETTINGS_UPDATE_LOCATION_ERROR:
      return {
        ...state,
        locations: {
          editLocationError: action.payload.value,
          results: [...state.locations.results],
        },
      };

    case SETTINGS_UPDATE_PAYMENTS_ENABLED:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePayments: {
            ...state.editPaymentsTab.enablePayments,
            isEnabled: action.payload.value,
          },
        },
      };

    case SETTINGS_UPDATE_AUTORESEND_ENABLED:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enableAutoResend: {
            ...state.editPaymentsTab.enableAutoResend,
            isEnabled: action.payload.value,
          },
        },
      };

    case SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED:
      return produce(state, (draft) => {
        Object.assign(draft.editPaymentsTab.enablePaymentsDuringBooking, {
          isEnabled: action.payload.value,
        });
      });

    case SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED:
      return produce(state, (draft) => {
        Object.assign(draft.editPaymentsTab.requirePaymentsDuringBooking, {
          isEnabled: action.payload.value,
        });
      });

    case SETTINGS_UPDATE_POS_ENABLED:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePos: {
            ...state.editPaymentsTab.enablePos,
            isEnabled: action.payload.value,
          },
        },
      };

    case SETTINGS_PAYMENTS_ENABLED_SUCCESS:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePayments: {
            ...state.editPaymentsTab.enablePayments,
            success: [action.payload.value],
          },
        },
      };

    case SETTINGS_PAYMENTS_ENABLED_ERROR:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePayments: {
            ...state.editPaymentsTab.enablePayments,
            error: [action.payload.value],
          },
        },
      };

    case SETTINGS_POS_ENABLED_SUCCESS:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePos: {
            ...state.editPaymentsTab.enablePos,
            success: [action.payload.value],
          },
        },
      };

    case SETTINGS_POS_ENABLED_ERROR:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePos: {
            ...state.editPaymentsTab.enablePos,
            error: [action.payload.value],
          },
        },
      };

    case SETTINGS_FETCH_SOLVPAY_STATUS:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          enablePos: {
            ...state.editPaymentsTab.enablePos,
            fetchError: [action.payload.value],
          },
        },
      };

    case SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          createNewAccount: {
            ...state.editPaymentsTab.createNewAccount,
            success: [action.payload.value],
          },
        },
      };

    case SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_ERROR:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          createNewAccount: {
            ...state.editPaymentsTab.createNewAccount,
            error: [action.payload.value],
          },
        },
      };

    case SETTINGS_CREATE_NEW_ACCOUNT_FETCH_ERROR:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          createNewAccount: {
            ...state.editPaymentsTab.createNewAccount,
            fetchError: [action.payload.value],
          },
        },
      };

    case SETTINGS_RESET_CREATE_ERROR_STATE:
      return {
        ...state,
        editPaymentsTab: {
          ...state.editPaymentsTab,
          createNewAccount: {
            ...state.editPaymentsTab.createNewAccount,
            error: [],
            fetchError: [],
            state: [],
          },
        },
      };

    case SETTINGS_FETCH_SERVICES_LIST_SUCCESS:
      return {
        ...state,
        services: action.payload.value,
      };

    case SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_SUCCESS:
      return {
        ...state,
        paymentLocations: action.payload.value,
      };

    case SETTINGS_EMPLOYEE_SEARCH_RETURNS_EMPTY:
      return {
        ...state,
        createEmployeeSearch: action.payload.value,
      };

    case SETTINGS_EMPLOYEE_SEARCH_RESET:
      return {
        ...state,
        createEmployeeSearch: null,
      };

    case SETTINGS_FETCH_SERVICES_LIST_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };

    case SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };

    case SETTINGS_PATCH_OPERATING_HOURS_SUCCESS:
      return {
        ...state,
        operatingHours: {
          ...state.operatingHours,
          success: action.payload.value,
        },
      };

    case SETTINGS_PATCH_OPERATING_HOURS_ERROR:
      return {
        ...state,
        operatingHours: {
          ...state.operatingHours,
          error: action.payload.value,
        },
      };

    case SETTINGS_ADDING_LOCATION:
      return {
        ...state,
        addLocation: {
          submitting: true,
        },
      };

    case SETTINGS_ADD_LOCATION_SUCCESS:
      return {
        ...state,
        addLocation: {
          submitting: false,
          location: action.payload.value,
        },
      };

    case SETTINGS_ADD_LOCATION_ERROR:
      return {
        ...state,
        addLocation: {
          submitting: false,
          error: action.payload.value,
        },
      };

    case SETTINGS_CLEAR_ADD_LOCATION_ERROR:
      return {
        ...state,
        addLocation: {
          error: null,
        },
      };

    case SETTINGS_FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP:
      return {
        ...state,
        physicalLocationsInGroup: {
          loading: true,
        },
      };

    case SETTINGS_FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP:
      return {
        ...state,
        telemedLocationsInGroup: {
          loading: true,
        },
      };

    case SETTINGS_RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP:
      return {
        ...state,
        physicalLocationsInGroup: {
          loading: false,
          locations: action.payload.value.results,
        },
      };

    case SETTINGS_RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP:
      return {
        ...state,
        telemedLocationsInGroup: {
          loading: false,
          locations: action.payload.value.results,
        },
      };

    case SETTINGS_RECEIVE_LAB_TEST_CONFIG:
      return {
        ...state,
        labTestConfig: action.payload.value.results,
      };

    case SETTINGS_RECEIVE_LAB_TEST_CONFIG_DEFAULT:
      return {
        ...state,
        labTestConfigDefaults: {
          ...state.labTestConfigDefaults,
          [action.payload.serviceId]: action.payload.value,
        },
      };

    case SETTINGS_RECEIVE_GROUPS: {
      return produce(state, (draft) => {
        const groupsById = action.payload.value.results.reduce(
          (groups, group) => ({
            ...groups,
            [group.id]: group,
          }),
          {}
        );
        Object.assign(draft.practiceSettings, { groups: groupsById });
      });
    }

    case SETTINGS_GROUPS_LOADING: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { groupsLoading: action.payload.value });
      });
    }

    case SETTINGS_GROUPS_ERROR: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { groupsError: action.payload.value });
      });
    }

    case SETTINGS_RECEIVE_PRACTICE: {
      return produce(state, (draft) => {
        const practiceId = action.payload.value.id;
        Object.assign(draft.practiceSettings?.practices?.results, {
          [practiceId]: action.payload.value,
        });
      });
    }

    case SETTINGS_RECEIVE_PRACTICES: {
      return produce(state, (draft) => {
        const practicesById = action.payload.value.results.reduce(
          (practices, practice) => ({
            ...practices,
            [practice.id]: practice,
          }),
          {}
        );
        Object.assign(draft.practiceSettings, {
          practices: {
            results: practicesById,
            page: action.payload.value.page,
          },
        });
      });
    }

    case SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { locations: action.payload.value.results });
      });
    }

    case SETTINGS_SET_SELECTED_PRACTICE_ID: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { selectedPracticeId: action.payload.value });
      });
    }

    case SETTINGS_PROVIDER_GROUP_LOCATIONS_ERROR: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { locationsError: action.payload.value });
      });
    }

    case SETTINGS_PROVIDER_GROUP_LOCATIONS_LOADING: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { locationsLoading: action.payload.value });
      });
    }

    case SETTINGS_PRACTICES_LOADING: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { practicesLoading: action.payload.value });
      });
    }

    case SETTINGS_PRACTICES_ERROR: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { practicesError: action.payload.value });
      });
    }

    case SETTINGS_SET_SEARCH_FILTERS: {
      return produce(state, (draft) => {
        Object.assign(draft.practiceSettings, { searchFilters: action.payload.value });
      });
    }

    default:
      return state;
  }
};
