import React from 'react';
import styled from 'styled-components';
import { Checkbox, Col, Row } from 'antd';
import { colors } from '../../../../constants/colors';
import { EMPTY_ARRAY, isEmptyArray } from '../../../../core/util/array';
import { array, bool, func, number } from 'prop-types';
import SelectedCountLabel from './SelectedCountLabel';

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px !important;

  .ant-checkbox::hover {
    border-color: ${colors.solvPink} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.solvPink} !important;
    border-color: ${colors.solvPink} !important;

    &::after {
      background-color: ${colors.solvPink} !important;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    &::after {
      background-color: ${colors.solvPink} !important;
    }
  }
`;

const SelectAll = (props) => {
  const {
    disabledOptions,
    getValue,
    handleChange,
    isMultipleAllowed,
    limit,
    options,
    selectedOptions: globalSelected,
    shouldShowLimitText,
    showSelectAll,
  } = props;

  const selectedOptions = options
    .filter((option) => globalSelected.includes(option.id || option.location_id))
    .map((filteredOption) => filteredOption.id || filteredOption.location_id);

  const isIndeterminate = () =>
    !isEmptyArray(selectedOptions) &&
    !isEmptyArray(options) &&
    selectedOptions.length !== options.length;

  const isAllChecked = () =>
    !isEmptyArray(selectedOptions) &&
    !isEmptyArray(options) &&
    selectedOptions.length === options.length;

  const getSelectAllCheckboxLabel = () => (isAllChecked() ? 'Clear all' : 'Select all');

  const onCheckAllChange = (e) =>
    handleChange(e.target.checked ? options.map((option) => getValue(option)) : EMPTY_ARRAY);

  const totalOptionCount = selectedOptions.filter((f) => !disabledOptions.includes(f));

  return (
    <Col>
      <Row align="middle" type="flex">
        {showSelectAll && isMultipleAllowed && options && options.length <= limit ? (
          <StyledCheckbox
            checked={isAllChecked()}
            indeterminate={isIndeterminate()}
            onChange={onCheckAllChange}
          >
            <span>
              {getSelectAllCheckboxLabel()}
              {' / '}
              <SelectedCountLabel
                limit={limit}
                selectedOptions={selectedOptions}
                shouldShowLimitText={shouldShowLimitText}
                totalOptionCount={totalOptionCount}
              />
            </span>
          </StyledCheckbox>
        ) : (
          <span>
            <SelectedCountLabel
              limit={limit}
              selectedOptions={selectedOptions}
              shouldShowLimitText={shouldShowLimitText}
              totalOptionCount={totalOptionCount}
            />
          </span>
        )}
      </Row>
    </Col>
  );
};

SelectAll.propTypes = {
  disabledOptions: array,
  getValue: func.isRequired,
  handleChange: func.isRequired,
  isMultipleAllowed: bool,
  limit: number,
  options: array,
  selectedOptions: array,
  shouldShowLimitText: bool,
  showSelectAll: bool,
};

export default SelectAll;
