import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: string) {
      return Promise.resolve();
    },
  };
};

const blacklist = [
  'bookings',
  'clinicAccounts',
  'form',
  'location',
  'handoffs',
  'runtime',
  'settings',
  'waitTime',
  'conversations',
  'imageData',
  'newVersion',
  'lookUp',
  'locations',
  'currentPatient',
];

const storage = typeof window !== 'undefined' ? createWebStorage('session') : createNoopStorage();

export const reduxPersistConfig = {
  key: 'root',
  storage,
  blacklist,
};
