import { isEmptyArray } from '../util/array';

class RequestManager {
  static isBlocked = false;
  static queue = [];

  addQueueControlLogicToRequestFunc = (requestFunc, isRequestBlocking, resolve, reject) => () => {
    requestFunc()
      .then(result => {
        if (isRequestBlocking) {
          this.unBlock();
        }

        return resolve(result);
      })
      .catch(result => {
        if (isRequestBlocking) {
          this.unBlock();
        }

        return reject(result);
      });

    if (isRequestBlocking) {
      RequestManager.isBlocked = true;
    } else {
      this.runNext();
    }
  };

  enqueue = func => RequestManager.queue.push(func);

  makeRequest = (requestFunc, isRequestBlocking = false) =>
    new Promise((resolve, reject) => {
      const wrappedFunc = this.addQueueControlLogicToRequestFunc(
        requestFunc,
        isRequestBlocking,
        resolve,
        reject
      );

      if (RequestManager.isBlocked) {
        this.enqueue(wrappedFunc);
      } else {
        wrappedFunc();
      }
    });

  runNext = () => {
    if (!isEmptyArray(RequestManager.queue)) {
      const nextRequest = RequestManager.queue.shift();
      nextRequest();
    }
  };

  unBlock = () => {
    RequestManager.isBlocked = false;
    this.runNext();
  };
}

const instance = new RequestManager();

export default instance;
