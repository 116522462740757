import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';
import { isEmptyObject } from '../util/object';

const servicesListLimit = 100;

const getServicesUrl = specialtiesFilters => {
  let url = `${DAPI_HOST}/v1/services?limit=${servicesListLimit}`;

  if (!isEmptyObject(specialtiesFilters)) {
    url += `&${getFilterString('specialties', specialtiesFilters)}`;
  }

  return url;
};

export { getServicesUrl };
