import { call, put, takeEvery } from 'redux-saga/effects';
import { getPackagesUrl } from '../../core/dapi/packages';
import { apiGet } from '../../core/dapi';
import { packagesError, packagesReceived } from '../../ducks/packages';

export class PackagesSagas {
  static FETCH_PACKAGES = 'saga/FETCH_PACKAGES';
}

export function* fetchPackages({ onError }) {
  try {
    const response = yield call(apiGet, getPackagesUrl());
    yield put(packagesReceived(response));
  } catch (e) {
    yield put(packagesError(e));
    onError();
  }
}

export default function* rootSaga() {
  yield takeEvery(PackagesSagas.FETCH_PACKAGES, fetchPackages);
}
