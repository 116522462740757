import {
  SETTINGS_CHANGE_TAB,
  SETTINGS_RECEIVE_LOCATIONS,
  SETTINGS_FETCHING_LOCATIONS_GROUPS,
  SETTINGS_RECEIVE_LOCATIONS_GROUPS,
  SETTINGS_ADD_LOCATIONS_GROUPS,
  SETTINGS_RESET_LOCATIONS_GROUPS,
  SETTINGS_LOCATIONS_ERROR,
  SETTINGS_SPECIAL_HOURS_SUCCESS,
  SETTINGS_SPECIAL_HOURS_ERROR,
  SETTINGS_RECEIVE_UPCOMING,
  SETTINGS_UPCOMING_ERROR,
  SETTINGS_UPDATE_LOCATION_SUCCESS,
  SETTINGS_UPDATE_LOCATION_ERROR,
  SETTINGS_RESERVATION_HOURS_ERROR,
  SETTINGS_RECEIVE_RESERVATION_HOURS,
  SETTINGS_RECEIVE_RESERVATION_HOURS_ERROR,
  SETTINGS_UPDATE_PAYMENTS_ENABLED,
  SETTINGS_UPDATE_POS_ENABLED,
  SETTINGS_PAYMENTS_ENABLED_SUCCESS,
  SETTINGS_PAYMENTS_ENABLED_ERROR,
  SETTINGS_POS_ENABLED_SUCCESS,
  SETTINGS_POS_ENABLED_ERROR,
  SETTINGS_FETCH_SOLVPAY_STATUS,
  SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_ERROR,
  SETTINGS_RESET_CREATE_ERROR_STATE,
  SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS,
  SETTINGS_CREATE_NEW_ACCOUNT_FETCH_ERROR,
  SETTINGS_UPDATE_AUTORESEND_ENABLED,
  SETTINGS_AUTORESEND_ENABLED_SUCCESS,
  SETTINGS_AUTORESEND_ENABLED_ERROR,
  SETTINGS_FETCH_SERVICES_LIST_SUCCESS,
  SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_SUCCESS,
  SETTINGS_CLEAR_LOCATION_RESULTS,
  SETTINGS_EMPLOYEE_SEARCH_RETURNS_EMPTY,
  SETTINGS_EMPLOYEE_SEARCH_RESET,
  SETTINGS_FETCH_OPERATING_HOURS_SUCCESS,
  SETTINGS_FETCH_OPERATING_HOURS_ERROR,
  SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_ERROR,
  SETTINGS_FETCH_SERVICES_LIST_ERROR,
  SETTINGS_PATCH_OPERATING_HOURS_SUCCESS,
  SETTINGS_PATCH_OPERATING_HOURS_ERROR,
  SETTINGS_ADDING_LOCATION,
  SETTINGS_ADD_LOCATION_SUCCESS,
  SETTINGS_ADD_LOCATION_ERROR,
  SETTINGS_CLEAR_ADD_LOCATION_ERROR,
  SETTINGS_RESERVATION_HOURS_FETCHING,
  SETTINGS_RESERVATION_HOURS_SUBMITTING,
  SETTINGS_SUBMITTING,
  SETTINGS_AVAILABILITY_RECOMMENDATIONS_FETCHING,
  SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS,
  SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS_ERROR,
  SETTINGS_RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
  SETTINGS_RECEIVE_LAB_TEST_CONFIG,
  SETTINGS_RECEIVE_LAB_TEST_CONFIG_DEFAULT,
  SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM,
  SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM,
  SETTINGS_RECEIVE_PRACTICES,
  SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS,
  SETTINGS_SET_SELECTED_PRACTICE_ID,
  SETTINGS_RECEIVE_PRACTICE,
  SETTINGS_PROVIDER_GROUP_LOCATIONS_LOADING,
  SETTINGS_PROVIDER_GROUP_LOCATIONS_ERROR,
  SETTINGS_PRACTICES_ERROR,
  SETTINGS_PRACTICES_LOADING,
  SETTINGS_RECEIVE_GROUPS,
  SETTINGS_GROUPS_ERROR,
  SETTINGS_GROUPS_LOADING,
  SETTINGS_SET_SEARCH_FILTERS,
  SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_SUCCESS,
  SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_ERROR,
  SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED,
  SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_SUCCESS,
  SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_ERROR,
  SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED,
} from '../constants';

const setSubmitting = (value) => ({
  type: SETTINGS_SUBMITTING,
  payload: { value },
});

const changeSettingsTab = (value) => ({
  type: SETTINGS_CHANGE_TAB,
  payload: { value },
});

const receiveLocationsForGroupEditForm = (value) => ({
  payload: { value },
  type: SETTINGS_RECEIVE_LOCATIONS_FOR_GROUP_EDIT_FORM,
});
const receiveNonPartnerLocationsForGroupEditForm = (value) => {
  return {
    payload: { value },
    type: SETTINGS_RECEIVE_NON_PARTNER_LOCATIONS_FOR_GROUP_EDIT_FORM,
  };
};
const receiveLocations = (value) => ({
  type: SETTINGS_RECEIVE_LOCATIONS,
  payload: { value },
});

const clearLocationsData = () => ({
  type: SETTINGS_CLEAR_LOCATION_RESULTS,
});

const fetchingLocationsGroups = () => ({
  type: SETTINGS_FETCHING_LOCATIONS_GROUPS,
});

const receiveLocationsGroups = (value) => ({
  type: SETTINGS_RECEIVE_LOCATIONS_GROUPS,
  payload: { value },
});

const addLocationsGroups = (value) => ({
  type: SETTINGS_ADD_LOCATIONS_GROUPS,
  payload: { value },
});

const resetLocationsGroups = () => ({ type: SETTINGS_RESET_LOCATIONS_GROUPS });

const locationsError = (value) => ({
  type: SETTINGS_LOCATIONS_ERROR,
  payload: { value },
});

const createSpecialOperatingHoursSuccess = (value) => ({
  type: SETTINGS_SPECIAL_HOURS_SUCCESS,
  payload: { value, event: 'create' },
});

const createSpecialOperatingHoursError = (value) => ({
  type: SETTINGS_SPECIAL_HOURS_ERROR,
  payload: { value },
});

const updateSpecialOperatingHoursSuccess = (value) => ({
  type: SETTINGS_SPECIAL_HOURS_SUCCESS,
  payload: { value, event: 'update' },
});

const receiveUpcomingSpecialOperatingHours = (value, locationId) => ({
  type: SETTINGS_RECEIVE_UPCOMING,
  payload: { value, locationId },
});

const upcomingSpecialOperatingHoursError = () => ({
  type: SETTINGS_UPCOMING_ERROR,
});

const setFetchingReservationHours = (value) => ({
  type: SETTINGS_RESERVATION_HOURS_FETCHING,
  payload: { value },
});

const setFetchingAvailabilityRecommendations = (value) => ({
  type: SETTINGS_AVAILABILITY_RECOMMENDATIONS_FETCHING,
  payload: { value },
});

const setSubmittingReservationHours = (value) => ({
  type: SETTINGS_RESERVATION_HOURS_SUBMITTING,
  payload: { value },
});

const createReservationHoursError = (value) => ({
  type: SETTINGS_RESERVATION_HOURS_ERROR,
  payload: { value },
});

const receiveReservationHoursSuccess = (value) => ({
  type: SETTINGS_RECEIVE_RESERVATION_HOURS,
  payload: { value },
});

const receiveReservationHoursError = () => ({
  type: SETTINGS_RECEIVE_RESERVATION_HOURS_ERROR,
});

const receiveAvailabilityRecommendationsSuccess = (value) => ({
  type: SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS,
  payload: { value },
});

const receiveAvailabilityRecommendationsError = (value) => ({
  type: SETTINGS_RECEIVE_AVAILABILITY_RECOMMENDATIONS_SUCCESS_ERROR,
  payload: { value },
});

const fetchOperatingHoursSuccess = (value) => ({
  type: SETTINGS_FETCH_OPERATING_HOURS_SUCCESS,
  payload: { value },
});

const fetchOperatingHoursError = (value) => ({
  type: SETTINGS_FETCH_OPERATING_HOURS_ERROR,
  payload: { value },
});

const updateLocationSuccess = (value) => ({
  type: SETTINGS_UPDATE_LOCATION_SUCCESS,
  payload: { value },
});

const updateLocationError = (value) => ({
  type: SETTINGS_UPDATE_LOCATION_ERROR,
  payload: { value },
});

const updatePaymentsEnabled = (value) => ({
  type: SETTINGS_UPDATE_PAYMENTS_ENABLED,
  payload: { value },
});

const updateAutoResendEnabled = (value) => ({
  type: SETTINGS_UPDATE_AUTORESEND_ENABLED,
  payload: { value },
});

const updatePosEnabled = (value) => ({
  type: SETTINGS_UPDATE_POS_ENABLED,
  payload: { value },
});

const updatePaymentsDuringBookingEnabled = (value) => ({
  type: SETTINGS_PAYMENTS_DURING_BOOKING_ENABLED,
  payload: { value },
});

const updateEnablePaymentsDuringBookingSuccess = (value) => ({
  type: SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_SUCCESS,
  payload: { value },
});

const updateEnablePaymentsDuringBookingError = (value) => ({
  type: SETTINGS_ENABLE_PAYMENTS_DURING_BOOKING_ERROR,
  payload: { value },
});

const updatePaymentsDuringBookingRequired = (value) => ({
  type: SETTINGS_PAYMENTS_DURING_BOOKING_REQUIRED,
  payload: { value },
});

const updateRequirePaymentsDuringBookingSuccess = (value) => ({
  type: SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_SUCCESS,
  payload: { value },
});

const updateRequirePaymentsDuringBookingError = (value) => ({
  type: SETTINGS_REQUIRE_PAYMENTS_DURING_BOOKING_ERROR,
  payload: { value },
});

const updatePaymentsSuccess = (value) => ({
  type: SETTINGS_PAYMENTS_ENABLED_SUCCESS,
  payload: { value },
});

const updatePaymentsError = (value) => ({
  type: SETTINGS_PAYMENTS_ENABLED_ERROR,
  payload: { value },
});

const updateAutoResendSuccess = (value) => ({
  type: SETTINGS_AUTORESEND_ENABLED_SUCCESS,
  payload: { value },
});

const updateAutoResendError = (value) => ({
  ype: SETTINGS_AUTORESEND_ENABLED_ERROR,
  payload: { value },
});

const updatePosSuccess = (value) => ({
  type: SETTINGS_POS_ENABLED_SUCCESS,
  payload: { value },
});

const updatePosError = (value) => ({
  type: SETTINGS_POS_ENABLED_ERROR,
  payload: { value },
});

const fetchSolvpayStatus = (value) => ({
  type: SETTINGS_FETCH_SOLVPAY_STATUS,
  payload: { value },
});

const createNewPaymentAccountSuccess = (value) => ({
  type: SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_SUCCESS,
  payload: { value },
});

const createNewPaymentAccountError = (value) => ({
  type: SETTINGS_CREATE_NEW_PAYMENT_ACCOUNT_ERROR,
  payload: { value },
});

const createNewPaymentAccountFetchError = (value) => ({
  type: SETTINGS_CREATE_NEW_ACCOUNT_FETCH_ERROR,
  payload: { value },
});

const resetNewPaymentAccountState = () => ({
  type: SETTINGS_RESET_CREATE_ERROR_STATE,
});

const fetchServicesListSuccess = (value) => ({
  type: SETTINGS_FETCH_SERVICES_LIST_SUCCESS,
  payload: { value },
});

const fetchPaymentAccountsSuccess = (value) => ({
  type: SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_SUCCESS,
  payload: { value },
});

const createEmployeeSearchEmpty = (value) => ({
  type: SETTINGS_EMPLOYEE_SEARCH_RETURNS_EMPTY,
  payload: { value },
});

const createEmployeeSearchReset = () => ({
  type: SETTINGS_EMPLOYEE_SEARCH_RESET,
});

const fetchServicesListError = (value) => ({
  type: SETTINGS_FETCH_SERVICES_LIST_ERROR,
  payload: { value },
});

const fetchPaymentAccountsError = (value) => ({
  type: SETTINGS_PAYMENT_ACCOUNT_LOCATIONS_ERROR,
  payload: { value },
});

const patchOperationHoursSuccess = (value) => ({
  type: SETTINGS_PATCH_OPERATING_HOURS_SUCCESS,
  payload: { value },
});

const patchOperationHoursError = (value) => ({
  type: SETTINGS_PATCH_OPERATING_HOURS_ERROR,
  payload: { value },
});

const addingLocation = () => ({ type: SETTINGS_ADDING_LOCATION });

const addLocationSuccess = (value) => ({
  type: SETTINGS_ADD_LOCATION_SUCCESS,
  payload: { value },
});

const addLocationError = (value) => ({
  type: SETTINGS_ADD_LOCATION_ERROR,
  payload: { value },
});

const clearAddLocationError = () => ({ type: SETTINGS_CLEAR_ADD_LOCATION_ERROR });

const fetchingPhysicalLocationsInStateByGroup = () => ({
  type: SETTINGS_FETCHING_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
});

const fetchingTelemedLocationsInStateByGroup = () => ({
  type: SETTINGS_FETCHING_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
});

const receivePhysicalLocationsInStateByGroup = (value) => ({
  type: SETTINGS_RECEIVE_PHYSICAL_LOCATIONS_IN_STATE_BY_GROUP,
  payload: { value },
});

const receiveTelemedLocationsInStateByGroup = (value) => ({
  type: SETTINGS_RECEIVE_TELEMED_LOCATIONS_IN_STATE_BY_GROUP,
  payload: { value },
});

const receiveLabResultsConfig = (value) => ({
  type: SETTINGS_RECEIVE_LAB_TEST_CONFIG,
  payload: { value },
});

const receiveLabResultConfigDefaults = (value, serviceId) => ({
  type: SETTINGS_RECEIVE_LAB_TEST_CONFIG_DEFAULT,
  payload: { value, serviceId },
});

const groupsReceived = (value) => ({
  type: SETTINGS_RECEIVE_GROUPS,
  payload: { value },
});

const groupsError = (value) => ({
  type: SETTINGS_GROUPS_ERROR,
  payload: { value },
});

const groupsLoading = (value) => ({
  type: SETTINGS_GROUPS_LOADING,
  payload: { value },
});

// practices collection received
const practicesReceived = (value) => ({
  type: SETTINGS_RECEIVE_PRACTICES,
  payload: { value },
});

// single practice received
const practiceReceived = (value) => ({
  type: SETTINGS_RECEIVE_PRACTICE,
  payload: { value },
});

const practicesError = (value) => ({
  type: SETTINGS_PRACTICES_ERROR,
  payload: { value },
});

const practicesLoading = (value) => ({
  type: SETTINGS_PRACTICES_LOADING,
  payload: { value },
});

const providerGroupLocationsLoading = (value) => ({
  type: SETTINGS_PROVIDER_GROUP_LOCATIONS_LOADING,
  payload: { value },
});

const receiveProviderGroupLocations = (value) => ({
  type: SETTINGS_RECEIVE_PROVIDER_GROUP_LOCATIONS,
  payload: { value },
});

const providerGroupLocationsError = (value) => ({
  type: SETTINGS_PROVIDER_GROUP_LOCATIONS_ERROR,
  payload: { value },
});

const setSelectedPracticeId = (value) => ({
  type: SETTINGS_SET_SELECTED_PRACTICE_ID,
  payload: { value },
});

const setSearchFilters = (value) => ({
  type: SETTINGS_SET_SEARCH_FILTERS,
  payload: { value },
});

export {
  changeSettingsTab,
  locationsError,
  receiveLocationsForGroupEditForm,
  receiveNonPartnerLocationsForGroupEditForm,
  receiveLocations,
  clearLocationsData,
  receiveLocationsGroups,
  addLocationsGroups,
  resetLocationsGroups,
  createSpecialOperatingHoursSuccess,
  createSpecialOperatingHoursError,
  receiveUpcomingSpecialOperatingHours,
  upcomingSpecialOperatingHoursError,
  updateLocationError,
  updateLocationSuccess,
  updateSpecialOperatingHoursSuccess,
  createReservationHoursError,
  receiveReservationHoursSuccess,
  receiveReservationHoursError,
  fetchOperatingHoursSuccess,
  fetchOperatingHoursError,
  updatePaymentsEnabled,
  updateAutoResendEnabled,
  updatePosEnabled,
  updatePaymentsSuccess,
  updatePaymentsError,
  updateAutoResendSuccess,
  updateAutoResendError,
  updatePosSuccess,
  updatePosError,
  updateEnablePaymentsDuringBookingSuccess,
  updateEnablePaymentsDuringBookingError,
  updatePaymentsDuringBookingEnabled,
  updateRequirePaymentsDuringBookingSuccess,
  updateRequirePaymentsDuringBookingError,
  updatePaymentsDuringBookingRequired,
  fetchSolvpayStatus,
  createNewPaymentAccountSuccess,
  createNewPaymentAccountError,
  createNewPaymentAccountFetchError,
  resetNewPaymentAccountState,
  fetchServicesListSuccess,
  fetchPaymentAccountsSuccess,
  createEmployeeSearchEmpty,
  createEmployeeSearchReset,
  fetchServicesListError,
  fetchPaymentAccountsError,
  patchOperationHoursSuccess,
  patchOperationHoursError,
  addingLocation,
  addLocationSuccess,
  addLocationError,
  clearAddLocationError,
  setFetchingReservationHours,
  setFetchingAvailabilityRecommendations,
  setSubmittingReservationHours,
  setSubmitting,
  receiveAvailabilityRecommendationsSuccess,
  receiveAvailabilityRecommendationsError,
  fetchingLocationsGroups,
  fetchingPhysicalLocationsInStateByGroup,
  fetchingTelemedLocationsInStateByGroup,
  receivePhysicalLocationsInStateByGroup,
  receiveTelemedLocationsInStateByGroup,
  receiveLabResultsConfig,
  receiveLabResultConfigDefaults,
  practiceReceived,
  practicesReceived,
  receiveProviderGroupLocations,
  setSelectedPracticeId,
  providerGroupLocationsLoading,
  providerGroupLocationsError,
  practicesError,
  practicesLoading,
  groupsReceived,
  groupsError,
  groupsLoading,
  setSearchFilters,
};
