import { colors } from '../../constants/colors';

export const selectorContainerStyles = `
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
  button {
    box-shadow: none !important;
    font-size: 14px;
    height: 37px;
    letter-spacing: 0.14px;
    line-height: 37px;
    margin-top: 5px;
    padding-left: 20px;
    position: relative; /* To position the i */
    text-align: left;
    width: 208px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      border-color: ${colors.solvPink};
      color: ${colors.solvPink};
    }
    i {
      color: ${colors.dorianGrey};
      position: absolute;
      top: 13px;
      right: 10px;
    }
  }

  .ant-select-selection {
    line-height: 15px;
    height: 37px;
    width: 300px;
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-top: 5px;
    padding-left: 13px;
    box-shadow: none !important;
    border-color: ${colors.greyish} !important;
    &:hover,
    &:focus {
      color: ${colors.solvPink};
      border-color: ${colors.solvPink} !important;
      box-shadow: none !important;
    }

    .ant-select-selection__rendered {
      line-height: 33px;
      font-size: 14px;
      letter-spacing: 0.14px;
    }

    .ant-select-arrow {
      font-size: 13px !important;
      color: ${colors.dorianGrey};
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
    display: flex;

    button {
      height: 28px; /* Match ReportsTab selector height */
      width: 200px;
      font-size: 12px;
      line-height: 28px;
      padding-left: 10px;

      i {
        color: ${colors.dorianGrey};

        position: absolute;
        top: 9px;
        right: 10px;
      }
    }
  }
`;

export const selectorPopoverStyles = `
  .ant-popover-inner-content {
    padding: 0;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .ant-popover-arrow {
    display: none;
  }
`;

export const selectorTitleStyles = `
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;

  @media (max-width: 768px) {
    margin-top: 8px;
    width: 30%;
  }
`;
