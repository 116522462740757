import { EMPTY_OBJECT } from '../constants';

class ProvidersActionTypes {
  static PROVIDERS_ERROR = 'PROVIDERS_ERROR';
  static PROVIDERS_LOADING = 'PROVIDERS_LOADING';
  static PROVIDER_RECEIVED = 'PROVIDER_RECEIVED';
  static PROVIDERS_RECEIVED = 'PROVIDERS_RECEIVED';
}

export const providersError = value => ({
  payload: { value },
  type: ProvidersActionTypes.PROVIDERS_ERROR,
});

export const providersLoading = value => ({
  payload: { value },
  type: ProvidersActionTypes.PROVIDERS_LOADING,
});

export const providerReceived = value => ({
  payload: { value },
  type: ProvidersActionTypes.PROVIDER_RECEIVED,
});

export const providersReceived = value => ({
  payload: { value },
  type: ProvidersActionTypes.PROVIDERS_RECEIVED,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ProvidersActionTypes.PROVIDERS_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case ProvidersActionTypes.PROVIDERS_LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ProvidersActionTypes.PROVIDER_RECEIVED:
      return {
        ...state,
        provider: action.payload.value,
      };
    case ProvidersActionTypes.PROVIDERS_RECEIVED:
      return {
        ...state,
        results: action.payload.value,
      };
    default:
      return state;
  }
};
