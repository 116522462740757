import { LOGIN_LOGOUT } from '../constants';
import { combineReducers } from 'redux';
import runtime from './runtime';
import login from './login';
import location from './location';
import language from './language';
import newBooking from './newBooking';
import lookUp from './lookUp';
import insurers from './insurers';
import autocomplete from './autocomplete';
import insurance from './insurance';
import eligibilityCheck from './eligibilityCheck';
import imageData from './imageData';
import report from './report';
import queue from './queue';
import clinicAccounts from './clinicAccounts';
import groups from './groups';
import waitTime from './waitTime';
import reviews from './reviews';
import reports from './reports';
import currentPatient from './currentPatient';
import graphs from './graphs';
import waitList from './waitList';
import accounts from './accounts';
import bookingHistory from './bookingHistory';
import pastBookings from './pastBookings';
import admin from './admin';
import paperworkResponses from './paperworkResponses';
import payments from '../ducks/payments';
import paymentCustomers from '../ducks/paymentCustomers';
import pulse from './pulse';
import charges from './charges';
import clinicAccountActivation from './clinicAccountActivation';
import settings from './settings';
import videoVisitModal from '../ducks/videoVisitModal';
import bulkUpload from '../ducks/BulkUpload';
import conversations from '../ducks/conversations';
import reviewsModeration from '../ducks/reviewsModeration';
import bulkClinicAccountActivation from '../ducks/bulkClinicAccountActivation';
import solvPatientBoost from '../ducks/solvPatientBoost';
import platformUsageAlerts from '../ducks/platformUsageAlerts';
import specialties from '../ducks/specialties';
import marketplaceInsurers from '../ducks/marketplaceInsurers';
import paperwork from '../ducks/paperwork';
import directory from '../ducks/directory';
import handoffs from '../ducks/handoffs';
import categories from '../ducks/categories';
import locationConsentForms from '../ducks/locationConsentForms';
import uclBrands from '../ducks/uclBrands';
import packages from '../ducks/packages';
import clinicDownloadReports from '../ducks/clinicDownloadReports';
import labResults from '../ducks/labResults';
import modalLocationIds from '../ducks/modalLocationIds';
import vaccinations from '../ducks/vaccinations';
import providers from '../ducks/providers';
import imageUpload from '../ducks/imageUpload';
import serviceLocations from '../ducks/serviceLocations';
import contacts from '../ducks/contacts';
import locationsImportToolFieldOptions from '../ducks/LocationImportTool';
import providerLocations from '../ducks/providerLocations';
import { newVersionSlice } from '../ducks/newVersion';
import { locationsSlice } from '../ducks/locations';
import servicePreferences from '../ducks/servicePreferences';
import sftpExportConfig from '../ducks/sftpExportConfig';
import { kioskSlice } from '../features/kioskV2/state/kioskState';
import { bookingsSlice } from '../ducks/bookings';
import { flagsSlice } from '../ducks/flags';

const getResetedState = (state: any) => ({
  runtime: {
    initialNow: state.runtime.initialNow,
    initialPath: state.runtime.initialPath,
    persistentStateLoaded: state.runtime.persistentStateLoaded,
  },
});

export default (state: any, action: any) =>
  combineReducers({
    accounts,
    admin,
    autocomplete,
    bookingHistory,
    bookings: bookingsSlice.reducer,
    flags: flagsSlice.reducer,
    bulkClinicAccountActivation,
    bulkUpload,
    categories,
    charges,
    clinicAccountActivation,
    clinicAccounts,
    clinicDownloadReports,
    contacts,
    conversations,
    currentPatient,
    directory,
    eligibilityCheck,
    graphs,
    groups,
    handoffs,
    imageData,
    imageUpload,
    insurance,
    insurers,
    labResults,
    language,
    location,
    locationConsentForms,
    locationsImportToolFieldOptions,
    login,
    lookUp,
    marketplaceInsurers,
    modalLocationIds,
    newBooking,
    packages,
    paperwork,
    paperworkResponses,
    pastBookings,
    paymentCustomers,
    payments,
    platformUsageAlerts,
    providerLocations,
    providers,
    pulse,
    queue,
    report,
    reports,
    reviews,
    reviewsModeration,
    runtime,
    serviceLocations,
    servicePreferences,
    settings,
    solvPatientBoost,
    specialties,
    uclBrands,
    vaccinations,
    videoVisitModal,
    waitList,
    waitTime,
    sftpExportConfig,
    newVersion: newVersionSlice.reducer,
    locations: locationsSlice.reducer,
    kiosk: kioskSlice.reducer,
  })(action.type !== LOGIN_LOGOUT ? state : getResetedState(state), action);
