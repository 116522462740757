import {
  INSURANCE_CHECK_SET_REQUEST,
  INSURANCE_CHECK_SUBMITTING,
  INSURANCE_CHECK_RESPONSE_RECEIVED,
  INSURANCE_CHECK_ERROR,
  INSURANCE_TOGGLE_TREE,
  INSURANCE_RESET_FORM,
} from '../constants';

export default function confirmReducer(state = {}, action) {
  switch (action.type) {
    case INSURANCE_CHECK_SET_REQUEST:
      return {
        request: action.payload.value,
      };
    case INSURANCE_CHECK_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    case INSURANCE_CHECK_RESPONSE_RECEIVED:
      return {
        ...state,
        submitting: false,
        result: action.payload.value,
      };
    case INSURANCE_CHECK_ERROR:
      return {
        ...state,
        submitting: false,
        error: action.payload.value,
      };
    case INSURANCE_TOGGLE_TREE:
      return {
        ...state,
        tree: {
          ...state.tree,
          ...action.payload.value,
        },
      };
    case INSURANCE_RESET_FORM:
      return {};
    default:
      return state;
  }
}
