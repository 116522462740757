import rootSaga from '../sagas';
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import { isProd } from '../config';
import { reduxPersistConfig } from './storage';
import { ManualRootState } from '../types/RootState';
import { NewVersionState } from '../ducks/newVersion';
import { LocationState } from '../ducks/locations';
import { BookingsState } from '../ducks/bookings';
import { KioskState } from '../features/kioskV2/state/kioskState';
import { FlagsState } from '../ducks/flags';
import { localStorageMiddleware } from './middleware/localStorageMiddleware';

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(reduxPersistConfig, rootReducer);

export const clearPersistedStore = () => {
  sessionStorage.removeItem('persist:root');
};

export const createStore = () => {
  return configureStore({
    reducer: persistedReducer,
    devTools: !isProd(),
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // This check is super slow in dev, so lets just disable it for now.
          isSerializable: () => true,
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: {
          ignoredPaths: [
            'bookings',
            'clinicAccounts',
            'conversations',
            'handoffs',
            'location',
            'login',
            'reports',
            'runtime',
            'waitList',
          ],
        },
      }).concat([sagaMiddleware, localStorageMiddleware]),
  });
};

export const store = createStore();

export const persistor = persistStore(store);

rootSaga.forEach((saga) => sagaMiddleware.run(saga));

// ReturnType<typeof store.getState> TODO enable this once all the reducers are converted to TS.
// Until then, just add any new redux-toolkit reducer state types here.
type TypedReducers = {
  newVersion: NewVersionState;
  locations: LocationState;
  kiosk: KioskState;
  bookings: BookingsState;
  flags: FlagsState;
};

export type RootState = TypedReducers & ManualRootState;

// Allow us to hook into redux state for e2e testing.
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.solv = {
    debug: {
      getReduxState: () => store.getState(),
    },
  };
}
