import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet, apiGetJson } from '../core/dapi';
import { getBookingByIdUrl } from '../core/dapi/bookings';
import { getUserProfileUrl } from '../core/dapi/userProfiles';
import { getAccountByIdUrl } from '../core/dapi/accounts';
import {
  setCurrentPatientBookingError,
  setCurrentPatientBookingSuccess,
  getCurrentPatientUserProfileError,
  getCurrentPatientUserProfileSuccess,
  getCurrentPatientAccountSummaryError,
  getCurrentPatientAccountSummary,
  receiveConsentFormSignatures,
  consentFormSignaturesError,
} from '../actions/currentPatient';
import { fetchInsuranceProfile } from './core/insuranceProfiles';
import { InsuranceTypes } from '../constants';
import { getConsentFormSignaturesByBookingIdUrl } from '../core/dapi/consentFormSignatures';
import { setRuntimeVariable } from '../actions/runtime';
import { RuntimeAttributes } from '../reducers/runtime';

export class CurrentPatientSagas {
  static SET_CURRENT_PATIENT = 'SAGA/SET_CURRENT_PATIENT';
}

export function* setCurrentPatient({ booking }) {
  yield put(
    setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_CONSENT_FORM_SIGNATURES, value: true })
  );

  const bookingResponse = yield call(apiGetJson, getBookingByIdUrl(booking.id));

  if (bookingResponse.errors) {
    yield put(setCurrentPatientBookingError(bookingResponse.errors));
    return;
  }

  yield put(setCurrentPatientBookingSuccess(bookingResponse.data));

  const {
    user_profile_id: userProfileId,
    insurance_profile_id: insuranceProfileId,
    account_id: accountId,
    dental_insurance_profile_id: dentalInsuranceProfileId,
    consent_forms_signed_date: consentFormsSignedDate,
  } = bookingResponse.data;

  if (userProfileId) {
    const userProfileResponse = yield call(apiGetJson, getUserProfileUrl(userProfileId));

    if (userProfileResponse.errors) {
      yield put(getCurrentPatientUserProfileError(userProfileResponse.errors));
    } else {
      yield put(getCurrentPatientUserProfileSuccess(userProfileResponse.data));
    }
  }

  if (insuranceProfileId) {
    yield call(fetchInsuranceProfile, { insuranceProfileId });
  }

  if (dentalInsuranceProfileId) {
    yield call(fetchInsuranceProfile, {
      insuranceProfileId: dentalInsuranceProfileId,
      insuranceType: InsuranceTypes.DENTAL,
    });
  }

  if (accountId) {
    const accountResponse = yield call(apiGetJson, getAccountByIdUrl(accountId));

    if (accountResponse.errors) {
      yield put(getCurrentPatientAccountSummaryError(accountResponse.errors));
    } else {
      yield put(getCurrentPatientAccountSummary(accountResponse.data));
    }
  }

  if (consentFormsSignedDate) {
    try {
      const consentFormSignaturesResponse = yield call(
        apiGet,
        getConsentFormSignaturesByBookingIdUrl(booking.id)
      );
      yield put(receiveConsentFormSignatures(consentFormSignaturesResponse));
    } catch (e) {
      yield put(consentFormSignaturesError(e));
    }
  }

  yield put(
    setRuntimeVariable({
      name: RuntimeAttributes.IS_FETCHING_CONSENT_FORM_SIGNATURES,
      value: false,
    })
  );
}

export default function* rootSaga() {
  yield takeEvery(CurrentPatientSagas.SET_CURRENT_PATIENT, setCurrentPatient);
}
