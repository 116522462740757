import { mergeArraysOfObjects } from '../core/util/array';

const CREATE_SUCCESS = 'locationConsentForms/CREATE_SUCCESS';
const RECEIVE = 'locationConsentForms/RECEIVE';
const SET_IS_UPDATING = 'locationConsentForms/SET_IS_UPDATING';
const UPDATE_SUCCESS = 'locationConsentForms/UPDATE_SUCCESS';
const UNSET_IS_UPDATING = 'locationConsentForms/UNSET_IS_UPDATING';

export const createLocationConsentFormSuccess = value => ({
  type: CREATE_SUCCESS,
  payload: { value },
});

export const receiveLocationConsentForms = value => ({
  type: RECEIVE,
  payload: { value },
});

export const setIsLocationConsentFormUpdating = value => ({
  type: SET_IS_UPDATING,
  payload: { value },
});

export const updateLocationConsentFormSuccess = value => ({
  type: UPDATE_SUCCESS,
  payload: { value },
});

export const unsetIsLocationConsentFormUpdating = value => ({
  type: UNSET_IS_UPDATING,
  payload: { value },
});

export default (state = { isUpdating: [] }, action) => {
  switch (action.type) {
    case RECEIVE:
      return {
        ...state,
        forms: action.payload.value,
      };
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        forms: mergeArraysOfObjects([action.payload.value], state.forms, 'id'),
      };
    case SET_IS_UPDATING: {
      const isUpdating = new Set(state.isUpdating);
      isUpdating.add(action.payload.value);

      return {
        ...state,
        isUpdating: Array.from(isUpdating),
      };
    }

    case UNSET_IS_UPDATING: {
      const isUpdating = new Set(state.isUpdating);
      isUpdating.delete(action.payload.value);

      return {
        ...state,
        isUpdating: Array.from(isUpdating),
      };
    }
    default:
      return state;
  }
};
