import {
  LOCATION_RESPONSE_RECEIVED,
  LOCATION_FAILED,
  LOADING_LOCATION,
  LOCATION_PATCH_SUCCESS,
  LOCATION_PATCH_STATUS_CLEAR,
  LOCATION_WAIT_LIST_COUNT_RECEIVED,
  LOCATION_RECEIVE_SPECIAL_OPERATING_HOURS,
  LOCATION_RECEIVE_OPERATING_HOURS,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_PRACTICES,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
  RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
  LOADING_LOCATION_SERVICE_PREFERENCES,
} from '../constants';
import { locationResponseFormatter } from '../reducers/formatters/location';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

function receiveLocation(value) {
  return {
    type: LOCATION_RESPONSE_RECEIVED,
    payload: { value: locationResponseFormatter(value) },
  };
}

function locationError(value) {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: LOCATION_FAILED,
    payload: { value },
  };
}

function locationPatchSuccess(value) {
  return {
    type: LOCATION_PATCH_SUCCESS,
    payload: { value },
  };
}

function locationPatchStatusClear() {
  return {
    type: LOCATION_PATCH_STATUS_CLEAR,
  };
}

const loadingLocation = () => ({ type: LOADING_LOCATION });

const locationWaitListCountUpdateReceived = (value) => ({
  type: LOCATION_WAIT_LIST_COUNT_RECEIVED,
  payload: { value },
});

const receiveLocationServicePreferencesPractices = (value) => ({
  type: LOCATION_RECEIVE_SERVICE_PREFERENCES_PRACTICES,
  payload: { value },
});

const loadingLocationServicePreferences = () => ({
  type: LOADING_LOCATION_SERVICE_PREFERENCES,
});

const receiveLocationServicePreferencesAppointments = (value) => ({
  type: LOCATION_RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS,
  payload: { value },
});

const locationServicePreferencesError = (value) => ({
  type: LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
  payload: { value },
});

const resetLocationServicePreferencesError = () => ({
  type: RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
});

const updateLocationSpecialOperatingHours = (value) => ({
  type: LOCATION_RECEIVE_SPECIAL_OPERATING_HOURS,
  payload: { value },
});

const updateLocationOperatingHours = (value) => ({
  type: LOCATION_RECEIVE_OPERATING_HOURS,
  payload: { value },
});

export {
  receiveLocation,
  locationError,
  loadingLocation,
  locationPatchSuccess,
  locationPatchStatusClear,
  locationWaitListCountUpdateReceived,
  loadingLocationServicePreferences,
  receiveLocationServicePreferencesPractices,
  receiveLocationServicePreferencesAppointments,
  locationServicePreferencesError,
  updateLocationSpecialOperatingHours,
  updateLocationOperatingHours,
  resetLocationServicePreferencesError,
};
