import { DAPI_HOST } from '../../config';

export type Insurer = {
  id: string;
  name: string;
  display_name: string;
  insurer_code: string;
  is_group?: boolean;
};

export type PverifyPayer = {
  id: string;
  name: string;
  payer_code: string;
};

const getInsurersListUrl = (searchStateCode: string) =>
  `${DAPI_HOST}/v1/insurers?` +
  'fields=id,name,display_name,insurer_code&limit=0' +
  `&popular_only=True&state=${searchStateCode}`;

const getFullInsurersListUrl = () =>
  `${DAPI_HOST}/v1/insurers?` + 'fields=id,name,display_name,insurer_code&limit=0';

const getFullEligibilityInsurersListUrl = () => `/v1/pverify_payers`;

export { getInsurersListUrl, getFullInsurersListUrl, getFullEligibilityInsurersListUrl };
