import { call, put, takeEvery } from 'redux-saga/effects';
import {
  bookingsError,
  partialUpdateExistingBooking,
  setSubmittingBookingUpdate,
} from '../../actions/bookings';
import { apiPatch, apiPost } from '../../core/dapi';
import { getBookingByIdUrl, getRescheduleBookingsUrl } from '../../core/dapi/bookings';
import { emptyFunction } from '../../core/util/function';
import { log } from '../../core/logger/log';
import { PubNubEvent, PubNubListeners, PubNubSource } from '../../components/Pubnub/usePubNub';

export class BookingsSagas {
  static PATCH_BOOKING = 'SAGA/PATCH_BOOKING';
}

export function* patchBooking({
  bookingId,
  patchData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmittingBookingUpdate(true));

  try {
    const response = yield call(apiPatch, getBookingByIdUrl(bookingId), patchData);
    yield put(partialUpdateExistingBooking(response));
    // Fallback just incase pubnub isn't working
    PubNubListeners.dispatch({
      type: PubNubEvent.PartialBookingUpdate,
      payload: response,
      source: PubNubSource.Local,
    });
    onSuccess();
  } catch (e) {
    yield put(bookingsError(e));
    log.error(e);
    onError(e.message);
  }

  yield put(setSubmittingBookingUpdate(false));
}

export function* rescheduleBooking({
  bookingId,
  newAppointmentDate,
  onError = emptyFunction,
  onSuccess = emptyFunction,
}) {
  try {
    const response = yield call(apiPost, getRescheduleBookingsUrl(), {
      booking_id: bookingId,
      appointment_date: newAppointmentDate,
    });
    yield call(onSuccess, response);
    return response;
  } catch (e) {
    log.error(e);
    yield call(onError, e.message);
    return null;
  }
}

export default function* rootSaga() {
  yield takeEvery(BookingsSagas.PATCH_BOOKING, patchBooking);
}
