import { put, call, takeEvery } from 'redux-saga/effects';
import {
  getPatchSolvRatingUrl,
  getSolvRatingUpdateIsContactedUrl,
} from '../../core/dapi/reviewModeration';
import { emptyFunction } from '../../core/util/function';
import { apiPatch, apiPost } from '../../core/dapi';
import { receiveReview } from '../../actions/reports';

export class SolvRatingsSagas {
  static UPDATE_SOLV_RATING = 'sagas/UPDATE_SOLV_RATING';
  static UPDATE_SOLV_RATING_IS_CONTACTED = 'sagas/UPDATE_SOLV_RATING_IS_CONTACTED';
}

function* updateSolvRating({ solvRatingId, clinicAccountId, patchData, onError = emptyFunction }) {
  try {
    const url = getPatchSolvRatingUrl(solvRatingId, clinicAccountId);
    const response = yield call(apiPatch, url, patchData);
    yield put(receiveReview(response));
  } catch (e) {
    onError(e);
  }
}

function* updateSolvRatingIsContacted({ solvRatingId, isContacted, onError = emptyFunction }) {
  try {
    const url = getSolvRatingUpdateIsContactedUrl(solvRatingId, isContacted);
    const response = yield call(apiPost, url, {});
    yield put(receiveReview(response));
  } catch (e) {
    onError(e);
  }
}

export default function* rootSaga() {
  yield takeEvery(SolvRatingsSagas.UPDATE_SOLV_RATING, updateSolvRating);
  yield takeEvery(SolvRatingsSagas.UPDATE_SOLV_RATING_IS_CONTACTED, updateSolvRatingIsContacted);
}
