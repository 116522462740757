import { EMPTY_OBJECT } from '../core/util/object';
import { Booking, RootState } from '../types/RootState';

export const getQueue = (reduxState: RootState) => reduxState?.queue ?? null;

export const getQueueSelectedLocationIds = (reduxState: RootState) =>
  getQueue(reduxState)?.selectedLocationIds ?? [];

export const getQueueCurrentTab = (reduxState: RootState) =>
  getQueue(reduxState)?.currentTab ?? null;

export const getQueueSubmitting = (reduxState: RootState) =>
  getQueue(reduxState)?.submitting ?? null;

export const getQueueSubmittingPatchForBookingId = (reduxState: RootState) =>
  getQueue(reduxState)?.submittingPatchForBookingId ?? null;

export const getQueueMostRecentBooking = (reduxState: RootState): Booking | {} =>
  getQueue(reduxState)?.mostRecentBooking ?? EMPTY_OBJECT;
