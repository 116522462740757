import { EMPTY_OBJECT } from '../constants';

export class ContactsActionTypes {
  static CONTACTS_CREATE_LOADING = 'CONTACTS_CREATE_LOADING';
  static CONTACTS_CREATE_ERROR = 'CONTACTS_CREATE_ERROR';
  static CONTACTS_COLLECTION_ERROR = 'CONTACTS_COLLECTION_ERROR';
  static CONTACTS_COLLECTION_LOADING = 'CONTACTS_COLLECTION_LOADING';
  static CONTACTS_COLLECTION_RECEIVED = 'CONTACTS_COLLECTION_RECEIVED';
}

export const contactsCreateLoading = value => ({
  payload: { value },
  type: ContactsActionTypes.CONTACTS_CREATE_LOADING,
});

export const contactsCreateError = value => ({
  payload: { value },
  type: ContactsActionTypes.CONTACTS_CREATE_ERROR,
});

export const contactsCollectionError = value => ({
  payload: { value },
  type: ContactsActionTypes.CONTACTS_COLLECTION_ERROR,
});

export const contactsCollectionLoading = value => ({
  payload: { value },
  type: ContactsActionTypes.CONTACTS_COLLECTION_LOADING,
});

export const contactsCollectionReceived = value => ({
  payload: { value },
  type: ContactsActionTypes.CONTACTS_COLLECTION_RECEIVED,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ContactsActionTypes.CONTACTS_CREATE_LOADING:
      return {
        ...state,
        createLoading: action.payload.value,
      };
    case ContactsActionTypes.CONTACTS_CREATE_ERROR:
      return {
        ...state,
        createError: action.payload.value,
      };
    case ContactsActionTypes.CONTACTS_COLLECTION_LOADING:
      return {
        ...state,
        collectionLoading: action.payload.value,
      };
    case ContactsActionTypes.CONTACTS_COLLECTION_ERROR:
      return {
        ...state,
        collectionError: action.payload.value,
      };
    case ContactsActionTypes.CONTACTS_COLLECTION_RECEIVED:
      return {
        ...state,
        contacts: action.payload.value,
      };
    default:
      return state;
  }
};
