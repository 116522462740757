import { put, call, takeEvery } from 'redux-saga/effects';
import {
  getPatchReviewGoogleGmbUrl,
  getReviewGoogleGmbUpdateIsContactedUrl,
} from '../../core/dapi/review';
import { emptyFunction } from '../../core/util/function';
import { apiPatch, apiPost } from '../../core/dapi';
import { receiveReview } from '../../actions/reports';

export class ReviewsGoogleGmbSagas {
  static UPDATE_REVIEW_GOOGLE_GMB = 'sagas/UPDATE_REVIEW_GOOGLE_GMB';
  static UPDATE_REVIEW_GOOGLE_GMB_IS_CONTACTED = 'sagas/UPDATE_REVIEW_GOOGLE_GMB_IS_CONTACTED';
}

function* updateReviewGoogleGmb({ reviewGoogleGmbId, patchData, onError = emptyFunction }) {
  try {
    const url = getPatchReviewGoogleGmbUrl(reviewGoogleGmbId);
    const response = yield call(apiPatch, url, patchData);
    yield put(receiveReview(response));
  } catch (e) {
    onError(e);
  }
}

function* updateReviewGoogleGmbIsContacted({
  reviewGoogleGmbId,
  isContacted,
  onError = emptyFunction,
}) {
  try {
    const url = getReviewGoogleGmbUpdateIsContactedUrl(reviewGoogleGmbId, isContacted);
    const response = yield call(apiPost, url, {});
    yield put(receiveReview(response));
  } catch (e) {
    onError(e);
  }
}

export default function* rootSaga() {
  yield takeEvery(ReviewsGoogleGmbSagas.UPDATE_REVIEW_GOOGLE_GMB, updateReviewGoogleGmb);
  yield takeEvery(
    ReviewsGoogleGmbSagas.UPDATE_REVIEW_GOOGLE_GMB_IS_CONTACTED,
    updateReviewGoogleGmbIsContacted
  );
}
