import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingFilterParameters } from '../components/Queue/hooks/useQueryBookings';

export interface BookingsState {
  filter?: BookingFilterParameters;
}

const initialState: BookingsState = {
  filter: undefined,
};

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setBookingQueryFilter: (state, payload: PayloadAction<BookingFilterParameters | undefined>) => {
      state.filter = payload.payload;
    },
  },
});

export const { setBookingQueryFilter } = bookingsSlice.actions;
