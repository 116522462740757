import { EMPTY_OBJECT } from '../constants';

class ImageUploadActionTypes {
  static IS_PROCESSING_IMAGES = 'imageUpload/IS_PROCESSING_IMAGES';
  static IS_REFRESHING_ENTITY = 'imageUpload/IS_REFRESHING_ENTITY';
  static PROCESS_IMAGES_ERROR = 'imageUpload/PROCESS_IMAGES_ERROR';
  static PROCESS_IMAGES_SUCCESS = 'imageUpload/PROCESS_IMAGES_SUCCESS';
  static RECEIVE_IMAGES = 'imageUpload/RECEIVE_LOCATION_IMAGES';
  static RESET = 'imageUpload/RESET';
}

export const isProcessingImages = (value) => ({
  payload: { value },
  type: ImageUploadActionTypes.IS_PROCESSING_IMAGES,
});

export const isRefreshingEntity = (value) => ({
  payload: { value },
  type: ImageUploadActionTypes.IS_REFRESHING_ENTITY,
});

export const processImagesError = (value) => ({
  payload: { value },
  type: ImageUploadActionTypes.PROCESS_IMAGES_ERROR,
});

export const processImagesSuccess = (value) => ({
  payload: { value },
  type: ImageUploadActionTypes.PROCESS_IMAGES_SUCCESS,
});

export const receiveImages = (value) => ({
  payload: { value },
  type: ImageUploadActionTypes.RECEIVE_IMAGES,
});

export const reset = () => ({ type: ImageUploadActionTypes.RESET });

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ImageUploadActionTypes.IS_PROCESSING_IMAGES:
      return {
        ...state,
        isProcessingImages: action.payload.value,
      };

    case ImageUploadActionTypes.IS_REFRESHING_ENTITY:
      return {
        ...state,
        isRefreshingEntity: action.payload.value,
      };

    case ImageUploadActionTypes.PROCESS_IMAGES_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };

    case ImageUploadActionTypes.PROCESS_IMAGES_SUCCESS:
      return {
        ...state,
        areImagesProcessed: action.payload.value,
        error: null,
      };

    case ImageUploadActionTypes.RECEIVE_IMAGES:
      return {
        ...state,
        images: action.payload.value,
      };

    case ImageUploadActionTypes.RESET:
      return {};

    default:
      return state;
  }
};
