import { GenericObj } from '@solvhealth/types/interfaces/generics';

export const filterSearchOption = (input: string, option: GenericObj) => {
  if (!option.props.children) return true;

  const stringValues = input.split(' ');
  for (let i = 0; i < stringValues.length; i++) {
    const currentStr = stringValues[i];

    if (option.props.children.toLowerCase().indexOf(currentStr.toLowerCase()) === -1) {
      return false;
    }
  }

  return true;
};

export enum BlobFileAction {
  DOWNLOAD = 'download',
  OPEN = 'open',
}

/**
 * Given a filename, blob, and filetype – this will create an invisible link and click it to download the file with the specified filename.
 * It can alternatively just open the file in a new tab, without downloading, though you can't specify a filename with window.open(), so it
 * will have the blobURL as a name.
 * Here's one of the stackoverflow posts about this that I referenced: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
 *
 * @param {string} fileName The name of the file without the filepath
 * @param {Blob} blob The javascript blob object
 * @param {string} fileType The type of the file (e.g. 'pdf', 'jpeg') for the filepath
 * @param {BlobFileAction} action A BlobFileAction enum value, like DOWNLOAD or OPEN to indicate what to do with the file
 */
export const nameAndDownloadOrOpenFile = (
  fileName: string,
  blob: Blob,
  fileType = 'pdf',
  action = BlobFileAction.DOWNLOAD
) => {
  var file = new Blob([blob], { type: `application/${fileType}` });
  const fileURL = URL.createObjectURL(file);
  if (action === BlobFileAction.OPEN) {
    window.open(fileURL);
  } else if (action === BlobFileAction.DOWNLOAD) {
    var a = document.createElement('a');
    a.setAttribute('style', 'display: none;');
    document.body.appendChild(a);
    a.href = fileURL;
    a.download = `${fileName}.${fileType}`;
    a.click();
    window.URL.revokeObjectURL(fileURL);
    document.body.removeChild(a);
  }
};

/**
 *
 * @param {string} url Presigned AWS S3 Url
 */
export const downloadAwsImage = (url: string) =>
  fetch(url)
    .then((image) => image.blob())
    .then((imageBlob) => {
      const fullFileName = new URL(url).pathname.split('/').pop();
      const [fileName, fileType] = (fullFileName ?? '').split('.');
      if (fileName && fileType) {
        nameAndDownloadOrOpenFile(fileName, imageBlob, fileType);
      } else {
        console.error('Error parsing filename from url:', url);
      }
    })
    .catch((e) => console.warn('Error downloading image:', e));

export const removeDuplicateObjectsFromArray = (arr: GenericObj[], key: string) => {
  const uniqueArr: any[] = [];
  const keys = new Set();
  arr.forEach((item: any) => {
    if (!keys.has(item[key])) {
      uniqueArr.push(item);
      keys.add(item[key]);
    }
  });
  return uniqueArr;
};
