import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetJson } from '../../core/dapi';
import {
  getDentalInsuranceProfileImageFrontSuccess,
  getDentalInsuranceProfileImageBackSuccess,
  getInsuranceProfileImageFrontSuccess,
  getInsuranceProfileImageBackSuccess,
  receivePhotoIdFrontUrl,
  receivePhotoIdBackUrl,
  receivePhotoIdFrontMetdata,
  receivePhotoIdBackMetdata,
} from '../../actions/currentPatient';
import { InsuranceTypes, InsuranceCardSide } from '../../constants';
import { getImageMetadataUrl, getImageUrl } from '../../core/dapi/images';

export class ImagesSagas {
  static FETCH_PRESIGNED_AWS_URL = 'ImagesSagas/FETCH_PRESIGNED_AWS_URL';
  static FETCH_PHOTO_ID_IMAGE_URLS = 'FETCH_PHOTO_ID_IMAGE_URLS';
  static FETCH_PHOTO_ID_METADATA = 'FETCH_PHOTO_ID_METADATA';
}

export function* saveImageUrl(imageUrl, imageType, saveFront, saveBack) {
  if (imageType === InsuranceCardSide.INSURANCE_CARD_FRONT) {
    yield put(saveFront(imageUrl));
  } else if (imageType === InsuranceCardSide.INSURANCE_CARD_BACK) {
    yield put(saveBack(imageUrl));
  }
}

export function* fetchPresignedAwsUrl(imageId, imageType, insuranceType = InsuranceTypes.MEDICAL) {
  if (imageId) {
    const url = getImageUrl(imageId);
    const response = yield call(apiGetJson, url);
    if (response.data) {
      if (insuranceType === InsuranceTypes.DENTAL) {
        yield call(
          saveImageUrl,
          response.data,
          imageType,
          getDentalInsuranceProfileImageFrontSuccess,
          getDentalInsuranceProfileImageBackSuccess
        );
      } else if (insuranceType === InsuranceTypes.MEDICAL) {
        yield call(
          saveImageUrl,
          response.data,
          imageType,
          getInsuranceProfileImageFrontSuccess,
          getInsuranceProfileImageBackSuccess
        );
      }
    }
  }
}

export function* fetchPhotoIdImageUrls({ userProfile }) {
  const photoIdFront = userProfile?.photo_id_front_id;
  const photoIdBack = userProfile?.photo_id_back_id;

  if (photoIdFront) {
    const frontImageUrl = yield call(apiGetJson, getImageUrl(photoIdFront));

    if (frontImageUrl.data) {
      yield put(receivePhotoIdFrontUrl(frontImageUrl.data.image_url));
    }
  }

  if (photoIdBack) {
    const backImageUrl = yield call(apiGetJson, getImageUrl(photoIdBack));

    if (backImageUrl.data) {
      yield put(receivePhotoIdBackUrl(backImageUrl.data.image_url));
    }
  }
}

export function* fetchPhotoIdMetadata({ userProfile }) {
  const photoIdFront = userProfile?.photo_id_front_id;
  const photoIdBack = userProfile?.photo_id_back_id;

  if (photoIdFront) {
    const frontImageMetadata = yield call(apiGetJson, getImageMetadataUrl(photoIdFront));

    if (frontImageMetadata.data) {
      yield put(receivePhotoIdFrontMetdata(frontImageMetadata.data));
    }
  }

  if (photoIdBack) {
    const backImageMetadata = yield call(apiGetJson, getImageMetadataUrl(photoIdBack));

    if (backImageMetadata.data) {
      yield put(receivePhotoIdBackMetdata(backImageMetadata.data));
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(ImagesSagas.FETCH_PRESIGNED_AWS_URL, fetchPresignedAwsUrl);
  yield takeEvery(ImagesSagas.FETCH_PHOTO_ID_IMAGE_URLS, fetchPhotoIdImageUrls);
  yield takeEvery(ImagesSagas.FETCH_PHOTO_ID_METADATA, fetchPhotoIdMetadata);
}
