import { call, put, takeEvery } from 'redux-saga/effects';
import {
  receiveProviderGroupLocations,
  practicesReceived,
  practiceReceived,
  practicesLoading,
  practicesError,
  providerGroupLocationsError,
  providerGroupLocationsLoading,
  groupsLoading,
  groupsReceived,
  groupsError,
} from '../../actions/settings';
import { apiGetAllPages } from './base';
import { apiGet, apiPatch, apiPost } from '../../core/dapi';
import {
  getPracticeUrl,
  getPracticesUrl,
  getPracticeLocationsUrl,
  getGroupPracticesUrl,
  getPaginatedPracticesUrl,
} from '../../core/dapi/practices';
import { getProviderGroupLocationsUrl } from '../../core/dapi/location';
import { getGroupsUrl } from '../../core/dapi/groups';

export class PracticesSagas {
  static FETCH_PRACTICE = 'saga/FETCH_PRACTICE';
  static FETCH_GROUPS = 'saga/FETCH_GROUPS';
  static FETCH_PRACTICES = 'saga/FETCH_PRACTICES';
  static CREATE_PRACTICE = 'saga/CREATE_PRACTICE';
  static FETCH_PROVIDER_GROUP_LOCATIONS = 'saga/FETCH_PROVIDER_GROUP_LOCATIONS';
  static UPDATE_PRACTICE_LOCATIONS = 'saga/UPDATE_PRACTICE_LOCATIONS';
  static UPDATE_GROUP_PRACTICES = 'saga/UPDATE_GROUP_PRACTICES';
  static UPDATE_PRACTICE = 'saga/UPDATE_PRACTICE';
}

function* fetchGroups() {
  yield put(groupsLoading(true));
  try {
    const response = yield call(apiGetAllPages, { url: getGroupsUrl() });
    yield put(groupsReceived(response));
  } catch (e) {
    yield put(groupsError(e));
  } finally {
    yield put(groupsLoading(false));
  }
}

function* fetchPractices({ page = 1, filters }) {
  yield put(practicesLoading(true));
  try {
    const response = yield call(apiGet, getPaginatedPracticesUrl({ page, filters }));
    yield put(practicesReceived(response));
  } catch (e) {
    yield put(practicesError(e));
  } finally {
    yield put(practicesLoading(false));
  }
}

function* createPractice({ postData }) {
  try {
    yield call(apiPost, getPracticesUrl({}), postData);
    yield call(fetchPractices, { page: 1 });
  } catch (e) {
    yield put(practicesError(e));
  } finally {
    yield put(practicesLoading(false));
  }
}

function* fetchPractice({ practiceId }) {
  yield put(practicesLoading(true));
  try {
    const response = yield call(apiGet, getPracticeUrl(practiceId));
    yield put(practiceReceived(response));
  } catch (e) {
    yield put(practicesError(e));
  } finally {
    yield put(practicesLoading(false));
  }
}

function* fetchProviderGroupLocations() {
  yield put(providerGroupLocationsLoading(true));
  try {
    const response = yield call(apiGetAllPages, { url: getProviderGroupLocationsUrl() });
    yield put(receiveProviderGroupLocations(response));
  } catch (e) {
    yield put(providerGroupLocationsError(e));
  } finally {
    yield put(providerGroupLocationsLoading(false));
  }
}

function* updatePractice({ practiceId, patchData }) {
  yield put(practicesLoading(true));
  try {
    const response = yield call(apiPatch, getPracticeUrl(practiceId), patchData);
    yield put(practiceReceived(response));
  } catch (e) {
    yield put(practicesError(e));
  } finally {
    yield put(practicesLoading(false));
  }
}

function* updateGroupPractices({ practiceId, postData }) {
  try {
    const response = yield call(apiPost, getGroupPracticesUrl(practiceId), postData);
    yield put(practiceReceived(response));
  } catch (e) {
    yield put(practicesError(e));
  }
}

function* updatePracticeLocations({ practiceId, postData }) {
  try {
    const response = yield call(apiPost, getPracticeLocationsUrl(practiceId), postData);
    yield put(practiceReceived(response));
  } catch (e) {
    yield put(practicesError(e));
  }
}

export default function* rootSaga() {
  yield takeEvery(PracticesSagas.FETCH_PRACTICE, fetchPractice);
  yield takeEvery(PracticesSagas.FETCH_PRACTICES, fetchPractices);
  yield takeEvery(PracticesSagas.CREATE_PRACTICE, createPractice);
  yield takeEvery(PracticesSagas.FETCH_PROVIDER_GROUP_LOCATIONS, fetchProviderGroupLocations);
  yield takeEvery(PracticesSagas.UPDATE_PRACTICE, updatePractice);
  yield takeEvery(PracticesSagas.UPDATE_PRACTICE_LOCATIONS, updatePracticeLocations);
  yield takeEvery(PracticesSagas.UPDATE_GROUP_PRACTICES, updateGroupPractices);
  yield takeEvery(PracticesSagas.FETCH_GROUPS, fetchGroups);
}
