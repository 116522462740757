import { call, put } from 'redux-saga/effects';
import { groupsError, receiveGroup } from '../../actions/groups';
import { apiGet } from '../../core/dapi';
import { getGroupUrl } from '../../core/dapi/groups';

export function* fetchGroup({ groupId }) {
  try {
    yield put(receiveGroup(yield call(apiGet, getGroupUrl(groupId))));
  } catch (e) {
    yield put(groupsError(e));
  }
}
