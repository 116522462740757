import {
  BOOKINGS_GRAPH_DATA_ERROR,
  BOOKINGS_GRAPH_DATA_RECEIVED,
  SET_GRAPH_LOCATIONS,
  RESET_REPORTS,
} from '../constants';

export default function graphsReducer(state = {}, action) {
  switch (action.type) {
    case BOOKINGS_GRAPH_DATA_RECEIVED: {
      const newState = { ...state };
      newState[action.payload.type] = action.payload.value;
      return newState;
    }

    case BOOKINGS_GRAPH_DATA_ERROR: {
      const newState = { ...state };
      newState.error = action.payload;
      return newState;
    }

    case SET_GRAPH_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };

    case RESET_REPORTS:
      return {};

    default:
      return state;
  }
}
