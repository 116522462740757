import { DAPI_AUTH_ENABLED } from '../../config';
import { getDapiAuthHeaders } from '../auth';

export const getOptions = () => {
  let options = {};
  if (DAPI_AUTH_ENABLED) {
    options = { headers: getDapiAuthHeaders() };
  }

  return options;
};

export const postOptions = (postData) => {
  let options = { headers: {} };

  if (DAPI_AUTH_ENABLED) {
    options = { headers: getDapiAuthHeaders() };
  }

  if (postData instanceof FormData) {
    options.method = 'POST';
    options.body = postData;
  } else if (typeof postData === 'object') {
    options.method = 'POST';
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(postData);
  }

  return options;
};

export const patchOptions = (patchData) => {
  let options = { headers: {} };
  if (DAPI_AUTH_ENABLED) {
    options = { headers: getDapiAuthHeaders() };
  }

  if (patchData instanceof FormData) {
    options.method = 'PATCH';
    options.body = patchData;
  } else {
    options.method = 'PATCH';
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(patchData);
  }

  return options;
};

export const deleteOptions = async (deleteData) => {
  let options = { headers: {} };
  if (DAPI_AUTH_ENABLED) {
    options = { headers: getDapiAuthHeaders() };
  }

  if (deleteData instanceof FormData) {
    options.method = 'DELETE';
    options.body = deleteData;
  } else if (typeof deleteData === 'object') {
    options.method = 'DELETE';
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(deleteData);
  }

  return options;
};
