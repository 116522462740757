import {
  PULSES_RECEIVE_DATA,
  PULSES_RECEIVE_DATA_ERROR,
  PULSES_CLEAR_ERRORS,
  PULSES_PATCH_DATA,
  PULSE_SET_LOCATION,
} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case PULSES_RECEIVE_DATA:
      return {
        ...state,
        pulseData: action.payload.value,
      };
    case PULSES_RECEIVE_DATA_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case PULSES_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case PULSES_PATCH_DATA:
      return {
        ...state,
        loading: action.payload.value,
      };
    case PULSE_SET_LOCATION:
      return {
        ...state,
        currentPulseLocation: action.payload.value,
      };
    default:
      return state;
  }
};
