import { DAPI_HOST } from '../../config';

const getLocationConsentFormsUrl = (activeOnly, locationId) => {
  let url = `${DAPI_HOST}/v1/location-consent-forms?`;

  if (activeOnly === true || activeOnly === false) {
    url += `active_only=${activeOnly}`;
  }

  if (locationId) {
    url += `&location_id=${locationId}`;
  }

  return url;
};

const getLocationConsentFormUrl = locationConsentFormId =>
  `${DAPI_HOST}/v1/location-consent-forms/${locationConsentFormId}`;

export { getLocationConsentFormsUrl, getLocationConsentFormUrl };
