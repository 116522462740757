import { EMPTY_OBJECT } from '../core/util/object';

const RECEIVE_HANDOFF_CODE = 'RECEIVE_HANDOFF_CODE';
const HANDOFF_CODE_ERROR = 'HANDOFF_CODE_ERROR';
const TOGGLE_POPOVER_VISIBILITY = 'TOGGLE_POPOVER_VISIBILITY';

const receiveHandoffCode = (value, handoffType) => ({
  type: RECEIVE_HANDOFF_CODE,
  payload: { value, handoffType },
});

const handoffCodeError = (value, handoffType) => ({
  type: HANDOFF_CODE_ERROR,
  payload: { value, handoffType },
});

const togglePopoverVisibility = (value, handoffType) => ({
  type: TOGGLE_POPOVER_VISIBILITY,
  payload: { value, handoffType },
});

const reducer = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  switch (action.type) {
    case RECEIVE_HANDOFF_CODE:
      return {
        ...state,
        [`${action.payload.handoffType}Code`]: action.payload.value,
        [`${action.payload.handoffType}CodeError`]: null,
      };
    case HANDOFF_CODE_ERROR:
      return {
        ...state,
        [`${action.payload.handoffType}CodeError`]: action.payload.value,
      };
    case TOGGLE_POPOVER_VISIBILITY:
      return {
        ...state,
        [`isVisible${action.payload.handoffType}`]: action.payload.value,
      };
    default:
      return state;
  }
};

export { reducer as default, receiveHandoffCode, handoffCodeError, togglePopoverVisibility };
