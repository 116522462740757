import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Row } from 'antd';
import { colors } from '../../../../constants/colors';
import { isMobile } from '../../../../core/util/device';
import SearchBar from './SearchBar';
import SelectAll from './SelectAll';
import { array, bool, func, number } from 'prop-types';

const HeaderWrapper = styled(Row)`
  background-color: ${colors.fatherJohnMisty};
  border: 1px solid ${colors.dorianGrey};
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 12px;
  flex-wrap: nowrap;
`;

const HeaderLeft = styled.div(
  (props) => css`
    display: flex;
    align-items: center;
    flex: auto;

    ${props.isMobile &&
    css`
      max-width: 50%;
      > div {
        width: 100px;
      }
    `};
  `
);

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 12px;
`;

const Clear = styled.div`
  margin-left: 10px;
  color: #4291f7;
  cursor: pointer;
`;

const Header = (props) => {
  const {
    disabledOptions,
    getLabel,
    getValue,
    handleChange,
    handleUnselectAll,
    isMultipleAllowed,
    limit,
    options,
    onButtonSubmit,
    selectedOptions,
    setCheckboxOptions,
    setSearchKeyword,
    shouldDisableButton,
    shouldShowLimitText,
    showSelectAll,
  } = props;

  const isApplyButtonDisabled = shouldDisableButton || selectedOptions.length < 1;

  if (options.length > limit) {
    return (
      <HeaderWrapper align="middle" justify="space-between" type="flex">
        <HeaderLeft isMobile={isMobile()}>
          <SearchBar
            getLabel={getLabel}
            options={options}
            setCheckboxOptions={setCheckboxOptions}
            setSearchKeyword={setSearchKeyword}
          />
          <Clear onClick={handleUnselectAll} data-testid={'checkbox-group-clear-all'}>
            Clear all
          </Clear>
        </HeaderLeft>
        <HeaderRight>
          <SelectAll
            getValue={getValue}
            handleChange={handleChange}
            isMultipleAllowed={isMultipleAllowed}
            limit={limit}
            options={options}
            selectedOptions={selectedOptions}
            disabledOptions={disabledOptions}
            shouldShowLimitText={shouldShowLimitText}
            showSelectAll={showSelectAll}
          />
          {onButtonSubmit && (
            <StyledButton type="primary" onClick={onButtonSubmit} disabled={isApplyButtonDisabled}>
              Apply
            </StyledButton>
          )}
        </HeaderRight>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper align="middle" justify="space-between" type="flex">
      <HeaderLeft>
        <SelectAll
          getValue={getValue}
          handleChange={handleChange}
          isMultipleAllowed={isMultipleAllowed}
          limit={limit}
          options={options}
          selectedOptions={selectedOptions}
          disabledOptions={disabledOptions}
          shouldShowLimitText={shouldShowLimitText}
          showSelectAll={showSelectAll}
        />
        {onButtonSubmit && (
          <StyledButton type="primary" onClick={onButtonSubmit} disabled={isApplyButtonDisabled}>
            Apply
          </StyledButton>
        )}
      </HeaderLeft>
      <SearchBar
        getLabel={getLabel}
        options={options}
        setCheckboxOptions={setCheckboxOptions}
        setSearchKeyword={setSearchKeyword}
      />
    </HeaderWrapper>
  );
};

Header.propTypes = {
  disabledOptions: array,
  getLabel: func.isRequired,
  getValue: func.isRequired,
  handleChange: func,
  handleUnselectAll: func,
  isMultipleAllowed: bool,
  limit: number,
  options: array,
  onButtonSubmit: func,
  selectedOptions: array,
  setCheckboxOptions: func,
  setSearchKeyword: func,
  shouldDisableButton: bool,
  shouldShowLimitText: bool,
  showSelectAll: bool,
};

export default Header;
