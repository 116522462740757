import { call, takeEvery, put } from 'redux-saga/effects';
import { apiGet, apiGetJson, apiPostJson } from '../core/dapi';
import { deleteS3ImageUrl, getS3ImagesUrl } from '../core/dapi/cardUpload';
import {
  processImagesError,
  processImagesSuccess,
  isProcessingImages,
  receiveImages,
  isRefreshingEntity,
} from '../ducks/imageUpload';
import { getProcessImagesUrl } from '../core/dapi/location';
import { fetchLocation } from './core/locations';
import { fetchProvider } from './core/providers';
import { fetchGroup } from './core/groups';

export class ImageUploadSagas {
  static DELETE_IMAGE = 'saga/DELETE_IMAGE';
  static FETCH_IMAGES = 'saga/FETCH_IMAGES';
  static PROCESS_IMAGES = 'saga/PROCESS_IMAGES';
  static REFRESH_DATA = 'saga/REFRESH_DATA';
}

function* deleteImage({ entityId, entityType, path }) {
  yield call(apiPostJson, deleteS3ImageUrl(), { path });

  const getResponse = yield call(apiGetJson, getS3ImagesUrl(entityId, entityType));
  yield put(receiveImages(getResponse));
}

function* fetchImages({ entityId, entityType }) {
  const response = yield call(apiGetJson, getS3ImagesUrl(entityId, entityType));
  yield put(receiveImages(response));
}

function* processImages({ entityId, entityType }) {
  yield put(isProcessingImages(true));

  try {
    yield call(apiGet, getProcessImagesUrl(entityId, entityType));
    yield put(processImagesSuccess(true));
  } catch (e) {
    yield put(processImagesError(e));
  } finally {
    yield put(isProcessingImages(false));
  }
}

function* refreshData({ entityId, entityType }) {
  yield put(isRefreshingEntity(true));

  let refresh;
  let params;
  if (entityType === 'group') {
    refresh = fetchGroup;
    params = { groupId: entityId };
  } else if (entityType === 'location') {
    refresh = fetchLocation;
    params = { locationId: entityId };
  } else if (entityType === 'provider') {
    refresh = fetchProvider;
    params = { providerId: entityId };
  }

  yield call(refresh, params);

  yield put(isRefreshingEntity(false));
}

export default function* rootSaga() {
  yield takeEvery(ImageUploadSagas.DELETE_IMAGE, deleteImage);
  yield takeEvery(ImageUploadSagas.FETCH_IMAGES, fetchImages);
  yield takeEvery(ImageUploadSagas.PROCESS_IMAGES, processImages);
  yield takeEvery(ImageUploadSagas.REFRESH_DATA, refreshData);
}
