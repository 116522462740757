import {
  ELIGIBILITY_CHECK_RECEIVED,
  ELIGIBILITY_CHECK_ERROR,
  ELIGIBILITY_CHECK_SUBMITTED,
  ELIGIBILITY_FACESHEET_RECEIVED,
  ELIGIBILITY_FACESHEET_ERROR,
  ELIGIBILITY_FACESHEET_RESET,
  ELIGIBILITY_FEEDBACK_SUCCESS,
  ELIGIBILITY_FEEDBACK_FAILURE,
  ELIGIBILITY_FEEDBACK_SUBMIT,
} from '../constants';

const eligibilityCheckSubmitted = () => ({
  type: ELIGIBILITY_CHECK_SUBMITTED,
});

const eligibilityCheckReceived = value => {
  const action = {
    type: ELIGIBILITY_CHECK_RECEIVED,
    payload: {},
  };
  action.payload = value;
  return action;
};

const eligibilityCheckError = value => ({
  type: ELIGIBILITY_CHECK_ERROR,
  payload: { value },
});

const eligibilityFaceSheetReceived = value => {
  const action = {
    type: ELIGIBILITY_FACESHEET_RECEIVED,
    payload: {},
  };
  action.payload = value;
  return action;
};

const eligibilityFaceSheetError = value => ({
  type: ELIGIBILITY_FACESHEET_ERROR,
  payload: { value },
});

const eligibilityFaceSheetReset = value => ({
  type: ELIGIBILITY_FACESHEET_RESET,
  payload: { value },
});

const eligibilityFeedbackSubmit = value => ({
  type: ELIGIBILITY_FEEDBACK_SUBMIT,
  payload: { value },
});

const eligibilityFeedbackSuccess = value => ({
  type: ELIGIBILITY_FEEDBACK_SUCCESS,
  payload: { value },
});

const eligibilityFeedbackFailure = value => ({
  type: ELIGIBILITY_FEEDBACK_FAILURE,
  payload: { value },
});

export {
  eligibilityCheckReceived,
  eligibilityCheckError,
  eligibilityCheckSubmitted,
  eligibilityFaceSheetReceived,
  eligibilityFaceSheetError,
  eligibilityFaceSheetReset,
  eligibilityFeedbackSuccess,
  eligibilityFeedbackFailure,
  eligibilityFeedbackSubmit,
};
