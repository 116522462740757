import { Checkbox, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Label } from '../../primitives';

const FormItem = Form.Item;

const CheckboxGroupFormField = ({
  form,
  fieldName,
  initialValue,
  label,
  options,
  location,
  showCopyIcon,
  fieldInfoTooltipDescription,
  showEditedIcon,
  showIsOptional,
}) => {
  const formValue = form.getFieldValue(fieldName);
  const copyValue = formValue ?? initialValue;

  return (
    <FormItem>
      <Label
        label={label}
        showCopyIcon={showCopyIcon}
        location={location}
        fieldInfoTooltipDescription={fieldInfoTooltipDescription}
        copyValue={copyValue}
        showEditedIcon={showEditedIcon}
        showIsOptional={showIsOptional}
      />
      {form.getFieldDecorator(fieldName, { initialValue })(<Checkbox.Group options={options} />)}
    </FormItem>
  );
};

CheckboxGroupFormField.propTypes = {
  form: PropTypes.object,
  fieldName: PropTypes.string,
  initialValue: PropTypes.array,
  label: PropTypes.string,
  options: PropTypes.array,
  location: PropTypes.object,
  showCopyIcon: PropTypes.bool,
  showEditedIcon: PropTypes.bool,
  fieldInfoTooltipDescription: PropTypes.string,
  showIsOptional: PropTypes.bool,
};

export default CheckboxGroupFormField;
