import { useDispatch, useSelector } from '../types/Redux';
import { useCallback, useEffect } from 'react';
import { createAction } from '@reduxjs/toolkit';
import { AccountLocation, Location } from '../types/RootState';

export const RUN_MIGRATION = 'RUN_MIGRATION';

export enum ReduxMigration {
  AddLocationType,
}

export const RunMigrationAction = createAction<ReduxMigration, string>(RUN_MIGRATION);

const EMPTY_OBJECT: Record<string, Location> = {};
const EMPTY_ARRAY: AccountLocation[] = [];

/**
 * Monitor Redux state and initiate migrations when necessary.
 * Redux migrations accommodate discrepancies between newer shapes and formats of Redux state and older,
 * out-of-date state we might encounter from redux-persist.
 *
 * TLDR: makes sure that persisted redux state in the users browser gets updated with correct fields when our shape changes.
 */
export function useReduxMigrate() {
  const dispatch = useDispatch();
  const accountLocations = useSelector((s) => s.login?.account?.locations ?? EMPTY_ARRAY);
  const locations = useSelector((s) => s.locations?.results ?? EMPTY_OBJECT);

  const runMigration = useCallback(
    (migration: ReduxMigration) => {
      dispatch({
        type: RUN_MIGRATION,
        payload: migration,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    for (let location of accountLocations) {
      if (location.$type !== 'account.location') {
        runMigration(ReduxMigration.AddLocationType);
        break;
      }
    }

    const locationKeys = Object.keys(locations);
    for (let locationKey of locationKeys) {
      if (locations[locationKey].$type !== 'location') {
        runMigration(ReduxMigration.AddLocationType);
        break;
      }
    }
  }, [accountLocations, locations, runMigration]);
}
