import {
  REVIEWS_DATA_ERROR,
  REVIEWS_DATA_RECEIVED,
  REVIEWS_NEW_REVIEWS_RECEIVED,
  RESET_REVIEW_COUNTER,
  STALE_REVIEWS_DATA,
} from '../constants';

export const createInitialState = () => ({
  data: [],
  reviewCounter: 0,
  /** @type {Error} */
  error: null,
  stale: true,
});

/**
 * General reviews-object reducer. This is not scoped to a particular location.
 * That per-location logic is handled by the locations reducer.
 * @param {{ type: string }} action
 * @returns {ReturnType<typeof createInitialState>}
 */
export default function reviewsReducer(state = createInitialState(), action) {
  switch (action.type) {
    case REVIEWS_DATA_RECEIVED: {
      const {
        payload: {
          value: { results },
          numberOfNewReviews,
        },
      } = action;
      return {
        ...state,
        data: results,
        reviewCounter: numberOfNewReviews || 0,
        stale: false,
      };
    }

    case REVIEWS_NEW_REVIEWS_RECEIVED: {
      const data = state.data;
      return {
        ...state,
        data: data.concat(action.payload.value.results),
        reviewCounter: action.payload.value.results.length,
      };
    }

    case REVIEWS_DATA_ERROR: {
      return {
        ...state,
        error: action.payload.value,
        dateTimeDataFetched: null,
        stale: true,
      };
    }

    case RESET_REVIEW_COUNTER: {
      return {
        ...state,
        reviewCounter: 0,
      };
    }

    case STALE_REVIEWS_DATA: {
      return {
        ...state,
        stale: true,
      };
    }

    default:
      return state;
  }
}
