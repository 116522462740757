import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { formatBookingDateOfBirth } from '../../../core/util/booking';
import { stripNonnumeric } from '../../../core/util/string';
import BirthDateMaskedInput from '../../Form/BirthDateMaskedInput';
import { Label } from '../../primitives';
import { BASE_FIELD_VALIDATIONS } from '../../../core/validation/commonFieldValidations';

const FormItem = Form.Item;

const BirthDateInputFormField = ({
  form,
  fieldName,
  initialValue,
  label,
  location,
  showCopyIcon,
  showEditedIcon,
  fieldInfoTooltipDescription,
  isRequired,
  showIsOptional,
  validateTrigger,
}) => {
  const formattedInitialValue = stripNonnumeric(formatBookingDateOfBirth(initialValue));
  const formValue = form.getFieldValue(fieldName)?.birthDate;
  const copyValue = formValue ?? formattedInitialValue;

  return (
    <FormItem>
      <Label
        label={label}
        showCopyIcon={showCopyIcon}
        initialValue={initialValue}
        location={location}
        fieldInfoTooltipDescription={fieldInfoTooltipDescription}
        copyValue={copyValue}
        showEditedIcon={showEditedIcon}
        showIsOptional={showIsOptional}
      />
      {form.getFieldDecorator(fieldName, {
        initialValue: { birthDate: formattedInitialValue },
        rules: isRequired
          ? BASE_FIELD_VALIDATIONS.DateOfBirthRequired
          : BASE_FIELD_VALIDATIONS.DateOfBirth,
        validateTrigger,
      })(<BirthDateMaskedInput enableMask />)}
    </FormItem>
  );
};

BirthDateInputFormField.propTypes = {
  form: PropTypes.object,
  fieldName: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  location: PropTypes.object,
  showCopyIcon: PropTypes.bool,
  showEditedIcon: PropTypes.bool,
  fieldInfoTooltipDescription: PropTypes.string,
  isRequired: PropTypes.bool,
  showIsOptional: PropTypes.bool,
  validateTrigger: PropTypes.string,
};

BirthDateInputFormField.defaultProps = {
  isRequired: false,
};

export default BirthDateInputFormField;
