import { camelToSnake } from './string';
import { isEmpty } from './empty';
import { GenericObject } from './generics';

const EMPTY_OBJECT: GenericObject = {};

const replaceErrors = (key: string, value: Error | any) => {
  if (value instanceof Error) {
    const error: GenericObject = {};

    Object.getOwnPropertyNames(value).forEach((errKey) => {
      // @ts-ignore
      error[errKey] = value[errKey];
    });

    return error;
  }

  return value;
};

const isEmptyObject = (obj: any): boolean => {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }

  if (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  return true;
};

const recursivelyRemoveEmptyValues = (obj: GenericObject): boolean => {
  if (isEmpty(obj)) {
    return false;
  }

  if (typeof obj !== 'object') {
    return true;
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (!recursivelyRemoveEmptyValues(value)) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return !isEmptyObject(obj);
};

const filterEmptyValues = (obj: GenericObject) => {
  if (isEmptyObject(obj)) {
    return obj;
  }

  const filtered: GenericObject = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'undefined' || value === null || value === '') {
      continue;
    }

    filtered[key] = value;
  }

  return filtered;
};

/**
 * @deprecated use optional chaining instead
 */
const safeGet = (obj: GenericObject, defaultVal: any = undefined) => {
  return (path: string | string[]) => {
    const [p] = typeof path === 'object' ? path : [path];

    let retValue = obj;

    for (const key of p.split('.')) {
      try {
        retValue = retValue[key];
      } catch (e) {
        return defaultVal;
      }
    }

    return typeof retValue === 'undefined' ? defaultVal : retValue;
  };
};

const convertSnakeToCamelCase = (obj: GenericObject): GenericObject => {
  if (isEmptyObject(obj)) {
    return obj;
  }

  const converted: GenericObject = {};
  for (const key of Object.keys(obj)) {
    const newKey = key.replace(/_(\w)/g, (originalString, firstCharacter) =>
      firstCharacter.toUpperCase()
    );
    converted[newKey] = obj[key];
  }

  return converted;
};

const getKeyByValue = (obj: GenericObject, value: any) =>
  Object.keys(obj).find((key) => obj[key] === value);

const convertSnakeToCamelCaseRecursive = (obj: GenericObject | Array<any>) => {
  if (typeof obj !== 'object' || isEmptyObject(obj)) {
    return obj;
  }

  function helper(curObj: GenericObject, i: number) {
    const updatedObj = convertSnakeToCamelCaseRecursive(curObj);
    // @ts-ignore
    const key = Array.isArray(this) ? i : getKeyByValue(this, curObj);
    // @ts-ignore
    this[key] = updatedObj;
  }

  const base = Array.isArray(obj) ? obj : convertSnakeToCamelCase(obj);
  Object.values(base).forEach(helper, base);

  return base;
};

const convertCamelToSnakeCase = (obj: GenericObject) => {
  if (isEmptyObject(obj)) {
    return obj;
  }

  const converted: GenericObject = {};
  for (const [key, value] of Object.entries(obj)) {
    converted[camelToSnake(key)] = value;
  }

  return converted;
};

type SerializableValue = null | string | number | boolean | SerializableObject | SerializableArray;
type SerializableObject = {
  [x: string]: SerializableValue;
};
type SerializableArray = SerializableValue[];

export function convertCamelToSnakeCaseRecursive(val: SerializableValue): SerializableValue {
  if (val == null) {
    return val;
  }
  if (Array.isArray(val)) {
    return val.map(convertCamelToSnakeCaseRecursive);
  }
  if (typeof val === 'object') {
    return Object.fromEntries(
      Object.entries(val).map(([key, valForKey]) => [
        camelToSnake(key),
        convertCamelToSnakeCaseRecursive(valForKey),
      ])
    );
  }
  return val;
}

const isShallowObjectsEqual = (obj1: GenericObject, obj2: GenericObject) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export {
  EMPTY_OBJECT,
  isEmptyObject,
  safeGet,
  replaceErrors,
  filterEmptyValues,
  convertSnakeToCamelCase,
  convertSnakeToCamelCaseRecursive,
  convertCamelToSnakeCase,
  recursivelyRemoveEmptyValues,
  isShallowObjectsEqual,
};
