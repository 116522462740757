import {
  HttpClient,
  httpDeleteJson,
  httpGetJson,
  httpPatchJson,
  httpPostJson,
  RequestOptions,
} from './http';
import { DAPI_CACTUS_HOST, DAPI_CLOUDFRONT_HOST, DAPI_HOST } from '../../config';

const initSlashRegex = /^\/+/;

/** Remove a redundant initial slash from a url-path string. */
const trimSlash = (urlPath = '') => {
  if (!urlPath) return urlPath;
  return urlPath.trim().replace(initSlashRegex, '');
};

export const dapiClient: HttpClient = {
  deleteJson<T>(url: string, deleteBody: any, opts?: RequestOptions): Promise<T> {
    return httpDeleteJson(`${DAPI_HOST}/${trimSlash(url)}`, deleteBody, opts);
  },
  getJson<T>(url: string, opts?: RequestOptions): Promise<T> {
    return httpGetJson(`${DAPI_HOST}/${trimSlash(url)}`, opts);
  },
  patchJson<T>(url: string, patchBody: any, opts?: RequestOptions): Promise<T> {
    return httpPatchJson(`${DAPI_HOST}/${trimSlash(url)}`, patchBody, opts);
  },
  postJson<T>(url: string, postBody: any, opts?: RequestOptions): Promise<T> {
    return httpPostJson(`${DAPI_HOST}/${trimSlash(url)}`, postBody, opts);
  },
};

export const dapiCactusClient: HttpClient = {
  deleteJson<T>(url: string, deleteBody: any, opts?: RequestOptions): Promise<T> {
    return httpDeleteJson(`${DAPI_CACTUS_HOST}/${trimSlash(url)}`, deleteBody, opts);
  },
  getJson<T>(url: string, opts?: RequestOptions): Promise<T> {
    return httpGetJson(`${DAPI_CACTUS_HOST}/${trimSlash(url)}`, opts);
  },
  patchJson<T>(url: string, patchBody: any, opts?: RequestOptions): Promise<T> {
    return httpPatchJson(`${DAPI_CACTUS_HOST}/${trimSlash(url)}`, patchBody, opts);
  },
  postJson<T>(url: string, postBody: any, opts?: RequestOptions): Promise<T> {
    return httpPostJson(`${DAPI_CACTUS_HOST}/${trimSlash(url)}`, postBody, opts);
  },
};

export const dapiCloudFrontClient: HttpClient = {
  deleteJson<T>(url: string, deleteBody: any, opts?: RequestOptions): Promise<T> {
    return httpDeleteJson(`${DAPI_CLOUDFRONT_HOST}/${trimSlash(url)}`, deleteBody, opts);
  },
  getJson<T>(url: string, opts?: RequestOptions): Promise<T> {
    return httpGetJson(`${DAPI_CLOUDFRONT_HOST}/${trimSlash(url)}`, opts);
  },
  patchJson<T>(url: string, patchBody: any, opts?: RequestOptions): Promise<T> {
    return httpPatchJson(`${DAPI_CLOUDFRONT_HOST}/${trimSlash(url)}`, patchBody, opts);
  },
  postJson<T>(url: string, postBody: any, opts?: RequestOptions): Promise<T> {
    return httpPostJson(`${DAPI_CLOUDFRONT_HOST}/${trimSlash(url)}`, postBody, opts);
  },
};
