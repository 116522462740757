import { isEmptyString, stripNonnumeric } from './string';
import { EMPTY_STRING } from '../../constants';

const databasePhoneRegex = /\+\d{11}/;

const isDatabasePhone = phone => databasePhoneRegex.test(phone);

const dataBasePhoneValueToDisplay = phone => {
  if (isEmptyString(phone)) {
    return EMPTY_STRING;
  }

  if (phone.length !== 12) {
    return phone;
  }

  return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8)}`;
};

const databasePhoneValueToTenDigits = phone => (phone || EMPTY_STRING).substring(2);

const sanitizePhone = phone => {
  const sanitizedPhone = stripNonnumeric(phone);
  return sanitizedPhone.replace(/^1/, '');
};

const normalizePhone = phone => {
  let sanitizedPhone = sanitizePhone(phone);
  if (sanitizedPhone.length === 10) {
    sanitizedPhone = `+1${sanitizedPhone}`;
  }

  return sanitizedPhone;
};

export {
  dataBasePhoneValueToDisplay,
  databasePhoneValueToTenDigits,
  isDatabasePhone,
  normalizePhone,
  sanitizePhone,
};
