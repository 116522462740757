import { EMPTY_OBJECT } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export class ProviderLocationsActionTypes {
  static ERROR = 'providerLocations/ERROR';
  static LOADING = 'providerLocations/LOADING';
  static RECEIVED = 'providerLocations/RECEIVED';
  static SYNCING = 'providerLocations/SYNCING';
  static UPDATED = 'providerLocations/UPDATED';
  static UPDATING = 'providerLocations/UPDATING';
}

export const providerLocationsError = (value) => ({
  payload: { value },
  type: ProviderLocationsActionTypes.ERROR,
});

export const providerLocationsLoading = (value) => ({
  payload: { value },
  type: ProviderLocationsActionTypes.LOADING,
});

export const providerLocationsReceived = (value) => ({
  payload: { value },
  type: ProviderLocationsActionTypes.RECEIVED,
});

export const providerLocationsSyncing = (isSyncing) => ({
  payload: { isSyncing },
  type: ProviderLocationsActionTypes.SYNCING,
});

export const providerLocationUpdated = (value) => ({
  payload: { value },
  type: ProviderLocationsActionTypes.UPDATED,
});

export const providerLocationUpdating = (value) => ({
  payload: { value },
  type: ProviderLocationsActionTypes.UPDATING,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ProviderLocationsActionTypes.ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case ProviderLocationsActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ProviderLocationsActionTypes.RECEIVED:
      return {
        ...state,
        results: action.payload.value,
      };
    case ProviderLocationsActionTypes.SYNCING:
      return {
        ...state,
        isSyncing: action.payload.isSyncing,
      };
    case ProviderLocationsActionTypes.UPDATED: {
      const newResults = (state.results ?? EMPTY_ARRAY)
        .slice()
        .filter((pl) => pl.id !== action.payload.value.id);
      newResults.push(action.payload.value);

      return {
        ...state,
        error: null,
        results: newResults,
      };
    }
    case ProviderLocationsActionTypes.UPDATING: {
      return {
        ...state,
        isUpdating: action.payload.value,
      };
    }
    default:
      return state;
  }
};
