import { v4 as uuidV4 } from 'uuid';
import { DAPI_HOST } from '../../config';

export const getLocationPaperworkFieldsUrl = (
  locationId,
  fieldsType,
  { activeOnly = true, cacheBust = false } = {}
) => {
  let url = `${DAPI_HOST}/v1/location-paperwork-fields?location_id=${locationId}`;

  if (fieldsType) {
    url += `&type_=${fieldsType}`;
  }

  if (!activeOnly) {
    url += `&include_inactive=${true}`;
  }

  if (cacheBust) {
    url += `&cacheBust=${uuidV4()}`;
  }

  return url;
};

export const getPostLocationPaperworkFieldsUrl = (locationId) =>
  `${DAPI_HOST}/v1/location-paperwork-fields/${locationId}`;

export const getUpsertManyLocationPaperworkFieldsUrl = () =>
  `${DAPI_HOST}/v1/location-paperwork-fields`;

export const getReplicateLocationPaperworkUrl = () =>
  `${DAPI_HOST}/v1/location-paperwork-fields/replicate-location`;
