import { Button, Modal } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { resetReviewCounter } from '../../actions/reviews';
import { setRuntimeVariable } from '../../actions/runtime';
import StarRating from '../../components/StarRating/StarRating';
import { LAST_REVIEW_SEEN_COOKIE_NAME } from '../../config';
import { getCookie, setCookie } from '../../core/util/cookie';
import { timeAgo } from '../../core/util/time';
import { selectors as locationsSelectors } from '../../ducks/locations';
import {
  selectors as modalLocationIdsSelectors,
  setReviewsModalLocationId,
} from '../../ducks/modalLocationIds';
import { getQueueSelectedLocationIds } from '../../selectors/queue';
import ReviewsModalLocationSelect from './ReviewsModalLocationSelect';

export const getLastReviewSeen = () => getCookie(LAST_REVIEW_SEEN_COOKIE_NAME);
export const setLastReviewSeen = () => setCookie(LAST_REVIEW_SEEN_COOKIE_NAME, moment.utc(), 90);

const ReviewsModal = ({
  resetReviewCounter: doResetReviewCounter,
  reviews,
  toggleReviewsModal,
  visible,
  locationId,
  availableLocationIds,
  onChangeLocationId,
}) => {
  useEffect(() => {
    if (locationId && reviews.data.length > 0) {
      setLastReviewSeen();
    }
  }, [locationId, reviews.data]);

  const renderSourceIcons = (review) => {
    if (review.source === 'solv') {
      return <img alt="Solv Health logo" src="/favicon.png" width="16" height="16" />;
    } else if (review.source === 'google') {
      return <img alt="Google logo" src="/images/icons/google.png" width="16" height="16" />;
    }

    return null;
  };

  const handleDismissModal = () => {
    toggleReviewsModal(false);
    availableLocationIds.forEach(doResetReviewCounter);
  };

  const chronologicalReviews = useMemo(() => {
    const { data = [] } = reviews ?? {};
    return [...data].sort(
      (a, b) => moment(b.rating_date).valueOf() - moment(a.rating_date).valueOf()
    );
  }, [reviews]);

  const getReviewsModalContent = () => (
    <div className="reviewContent">
      {chronologicalReviews.map((review, i) => (
        <div key={i} className="review-corpus">
          <div className="review-container">
            <div>
              {renderSourceIcons(review)}
              <StarRating starRating={review.rating} containerClassName="review-star" size={15} />
            </div>
            <div>{timeAgo(review.rating_date)}</div>
          </div>
          <div>{review.review}</div>
        </div>
      ))}
      {(reviews?.data?.length ?? 0) === 0 && (
        <div>{!!locationId ? 'No recent reviews available.' : 'Please select a location.'}</div>
      )}
    </div>
  );

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible
      onCancel={handleDismissModal}
      title={
        availableLocationIds.length < 2 ? (
          'Recent Reviews'
        ) : (
          <ReviewsModalLocationSelect
            locationId={locationId}
            locationIdOptions={availableLocationIds}
            onChange={onChangeLocationId}
          />
        )
      }
      footer={
        <Button onClick={handleDismissModal} type="primary">
          Ok
        </Button>
      }
    >
      {getReviewsModalContent()}
    </Modal>
  );
};

ReviewsModal.propTypes = {
  resetReviewCounter: PropTypes.func,
  reviews: PropTypes.object,
  toggleReviewsModal: PropTypes.func,
  visible: PropTypes.bool,
  availableLocationIds: PropTypes.arrayOf(PropTypes.string),
  locationId: PropTypes.string,
  onChangeLocationId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const queueSelectedLocationIds = getQueueSelectedLocationIds(state);
  let locationId = modalLocationIdsSelectors.getReviewsModalSelectedLocationId(state);
  if (locationId == null && queueSelectedLocationIds?.length === 1) {
    locationId = queueSelectedLocationIds[0] ?? null;
  }
  return {
    availableLocationIds: queueSelectedLocationIds,
    locationId,
    reviews: locationsSelectors.getLocationReviews(state, locationId),
    visible: state.runtime.showReviewsModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetReviewCounter(locationId) {
    dispatch(resetReviewCounter({ locationId: locationId || undefined }));
  },

  toggleReviewsModal(visible) {
    dispatch(setRuntimeVariable({ name: 'showReviewsModal', value: visible }));
  },

  onChangeLocationId(locationId) {
    dispatch(setReviewsModalLocationId(locationId || null));
  },
});

const ConnectedReviewsModal = connect(mapStateToProps, mapDispatchToProps)(ReviewsModal);

export default ConnectedReviewsModal;
