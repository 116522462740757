import { v4 as uuidV4 } from 'uuid';

export default class Notifications {
  static SUBSCRIPTION_CHANGE_KEY = 'SUBSCRIPTION_CHANGE';

  static urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  constructor(pushManager, applicationServerKey) {
    this.applicationServerKey = Notifications.urlB64ToUint8Array(applicationServerKey);
    this.pushManager = pushManager;
    this.serviceWorker = navigator.serviceWorker;

    this.listeners = {};
  }

  subscribe() {
    const { applicationServerKey, pushManager } = this;

    return pushManager
      .subscribe({
        applicationServerKey,
        userVisibleOnly: true,
      })
      .then((subscription) => {
        this.callListeners(Notifications.SUBSCRIPTION_CHANGE_KEY, subscription);
        return subscription;
      });
  }

  getSubscription() {
    return this.pushManager.getSubscription();
  }

  onSubscriptionChange(cb) {
    return this.addListener(Notifications.SUBSCRIPTION_CHANGE_KEY, cb);
  }

  getRemoveListenerFunc(uuid, type) {
    return () => delete this.listeners[type][uuid];
  }

  addListener(type, cb) {
    const { listeners } = this;
    const id = uuidV4();

    if (typeof listeners[type] !== 'object') {
      listeners[type] = {};
    }

    listeners[type][id] = cb;

    return this.getRemoveListenerFunc(id, type);
  }

  callListeners(type, data) {
    const listenerObj = this.listeners[type];
    for (const id in listenerObj) {
      if (typeof listenerObj[id] === 'function') {
        listenerObj[id](data);
      }
    }
  }

  listen() {
    const { serviceWorker } = this;

    serviceWorker.addEventListener('message', (event) => {
      const { data } = event;

      if (data && data.type) {
        this.callListeners(data.type, data);
      }
    });
  }
}
