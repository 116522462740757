import { EMPTY_ARRAY } from '../core/util/array';

export class MarketplaceInsurersActionTypes {
  static INSURERS_RECEIVED = 'INSURERS_RECEIVED';
  static INSURERS_ERROR = 'INSURERS_ERROR';
}

export const insurersReceived = value => ({
  type: MarketplaceInsurersActionTypes.INSURERS_RECEIVED,
  payload: { value },
});

export const insurersError = value => ({
  type: MarketplaceInsurersActionTypes.INSURERS_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case MarketplaceInsurersActionTypes.INSURERS_RECEIVED:
      return action.payload.value.results;
    case MarketplaceInsurersActionTypes.INSURERS_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
