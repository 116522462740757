import {
  clinicHasCustomConfiguration,
  getClinicConfigAttribute,
  REVIEW_DEFAULT_MINIMUM_RATING,
} from '../../config/clinic';
import { DAPI_CACTUS_HOST, DAPI_HOST } from '../../config';
import moment from 'moment';

const getReviewsUrl = (locationId, externalFromReviewsDate, source = 'all') => {
  const maxReviewDayAge = clinicHasCustomConfiguration(locationId, 'max_review_day_age')
    ? getClinicConfigAttribute(locationId, 'max_review_day_age')
    : 7; // 7 days

  const maxNumReviews = clinicHasCustomConfiguration(locationId, 'max_number_reviews')
    ? getClinicConfigAttribute(locationId, 'max_number_reviews')
    : 10;

  const fromReviewsDate = externalFromReviewsDate
    ? moment(externalFromReviewsDate).utc().add(1, 's').format('YYYY-MM-DD HH:mm:ss')
    : moment()
        .utc()
        .subtract(maxReviewDayAge - 1, 'd')
        .format('YYYY-MM-DD');

  const minimumRating = clinicHasCustomConfiguration(locationId, 'review_minimum_rating')
    ? getClinicConfigAttribute(locationId, 'review_minimum_rating')
    : REVIEW_DEFAULT_MINIMUM_RATING;

  return (
    `${DAPI_CACTUS_HOST}/v1/locations/${locationId}/reviews?&rating_date=${fromReviewsDate}_to_NOW` +
    `&minimum_rating=${minimumRating}&page=1&limit=${maxNumReviews}&rating_source=${source}`
  );
};

const getPatchReviewGoogleGmbUrl = (reviewGoogleGmbId) =>
  `${DAPI_HOST}/v1/reviews-google-gmb/${reviewGoogleGmbId}`;

const getReviewGoogleGmbUpdateIsContactedUrl = (reviewGoogleGmbId, isContacted) =>
  `${DAPI_HOST}/v1/reviews-google-gmb/${reviewGoogleGmbId}/update-is-contacted?is_contacted=${isContacted}`;

export { getReviewsUrl, getPatchReviewGoogleGmbUrl, getReviewGoogleGmbUpdateIsContactedUrl };
