import { parseLatLongString } from '../../core/util/geo';
import moment from 'moment';
import { isEmptyString } from '../../core/util/string';
import { EMPTY_OBJECT } from '../../core/util/object';
import { isProviderGroupLocation } from '../../core/util/location';
import { GenericObject } from '../../core/util/generics';
import { LocationUnformatted, Location } from '../../types/RootState';

const formatSlot = (slot: {
  availability: any;
  appointment_date: moment.MomentInput;
  busy: any;
  is_reservations_disabled: any;
}) => ({
  availability: slot.availability,
  appointmentDate: moment(slot.appointment_date).valueOf(),
  busy: slot.busy,
  isReservationsDisabled: slot.is_reservations_disabled,
});

const services = [
  {
    key: 'isPediatricPatientsAccepted',
    label: 'Pediatrics',
  },
  {
    key: 'isWellWomanExamAvailable',
    label: "Women's Health",
  },
  {
    key: 'isXRayAvailable',
    label: 'X-rays',
  },
];

/**
 * Normalize location data into a friendly format.
 * @param {object} location
 * @return {object}
 */
const locationResponseFormatter = (
  location: Location & LocationUnformatted & GenericObject
): Location => {
  if (location.isFormatted) {
    return location;
  }

  const formatted: GenericObject = {};
  const regex = /_(\w)/g;
  for (const key in location) {
    if (!location.hasOwnProperty(key)) {
      continue;
    }

    const camelCaseKey = key.replace(regex, (m, w) => w.toUpperCase());
    let value: Record<string, any> | null = null;
    switch (camelCaseKey) {
      case 'latLong':
        value = parseLatLongString(location.lat_long);
        break;
      case 'hours':
      case 'hoursDefault':
        value = {};
        for (const day in location[key]) {
          if (location[key].hasOwnProperty(day)) {
            value[day] = location[key][day].map(
              (hourInterval: { from_time: any; to_time: any }) => ({
                fromTime: hourInterval.from_time,
                toTime: hourInterval.to_time,
              })
            );
          }
        }

        break;
      case 'appointmentDates':
        value = location.appointment_dates.map((appointmentDate: string) =>
          moment(appointmentDate).valueOf()
        );
        break;
      case 'slots':
        value = isProviderGroupLocation(location)
          ? location?.slots?.appointments
          : location.slots.map(formatSlot);
        break;
      default:
        value = location[key];
    }

    formatted.facilities = [];
    for (const service of services) {
      if (location[service.key]) {
        formatted.facilities.push(service);
      }
    }

    formatted[camelCaseKey] = value;
  }

  if (!isEmptyString(location.display_name_primary)) {
    formatted.name = location.display_name_primary;
  }

  formatted.isFormatted = true;
  formatted.isOpaque = false;
  formatted.conversations = {
    conversations: EMPTY_OBJECT,
    currentPage: null,
    hasMorePages: null,
    unreadCount: 0,
  };

  const formattedLocation = formatted as Location;

  formattedLocation.$type = 'location';

  return formattedLocation;
};

const locationsResponseFormatter = (
  locations: Array<LocationUnformatted & Location & GenericObject>
) => locations.map(locationResponseFormatter);

export { formatSlot, locationResponseFormatter, locationsResponseFormatter };
