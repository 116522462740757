import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Input, message } from 'antd';
import styled from 'styled-components';
import { analyticsTrackEvent } from '../../../core/analytics';
import { TRIGGER_TO_EVENT_NAME_MAP } from '../../../core/analytics/events';
import { getTrackingProperties } from '../../../core/analytics/properties';
import OneTimeDismissablePopover from '../../Popover/OneTimeDismissablePopover';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from '../../../constants/colors';
import { EMPTY_STRING } from '../../../constants';
import { isEmpty } from '../../../core/util/empty';

const FormItem = Form.Item;

const StyledFormItem = styled(FormItem)`
  label {
    text-transform: capitalize;
    color: #243242;
    font-size: 13px;
  }

  input {
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.backgroundColor};
    color: ${colors.blueMonday};
    font-size: 15px;
    cursor: default;
    margin-left: 3px;
    height: 32px;
    padding: 6px 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      border: 1px solid ${props => props.backgroundColor};
    }
  }
`;

const StyledCopyFlexContainer = styled.span`
  display: flex;
`;

const StyledCopyIcon = styled.img`
  margin: 1px 0 0 3px;
  cursor: pointer;
`;

class CopyModeFormField extends PureComponent {
  shouldShowCopyIcon = () => this.props.showCopyIcon && !isEmpty(this.props.initialValue);

  showCopySuccessMessage = () => {
    message.config({ top: 75 });
    message.success(`${this.props.label} copied`, 2);

    analyticsTrackEvent(
      TRIGGER_TO_EVENT_NAME_MAP.get(`Copy ${this.props.label}`),
      getTrackingProperties({ login: this.props.login, location: this.props.location })
    );
  };

  getCopyToClipboardLabel = () => {
    if (!this.shouldShowCopyIcon()) {
      return (
        <StyledCopyFlexContainer>
          <span>{this.props.label}</span>
        </StyledCopyFlexContainer>
      );
    }

    if (this.props.shouldShowOneTimeDismissablePopover) {
      return (
        <StyledCopyFlexContainer>
          <CopyToClipboard
            text={this.props.form.getFieldValue(this.props.fieldName)}
            onCopy={this.showCopySuccessMessage}
          >
            <span>
              {this.props.label}
              <OneTimeDismissablePopover
                popoverId={`CopyFieldsPopover - ${this.props.login.account.id}`}
                popoverText="Click here to copy any field."
              >
                <StyledCopyIcon src="/images/icons/copy.svg" alt="copy" />
              </OneTimeDismissablePopover>
            </span>
          </CopyToClipboard>
        </StyledCopyFlexContainer>
      );
    }

    return (
      <StyledCopyFlexContainer>
        <CopyToClipboard
          text={this.props.form.getFieldValue(this.props.fieldName)}
          onCopy={this.showCopySuccessMessage}
        >
          <span>
            {this.props.label}
            {this.shouldShowCopyIcon() && (
              <StyledCopyIcon src="/images/icons/copy.svg" alt="copy" />
            )}
          </span>
        </CopyToClipboard>
      </StyledCopyFlexContainer>
    );
  };

  render = () => (
    <StyledFormItem
      colon={false}
      label={this.getCopyToClipboardLabel()}
      backgroundColor={this.props.backgroundColor}
    >
      {this.props.form.getFieldDecorator(this.props.fieldName, {
        initialValue: this.props.initialValue || EMPTY_STRING,
      })(<Input disabled data-hj-suppress />)}
    </StyledFormItem>
  );
}

CopyModeFormField.defaultProps = {
  showCopyIcon: true,
  shouldShowInsructionalOneTimePopConfirm: false,
  backgroundColor: '#fff',
};

CopyModeFormField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  showCopyIcon: PropTypes.bool,
  login: PropTypes.object,
  backgroundColor: PropTypes.string,
  location: PropTypes.object,
};

export default CopyModeFormField;
