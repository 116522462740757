import {
  RECEIVE_PAPERWORK_RESPONSES,
  RESET_PAPERWORK_RESPONSES,
  PAPERWORK_RESPONSES_ERROR,
} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PAPERWORK_RESPONSES:
      return {
        responses: action.payload.value.response_fields,
      };
    case RESET_PAPERWORK_RESPONSES:
      return {};
    case PAPERWORK_RESPONSES_ERROR:
      return {
        error: action.payload.value,
      };
    default:
      return state;
  }
};
