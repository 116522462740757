import { ANALYTICS } from '../../config';
import { GenericObject } from '../util/generics';
import { isClientSide } from '../util/system';

// This file is pulled out of src/core/analytics/index.ts to help
// with managing cyclical dependencies now that analyticsTrackEvent
// refereneces the Redux store.

export const trackOptions = {
  integrations: ANALYTICS.integrations,
};

export const isAnalyticsEnabled = () =>
  Boolean(ANALYTICS.enabled && isClientSide() && window.analytics);

export const coreAnalyticsTrackEvent = (event: string, properties: GenericObject = {}) => {
  if (isAnalyticsEnabled()) {
    window.analytics.track(
      event,
      {
        environment: process.env.NEXT_PUBLIC_SOLV_ENV || 'Unknown',
        repo: 'manage-dev',
        ...properties,
        send_to: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
        manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      },
      trackOptions
    );
  }
};
