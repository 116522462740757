import { standardizeDate } from '../../../../core/util/date';

export const addIEPrototypeForReadAsBinaryString = () => {
  FileReader.prototype.readAsBinaryString = function (fileData) {
    // eslint-disable-line
    let binary = '';
    const _this = this;
    const pt = _this;
    const reader = new FileReader();

    reader.onload = () => {
      const bytes = new Uint8Array(reader.result);

      bytes.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      pt.content = binary;
      pt.onload();
    };

    reader.readAsArrayBuffer(fileData);
  };
};

export const handleGenerateErrorCsvRow = (errors, errorEndpoint, payload, csvData) => {
  const newCsvData = csvData;
  let errorCount = 0;

  errors.forEach((error) => {
    newCsvData[`error${++errorCount}`] = error.description;
  });

  newCsvData['request endpoint'] = errorEndpoint;
  newCsvData['request payload'] = typeof payload === 'string' ? payload : JSON.stringify(payload);

  errorCount = 0;
  return newCsvData;
};

export const handleGenerateSoftMatchCsvRow = (matches, csvData) => {
  const newCsvData = csvData;

  newCsvData.account_first_name = matches.first_name;
  newCsvData.account_last_name = matches.last_name;

  return newCsvData;
};

// if whitespace and casing are the only differences, consider it a "hard" match
// otherwise consider it a soft match
export const hasANameMismatch = (responseData, csvData) =>
  responseData.first_name.trim().toLowerCase() !== csvData.first_name.trim().toLowerCase() ||
  responseData.last_name.trim().toLowerCase() !== csvData.last_name.trim().toLowerCase();

export const formatInputs = (account) => {
  const formattedAccountData = {
    ...account,
    first_name: account.first_name.trim(),
    last_name: account.last_name.trim(),
    birth_date: standardizeDate(account.birth_date.trim()),
    appointment_date: standardizeDate(account.appointment_date.trim()),
    location_id: account.location_id.trim(),
    phone: account.phone.trim(),
    email: account.email.trim(),
    due_date: standardizeDate(account.due_date.trim()),
  };

  return formattedAccountData;
};
