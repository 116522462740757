import { DAPI_HOST } from '../../config';
import { getMultiFiltersString } from './index';
import { isEmpty } from '../util/empty';

const PRACTICE_FIELDS = [
  'id',
  'display_name_primary',
  'display_name_secondary',
  'locations',
  'group',
];

export const getPracticeUrl = practiceId =>
  `${DAPI_HOST}/v1/practices/${practiceId}?fields=${PRACTICE_FIELDS.join('')}`;

export const getPracticesUrl = () => `${DAPI_HOST}/v1/practices`;

export const getPaginatedPracticesUrl = ({ filters, page = 1, limit = 20 }) => {
  let url = `${DAPI_HOST}/v1/practices?page=${page}&limit=${limit}`;
  if (!isEmpty(filters)) url += `&filters=${getMultiFiltersString(filters)}`;
  return url;
};

export const getPracticeLocationsUrl = practiceId =>
  `${DAPI_HOST}/v1/practices/${practiceId}/practice-locations`;

export const getGroupPracticesUrl = practiceId =>
  `${DAPI_HOST}/v1/practices/${practiceId}/group-practices`;
