import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet, apiPatch, apiPost } from '../../core/dapi';
import {
  createVaccinationUrl,
  getVaccinationsUrl,
  updateVaccinationUrl,
} from '../../core/dapi/vaccinations';
import {
  vaccinationsCreateError,
  vaccinationsCreating,
  vaccinationsError,
  vaccinationsForgetFormValues,
  vaccinationsLoading,
  vaccinationsReceived,
  vaccinationsRememberFormValues,
} from '../../ducks/vaccinations';

export class VaccinationsSagas {
  static CREATE_VACCINATION = 'saga/CREATE_VACCINATION';
  static FETCH_VACCINATIONS = 'saga/FETCH_VACCINATIONS';
  static UPDATE_VACCINATION = 'saga/UPDATE_VACCINATION';
}

export function* createVaccination({ onSuccess, postData, shouldRemember }) {
  yield put(vaccinationsCreating(true));

  if (shouldRemember) {
    yield put(vaccinationsRememberFormValues(postData));
  } else {
    yield put(vaccinationsForgetFormValues());
  }

  try {
    yield call(apiPost, createVaccinationUrl(), postData);
    onSuccess();
  } catch (e) {
    yield put(vaccinationsCreateError(e));
  } finally {
    yield put(vaccinationsCreating(false));
  }
}

export function* fetchVaccinations({ bookingId }) {
  yield put(vaccinationsLoading(true));

  try {
    const response = yield call(apiGet, getVaccinationsUrl(bookingId));
    yield put(vaccinationsReceived(response.results));
  } catch (e) {
    yield put(vaccinationsError(e));
  } finally {
    yield put(vaccinationsLoading(false));
  }
}

export function* updateVaccination({ onSuccess, patchData, vaccinationId }) {
  yield put(vaccinationsCreating(true));

  try {
    yield call(apiPatch, updateVaccinationUrl(vaccinationId), patchData);
    onSuccess();
  } catch (e) {
    yield put(vaccinationsCreateError(e));
  } finally {
    yield put(vaccinationsCreating(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(VaccinationsSagas.CREATE_VACCINATION, createVaccination);
  yield takeEvery(VaccinationsSagas.FETCH_VACCINATIONS, fetchVaccinations);
  yield takeEvery(VaccinationsSagas.UPDATE_VACCINATION, updateVaccination);
}
