import {
  CLINIC_ACCOUNTS_FETCHING,
  CLINIC_ACCOUNTS_RECEIVED,
  CLINIC_ACCOUNTS_ERROR,
  CLINIC_ACCOUNTS_PATCHED,
  CLINIC_ACCOUNTS_ADD_LOCATIONS,
  CLINIC_ACCOUNTS_DELETE_LOCATIONS,
  CLINIC_ACCOUNTS_ADD_GROUPS,
  CLINIC_ACCOUNTS_DELETE_GROUPS,
  CLINIC_ACCOUNTS_PATCH_SUBMITTED,
  CLINIC_ACCOUNTS_PATCH_ERROR,
  CLINIC_ACCOUNTS_RESET_PATCH_STATE,
  CLINIC_ACCOUNTS_PASSWORD_CHANGED,
  CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS,
  CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR,
  CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS,
} from '../constants';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

const fetchingClinicAccounts = () => ({
  type: CLINIC_ACCOUNTS_FETCHING,
});

const receiveClinicAccounts = (value) => ({
  type: CLINIC_ACCOUNTS_RECEIVED,
  payload: { value },
});

const clinicAccountsError = (value) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: CLINIC_ACCOUNTS_ERROR,
    payload: { value },
  };
};

const clinicAccountPatched = (value) => ({
  type: CLINIC_ACCOUNTS_PATCHED,
  payload: { value },
});

const clinicAccountAddLocation = (value) => ({
  type: CLINIC_ACCOUNTS_ADD_LOCATIONS,
  payload: { value },
});

const clinicAccountDeleteLocation = (value) => ({
  type: CLINIC_ACCOUNTS_DELETE_LOCATIONS,
  payload: { value },
});

const clinicAccountAddGroup = (value) => ({
  type: CLINIC_ACCOUNTS_ADD_GROUPS,
  payload: { value },
});

const clinicAccountDeleteGroup = (value) => ({
  type: CLINIC_ACCOUNTS_DELETE_GROUPS,
  payload: { value },
});

const clinicAccountPasswordChanged = () => ({ type: CLINIC_ACCOUNTS_PASSWORD_CHANGED });

const clinicAccountPatchSubmitted = () => ({
  type: CLINIC_ACCOUNTS_PATCH_SUBMITTED,
});

const clinicAccountPatchError = (value) => ({
  type: CLINIC_ACCOUNTS_PATCH_ERROR,
  payload: { value },
});

const clinicAccountsAddAllLocationsSuccess = () => ({
  type: CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS,
});

const clinicAccountsAddAllLocationsError = (value) => ({
  type: CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR,
  payload: { value },
});

const clinicAccountsResetAddAllLocations = () => ({
  type: CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS,
});

const resetClinicAccountPatchState = () => ({ type: CLINIC_ACCOUNTS_RESET_PATCH_STATE });

export {
  receiveClinicAccounts,
  clinicAccountsError,
  clinicAccountPatched,
  clinicAccountAddLocation,
  clinicAccountDeleteLocation,
  clinicAccountAddGroup,
  clinicAccountDeleteGroup,
  clinicAccountPasswordChanged,
  clinicAccountPatchSubmitted,
  clinicAccountPatchError,
  fetchingClinicAccounts,
  clinicAccountsAddAllLocationsSuccess,
  clinicAccountsAddAllLocationsError,
  clinicAccountsResetAddAllLocations,
  resetClinicAccountPatchState,
};
