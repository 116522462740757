/* eslint-disable camelcase */
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import {
  BOOKING_REQUEST_FAILED,
  BOOKING_RESPONSE_RECEIVED,
  EMPTY_STRING,
  PaperworkTypes,
  SETTINGS_POST_LOCATION_PAPERWORK_FIELD_ERROR,
  SETTINGS_POST_LOCATION_PAPERWORK_FIELD_SUCCESS,
} from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';
import { emptyFunction } from '../core/util/function';
import { EMPTY_OBJECT } from '../core/util/object';
import { PaperworkSagas } from '../sagas/Paperwork';

const RECEIVE_ALL_PAPERWORK_FIELDS = 'RECEIVE_ALL_PAPERWORK_FIELDS';
const RECEIVE_LOCATION_PAPERWORK_FIELDS = 'RECEIVE_LOCATION_PAPERWORK_FIELDS';
const RECEIVE_UPDATED_LOCATION_PAPERWORK_FIELD = 'RECEIVE_UPDATED_LOCATION_PAPERWORK_FIELD';
const PAPERWORK_CUSTOM_FIELDS_ERROR = 'PAPERWORK_CUSTOM_FIELDS_ERROR';
const PAPERWORK_GET_ALL_FIELDS_ERROR = 'PAPERWORK_GET_ALL_FIELDS_ERROR';
const SAVE_PAPERWORK_RESPONSES_TO_SUBMIT = 'SAVE_PAPERWORK_RESPONSES_TO_SUBMIT';
const CREATE_PAPERWORK_FIELD_SUCCESS = 'paperwork/CREATE_PAPERWORK_FIELD';
const PATCH_PAPERWORK_FIELD_SUCCESS = 'paperwork/PATCH_PAPERWORK_FIELD';
const SOFT_DELETE_PAPERWORK_FIELD_SUCCESS = 'paperwork/SOFT_DELETE_PAPERWORK_FIELD';
const REFRESH_PAPERWORK_FIELDS_SUCCESS = 'paperwork/FETCH_ALL_PAPERWORK_FIELDS_SUCCESS';

const pickPaperworkFieldColumns = (obj = {}) =>
  pick(obj, [
    'id',
    'field_name',
    'field_display_name',
    'field_type',
    'response_options',
    'placeholder',
    'is_standard_field',
    'active',
    'created_date',
    'updated_date',
    'is_required_field',
    'product_context',
  ]);

/**
 * Fetch all paperwork fields matching
 * @param {object} paperworkFieldsFilters a standard format filter-object, relative to paperwork_fields entities
 * @param {string} orderBy an order by statement, such as `'field_display_name ASC'`
 * @returns a redux action that triggers a saga
 */
const fetchAllPaperworkFields = (filters = {}, orderBy = EMPTY_STRING) => ({
  type: PaperworkSagas.FETCH_ALL_PAPERWORK_FIELDS,
  filters: pickPaperworkFieldColumns(filters),
  ...(!!orderBy && { orderBy }),
});

/**
 * Re-fetch and update the data of the specified paperwork fields. Ignore PFs that
 * are not already fetched.
 */
const refreshPaperworkFields = ({ paperworkFieldIds = [] }) => ({
  type: PaperworkSagas.REFRESH_PAPERWORK_FIELDS,
  paperworkFieldIds,
});

const submitPaperworkResponses = ({ responseFields, bookingId, onSuccess, onError }) => ({
  type: PaperworkSagas.SUBMIT_PAPERWORK_RESPONSES,
  responseFields,
  bookingId,
  onSuccess,
  onError,
});

const refreshPaperworkFieldsSuccess = ({ paperworkFields }) => ({
  type: REFRESH_PAPERWORK_FIELDS_SUCCESS,
  payload: { paperworkFields },
});

const receiveAllPaperworkFields = (value) => ({
  type: RECEIVE_ALL_PAPERWORK_FIELDS,
  payload: { value },
});

const getAllPaperworkFieldsError = (value) => ({
  type: PAPERWORK_GET_ALL_FIELDS_ERROR,
  payload: { value },
});

const receiveLocationPaperworkFields = (value, fieldsType) => ({
  type: RECEIVE_LOCATION_PAPERWORK_FIELDS,
  payload: { value, fieldsType },
});

const receiveUpdatedLocationPaperworkField = (value) => ({
  type: RECEIVE_UPDATED_LOCATION_PAPERWORK_FIELD,
  payload: { value },
});

const locationPaperworkFieldsError = (value) => ({
  type: PAPERWORK_CUSTOM_FIELDS_ERROR,
  payload: { value },
});

const updateLocationPaperworkFieldSuccess = (value) => ({
  type: SETTINGS_POST_LOCATION_PAPERWORK_FIELD_SUCCESS,
  payload: { value },
});

const updateLocationPaperworkFieldError = (value) => ({
  type: SETTINGS_POST_LOCATION_PAPERWORK_FIELD_ERROR,
  payload: { value },
});

const savePaperworkResponsesToSubmit = (value) => ({
  type: SAVE_PAPERWORK_RESPONSES_TO_SUBMIT,
  payload: { value },
});

/**
 * @param {object} paperworkFieldProps
 * @param {{
 *  locationsToAssign?: string[],
 *  onSuccess?: function,
 *  onError?: function,
 *  onComplete?: function,
 * }}
 */
const createPaperworkField = (
  paperworkFieldProps,
  { locationsToAssign = [], onSuccess, onError, onComplete } = {}
) => ({
  type: PaperworkSagas.CREATE_PAPERWORK_FIELD,
  fieldProps: pickPaperworkFieldColumns(paperworkFieldProps),
  locationsToAssign,
  onSuccess,
  onError,
  onComplete,
});

const createPaperworkFieldSuccess = (value) => ({
  type: CREATE_PAPERWORK_FIELD_SUCCESS,
  payload: { value },
});

/**
 * @param {string} pfId the id of the paperwork-field
 * @param {object} paperworkFieldProps
 * @param {{
 *  locationsToAssign: string[],
 *  onSuccess?: function,
 *  onError?: function,
 *  onComplete?: function,
 * }}
 */
const patchPaperworkField = (
  pfId,
  paperworkFieldProps = {},
  { locationsToAssign = [], onSuccess, onError, onComplete } = {}
) => ({
  type: PaperworkSagas.PATCH_PAPERWORK_FIELD,
  fieldProps: pickPaperworkFieldColumns(paperworkFieldProps),
  fieldId: pfId,
  locationsToAssign,
  onSuccess,
  onError,
  onComplete,
});

/** @typedef {{ id: string, active?: boolean, is_required_field?: boolean }} IdLpfPatch  */
/** @typedef {{ location_id: string, paperwork_field_id: string, active?: boolean, is_required_field?: boolean }} LocationPaperworkFieldLpfPatch  */

/**
 * @param {(IdLpfPatch | LocationPaperworkFieldLpfPatch)[]} lpfs
 * @param {{
 * onSuccess?: function
 * onError?: function,
 * onComplete?: function,
 * }} options
 */
const upsertLocationPaperworkFields = (
  lpfs,
  { onSuccess = emptyFunction, onError = emptyFunction, onComplete = emptyFunction }
) => ({
  type: PaperworkSagas.UPSERT_LOCATION_PAPERWORK_FIELDS,
  /** @type {typeof lpfs} */
  locationPaperworkFields:
    lpfs
      ?.map((lpf) =>
        pick(lpf, [
          'id',
          'paperwork_field_id',
          'location_id',
          'active',
          'is_required_field',
          'ranking',
        ])
      )
      ?.filter((lpf) => lpf?.id || (lpf?.paperwork_field_id && lpf?.location_id)) ?? [],
  onSuccess,
  onError,
  onComplete,
});

const patchPaperworkFieldSuccess = (value) => ({
  type: PATCH_PAPERWORK_FIELD_SUCCESS,
  payload: { value },
});

/**
 * @param {number | string} paperworkFieldId
 * @returns a redux action that triggers a saga
 */
const softDeletePaperworkField = (paperworkFieldId) => ({
  type: PaperworkSagas.SOFT_DELETE_PAPERWORK_FIELD,
  id: paperworkFieldId,
});

const softDeletePaperworkFieldSuccess = (value) => ({
  type: SOFT_DELETE_PAPERWORK_FIELD_SUCCESS,
  payload: { value },
});

/**
 * @param {string} locationId
 * @param {{
 *  fieldsType?: import('../constants/enums').PaperworkFieldProductContext,
 *  productContext?: import('../constants/enums').PaperworkFieldProductContext,
 *  activeOnly?: boolean,
 *  cacheBust?: boolean,
 *  onSuccess?: () => void,
 *  onError?: () => void,
 *  onComplete?: () => void,
 * }} param1
 */
const fetchLocationPaperworkFields = (
  locationId,
  { fieldsType, productContext, activeOnly, cacheBust = false, onSuccess, onError, onComplete } = {}
) => ({
  type: PaperworkSagas.FETCH_LOCATION_PAPERWORK_FIELDS,
  locationId,
  ...(!!fieldsType && { fieldsType }),
  ...(!!productContext && { fieldsType: productContext }),
  ...(activeOnly != null && { activeOnly }),
  cacheBust,
  onSuccess,
  onError,
  onComplete,
});

/**
 *
 * @param {{
 *   locationId: string,
 *   targetLocationIds: string[],
 *   onSuccess?: (...args: any[]) => void,
 *   onError?: (...args: any[]) => void,
 *   onComplete?: (...args: any[]) => void,
 * }} params
 */
const replicatePaperworkOfLocation = ({
  locationId,
  targetLocationIds,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  onComplete = emptyFunction,
}) => ({
  type: PaperworkSagas.REPLICATE_PAPERWORK_OF_LOCATION,
  locationId,
  targetLocationIds,
  onSuccess,
  onError,
  onComplete,
});

// Reducer & state

const initialState = {
  // paperwork-fields

  /** @type {object[]} */
  fields: [],

  // location-paperwork-fields

  /** @type {object[]} */
  standardFields: [],
  /** @type {object[]} */
  customFields: [],
  /** @type {object[]} */
  standardKioskFields: [],
  /** @type {object[]} */
  customKioskFields: [],
  /** @type {object[]} */
  facesheetFields: [],
  /** @type {object[]} */
  standardFacesheetFields: [],

  // these relate creating/updating location-paperwork-fields

  /** @type {object | null} */
  success: null,

  /** @type {object | null} */
  error: null,

  // these relate to answering paperwork-field questions in a kiosk context

  /** @type {any} */
  paperworkResponsesToSubmit: null,
};

const groupLpfsByStandardAndType = (lpfs) => {
  const [standardLpfs, customLpfs] = partition(lpfs, (lpf) => !!lpf.is_standard_field);
  const {
    [PaperworkTypes.REGISTRATION]: standardRegistration = EMPTY_ARRAY,
    [PaperworkTypes.KIOSK]: standardKiosk = EMPTY_ARRAY,
    [PaperworkTypes.FACESHEET]: standardFacesheet = EMPTY_ARRAY,
  } = groupBy(standardLpfs, (lpf) => lpf.type);
  const {
    [PaperworkTypes.REGISTRATION]: customRegistration = EMPTY_ARRAY,
    [PaperworkTypes.KIOSK]: customKiosk = EMPTY_ARRAY,
    [PaperworkTypes.FACESHEET]: customFacesheet = EMPTY_ARRAY,
  } = groupBy(customLpfs, (lpf) => lpf.type);

  return {
    standardRegistration,
    standardKiosk,
    standardFacesheet,
    customRegistration,
    customKiosk,
    customFacesheet,
  };
};

const sortLpfsByRanking = (lpfs) => sortBy(lpfs, (lpf) => lpf?.ranking);

/** @typedef {typeof initialState} PaperworkState */

const reducer = (state = initialState, action = EMPTY_OBJECT) => {
  switch (action.type) {
    case RECEIVE_ALL_PAPERWORK_FIELDS: {
      return {
        ...state,
        fields: action.payload.value.results,
      };
    }
    case RECEIVE_LOCATION_PAPERWORK_FIELDS: {
      const newLpfs = action.payload.value;
      const fieldsType = action.payload.fieldsType;
      const {
        standardRegistration,
        standardKiosk,
        standardFacesheet,
        customRegistration,
        customKiosk,
        customFacesheet,
      } = groupLpfsByStandardAndType(newLpfs);
      return {
        ...state,
        ...((!fieldsType || fieldsType === PaperworkTypes.REGISTRATION) && {
          standardFields: sortLpfsByRanking(standardRegistration),
          customFields: sortLpfsByRanking(customRegistration),
        }),
        ...((!fieldsType || fieldsType === PaperworkTypes.KIOSK) && {
          standardKioskFields: sortLpfsByRanking(standardKiosk),
          customKioskFields: sortLpfsByRanking(customKiosk),
        }),
        ...((!fieldsType || fieldsType === PaperworkTypes.REGISTRATION) && {
          facesheetFields: sortLpfsByRanking(customFacesheet),
          standardFacesheetFields: sortLpfsByRanking(standardFacesheet),
        }),
      };
    }
    case RECEIVE_UPDATED_LOCATION_PAPERWORK_FIELD: {
      const updatedLpf = action.payload.value;

      let {
        standardFields,
        customFields,
        standardKioskFields,
        customKioskFields,
        facesheetFields,
        standardFacesheetFields,
      } = state;

      if (updatedLpf.is_standard_field) {
        if (updatedLpf.type === PaperworkTypes.REGISTRATION) {
          standardFields =
            standardFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ??
            EMPTY_ARRAY;
        } else if (updatedLpf.type === PaperworkTypes.KIOSK) {
          standardKioskFields =
            standardKioskFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ??
            EMPTY_ARRAY;
        } else if (updatedLpf.type === PaperworkTypes.FACESHEET) {
          standardFacesheetFields =
            standardFacesheetFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ??
            EMPTY_ARRAY;
        }
      } else {
        if (updatedLpf.type === PaperworkTypes.REGISTRATION) {
          customFields =
            customFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ?? EMPTY_ARRAY;
        } else if (updatedLpf.type === PaperworkTypes.KIOSK) {
          customKioskFields =
            customKioskFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ??
            EMPTY_ARRAY;
        } else if (updatedLpf.type === PaperworkTypes.FACESHEET) {
          facesheetFields =
            facesheetFields?.map((lpf) => (lpf?.id === updatedLpf ? updatedLpf : lpf)) ??
            EMPTY_ARRAY;
        }
      }

      return {
        ...state,
        standardFields,
        customFields,
        standardKioskFields,
        customKioskFields,
        facesheetFields,
        standardFacesheetFields,
      };
    }
    case SETTINGS_POST_LOCATION_PAPERWORK_FIELD_SUCCESS: {
      return {
        ...state,
        success: action.payload.value,
      };
    }
    case SETTINGS_POST_LOCATION_PAPERWORK_FIELD_ERROR: {
      return {
        error: action.payload.value,
      };
    }
    case PAPERWORK_CUSTOM_FIELDS_ERROR: {
      return {
        ...state,
        error: action.payload.value,
      };
    }
    case SAVE_PAPERWORK_RESPONSES_TO_SUBMIT: {
      return {
        ...state,
        paperworkResponsesToSubmit: action.payload.value,
      };
    }
    case BOOKING_RESPONSE_RECEIVED:
    case BOOKING_REQUEST_FAILED: {
      return {
        ...state,
        paperworkResponsesToSubmit: EMPTY_OBJECT,
      };
    }
    case CREATE_PAPERWORK_FIELD_SUCCESS: {
      const fields = [action.payload.value, ...state.fields];

      return { ...state, fields };
    }
    case PATCH_PAPERWORK_FIELD_SUCCESS: {
      const fields = state.fields.map((field) => {
        if (field.id === action.payload.value.id) {
          return action.payload.value;
        }
        return field;
      });
      return { ...state, fields };
    }
    case REFRESH_PAPERWORK_FIELDS_SUCCESS: {
      const {
        payload: { paperworkFields: updatedPaperworkFields },
      } = action;
      const newPaperworkFields = state.fields.map(
        (pf) => updatedPaperworkFields.find((otherPf) => pf.id === otherPf.id) ?? pf
      );
      return { ...state, fields: newPaperworkFields };
    }
    case SOFT_DELETE_PAPERWORK_FIELD_SUCCESS: {
      const fields = state.fields.filter((field) => field.id !== action.payload.value.id);
      return { ...state, fields };
    }
    default:
      return state;
  }
};

/** @typedef {any} ReduxState */
const selectors = {
  /**
   * @param {ReduxState} state
   * @returns {PaperworkState}
   */
  getPaperworkState(state) {
    return state?.paperwork ?? null;
  },
  getPaperworkFields(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.fields ?? EMPTY_ARRAY;
  },
  getNumPaperworkFields(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkFields(state)?.length ?? 0;
  },
  /**
   * @param {ReduxState} state
   * @param {string} paperworkFieldId
   */
  getPaperworkFieldById(state, paperworkFieldId) {
    return selectors.getPaperworkFields(state).find((pf) => pf?.id === paperworkFieldId) ?? null;
  },
  /**
   * Returns a new data structure: avoid using the output of this function as a component prop.
   * @param {ReduxState} state
   * @param {string[]} paperworkFieldIds
   */
  getPaperworkFieldsById(state, paperworkFieldIds) {
    const pfIdSet = new Set(paperworkFieldIds);
    return (
      selectors.getPaperworkFields(state)?.filter?.((pf) => pfIdSet.has(pf?.id)) ?? EMPTY_ARRAY
    );
  },
  getStandardRegistrationLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.standardFields ?? EMPTY_ARRAY;
  },
  getCustomRegistrationLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.customFields ?? EMPTY_ARRAY;
  },
  getStandardKioskLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.standardKioskFields ?? EMPTY_ARRAY;
  },
  getCustomKioskLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.customKioskFields ?? EMPTY_ARRAY;
  },
  getStandardFacesheetLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.standardFacesheetFields ?? EMPTY_ARRAY;
  },
  getCustomFacesheetLpfs(/** @type {ReduxState} */ state) {
    return selectors.getPaperworkState(state)?.facesheetFields ?? EMPTY_ARRAY;
  },
};

export {
  reducer as default,
  getAllPaperworkFieldsError,
  receiveAllPaperworkFields,
  receiveLocationPaperworkFields,
  receiveUpdatedLocationPaperworkField,
  locationPaperworkFieldsError,
  savePaperworkResponsesToSubmit,
  updateLocationPaperworkFieldError,
  updateLocationPaperworkFieldSuccess,
  createPaperworkField,
  createPaperworkFieldSuccess,
  patchPaperworkField,
  patchPaperworkFieldSuccess,
  softDeletePaperworkField,
  softDeletePaperworkFieldSuccess,
  fetchAllPaperworkFields,
  upsertLocationPaperworkFields,
  refreshPaperworkFields,
  refreshPaperworkFieldsSuccess,
  fetchLocationPaperworkFields,
  replicatePaperworkOfLocation,
  submitPaperworkResponses,
  selectors,
};
