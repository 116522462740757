import {
  GROUPS_FETCHING,
  GROUPS_RECEIVED,
  GROUPS_ERROR,
  GROUPS_PATCH_SUBMITTED,
  GROUPS_PATCH_ERROR,
  GROUPS_PATCH_GROUP,
  GROUP_RECEIVED,
} from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

const updateGroup = (existingList, updatedGroup) => {
  const indexOfGroup = existingList.findIndex((group) => group.id === updatedGroup.id);
  if (indexOfGroup < 0) {
    return existingList;
  }
  const existingGroup = existingList[indexOfGroup];
  const newList = existingList.slice();

  const newGroup = {
    ...existingGroup,
    ...updatedGroup,
  };

  newList.splice(indexOfGroup, 1, newGroup);

  return newList;
};

export default function groupsReducer(state = {}, action) {
  switch (action.type) {
    case GROUPS_FETCHING:
      return {
        ...state,
        isFetchingGroups: true,
      };
    case GROUPS_RECEIVED:
      return {
        ...state,
        isFetchingGroups: false,
        hasFetchedGroups: true,
        groups: action.payload.value.results,
      };
    case GROUPS_PATCH_SUBMITTED:
      return {
        ...state,
        patchSubmitting: true,
        patchError: null,
      };
    case GROUP_RECEIVED:
      return {
        ...state,
        groups: (state.groups ?? EMPTY_ARRAY).map((group) => {
          if (group.id !== action.payload.value.id) {
            return group;
          }

          return {
            ...group,
            ...action.payload.value,
          };
        }),
      };
    case GROUPS_PATCH_GROUP:
      return {
        ...state,
        groups: updateGroup(state.groups, action.payload.group),
        patchSubmitting: false,
      };
    case GROUPS_PATCH_ERROR:
      return {
        ...state,
        patchError: action.payload.value,
      };
    case GROUPS_ERROR:
      return {
        error: action.payload.value,
      };
    default:
      return state;
  }
}
