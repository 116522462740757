import { takeEvery } from 'redux-saga/effects';
import {
  fetchClinicPaymentInfo,
  enablePayments,
  enablePos,
  createNewPaymentsAccount,
  resetNewPaymentState,
  enableAutoResend,
  enablePaymentsDuringBooking,
  requirePaymentsDuringBooking,
} from './core/EditPaymentOptionsSagas';
import {
  ENABLE_POS_IN_LOCATIONS_SETTINGS,
  FETCH_CLINIC_ACCOUNTS,
  ENABLE_PAYMENTS_IN_LOCATIONS_SETTINGS,
  CREATE_PAYMENT_ACCOUNT,
  RESET_CREATE_PAYMENT_ACCOUNT,
  ENABLE_AUTORESEND_IN_LOCATIONS_SETTINGS,
  ENABLE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS,
  REQUIRE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS,
} from '../constants';

export default function* rootSaga() {
  yield takeEvery(FETCH_CLINIC_ACCOUNTS, fetchClinicPaymentInfo);
  yield takeEvery(ENABLE_PAYMENTS_IN_LOCATIONS_SETTINGS, enablePayments);
  yield takeEvery(ENABLE_POS_IN_LOCATIONS_SETTINGS, enablePos);
  yield takeEvery(CREATE_PAYMENT_ACCOUNT, createNewPaymentsAccount);
  yield takeEvery(RESET_CREATE_PAYMENT_ACCOUNT, resetNewPaymentState);
  yield takeEvery(ENABLE_AUTORESEND_IN_LOCATIONS_SETTINGS, enableAutoResend);
  yield takeEvery(
    ENABLE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS,
    enablePaymentsDuringBooking
  );
  yield takeEvery(
    REQUIRE_PAYMENTS_DURING_BOOKING_IN_LOCATIONS_SETTINGS,
    requirePaymentsDuringBooking
  );
}
