import Cookies from 'js-cookie';
import { DAPI_TOKEN, AUTH_TOKEN_COOKIE_NAME } from '../../config';
import { LoginToken } from '../../types/RootState';
import {
  deleteUniversalAuthSession,
  parseUniversalCookie,
  persistUniversalAuthSession,
} from './universalAuthSession';
import { analyticsReset } from '../analytics/reset';

export const setAuth = async (
  token: LoginToken,
  opts: {
    clearRemixCache: boolean;
  }
) => {
  await persistUniversalAuthSession(token, opts);
};

export const getAuth = (): LoginToken | undefined => {
  try {
    const cookie = Cookies.get(AUTH_TOKEN_COOKIE_NAME);
    if (!cookie) return undefined;
    const value = JSON.parse(decodeURIComponent(cookie));
    return parseUniversalCookie(value);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getAuthHeader = () => `Bearer ${getAuth()?.access_token}`;

export const removeAuth = () => {
  localStorage.removeItem('dapi_authorization');
  deleteUniversalAuthSession();
  analyticsReset();
};

export const getClinicAccountId = (): string | undefined => {
  return getAuth()?.clinic_account_id;
};

export const getDapiAuthHeaders = () => {
  // Use the public token on the server side
  if (typeof window === 'undefined') {
    return { Authorization: `Bearer ${DAPI_TOKEN}` };
  }

  const auth = getAuth();
  if (!auth) {
    return { Authorization: `Bearer ${DAPI_TOKEN}` }; // Public dapi token
  }
  return { Authorization: `Bearer ${auth.access_token}` };
};

export const addAuthTrackingParams = (url: string) => {
  return url;
};
