import PropTypes from 'prop-types';
import React from 'react';

const StarRating = props => {
  const starValues = [];
  for (let i = 0; i < 5; i++) {
    starValues.push(props.starRating - i > 1 ? 1 : props.starRating - i);
  }

  const getStarImageFileName = starValue => {
    if (starValue >= 0.75) {
      return 'star_filled.svg';
    }

    if (starValue >= 0.25) {
      return 'star_half_filled.svg';
    }

    return 'star_empty.svg';
  };

  const stars = () =>
    starValues.map((starValue, i) => (
      <img
        key={i}
        src={`/images/icons/${getStarImageFileName(starValue)}`}
        width={props.size}
        height={props.size}
        role="presentation"
      />
    ));

  const getStarValue = value => {
    if (value === 0) {
      return 'No Ratings';
    }

    return value.toFixed(1);
  };

  return (
    <span className={props.containerClassName}>
      {(!props.starRating === 0 || props.showFiveEmptyStars) && stars()}
      {props.displayValue && <span>{getStarValue(props.starRating)}</span>}
    </span>
  );
};

StarRating.propTypes = {
  containerClassName: PropTypes.string,
  displayValue: PropTypes.bool.isRequired,
  showFiveEmptyStars: PropTypes.bool,
  size: PropTypes.number.isRequired,
  starRating: PropTypes.number.isRequired,
};

StarRating.defaultProps = {
  displayValue: false,
  showFiveEmptyStars: true,
};

export default StarRating;
