import React from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { Label } from '../../primitives';
import { GetFieldDecoratorOptions, ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { Location } from '../../../types/RootState';
import { TextAreaProps } from 'antd/lib/input/TextArea';

const FormItem = Form.Item;

const StyledFormItem = styled(FormItem)`
  input {
    background-color: ${colors.theWhiteAlbum};
    color: ${colors.blueMonday};
    font-size: 15px;
    cursor: default;
    margin-left: 3px;
    height: 32px;
    padding: 6px 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

type Props = {
  form: WrappedFormUtils;
  fieldName: string;
  label?: string | null;
  initialValue: any;
  required?: boolean;
  requiredMessage?: string;
  disabled?: boolean;
  placeholder?: string;
  validator?: ValidationRule['validator'];
  type?: string;
  showCopyIcon?: boolean;
  location: Location;
  fieldInfoTooltipDescription?: string;
  showEditedIcon?: boolean;
  validateTrigger?: GetFieldDecoratorOptions['validateTrigger'];
  showIsOptional?: boolean;
  rules?: any[];
  isTextArea?: boolean;
  textAreaProps?: TextAreaProps;
} & {
  [key: string]: any;
};

const InputFormField = ({
  form,
  fieldName,
  label,
  initialValue,
  required,
  requiredMessage,
  disabled,
  placeholder,
  validator,
  type,
  showCopyIcon,
  location,
  fieldInfoTooltipDescription,
  showEditedIcon,
  validateTrigger,
  showIsOptional,
  rules = [],
  textAreaProps,
  isTextArea,
  ...additionalInputProps
}: Props) => {
  const formValue = form.getFieldValue(fieldName);
  const copyValue = formValue ?? initialValue;

  const input = () => {
    if (isTextArea) {
      return (
        <Input.TextArea
          disabled={disabled}
          placeholder={placeholder}
          {...additionalInputProps}
          data-hj-suppress
          {...textAreaProps}
        />
      );
    }
    return (
      <Input
        disabled={disabled}
        placeholder={placeholder}
        {...additionalInputProps}
        type={type}
        data-hj-suppress
      />
    );
  };

  return (
    <StyledFormItem>
      <Label
        label={label}
        showCopyIcon={showCopyIcon}
        location={location}
        fieldInfoTooltipDescription={fieldInfoTooltipDescription}
        copyValue={copyValue}
        showEditedIcon={showEditedIcon}
        showIsOptional={showIsOptional}
      />
      {form.getFieldDecorator(fieldName, {
        initialValue,
        rules: [{ required, message: requiredMessage, type }, { validator }].concat(rules),
        validateTrigger,
      })(input())}
    </StyledFormItem>
  );
};

InputFormField.defaultProps = {
  showCopyIcon: true,
};

export default InputFormField;
