import { EMPTY_OBJECT, RESET_CURRENT_PATIENT } from '../constants';

export class VaccinationsActionTypes {
  static VACCINATIONS_CREATE_ERROR = 'VACCINATIONS_CREATE_ERROR';
  static VACCINATIONS_CREATING = 'VACCINATIONS_CREATING';
  static VACCINATIONS_ERROR = 'VACCINATIONS_ERROR';
  static VACCINATIONS_FORGET_FORM_VALUES = 'VACCINATIONS_FORGET_FORM_VALUES';
  static VACCINATIONS_LOADING = 'VACCINATIONS_LOADING';
  static VACCINATIONS_RECEIVED = 'VACCINATIONS_RECEIVED';
  static VACCINATIONS_REMEMBER_FORM_VALUES = 'VACCINATIONS_REMEMBER_FORM_VALUES';
}

export const vaccinationsCreateError = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_CREATE_ERROR,
});

export const vaccinationsCreating = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_CREATING,
});

export const vaccinationsError = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_ERROR,
});

export const vaccinationsForgetFormValues = () => ({
  type: VaccinationsActionTypes.VACCINATIONS_FORGET_FORM_VALUES,
});

export const vaccinationsLoading = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_LOADING,
});

export const vaccinationsReceived = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_RECEIVED,
});

export const vaccinationsRememberFormValues = value => ({
  payload: { value },
  type: VaccinationsActionTypes.VACCINATIONS_REMEMBER_FORM_VALUES,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case VaccinationsActionTypes.VACCINATIONS_CREATE_ERROR:
      return {
        ...state,
        createError: action.payload.value,
      };
    case VaccinationsActionTypes.VACCINATIONS_CREATING:
      return {
        ...state,
        isCreating: action.payload.value,
      };
    case VaccinationsActionTypes.VACCINATIONS_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case VaccinationsActionTypes.VACCINATIONS_FORGET_FORM_VALUES:
      return {
        ...state,
        rememberedValues: {},
      };
    case VaccinationsActionTypes.VACCINATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case VaccinationsActionTypes.VACCINATIONS_RECEIVED:
      return {
        ...state,
        results: action.payload.value,
      };
    case VaccinationsActionTypes.VACCINATIONS_REMEMBER_FORM_VALUES:
      return {
        ...state,
        rememberedValues: {
          administering_provider_first_name: action.payload.value.administering_provider_first_name,
          administering_provider_last_name: action.payload.value.administering_provider_last_name,
          administration_route: action.payload.value.administration_route,
          administration_site: action.payload.value.administration_site,
          product: action.payload.value.product,
          product_expiration_date: action.payload.value.product_expiration_date,
          product_lot_number: action.payload.value.product_lot_number,
          protection_indicator: action.payload.value.protection_indicator,
          publicity_indicator: action.payload.value.publicity_indicator,
          shouldRemember: action.payload.value.shouldRemember,
        },
      };
    case RESET_CURRENT_PATIENT:
      return { rememberedValues: state.rememberedValues };
    default:
      return state;
  }
};
