import { EMPTY_OBJECT } from '../core/util/object';

const RECEIVE_UNCLAIMED_PLATFORM_USAGE_ALERTS = 'RECEIVE_UNCLAIMED_PLATFORM_USAGE_ALERTS';
const RECEIVE_CLAIMED_PLATFORM_USAGE_ALERTS = 'RECEIVE_CLAIMED_PLATFORM_USAGE_ALERTS';
const FETCHING_ALERTS = 'FETCHING_ALERTS';
const PLATFORM_USAGE_ALERTS_ERROR = 'PLATFORM_USAGE_ALERTS_ERROR';
const RECEIVE_CLAIMED_TAB_FILTERS = 'RECEIVE_CLAIMED_TAB_FILTERS';

const receiveUnclaimedPlatformUsageAlerts = value => ({
  type: RECEIVE_UNCLAIMED_PLATFORM_USAGE_ALERTS,
  payload: { value },
});

const receiveClaimedPlatformUsageAlerts = value => ({
  type: RECEIVE_CLAIMED_PLATFORM_USAGE_ALERTS,
  payload: { value },
});

const fetchingAlerts = value => ({
  type: FETCHING_ALERTS,
  payload: { value },
});

const platformUsageAlertsError = value => ({
  type: PLATFORM_USAGE_ALERTS_ERROR,
  payload: { value },
});

const receiveClaimedTabFilters = value => ({
  type: RECEIVE_CLAIMED_TAB_FILTERS,
  payload: { value },
});

const reducer = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  switch (action.type) {
    case RECEIVE_UNCLAIMED_PLATFORM_USAGE_ALERTS:
      return {
        ...state,
        unclaimed: action.payload.value.results,
      };
    case RECEIVE_CLAIMED_PLATFORM_USAGE_ALERTS:
      return {
        ...state,
        claimed: action.payload.value,
      };
    case FETCHING_ALERTS:
      return {
        ...state,
        fetching: action.payload.value,
      };
    case PLATFORM_USAGE_ALERTS_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case RECEIVE_CLAIMED_TAB_FILTERS:
      return {
        ...state,
        filters: action.payload.value,
      };
    default:
      return state;
  }
};

export {
  reducer as default,
  receiveUnclaimedPlatformUsageAlerts,
  receiveClaimedPlatformUsageAlerts,
  fetchingAlerts,
  platformUsageAlertsError,
  receiveClaimedTabFilters,
};
