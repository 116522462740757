import { QUEUE_SET_LOCATION_IDS } from '../constants';

export const SET_CHAT_MODAL_LOCATION_ID = 'modalLocationIds/SET_CHAT_MODAL_LOCATION_ID';
export const SET_ADD_PATIENT_MODAL_LOCATION_ID =
  'modalLocationIds/SET_ADD_PATIENT_MODAL_LOCATION_ID';
export const SET_REVIEWS_MODAL_LOCATION_ID = 'modalLocationIds/SET_REVIEWS_MODAL_LOCATION_ID';

export const setChatModalLocationId = locationId => ({
  type: SET_CHAT_MODAL_LOCATION_ID,
  payload: { locationId },
});

export const setAddPatientModalLocationId = locationId => ({
  type: SET_ADD_PATIENT_MODAL_LOCATION_ID,
  payload: { locationId },
});

export const setReviewsModalLocationId = locationId => ({
  type: SET_REVIEWS_MODAL_LOCATION_ID,
  payload: { locationId },
});

const getInitialState = () => ({
  chat: null,
  addPatient: null,
  reviews: null,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_CHAT_MODAL_LOCATION_ID: {
      return {
        ...state,
        chat: action.payload.locationId,
      };
    }
    case SET_ADD_PATIENT_MODAL_LOCATION_ID: {
      return {
        ...state,
        addPatient: action.payload.locationId,
      };
    }
    case QUEUE_SET_LOCATION_IDS: {
      const {
        payload: { value: newSelectedLocationIds },
      } = action;
      return {
        ...state,
        chat: newSelectedLocationIds.includes(state.chat) ? state.chat : null,
        reviews: newSelectedLocationIds.includes(state.reviews) ? state.reviews : null,
      };
    }
    case SET_REVIEWS_MODAL_LOCATION_ID: {
      return {
        ...state,
        reviews: action.payload.locationId,
      };
    }
    default:
      return state;
  }
};

export const selectors = {
  getModalLocationIds(reduxState) {
    return reduxState?.modalLocationIds ?? null;
  },
  getChatModalSelectedLocationId(state) {
    return selectors.getModalLocationIds(state)?.chat ?? null;
  },
  getAddPatientModalSelectedLocationId(state) {
    return selectors.getModalLocationIds(state)?.addPatient ?? null;
  },
  getReviewsModalSelectedLocationId(state) {
    return selectors.getModalLocationIds(state)?.reviews ?? null;
  },
};
