import { EMPTY_OBJECT } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

const LAB_RESULTS_RECEIVED = 'labResults/RESULTS_RECEIVED';
const IMPORT_HISTORY_RESULTS_RECEIVED = 'labResults/IMPORT_HISTORY_RECEIVED';
const LAB_RESULT_UPLOAD_RECEIVED = 'labResultUploads/UPLOAD_RECEIVED';
export const LAB_RESULTS_SET_FILTERS = 'labResults/RESULTS_FILTERS';
export const IMPORT_HISTORY_SET_FILTERS = 'labResults/IMPORT_HISTORY_FILTERS';

/**
 * Results set def
 * @typedef {Object} ResultsSet
 * @property {object} page - pagination info
 * @property {Array} results - array containing results entities
 */

/**
 * Lab Results state def
 * @typedef {Object} LabResultState
 * @property {ResultsSet} imports - imports history results
 * @property {ResultsSet} results - lab results results
 * @property {object} resultFilters - filters for GET /lab-results
 * @property {object} importFilters - filters for GET /lab-result-uploads
 * @property {object} labResultUploads - lab results uploads results
 */

/**
 * @typedef {Object} LabResultsAction
 * @property {
 *   | LAB_RESULTS_RECEIVED
 *   | IMPORT_HISTORY_RESULTS_RECEIVED
 *   | LAB_RESULTS_SET_FILTERS
 *   | IMPORT_HISTORY_SET_FILTERS
 * } type
 * @property {any} payload
 */

export const receiveLabResults = value => ({
  type: LAB_RESULTS_RECEIVED,
  payload: value,
});

export const receiveImportHistory = value => ({
  type: IMPORT_HISTORY_RESULTS_RECEIVED,
  payload: value,
});

export const receiveLabResultUpload = value => ({
  type: LAB_RESULT_UPLOAD_RECEIVED,
  payload: value,
});

export const setLabResultFilters = value => ({
  type: LAB_RESULTS_SET_FILTERS,
  payload: value,
});

export const setImportHistoryFilters = value => ({
  type: IMPORT_HISTORY_SET_FILTERS,
  payload: value,
});

const EMPTY_RESULTS = {
  page: EMPTY_OBJECT,
  results: EMPTY_ARRAY,
};

const DEFAULT_STATE = {
  imports: EMPTY_RESULTS,
  results: EMPTY_RESULTS,
  resultFilters: EMPTY_OBJECT,
  importFilters: EMPTY_OBJECT,
  labResultUploads: EMPTY_OBJECT,
};

/**
 * Reducer for lab result.
 *
 * @param {LabResultState} state - existing reducer state
 * @param {LabResultsAction} action
 *
 * @return {LabResultState}
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LAB_RESULTS_RECEIVED:
      return { ...state, results: action.payload ?? EMPTY_OBJECT };
    case IMPORT_HISTORY_RESULTS_RECEIVED:
      return { ...state, imports: action.payload ?? EMPTY_OBJECT };
    case LAB_RESULTS_SET_FILTERS:
      return { ...state, resultFilters: action.payload ?? EMPTY_OBJECT };
    case IMPORT_HISTORY_SET_FILTERS:
      return { ...state, importFilters: action.payload ?? EMPTY_OBJECT };
    case LAB_RESULT_UPLOAD_RECEIVED:
      return { ...state, labResultUploads: action.payload ?? EMPTY_OBJECT };
    default:
      return state;
  }
};
