import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';

const getProviderLocationsUrl = (filterActive, locationId) => {
  const filters = getFilterString('provider_locations', { location_id: locationId });

  return `${DAPI_HOST}/v1/provider-locations?${filters}&filter_active=${filterActive}`;
};

const syncProviderLocationsUrl = () => `${DAPI_HOST}/v1/provider-locations/update`;

const updateProviderLocationUrl = (providerLocationId) =>
  `${DAPI_HOST}/v1/provider-locations/${providerLocationId}`;

export { getProviderLocationsUrl, syncProviderLocationsUrl, updateProviderLocationUrl };
