import { put, takeEvery, call } from 'redux-saga/effects';
import { LocationConsentFormsSagas } from './core/locationConsentForms';
import { buildAwsPath, getS3ConsentFormSigningUrl } from '../core/util/locationConsentForms';
import { isProd } from '../config';
import { log } from '../core/logger/log';
import { PDFDocument } from 'pdf-lib';
export class ConsentFormUploadSagas {
  static UPLOAD_CONSENT_FORM = 'sagas/UPLOAD_CONSENT_FORM';
}
const MAX_CONSENT_FORM_SIZE = 800;
export const ERROR_UPLOAD_PDF_SIZE_TOO_BIG = `File size can't exceed ${MAX_CONSENT_FORM_SIZE}Kbs`;
export const ERROR_UPLOAD_PDF_EMPTY = `File size is 0 bytes`;
export const ERROR_UPLOAD_PDF_CORRUPTED = `PDF file seems to be corrupted`;

const s3SignedUrl = ({ name, type }) =>
  fetch(getS3ConsentFormSigningUrl(name, type), {
    method: 'GET',
    headers: {
      'Content-Type': type,
    },
  }).then((res) => res.json());

const putToS3 = (url, { image, type, contentDisposition }) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': type,
      'x-amz-server-side-encryption': 'AES256',
      'Content-Disposition': contentDisposition,
    },
    body: image,
  });

function fileEmpty(file, onFailure) {
  if (file.size <= 0) {
    const error = ERROR_UPLOAD_PDF_EMPTY;
    if (onFailure) onFailure(error);
    return true;
  }
  return false;
}

function fileTooBig(file, onFailure) {
  const fileSize = file.size / 1024;
  if (fileSize > MAX_CONSENT_FORM_SIZE) {
    const error = ERROR_UPLOAD_PDF_SIZE_TOO_BIG;
    if (onFailure) onFailure(error);
    return true;
  }
  return false;
}

async function checkValidPDF(file) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    return { succeeded: pdfDoc !== null, error: null };
  } catch (error) {
    console.error('Error parsing PDF:', error);
    return { succeeded: false, error: ERROR_UPLOAD_PDF_CORRUPTED };
  }
}
function* uploadConsentForm({ file, displayName, locationId, groupId, onSuccess, onFailure }) {
  try {
    if (fileEmpty(file, onFailure)) return;
    if (fileTooBig(file, onFailure)) return;
    const { succeeded, error } = yield call(checkValidPDF, file);
    if (!succeeded) {
      if (onFailure) onFailure(error);
      return;
    }
    const bucket = isProd() ? 'solv-consent-forms' : 'bin-test-upload';

    const awsPath = buildAwsPath(file.name, groupId);
    const image = { awsPath, image: file, type: file.type, name: awsPath };
    const { signedRequest } = yield call(s3SignedUrl, image);
    const s3UploadResponse = yield call(putToS3, signedRequest, image);

    if (s3UploadResponse && s3UploadResponse.ok) {
      log.debug('S3 upload success');

      // creating location consent form record in db
      yield put({
        type: LocationConsentFormsSagas.CREATE_FORM,
        displayName,
        locationId,
        groupId,
        awsBucketName: bucket,
        awsPath,
        onSuccess,
        onFailure,
      });
    } else {
      if (onFailure) onFailure();
    }
  } catch (e) {
    log.error(e);
    if (onFailure) onFailure(e);
  }
}

export default function* rootSaga() {
  yield takeEvery(ConsentFormUploadSagas.UPLOAD_CONSENT_FORM, uploadConsentForm);
}
