import { array, bool, func, string } from 'prop-types';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Row, Tooltip } from 'antd';
import { isEmptyArray, sortAlphabetical } from '../../../../core/util/array';
import { colors } from '../../../../constants/colors';
import { isEmpty } from '../../../../core/util/empty';
import { Spin } from '../../../primitives';
import sortByLodash from 'lodash/sortBy';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from '../../../../types/Redux';
import { isSolvEmployee } from '../../../../core/session';

const EmptyStateText = styled.div`
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px !important;

  .ant-checkbox:hover {
    border-color: ${colors.solvPink} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.solvPink} !important;
    border-color: ${colors.solvPink} !important;

    &::after {
      background-color: ${colors.solvPink} !important;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    &::after {
      background-color: ${colors.solvPink} !important;
    }
  }
`;

const OnePerLine = styled(Row)`
  border: 1px solid ${colors.dorianGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 12px;

  .ant-checkbox-wrapper {
    line-height: 2;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const OnePerLineWithReactWindow = styled(Row)`
  border: 1px solid ${colors.dorianGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  max-height: 300px;
  height: 200px;
  padding-top: 8px;

  .ant-checkbox-wrapper {
    line-height: 2;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const TwoPerLine = styled(OnePerLine)`
  display: flex !important;
`;

const TwoPerLineWithReactWindow = styled(OnePerLineWithReactWindow)`
  display: flex !important;
`;

const StyledColumn = styled.div`
  ${(props) =>
    !props.onePerLine &&
    css`
      flex: 1;
      min-width: 0;
      overflow-x: clip;
    `};
`;

const Checkboxes = (props) => {
  const {
    checkboxOptions,
    disabledOptions,
    emptyStateText,
    getLabel,
    getValue,
    handleChange,
    loading,
    onePerLine,
    options,
    selectedOptions,
    appliedOptions,
    sortBy,
  } = props;

  const isSolvStaff = useSelector((w) => isSolvEmployee(w.login));

  const items = useMemo(() => {
    if (isEmpty(checkboxOptions)) return [];
    const alphabetized = sortAlphabetical([...checkboxOptions], sortBy || getLabel).map(
      (checkbox) => ({
        label: getLabel(checkbox),
        value: getValue(checkbox),
      })
    );

    if (appliedOptions) {
      // sorts applied options to the top
      return sortByLodash(alphabetized, (option) => !appliedOptions.includes(option.value));
    }

    return alphabetized;
  }, [checkboxOptions, sortBy, getLabel, getValue, appliedOptions]);

  if (loading) {
    return <Spin minHeight="200px" />;
  } else if (isEmptyArray(options)) {
    return <EmptyStateText>{emptyStateText}</EmptyStateText>;
  }

  const [oneLineComponent, twoLineComponent] = isSolvStaff
    ? [OnePerLineWithReactWindow, TwoPerLineWithReactWindow]
    : [OnePerLine, TwoPerLine];

  const Container = onePerLine ? oneLineComponent : twoLineComponent;

  const columns = onePerLine
    ? [items]
    : [items.slice(0, items.length / 2 + 1), items.slice(items.length / 2 + 1)];

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const checkbox = columns[columnIndex][rowIndex];
    if (!checkbox) {
      return null;
    }
    return (
      <div style={style}>
        <StyledCheckbox
          checked={
            !isEmptyArray(selectedOptions) &&
            selectedOptions.includes(checkbox.value) &&
            !disabledOptions.includes(checkbox.value)
          }
          onChange={() => handleChange(checkbox.value)}
          key={checkbox.value}
          disabled={disabledOptions.includes(checkbox.value)}
        >
          <Tooltip title={checkbox.label}>{checkbox.label}</Tooltip>
        </StyledCheckbox>
      </div>
    );
  };

  // If solv staff, have it use react-window, will release this to everyone once its stable.
  if (isSolvStaff) {
    return (
      <Container>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Grid
                columnCount={columns.length}
                columnWidth={(_) => 335}
                height={height}
                rowCount={columns[0].length}
                rowHeight={(_) => 35}
                width={width}
              >
                {Cell}
              </Grid>
            );
          }}
        </AutoSizer>
      </Container>
    );
  }

  return (
    <Container>
      {columns.map((column, index) => (
        <StyledColumn key={`column-${index}`} onePerLine={onePerLine}>
          {column.map((checkbox) => (
            <StyledCheckbox
              checked={
                !isEmptyArray(selectedOptions) &&
                selectedOptions.includes(checkbox.value) &&
                !disabledOptions.includes(checkbox.value)
              }
              onChange={() => handleChange(checkbox.value)}
              key={checkbox.value}
              disabled={disabledOptions.includes(checkbox.value)}
            >
              <Tooltip title={checkbox.label}>{checkbox.label}</Tooltip>
            </StyledCheckbox>
          ))}
        </StyledColumn>
      ))}
    </Container>
  );
};

Checkboxes.propTypes = {
  checkboxOptions: array,
  disabledOptions: array,
  emptyStateText: string.isRequired,
  getLabel: func.isRequired,
  getValue: func.isRequired,
  handleChange: func.isRequired,
  loading: bool,
  onePerLine: bool,
  options: array,
  selectedOptions: array,
  sortBy: func,
  appliedOptions: array,
};

export default Checkboxes;
