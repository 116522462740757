export const ALLIANCE_GROUP_ID = 'oLG5dQ';
export const CHILDRENS_URGENT_CARE_GROUP_ID = 'PLe7QR';
export const CITYDOC_GROUP_ID = '6QawLr';
export const MULTICARE_GROUP_ID = 'VLZqp5';
export const MULTICARE_TRAINING_GROUP_ID = 'kpNMQz';
export const NEXTCARE_GROUP_ID = 'mWBmLo';
export const NEXTCARE_SOLV_TESTING_GROUP_ID = '9pMRLE';
export const PM_PEDIATRICS_GROUP_ID = 'aLO0OW';
export const POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID = 'RWdoLz';
export const PORTLAND_URGENT_CARE_GROUP_ID = 'nL8RQr';
export const PROHEALTH_GROUP_ID = 'bQ2rmQ';
export const RENOWN_GROUP_ID = 'NQnnlQ';
export const RENOWN_TRAINING_GROUP_ID = '9pm1jp';
export const SMART_CARE_GROUP_ID = 'PWvnWD';
export const URGENT_CARE_FOR_KIDS_GROUP_ID = 'aLOBp1';
export const WESTMED_GROUP_ID = '2QAXaW';
export const URGENT_TEAM_GROUP_ID = 'PWv2EW';
export const BETTERMED_GROUP_ID = 'aLOqOQ';
export const AFC_EAST_TN_GROUP_ID = 'ALXV1Q';
export const TOTAL_ACCESS_URGENT_CARE_GROUP_ID = 'rWKk9W';
export const LITTLE_SPURS_GROUP_ID = 'oWgBXL';
export const FORMATION_HEALTH_GROUP_ID = 'Dp3PGL';
export const MI_EXPRESS_CARE_GROUP_ID = '9pM6gL';
export const CITY_LIFE_HEALTH_GROUP_ID = '3pzqJQ';
export const PIERCE_COUNTY_WA_GROUP_ID = 'rWKKyW';
export const SPECIALYSTS_MINNESOTA_GROUP_ID = 'Apby0Q';
export const KAUFMAN_COUNTY_CITY_OF_TERRELL_GROUP_ID = 'rpqyYp';
export const MEDSTAR_GROUP_ID = 'PWvOZQ';
export const MEDSTAR_TELEMED_GROUP_ID = 'aLOwvL';
export const ZOOMCARE_GROUP_ID = 'RWdMYQ';
export const HOAG_GROUP_ID = 'W655rL';
