import getConfig from 'next/config';
import { GenericObject } from '../util/generics';

/**
 * Server can access client and server config, so check both config objects.
 * @param name
 */

const getConfigOrDefault = () => {
  return (
    getConfig() || {
      serverRuntimeConfig: {},
      publicRuntimeConfig: {},
    }
  );
};

export function getServerConfig(name: string): string | undefined {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfigOrDefault();
  return serverRuntimeConfig[name] || publicRuntimeConfig[name];
}

/**
 * Client can only access public runtime config so only check that.
 * @param name
 */
export function getClientConfig(name: string): string | undefined {
  const { publicRuntimeConfig } = getConfigOrDefault();
  return publicRuntimeConfig[name];
}

export function getAllClientConfig(): GenericObject {
  const { publicRuntimeConfig } = getConfigOrDefault();
  return publicRuntimeConfig;
}

/**
 * Server has access to both server and public runtime config so include both.
 */
export function getAllServerConfig(): GenericObject {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfigOrDefault();
  return { ...serverRuntimeConfig, ...publicRuntimeConfig };
}
