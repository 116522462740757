import { SLOT_LOOK_BACK_DURATION, QUEUE_PAST_BOOKING_THRESHOLD_IN_MINUTES } from '../../config';
import groupBy from 'lodash/groupBy';
import { isEmptyArray } from '../util/array';
import moment from 'moment-timezone';

/**
 * Get fully constructed slot data for a single day.  If the chosen day is in the slots array that
 * comes back from dapi, it will return those slots.  Otherwise it will construct slots from the
 * operating hours.
 * @param location {Object} Location data from dapi
 * @param date {Object} Moment object for the day for which you want the slots
 * @returns {Array}
 */
const getSlotsByDay = (location, date) => {
  if (!location || !location.slots) {
    return [];
  }

  return location.slots.filter((slot) => moment(slot.appointmentDate).isSame(date, 'day'));
};

/**
 * Is it possible for a user to book an appointment at this location?
 * @param location
 * @return {boolean}
 */
const canBook = (location) => {
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  return (
    getSlotsByDay(location, today.date()).length > 0 ||
    getSlotsByDay(location, tomorrow.date()).length > 0
  );
};

/**
 * Get first available slot
 * @param {object} location
 * @return {number}
 */
const getFirstAvailableAppointmentTime = (location) => {
  const todaySlots = getSlotsByDay(location, moment());
  for (const slot of todaySlots) {
    if (slot.availability !== 0) {
      return slot.appointmentDate;
    }
  }

  const tomorrowSlots = getSlotsByDay(location, moment().add(1, 'day'));
  for (const slot of tomorrowSlots) {
    if (slot.availability !== 0) {
      return slot.appointmentDate;
    }
  }

  return null;
};

/**
 * Get nearest slot to selected appointment time
 * Looks back 15 minutes and all of the way into tomorrow.
 * @param location {object}
 * @param appointmentTime {number}
 * @param isQueue {boolean}
 * @param allowLastSlotOfDay {boolean}
 * @param isReservation {boolean}
 * @return {number}
 */
const getNearestSlotAvailable = (
  location,
  appointmentTime,
  isQueue = false,
  allowLastSlotOfDay = false,
  isReservation
) => {
  const date = moment(appointmentTime);

  const slotAvailable = (slot) => {
    if (isReservation && slot.isReservationsDisabled) {
      return false;
    }

    return (
      slot.availability !== 0 && slot.appointmentDate >= appointmentTime - SLOT_LOOK_BACK_DURATION
    );
  };

  const todaySlots = getSlotsByDay(location, moment());
  for (const slot of todaySlots) {
    if (slotAvailable(slot)) return slot.appointmentDate;
  }

  const slotsOnDate = getSlotsByDay(location, date);
  for (const slot of slotsOnDate) {
    if (slotAvailable(slot)) {
      return slot.appointmentDate;
    }
  }

  if (allowLastSlotOfDay && !isEmptyArray(todaySlots) && !isReservation) {
    return todaySlots[todaySlots.length - 1].appointmentDate;
  }

  for (let i = 1; i <= 6; i++) {
    const slots = getSlotsByDay(location, moment().add(i, 'day'));
    for (const slot of slots) {
      if (slotAvailable(slot)) return slot.appointmentDate;
    }
  }

  return null;
};

/**
 * Get nearest slot to selected appointment time
 * @param location
 * @param requestedAppointmentTime
 * @return {number}
 */
const getNearestSlotAvailableToday = (location, requestedAppointmentTime) => {
  const slotAvailable = (slot) =>
    slot.availability !== 0 &&
    slot.appointmentDate >= requestedAppointmentTime - SLOT_LOOK_BACK_DURATION;
  const todaySlots = getSlotsByDay(location, moment());
  for (const slot of todaySlots) {
    if (slotAvailable(slot)) return slot.appointmentDate;
  }

  // if nothing is technically available today, then return the last slot in the day
  // ...if it's available
  const lastSlot = todaySlots[todaySlots.length - 1];
  if (lastSlot.availability !== 0) {
    return lastSlot.appointmentDate;
  }

  return null;
};

/**
 * Get all available slots at or after the requested time.
 * @param location
 * @param requestedTime
 */
const getAvailableSlotsByRequestedTime = (location, requestedTime) => {
  const allSlots = getSlotsByDay(location, moment(requestedTime));
  const nearestSlotTime = getNearestSlotAvailable(location, requestedTime);
  return allSlots.filter((slot) => slot.appointmentDate >= nearestSlotTime);
};

/**
 *
 * @param location {object} location object
 * @param x {number} time window for appointment match in minutes
 * @param requestedAppointmentTime {number} epoch time of requested appointment
 */
const locationHasAvailabilityWithinXMinutesOfRequestedAppointmentTime = (
  location,
  x,
  requestedAppointmentTime
) => {
  const xInMilliseconds = x * 60 * 1000;
  return location.slots.some(
    (slot) =>
      Math.abs(slot.appointmentDate - requestedAppointmentTime) <= xInMilliseconds &&
      slot.availability !== 0
  );
};

/**
 * Returns a list of appointment slots after the requested appointment time
 * @param location {object} DAPI location object
 * @param requestedAppointmentTime {number} requested appointment time in milliseconds since epoch
 */
const getLocationsSlotsAfterRequestedAppointmentTime = (location, requestedAppointmentTime) =>
  location.slots.filter((slot) => slot.appointmentDate >= requestedAppointmentTime);

/**
 * Returns object with today's and tomorrow's slots as arrays
 * @param location {object} DAPI location object
 * @returns {{today: Array, tomorrow: Array}}
 */
const getSlotsForTodayAndTomorrow = (location) => {
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  return {
    today: getSlotsByDay(location, today),
    tomorrow: getSlotsByDay(location, tomorrow),
  };
};

/**
 * Filters out appointment slots in the past
 * @param slots {Array} [{ appointmentDate: number, availability: number }]
 */
const removeSlotsBeforeNow = (slots) =>
  slots.filter((slot) => slot.appointmentDate >= moment().valueOf());

const getNumberOfSlots = (location, appointmentTime) => {
  const matchingSlot = location.slots.filter((slot) => slot.appointmentDate === appointmentTime);

  if (matchingSlot.length) {
    return matchingSlot[0].availability;
  }

  return 0;
};

const getHoursWhenAllAppointmentsTakenInDay = (location, day) => {
  const slotsToday = getSlotsByDay(location, day);

  const slotsGroupedByHour = groupBy(slotsToday, (slot) =>
    moment(slot.appointmentDate).format('H')
  );

  const hoursThatAreBookedOut = [];
  for (const hour in slotsGroupedByHour) {
    if (slotsGroupedByHour.hasOwnProperty(hour)) {
      if (slotsGroupedByHour[hour].every((slot) => slot.availability === 0)) {
        hoursThatAreBookedOut.push(Number(hour));
      }
    }
  }

  return hoursThatAreBookedOut;
};

const appointmentIntervalsTakenInThisHour = (location, date, hour) => {
  const slotsToday = getSlotsByDay(location, date);

  const slotsGroupedByHour = groupBy(slotsToday, (slot) =>
    moment(slot.appointmentDate).format('H')
  );

  const minutesThatAreBookedOut = [];

  if (slotsGroupedByHour.hasOwnProperty(hour)) {
    const slotsGroupedByMinute = groupBy(slotsGroupedByHour[hour], (slot) =>
      moment(slot.appointmentDate).format('mm')
    );
    for (const minute in slotsGroupedByMinute) {
      if (slotsGroupedByMinute.hasOwnProperty(minute)) {
        if (slotsGroupedByMinute[minute].every((slot) => slot.availability === 0)) {
          minutesThatAreBookedOut.push(Number(minute));
        }
      }
    }
  }

  return minutesThatAreBookedOut;
};

const minutesTooFarInThePast = () => {
  const currentMinute = moment().minutes();
  const threshold = currentMinute - QUEUE_PAST_BOOKING_THRESHOLD_IN_MINUTES;
  if (threshold > 0) {
    return [...new Array(threshold).keys()];
  }

  return [];
};

/**
 * Returns true if there are no more slots at location after date
 * @param location {object} DAPI location object
 * @param date {object} moment object
 * @returns {boolean}
 */
const isAfterLastSlotOfTheDay = (location, date) => {
  const slots = getSlotsByDay(location, date);
  const lastSlot = slots[slots.length - 1];
  return moment(lastSlot.appointmentDate).isBefore(date);
};

const getNextNAvailableSlots = (n, location) => {
  const slots = removeSlotsBeforeNow(location.slots);

  const nearestAvailableToday = getNearestSlotAvailableToday(location, Date.now());

  // make sure to always include today's last slot, even if the clinic is closed
  if (nearestAvailableToday.appointmentDate !== slots[0].appointmentDate) {
    slots.unshift(nearestAvailableToday);
  }

  return slots;
};

const isSlotsAvailableOnDay = (location, date, isReservation) => {
  const slots = getSlotsByDay(location, date);

  return slots.some((slot) => {
    if (isReservation && slot.isReservationsDisabled) {
      return false;
    }

    return slot.availability !== 0;
  });
};

const isSlotAvailable = (slot, opts = {}) => {
  if (opts.isReservation && slot.isReservationsDisabled) {
    return false;
  }

  return slot.availability > 0;
};

export {
  canBook,
  getFirstAvailableAppointmentTime,
  getNearestSlotAvailable,
  getNearestSlotAvailableToday,
  getAvailableSlotsByRequestedTime,
  locationHasAvailabilityWithinXMinutesOfRequestedAppointmentTime,
  getLocationsSlotsAfterRequestedAppointmentTime,
  getSlotsForTodayAndTomorrow,
  removeSlotsBeforeNow,
  getNumberOfSlots,
  getHoursWhenAllAppointmentsTakenInDay,
  appointmentIntervalsTakenInThisHour,
  minutesTooFarInThePast,
  getSlotsByDay,
  isAfterLastSlotOfTheDay,
  getNextNAvailableSlots,
  isSlotsAvailableOnDay,
  isSlotAvailable,
};
