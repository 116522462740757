import { EMPTY_OBJECT } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export const getServicePreferences = (reduxState) => reduxState?.servicePreferences ?? null;

export class ServicePreferencesActionTypes {
  static ERROR = 'servicePreferences/ERROR';
  static LOADING = 'servicePreferences/LOADING';
  static RECEIVED = 'servicePreferences/RECEIVED';
  static UPDATED = 'servicePreferences/UPDATED';
  static UPDATING = 'servicePreferences/UPDATING';
}

export const servicePreferencesError = (value) => ({
  payload: { value },
  type: ServicePreferencesActionTypes.ERROR,
});

export const servicePreferencesLoading = (value) => ({
  payload: { value },
  type: ServicePreferencesActionTypes.LOADING,
});

export const servicePreferencesReceived = (value) => ({
  payload: { value },
  type: ServicePreferencesActionTypes.RECEIVED,
});

export const servicePreferencesUpdated = (value) => ({
  payload: { value },
  type: ServicePreferencesActionTypes.UPDATED,
});

export const servicePreferencesUpdating = (value) => ({
  payload: { value },
  type: ServicePreferencesActionTypes.UPDATING,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ServicePreferencesActionTypes.ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case ServicePreferencesActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ServicePreferencesActionTypes.RECEIVED:
      return {
        ...state,
        results: action.payload.value,
      };
    case ServicePreferencesActionTypes.UPDATED: {
      const newResults = (state.results ?? EMPTY_ARRAY)
        .slice()
        .filter((pl) => pl.id !== action.payload.value.id);
      newResults.push(action.payload.value);

      return {
        ...state,
        error: null,
        results: newResults,
      };
    }
    case ServicePreferencesActionTypes.UPDATING: {
      return {
        ...state,
        isUpdating: action.payload.value,
      };
    }
    default:
      return state;
  }
};
