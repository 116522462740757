import {
  RESET_REPORTS,
  REPORT_ERROR,
  SET_REPORT_DATES,
  SET_REPORT_LOCATIONS,
  SET_PERFORMANCE_PRICING_LOCATIONS_INFO,
  SET_EARLIEST_LIVE_DATE,
  SET_PROVIDERS,
  RECEIVE_REVIEWS_SUMMARY,
  REVIEWS_SUMMARY_ERROR,
  RECEIVE_REVIEWS,
  RECEIVE_REVIEW,
  RESET_REVIEWS_CURENT_PAGE,
  REVIEWS_ERROR,
  RESET_REVIEWS,
  UPDATE_FILTERS,
  LOGIN_LOGOUT,
  PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED,
  PAPERLESS_REPORTS_GRAPH_DATA_ERROR,
  TELEMED_REPORTS_GRAPH_DATA_RECEIVED,
  TELEMED_REPORTS_GRAPH_DATA_ERROR,
  SET_DOWNLOAD_REPORT_FILE_FORMAT,
} from '../constants';
import { EMPTY_ARRAY, isEmptyArray } from '../core/util/array';

const createInitialState = () => ({
  data: EMPTY_ARRAY,
  error: null,
  fetched: null,
  from: null,
  to: null,
  reviews: {},
  locationIds: EMPTY_ARRAY,
  hasUnselectedAllLocations: false,
  fileFormat: 'xlsx',
  hasPerformancePricingLocations: false,
  performancePricingLocations: {},
  locationIdsLiveMoreThan30Days: [],
  earliestLiveDate: {},
});

const deleteExtraneousKeysFromNewReview = (originalReview, newReview) => {
  const strippedNewReview = { ...newReview };

  Object.keys(newReview).forEach((key) => {
    if (!originalReview.hasOwnProperty(key)) delete strippedNewReview[key];
  });

  return strippedNewReview;
};

const updateOneReview = (originalReviews, newReview) =>
  originalReviews.map((review) => {
    if (review.solv_ratings_id === newReview.id || review.reviews_google_gmb_id === newReview.id) {
      const strippedNewReview = deleteExtraneousKeysFromNewReview(review, newReview);

      return {
        ...review,
        ...strippedNewReview,
      };
    }

    return review;
  });

export default function reportsReducer(state = createInitialState(), action) {
  switch (action.type) {
    case RESET_REPORTS:
      return {
        ...state,
        data: null,
        error: null,
        fetched: null,
      };
    case REPORT_ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case SET_REPORT_DATES:
      return {
        ...state,
        from: action.payload[0],
        to: action.payload[1],
      };
    case SET_REPORT_LOCATIONS:
      return {
        ...state,
        locationIds: action.payload.value,
        hasUnselectedAllLocations: isEmptyArray(action.payload.value),
      };
    case SET_PERFORMANCE_PRICING_LOCATIONS_INFO:
      return {
        ...state,
        hasPerformancePricingLocations: action.payload.hasPerformancePricingLocations,
        performancePricingLocations: action.payload.performancePricingLocations,
        locationIdsLiveMoreThan30Days: action.payload.locationIdsLiveMoreThan30Days,
      };
    case SET_EARLIEST_LIVE_DATE:
      return {
        ...state,
        earliestLiveDate: action.payload.momentObject,
      };
    case SET_PROVIDERS:
      return {
        ...state,
        providerIds: action.payload.value,
      };
    case RECEIVE_REVIEWS_SUMMARY:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          summaryData: action.payload.value,
        },
      };
    case REVIEWS_SUMMARY_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          summaryError: action.payload.value,
        },
      };
    case RECEIVE_REVIEWS: {
      let reviewsData = EMPTY_ARRAY;
      if (state.reviews.currentPage && state.reviews.currentPage > 0) {
        reviewsData = state.reviews.reviewsData.concat(action.payload.value.results);
      } else {
        reviewsData = action.payload.value.results;
      }
      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviewsData,
          currentPage: action.payload.currentPage,
          hasMorePages: action.payload.hasMorePages,
        },
      };
    }
    case RECEIVE_REVIEW: {
      const reviewsData = updateOneReview(state.reviews.reviewsData, action.payload.value);

      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviewsData,
        },
      };
    }
    case PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED:
      return {
        ...state,
        paperlessReportsGraphData: {
          ...state.paperlessReportsGraphData,
          [action.payload.type]: action.payload.value,
        },
      };
    case PAPERLESS_REPORTS_GRAPH_DATA_ERROR:
      return {
        ...state,
        paperlessReportsGraphDataError: action.payload,
      };
    case TELEMED_REPORTS_GRAPH_DATA_RECEIVED:
      return {
        ...state,
        telemedReportsGraphData: {
          ...state.telemedReportsGraphData,
          [action.payload.type]: action.payload.value,
        },
      };
    case TELEMED_REPORTS_GRAPH_DATA_ERROR:
      return {
        ...state,
        telemedReportsGraphDataError: action.payload,
      };
    case RESET_REVIEWS_CURENT_PAGE:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          currentPage: 0,
        },
      };
    case REVIEWS_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          reviewsError: action.payload.value,
        },
      };
    case RESET_REVIEWS:
      return {
        ...state,
        reviews: {
          reviewsData: [],
          summaryData: {},
        },
      };
    case UPDATE_FILTERS:
      return Object.assign({}, state, action.payload.value);
    case LOGIN_LOGOUT:
      return createInitialState();
    case SET_DOWNLOAD_REPORT_FILE_FORMAT:
      if (action.payload !== state.fileFormat && typeof action.payload === 'string') {
        return {
          ...state,
          fileFormat: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
}
