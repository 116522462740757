import { DAPI_HOST } from '../../config';
import { isEmptyObject } from '../util/object';
import { getFilterString } from './index';

const getUnclaimedPlatformUsageAlertsUrl = () =>
  `${DAPI_HOST}/v1/platform-usage-alerts?order_by=display_name_primary%20ASC`;

const getClaimedPlatformUsageAlertsUrl = (page, filters) => {
  const alertsFilters = { ...filters };

  let url = `${DAPI_HOST}/v1/platform-usage-alerts?page=${page}&order_by=updated_date%20DESC&unclaimed_only=false`;

  if (!isEmptyObject(alertsFilters)) {
    if ('display_name_primary' in alertsFilters) {
      url += `&display_name_primary=${encodeURIComponent(
        alertsFilters.display_name_primary.join(',')
      )}`;
      delete alertsFilters.display_name_primary;
    }

    if ('display_name_secondary' in alertsFilters) {
      url += `&display_name_secondary=${encodeURIComponent(
        alertsFilters.display_name_secondary.join(',')
      )}`;
      delete alertsFilters.display_name_secondary;
    }

    if ('group_name' in alertsFilters) {
      url += `&group_name=${encodeURIComponent(alertsFilters.group_name.join(','))}`;
      delete alertsFilters.group_name;
    }

    if (!isEmptyObject(alertsFilters)) {
      url += `&${getFilterString('platform_usage_alerts', alertsFilters)}`;
    }
  }

  return url;
};

const getPlatformAlertsPatchUrl = alertId => `${DAPI_HOST}/v1/platform-usage-alerts/${alertId}`;

const getClearAllUnclaimedPlatformAlertsUrl = () =>
  `${DAPI_HOST}/v1/platform-usage-alerts/clear-all`;

const getClaimedTabFiltersUrl = () => `${DAPI_HOST}/v1/platform-usage-alerts/table-filters`;

export {
  getUnclaimedPlatformUsageAlertsUrl,
  getClaimedPlatformUsageAlertsUrl,
  getPlatformAlertsPatchUrl,
  getClearAllUnclaimedPlatformAlertsUrl,
  getClaimedTabFiltersUrl,
};
