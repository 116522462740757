import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from 'react-redux';
import { RootState, store } from '../store/configureStore';
import { Action, ThunkAction } from '@reduxjs/toolkit';

export const EMPTY_ACTION: DispatchAction = { type: '', payload: {} };

export type DispatchAction = { type: string; payload: any };

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = useReduxDispatch;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
