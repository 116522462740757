import { all, call, put, select, debounce, race, delay, takeEvery } from 'redux-saga/effects';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import {
  getBookingsGraphsUrl,
  getPaperlessReportsGraphUrl,
  getTelemedReportsGraphUrl,
} from '../core/dapi/reports';
import { apiGetJson } from '../core/dapi';
import { isEmpty } from '../core/util/empty';
import { safeGet } from '../core/util/object';
import { receiveGraphData, graphDataError } from '../actions/graphs';
import { setRuntimeVariable } from '../actions/runtime';
import {
  GRAPH_TYPE_VISIT_LENGTH,
  GRAPH_TYPE_AVG_VISIT_LENGTH,
  GRAPH_TYPE_PATIENT_VOLUME,
  VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_LAST_MONTH,
  VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_ALL_TIME,
  GRAPH_TYPE_PATIENT_SATISFACTION,
  GRAPH_TYPE_FAKE_PHONE_NUMBERS,
  GRAPH_TYPE_PAPERLESS_REGISTRATIONS,
  GRAPH_TYPE_PAPERLESS_TIME_SAVED,
  GRAPH_TYPE_PAPERLESS_WAIT_TIME,
  GRAPH_TYPE_TELEMED_DAILY_ACTIVE_PROVIDERS,
  GRAPH_TYPE_TELEMED_BOOKINGS,
  GRAPH_TYPE_TELEMED_TIME_IN_CALL,
  GRAPH_TYPE_TELEMED_PATIENT_DEVICES,
} from '../constants';
import { sortArrayOfObjectsByKey, SORT_ASC_STRING } from '../core/util/array';
import {
  paperlessReportsGraphDataError,
  telemedReportsGraphDataError,
  receivePaperlessReportsGraphData,
  receiveTelemedReportsGraphData,
  setPerformancePricingLocationInfo,
  setEarliestLiveDate,
} from '../actions/reports';
import { locationsResponseFormatter } from '../reducers/formatters/location';
import { arrayToObject } from '../core/util/array';
import {
  getPerformancePricingLocationsInfo,
  getEarliestLiveDate,
  getDateRange,
} from '../routes/reports/util';
import { fetchLocations, FetchLocationsFulfilledAction, selectors } from '../ducks/locations';
import { waitFor } from './util/sagaUtil';
import { log } from '../core/logger/log';

const IS_GETTING_BOOKINGS_GRAPH_DATA = 'isGettingBookingsGraphData';
const IS_GETTING_REPORTS_GRAPH_DATA = 'isGettingReportsGraphData';
const IS_GETTING_PAPERLESS_REPORTS_GRAPH_DATA = 'isGettingPaperlessReportsGraphData';
const IS_GETTING_TELEMED_REPORTS_GRAPH_DATA = 'isGettingTelemedReportsGraphData';
const DATE_FILTER_KEY = 'date';

export const FETCH_REPORT_LOCATION_DATA = 'FETCH_REPORT_LOCATION_DATA';
export const FETCH_BOOKINGS_GRAPH_DATA = 'FETCH_BOOKINGS_GRAPH_DATA';
export const FETCH_PAPERLESS_REPORTS_GRAPH_DATA = 'FETCH_PAPERLESS_REPORTS_GRAPH_DATA';
export const FETCH_TELEMED_REPORTS_GRAPH_DATA = 'FETCH_TELEMED_REPORTS_GRAPH_DATA';

const handleGetPaperlessRegistrations = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      ahead_of_time: 0,
      in_clinic: 0,
      count_mobile_in_clinic: 0,
      count_tablet_in_clinic: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result.ahead_of_time += res.ahead_of_time;
      result.in_clinic += res.in_clinic;
      result.count_mobile_in_clinic += res.count_mobile_in_clinic;
      result.count_tablet_in_clinic += res.count_tablet_in_clinic;
    });
    results.push(result);
  });
  return results;
};

const handleGetPaperlessWaitTime = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      ahead_of_time: 0,
      in_clinic: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result.ahead_of_time = res.ahead_of_time;
      result.in_clinic = res.in_clinic;
    });
    results.push(result);
  });
  return results;
};

const handleGetPaperlessTimeSaved = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      time_saved: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result.time_saved += res.time_saved;
    });
    results.push(result);
  });
  return results;
};

const handleGetPatientVolume = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      'call-ahead': 0,
      'walk-in': 0,
      website: 0,
      solv: 0,
      solv_new: 0,
      solv_returning: 0,
      clinic_website: 0,
      clinic_website_new: 0,
      clinic_website_returning: 0,
      impressions: 0,
      favorites: 0,
      online_wait_time_bookings: 0,
      online_wait_time_sum: 0,
      walkin_wait_time_bookings: 0,
      walkin_wait_time_sum: 0,
      online_door_to_door_time_bookings: 0,
      online_door_to_door_time_sum: 0,
      walkin_door_to_door_time_bookings: 0,
      walkin_door_to_door_time_sum: 0,
      walkin_used_kiosk_bookings: 0,
      walkin_used_kiosk_sum: 0,
      online_seen_on_time_bookings: 0,
      online_seen_on_time_sum: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result['call-ahead'] += res['call-ahead'];
      result['walk-in'] += res['walk-in'];
      result.website += res.website;
      result.solv += res.solv_new_data + res.solv_existing_data;
      result.solv_new += res.solv_new_data;
      result.solv_returning += res.solv_existing_data;
      result.clinic_website += res.clinic_website_new_data + res.clinic_website_existing_data;
      result.clinic_website_new += res.clinic_website_new_data;
      result.clinic_website_returning += res.clinic_website_existing_data;
      result.impressions += res.impressions;
      result.favorites += res.favorites;
      result.online_wait_time_bookings += res.online_wait_time_bookings;
      result.online_wait_time_sum += res.online_wait_time_sum;
      result.walkin_wait_time_bookings += res.walkin_wait_time_bookings;
      result.walkin_wait_time_sum += res.walkin_wait_time_sum;
      result.online_door_to_door_time_bookings += res.online_door_to_door_time_bookings;
      result.online_door_to_door_time_sum += res.online_door_to_door_time_sum;
      result.walkin_door_to_door_time_bookings += res.walkin_door_to_door_time_bookings;
      result.walkin_door_to_door_time_sum += res.walkin_door_to_door_time_sum;
      result.walkin_used_kiosk_bookings += res.walkin_used_kiosk_bookings;
      result.walkin_used_kiosk_sum += res.walkin_used_kiosk_sum;
      result.online_seen_on_time_bookings += res.online_seen_on_time_bookings;
      result.online_seen_on_time_sum += res.online_seen_on_time_sum;
    });

    results.push(result);
  });

  return results;
};

const handleGetVisitLength = (successes) => {
  const results = [];
  successes.forEach((success) => {
    if (
      success.avg_arrive_to_ready_minutes !== null &&
      success.avg_exam_to_discharge_minutes !== null &&
      success.avg_ready_to_exam_minutes !== null
    ) {
      results.push(success);
    }
  });

  if (results.length > 1 && !isEmpty(successes)) {
    results.push(successes[successes.length - 1]);
  }

  return results;
};

const handleGetPatientSatisfaction = (successes) => {
  const results = {
    solv: {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      average: 0,
      count: 0,
    },
    google: {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      average: 0,
      count: 0,
    },
  };

  successes.forEach((success) => {
    if (success.solv) {
      results.solv.one += success.solv.one;
      results.solv.two += success.solv.two;
      results.solv.three += success.solv.three;
      results.solv.four += success.solv.four;
      results.solv.five += success.solv.five;
      results.solv.count += success.solv.count;
    }

    if (success.google) {
      results.google.one += success.google.one;
      results.google.two += success.google.two;
      results.google.three += success.google.three;
      results.google.four += success.google.four;
      results.google.five += success.google.five;
      results.google.count += success.google.count;
    }
  });

  results.solv.average = Number(
    (
      (results.solv.one +
        2 * results.solv.two +
        3 * results.solv.three +
        4 * results.solv.four +
        5 * results.solv.five) /
        results.solv.count || 0
    ).toFixed(2)
  );
  results.solv.one = Math.round((results.solv.one * 100) / results.solv.count) || 0;
  results.solv.two = Math.round((results.solv.two * 100) / results.solv.count) || 0;
  results.solv.three = Math.round((results.solv.three * 100) / results.solv.count) || 0;
  results.solv.four = Math.round((results.solv.four * 100) / results.solv.count) || 0;
  results.solv.five = Math.round((results.solv.five * 100) / results.solv.count) || 0;

  results.google.average = Number(
    (
      (results.google.one +
        2 * results.google.two +
        3 * results.google.three +
        4 * results.google.four +
        5 * results.google.five) /
        results.google.count || 0
    ).toFixed(2)
  );
  results.google.one = Math.round((results.google.one * 100) / results.google.count) || 0;
  results.google.two = Math.round((results.google.two * 100) / results.google.count) || 0;
  results.google.three = Math.round((results.google.three * 100) / results.google.count) || 0;
  results.google.four = Math.round((results.google.four * 100) / results.google.count) || 0;
  results.google.five = Math.round((results.google.five * 100) / results.google.count) || 0;

  return results;
};

const handleFakePhoneNumbers = (successes) => {
  const results = [];
  successes.forEach((success) => {
    if (success && success.queue_fake_phone_bookings && success.queue_bookings) {
      results.push(success);
    }
  });

  return results;
};

const handleGetDailyActiveProviders = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      count_daily_providers: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result.count_daily_providers += res.count_daily_providers;
    });
    results.push(result);
  });

  return results;
};

const handleGetTelemedTimeInCall = (successes) => {
  const results = [];
  const unFilteredSuccesses = groupBy(successes, DATE_FILTER_KEY);
  Object.keys(unFilteredSuccesses).forEach((unfilteredRes) => {
    const result = {
      total_time_in_calls: 0,
      total_calls: 0,
    };
    unFilteredSuccesses[unfilteredRes].forEach((res) => {
      result.date = res.date;
      result.total_time_in_calls += res.total_time_in_calls;
      result.total_calls += res.total_calls;
    });
    results.push(result);
  });

  return results;
};

export function* fetchBookingsGraphData({ locationIds, to, from, graphType }) {
  yield put(setRuntimeVariable({ name: IS_GETTING_BOOKINGS_GRAPH_DATA, value: true }));

  const errors = [];
  const successes = [];

  const chunked = chunk(locationIds, 100);

  const responses = yield all(
    chunked.map((ids) => {
      let bookingsGraphUrl = getBookingsGraphsUrl(ids, from, to, graphType);
      if (
        graphType === VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_LAST_MONTH ||
        graphType === VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_ALL_TIME
      ) {
        bookingsGraphUrl = getBookingsGraphsUrl(ids, from, to, GRAPH_TYPE_PATIENT_VOLUME);
      }
      return call(apiGetJson, bookingsGraphUrl);
    })
  );

  if (graphType === GRAPH_TYPE_PATIENT_SATISFACTION) {
    responses.forEach((resp) => {
      if ('errors' in resp) {
        errors.push(safeGet(resp)('errors'));
      } else if (!isEmpty(safeGet(resp)('data'))) {
        successes.push(safeGet(resp)('data'));
      }
    });
  } else {
    responses.forEach((resp) => {
      if ('errors' in resp) {
        errors.push(safeGet(resp)('errors'));
      } else if (!isEmpty(safeGet(resp)('data'))) {
        safeGet(resp)('data').forEach((r) => successes.push(r));
      }
    });
    sortArrayOfObjectsByKey(successes, DATE_FILTER_KEY, SORT_ASC_STRING);
  }

  if (!isEmpty(errors)) {
    yield put(graphDataError(errors));
  } else {
    if (
      graphType === GRAPH_TYPE_PATIENT_VOLUME ||
      graphType === VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_LAST_MONTH ||
      graphType === VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_ALL_TIME
    ) {
      yield put(receiveGraphData(graphType)(handleGetPatientVolume(successes)));
    } else if (graphType === GRAPH_TYPE_VISIT_LENGTH) {
      yield put(receiveGraphData(graphType)(handleGetVisitLength(successes)));
    } else if (graphType === GRAPH_TYPE_AVG_VISIT_LENGTH) {
      yield put(receiveGraphData(graphType)(successes));
    } else if (graphType === GRAPH_TYPE_PATIENT_SATISFACTION) {
      yield put(receiveGraphData(graphType)(handleGetPatientSatisfaction(successes)));
    } else if (graphType === GRAPH_TYPE_FAKE_PHONE_NUMBERS) {
      yield put(receiveGraphData(graphType)(handleFakePhoneNumbers(successes)));
    }
  }

  yield put(setRuntimeVariable({ name: IS_GETTING_BOOKINGS_GRAPH_DATA, value: false }));
}

export function* fetchPaperlessReportsGraphData({ locationIds, to, from, graphType }) {
  yield put(setRuntimeVariable({ name: IS_GETTING_PAPERLESS_REPORTS_GRAPH_DATA, value: true }));

  const errors = [];
  const successes = [];

  const responses = yield all(
    locationIds.map((ids) => {
      const paperlessReportsGraphUrl = getPaperlessReportsGraphUrl([ids], from, to, graphType);
      const response = call(apiGetJson, paperlessReportsGraphUrl);

      return response;
    })
  );

  responses.forEach((resp) => {
    if ('errors' in resp) {
      errors.push(safeGet(resp)('errors'));
    } else if (!isEmpty(safeGet(resp)('data'))) {
      safeGet(resp)('data').forEach((r) => successes.push(r));
    }
  });

  sortArrayOfObjectsByKey(successes, DATE_FILTER_KEY, SORT_ASC_STRING);

  if (!isEmpty(errors)) {
    yield put(paperlessReportsGraphDataError(errors));
  } else {
    if (graphType === GRAPH_TYPE_PAPERLESS_REGISTRATIONS) {
      yield put(
        receivePaperlessReportsGraphData(graphType)(handleGetPaperlessRegistrations(successes))
      );
    } else if (graphType === GRAPH_TYPE_PAPERLESS_WAIT_TIME) {
      yield put(receivePaperlessReportsGraphData(graphType)(handleGetPaperlessWaitTime(successes)));
    } else if (graphType === GRAPH_TYPE_PAPERLESS_TIME_SAVED) {
      yield put(
        receivePaperlessReportsGraphData(graphType)(handleGetPaperlessTimeSaved(successes))
      );
    }
  }

  yield put(setRuntimeVariable({ name: IS_GETTING_PAPERLESS_REPORTS_GRAPH_DATA, value: false }));
}

export function* fetchReportsLocationsData({ locationIds }) {
  if (locationIds.length === 0) {
    return;
  }

  yield put(setRuntimeVariable({ name: IS_GETTING_REPORTS_GRAPH_DATA, value: true }));

  yield put(
    fetchLocations({
      locationIds,
    })
  );

  // Wait for all the location data to be loaded
  const { timeout } = yield race({
    posts: call(
      waitFor,
      (state) => {
        return selectors.getLocationsFromIds(state, locationIds).length === locationIds.length;
      },
      FetchLocationsFulfilledAction
    ),
    // This usually only takes like 5 seconds for even 150+ locations, but doing 60 here just to be safe.
    timeout: delay(60 * 1000),
  });

  // If we timeout loading the locations, just return because we don't have all the data
  if (timeout) {
    log.error(`timed out loading locations for reports: total locations: ${locationIds.length}`);
    return;
  }

  const locations = yield select((s) => selectors.getLocationsFromIds(s, locationIds));
  const formattedLocations = arrayToObject(locationsResponseFormatter(locations));

  const {
    performancePricingLocations,
    hasPerformancePricingLocations,
    locationIdsLiveMoreThan30Days,
  } = getPerformancePricingLocationsInfo(formattedLocations, locationIds);
  yield put(
    setPerformancePricingLocationInfo({
      performancePricingLocations,
      hasPerformancePricingLocations,
      locationIdsLiveMoreThan30Days,
    })
  );

  const earliestLiveDate = getEarliestLiveDate(formattedLocations);
  yield put(setEarliestLiveDate(earliestLiveDate));

  const { start, end } = getDateRange('28', '1');
  const performancePricingLocationIds = Object.keys(performancePricingLocations);
  yield call(fetchBookingsGraphData, {
    locationIds: performancePricingLocationIds,
    from: start,
    to: end,
    graphType: VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_LAST_MONTH,
  });
  yield call(fetchBookingsGraphData, {
    locationIds,
    from: earliestLiveDate,
    to: end,
    graphType: VIRTUAL_GRAPH_TYPE_PATIENT_VOLUME_ALL_TIME,
  });

  yield put(setRuntimeVariable({ name: IS_GETTING_REPORTS_GRAPH_DATA, value: false }));
}

export function* fetchTelemedReportsGraphData({ locationIds, to, from, graphType }) {
  yield put(setRuntimeVariable({ name: IS_GETTING_TELEMED_REPORTS_GRAPH_DATA, value: true }));

  const errors = [];
  const successes = [];

  const responses = yield all(
    locationIds.map((id) => {
      const telemedReportsGraphUrl = getTelemedReportsGraphUrl(id, from, to, graphType);
      const response = call(apiGetJson, telemedReportsGraphUrl);

      return response;
    })
  );

  responses.forEach((resp) => {
    if ('errors' in resp) {
      errors.push(safeGet(resp)('errors'));
    } else if (!isEmpty(safeGet(resp)('data'))) {
      safeGet(resp)('data').forEach((r) => successes.push(r));
    }
  });

  sortArrayOfObjectsByKey(successes, DATE_FILTER_KEY, SORT_ASC_STRING);

  if (!isEmpty(errors)) {
    yield put(telemedReportsGraphDataError(errors));
  } else {
    if (
      graphType === GRAPH_TYPE_TELEMED_BOOKINGS ||
      graphType === GRAPH_TYPE_TELEMED_PATIENT_DEVICES
    ) {
      yield put(receiveTelemedReportsGraphData(graphType, successes));
    } else if (graphType === GRAPH_TYPE_TELEMED_DAILY_ACTIVE_PROVIDERS) {
      yield put(
        receiveTelemedReportsGraphData(graphType, handleGetDailyActiveProviders(successes))
      );
    } else if (graphType === GRAPH_TYPE_TELEMED_TIME_IN_CALL) {
      yield put(receiveTelemedReportsGraphData(graphType, handleGetTelemedTimeInCall(successes)));
    }
  }
  yield put(setRuntimeVariable({ name: IS_GETTING_TELEMED_REPORTS_GRAPH_DATA, value: false }));
}

export default function* rootSaga() {
  yield debounce(1500, FETCH_REPORT_LOCATION_DATA, fetchReportsLocationsData);
  yield takeEvery(FETCH_BOOKINGS_GRAPH_DATA, fetchBookingsGraphData);
  yield takeEvery(FETCH_PAPERLESS_REPORTS_GRAPH_DATA, fetchPaperlessReportsGraphData);
  yield takeEvery(FETCH_TELEMED_REPORTS_GRAPH_DATA, fetchTelemedReportsGraphData);
}
