import PropTypes from 'prop-types';
import React from 'react';
import ModalLocationPicker from '../ModalLocationPicker/ModalLocationPicker';

const ReviewsModalLocationSelect = ({ locationId, locationIdOptions, onChange }) => (
  <ModalLocationPicker
    currentModalLocationId={locationId ?? undefined}
    handleSelectLocation={onChange}
    selectedLocationIds={locationIdOptions}
  />
);
ReviewsModalLocationSelect.propTypes = {
  locationId: PropTypes.string,
  locationIdOptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReviewsModalLocationSelect;
