import {
  WAIT_LIST_RECEIVED,
  WAIT_LIST_ERROR,
  WAIT_LIST_LOADING,
  NEXT_ONLINE_APPOINTMENT_RECEIVED,
  CLEAR_NEXT_ONLINE_APPOINTMENT,
} from '../constants';

export default function waitTime(state = { results: {} }, action) {
  switch (action.type) {
    case WAIT_LIST_RECEIVED: {
      const newState = {
        ...state,
        loading: false,
        error: null,
      };
      newState.results[action.locationId] = action.payload;
      return newState;
    }

    case WAIT_LIST_ERROR: {
      const newState = {
        ...state,
        loading: false,
        error: action.payload,
      };
      newState.results[action.locationId] = null;
      return newState;
    }

    case WAIT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case NEXT_ONLINE_APPOINTMENT_RECEIVED:
      return {
        ...state,
        loading: false,
        error: null,
        nextOnlineAppointment: action.payload,
      };

    case CLEAR_NEXT_ONLINE_APPOINTMENT:
      return {
        ...state,
        nextOnlineAppointment: null,
      };

    default:
      return state;
  }
}
