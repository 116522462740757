import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledImagePreload = styled.img`
  display: none;
`;

class ImagePreload extends PureComponent {
  render = () => <StyledImagePreload src={this.props.src} />;
}

ImagePreload.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImagePreload;
