import { DAPI_HOST, ACUANT_BASE_URL, ACUANT_API_KEY, DAPI_CACTUS_HOST } from '../../config';
import fetch from 'isomorphic-fetch';

const getImageUploadUrl = () => `${DAPI_HOST}/v2/images`;

const mapAcuantToFields = (response) => {
  const updatedResponse = {};

  // key value pairs for translating ocr fields to proper variable names
  updatedResponse.firstName = response.FirstName;
  updatedResponse.lastName = response.LastName;
  updatedResponse.insurerCode = response.PlanProvider;
  updatedResponse.memberCode = response.MemberId;
  updatedResponse.insurerType = response.PlanType;
  updatedResponse.rawText = response.RawText;

  return updatedResponse;
};

const acuantCropUrl = () => `${ACUANT_BASE_URL}/True/0/200/True`;
const acuantNoCropUrl = () => `${ACUANT_BASE_URL}/False/0/200/False`;

const getImageDownloadUrl = (id) => `${DAPI_HOST}/v2/images/${id}`;
const getImageUrl = (id) => `${DAPI_HOST}/v1/images_url/${id}`;
const getImageMetadataUrl = (id) => `${DAPI_HOST}/v1/images_metadata/${id}`;

const buildAcuantData = (fileData) => {
  const data = new FormData();
  data.set('image', fileData);
  return data;
};

const buildImageFormData = (fileData, imageType, ocrResponse) => {
  const ocr = ocrResponse;
  delete ocr.ReformattedImage;

  const data = new FormData();
  data.append('image', fileData);
  data.append('image_type', imageType);
  data.append('ocr_response', JSON.stringify(ocr));
  return data;
};

function acuantPostImage(url, postData) {
  const options = { headers: {} };
  options.method = 'POST';
  options.headers.Authorization = ACUANT_API_KEY;
  options.body = postData;

  return fetch(url, options).then((req) => req.json());
}

const getCardPdfUrl = (currentPatient, card) => {
  const cardFrontId = card.card_front_id || card.cardId;
  const cardFrontParam = cardFrontId ? `&card_front=${cardFrontId}` : '';
  const cardBackParam = card.card_back_id ? `&card_back=${card.card_back_id}` : '';
  return (
    `${DAPI_CACTUS_HOST}/v1/images/card.pdf?name=${currentPatient.first_name}_${currentPatient.last_name}` +
    `${cardFrontParam}${cardBackParam}`
  );
};

export {
  buildImageFormData,
  mapAcuantToFields,
  getImageUploadUrl,
  acuantCropUrl,
  acuantNoCropUrl,
  acuantPostImage,
  getImageDownloadUrl,
  buildAcuantData,
  getImageUrl,
  getImageMetadataUrl,
  getCardPdfUrl,
};
