import Router from 'next/router';

const history = {
  push: (path) => {
    Router.push(path);
  },
  createHref: (path) => {
    return `/${path}`;
  },
  replace: (path) => {
    Router.replace(path);
  },
  back: () => {
    Router.back();
  },
};

export default history;
