import { useInitializeFlags } from '../core/flags/flags';
import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { FullPageSpinner } from './App/FullPageSpinner';

export function Startup({ children }: { children: any }) {
  const ready = useInitializeFlags();
  return !ready ? <FullPageSpinner /> : <>{children}</>;
}
