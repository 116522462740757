import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const StyledAppointmentTypeBadge = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: ${props => (props.showBorder ? '1px solid' : 'none')};
  border-color: ${props => props.color};
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width};
  height: ${props => props.height};
  padding: 10px 6px;

  img {
    width: 12px;
    margin-right: 5px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const StyledBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

class BookingBadge extends PureComponent {
  render = () => (
    <StyledAppointmentTypeBadge
      backgroundColor={this.props.backgroundColor}
      color={this.props.color}
      showBorder={this.props.showBorder}
      width={this.props.width}
      height={this.props.height}
    >
      {this.props.iconName ? (
        <StyledBadgeContainer>
          <StyledIcon type={this.props.iconName} />
          <span>{this.props.title}</span>
        </StyledBadgeContainer>
      ) : (
        <StyledBadgeContainer>
          <img src={this.props.imgSource} alt={this.props.altText} />
          <span>{this.props.title}</span>
        </StyledBadgeContainer>
      )}
    </StyledAppointmentTypeBadge>
  );
}

BookingBadge.defaultProps = {
  showBorder: false,
  width: '100px',
  height: '30px',
};

BookingBadge.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  imgSource: PropTypes.string,
  iconName: PropTypes.string,
  altText: PropTypes.string,
  showBorder: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BookingBadge;
