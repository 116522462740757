import { DAPI_HOST } from '../../config';
import { isEmptyObject } from '../util/object';
import { getFilterString } from './index';

const getMarketplaceInsurersUrl = filters => {
  let url = `${DAPI_HOST}/v1/marketplace-insurers`;

  if (!isEmptyObject(filters)) {
    url += `?${getFilterString('marketplace_insurers', filters)}`;
  }

  return url;
};

export { getMarketplaceInsurersUrl };
