import { comparator } from './string';
import moment from 'moment-timezone';
import { GenericObject } from './generics';

const EMPTY_ARRAY: unknown[] = [];
const EMPTY_ARRAY_NEVER: never[] = [];

const isEmptyArray = (array: unknown) => !Array.isArray(array) || array.length < 1;

const SORT_ASC = 1;
const SORT_DESC = -1;
const SORT_ASC_STRING = 'ASC';
const SORT_DESC_STRING = 'DESC';

/**
 * Sort an array alphabetically
 * @param array Array
 * @param getStringForComparison function
 * @param sortOrder integer
 */
const sortAlphabetical = <T>(
  array: T[],
  getStringForComparison: (item: T) => string,
  sortOrder = SORT_ASC
): Array<T> =>
  isEmptyArray(array)
    ? array
    : array.sort((item1, item2) => {
        const str1 = getStringForComparison(item1).toLowerCase();
        const str2 = getStringForComparison(item2).toLowerCase();
        return comparator(str1, str2) * sortOrder;
      });

const sortByTimestamps = <T>(
  array: T[],
  getTimestampForComparison: (item: T) => string,
  sortOrder = SORT_ASC
): Array<T> =>
  isEmptyArray(array)
    ? array
    : array.sort((item1, item2) => {
        const date1 = moment(getTimestampForComparison(item1));
        const date2 = moment(getTimestampForComparison(item2));
        // @ts-ignore
        return (date1 - date2) * sortOrder;
      });

const diffArrays = (oldArray = EMPTY_ARRAY, newArray = EMPTY_ARRAY) => {
  const addedElements: unknown[] = [];
  const removedElements: unknown[] = [];

  newArray.forEach((element) => {
    if (!oldArray.includes(element)) {
      addedElements.push(element);
    }
  });

  oldArray.forEach((element) => {
    if (!newArray.includes(element)) {
      removedElements.push(element);
    }
  });

  return { addedElements, removedElements };
};

const safeMap = (array = EMPTY_ARRAY, func: (item: any) => any) => array.map(func);

const mergeArraysOfObjects = <T>(a: T[] = [], b: T[] = [], comparisonKey: string): T[] => {
  const merged: T[] = [...a];

  b.forEach((bEl) => {
    // @ts-ignore
    if (!a.find((aEl) => aEl[comparisonKey] === bEl[comparisonKey])) {
      // @ts-ignore
      merged.push({ ...bEl });
    }
  });

  return merged;
};

const isObjectInArray = <T>(element: T, array: Array<T>, getKey: (ele: T) => string): boolean =>
  array.some((arrayEl) => getKey(arrayEl) === getKey(element));

const sortArrayOfObjectsByKey = <T extends GenericObject>(
  array: Array<T>,
  key: string,
  sortOrder = SORT_DESC_STRING
): Array<T> => {
  if (isEmptyArray(array)) return array;

  const compareDesc = (a: T, b: T) => {
    if (a[key] > b[key]) {
      return SORT_DESC;
    }

    if (a[key] < b[key]) {
      return SORT_ASC;
    }

    return 0;
  };

  const compareAsc = (a: T, b: T) => {
    if (a[key] < b[key]) {
      return SORT_DESC;
    }

    if (a[key] > b[key]) {
      return SORT_ASC;
    }

    return 0;
  };

  if (sortOrder === SORT_ASC_STRING) {
    return array.sort(compareAsc);
  }

  return array.sort(compareDesc);
};

const range = (start: number, end: number, step: number) => {
  const arr = [];

  for (let i = start; i <= end; i += step) {
    arr.push(i);
  }

  return arr;
};

const flattenArrayOfArrays = (twoDim: any[]) => twoDim.reduce((acc, cur) => acc.concat(cur), []);

// https://scotch.io/courses/the-ultimate-guide-to-javascript-algorithms/combining-arrays-without-duplicates
const mergeArrays = (...arrays: never[][]): any[] => {
  let jointArray: any[] = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });

  // @ts-ignore
  return [...new Set([...jointArray])];
};

/**
 * Converts an array of objects into one object.
 * As keys, it uses the respective values of some common property (ie 'id').
 * E.g. [{ id: 1 }, { id: 2 }, { id : 3 }] => { 1: { id: 1 }, 2: { id: 2 }, 3: { id: 3 }}
 * Inspired by https://stackoverflow.com/a/4215753/14781986
 */
const arrayToObject = (array: any[], commonKey = 'id'): GenericObject =>
  array.reduce((acc, cur) => ({ ...acc, [cur[commonKey]]: cur }), {});

// Insired by https://stackoverflow.com/a/19746771/14781986
// Note: this only works for 'scalar' arrays (where elements can be compared with ===)
const areEqualArrays = (arr1: any[], arr2: any[]): boolean => {
  const arr1Sorted = arr1.slice().sort();
  const arr2Sorted = arr2.slice().sort();
  return arr1.length === arr2.length && arr1Sorted.every((el, i) => el === arr2Sorted[i]);
};

/**
 * @returns whether or not the arrays have the same (===) values in the corresponding indexes.
 */
function areStrictEqualArrays(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true;
  if (arr1 == null) return false;
  if (arr2 == null) return false;
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

type Primitives = string | number | boolean | Symbol;

export function inArray(value: Primitives, values: Primitives[]) {
  for (let v of values) {
    if (v === value) {
      return true;
    }
  }
  return false;
}

export function removeFirst<V>(array: V[], val: V) {
  const index = array.indexOf(val);
  if (index < 0) return array;
  const copy: typeof array = new Array(array.length - 1);
  for (let i = 0; i < index; i++) {
    copy[i] = array[i];
  }
  for (let i = index + 1; i < array.length; i++) {
    copy[i - 1] = array[i];
  }
  return copy;
}

export {
  diffArrays,
  isEmptyArray,
  safeMap,
  sortAlphabetical,
  sortByTimestamps,
  EMPTY_ARRAY,
  EMPTY_ARRAY_NEVER,
  SORT_ASC,
  SORT_DESC,
  mergeArraysOfObjects,
  isObjectInArray,
  sortArrayOfObjectsByKey,
  SORT_ASC_STRING,
  SORT_DESC_STRING,
  range,
  flattenArrayOfArrays,
  mergeArrays,
  arrayToObject,
  areEqualArrays,
  areStrictEqualArrays,
};
