import { DAPI_HOST } from '../../config';
import moment from 'moment';
import { isEmptyArray } from '../util/array';
import { isEmptyObject } from '../util/object';
import { apiPatchDispatchable, getFilterString } from './index';
import { ORIGIN_QUEUE } from '../../constants';

const getDateRangeForBookingsRequest = () => {
  const dateFormat = 'YYYY-MM-DD';
  const yesterday = moment().subtract(1, 'day');
  const twoDaysFromNow = moment().add(2, 'day');

  return { start: yesterday.format(dateFormat), end: twoDaysFromNow.format(dateFormat) };
};

export const getPublicBookingsByLocationAndPhone = (locationIds, phone) => {
  const { start, end } = getDateRangeForBookingsRequest();

  return (
    `${DAPI_HOST}/v1/public-bookings` +
    `?location_id=${locationIds}` +
    `&appointment_date=${start}_to_${end}` +
    `&filters=bookings.phone:${encodeURIComponent(phone)};` +
    'bookings.status:reserved,pending,confirmed' +
    '&order_by=appointment_date%20ASC'
  );
};

export const getBookingByIdUrl = (bookingId) => `${DAPI_HOST}/v1/bookings/${bookingId}`;

export const patchBookingDispatchable = (dispatch, bookingId, patchData, onSuccess, onError) => {
  dispatch(
    apiPatchDispatchable(getBookingByIdUrl(bookingId), patchData, onSuccess, onError, ORIGIN_QUEUE)
  );
};

export const getBookingHistoryUrl = (bookingId) => `${DAPI_HOST}/v1/bookings/${bookingId}/history?`;

export const getBookingsUrl = (filters) =>
  `${DAPI_HOST}/v1/bookings?${getFilterString(
    'bookings',
    filters
  )}&order_by=created_date%20DESC&query_for_results_count=True`;

export const getMostRecentBookingForAccountAtLocationUrl = (accountId, locationId) =>
  `${DAPI_HOST}/v1/bookings?location_id=${locationId}` +
  `&filters=bookings.account_id:${accountId}&order_by=created_date%20DESC`;

export const getRescheduleBookingsUrl = () => `${DAPI_HOST}/v1/registration/reschedule`;

export const getTransferBookingsUrl = () => `${DAPI_HOST}/v1/registration/transfer`;

export const getGetBookingUrl = (bookingId, calling, author, count) =>
  `${getBookingByIdUrl(bookingId)}?calling_function=${calling}&clinic_account_id=${author}` +
  `&count=${count}`;

export const getBookingsSummaryUrl = (locationIds) =>
  `${DAPI_HOST}/v2/bookings/summary?location_ids=${locationIds.join(',')}`;

export const getAllBookingsByLocationIdUrl = (locationId, limit, page, filters, dateRange) => {
  const filterString = getFilterString('bookings', filters);

  let url = `${DAPI_HOST}/v1/bookings?location_id=${locationId}&limit=${limit}&page=${page}&${filterString}`;

  if (!isEmptyObject(dateRange)) {
    url += `&appointment_date=${dateRange.from}_to_${dateRange.to}`;
  }

  return url;
};
