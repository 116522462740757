import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row as AntRow } from 'antd';
import withStyles from '../../../core/isomorphic-style-loader/withStyles';
import { mergeClassNames } from '../util';
import s from './Row.module.scss';
import {
  ROW_VERTICAL_PADDING_NONE,
  ROW_VERTICAL_PADDING_SMALL,
  ROW_VERTICAL_PADDING_MEDIUM,
  ROW_VERTICAL_PADDING_LARGE,
} from '../constants';

class Row extends PureComponent {
  render = () => {
    const { verticalPadding, ...props } = this.props;

    return (
      <AntRow {...props} className={mergeClassNames(s[verticalPadding], props)}>
        {this.props.children}
      </AntRow>
    );
  };
}

Row.defaultProps = {
  verticalPadding: ROW_VERTICAL_PADDING_SMALL,
};

Row.propsTypes = {
  children: PropTypes.node.isRequired,
  verticalPadding: PropTypes.oneOf([
    ROW_VERTICAL_PADDING_NONE,
    ROW_VERTICAL_PADDING_SMALL,
    ROW_VERTICAL_PADDING_MEDIUM,
    ROW_VERTICAL_PADDING_LARGE,
  ]),
};

export default withStyles(s)(Row);
