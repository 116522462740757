import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Check extends PureComponent {
  render = () => (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8.63636L5.26667 13L17 1" stroke={this.props.stroke} strokeWidth="2" />
    </svg>
  );
}

Check.propTypes = {
  stroke: PropTypes.string.isRequired,
};
