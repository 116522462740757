const buildAwsPath = (fileName, groupId) =>
  `${groupId}/${new Date().valueOf()}_${fileName.replace(/\s/g, '')}`;

const buildConsentFormUrl = form =>
  `https://${form.aws_bucket_name}.s3.amazonaws.com/${form.aws_path}`;

const getS3ConsentFormSigningUrl = (name, type) => {
  const n = encodeURIComponent(name);
  const t = encodeURIComponent(type);
  return `/api/s3-files/consent-forms?file-name=${n}&file-type=${t}`;
};

export { buildAwsPath, buildConsentFormUrl, getS3ConsentFormSigningUrl };
