import { call, put, takeEvery } from 'redux-saga/effects';
import {
  receiveImportHistory,
  receiveLabResults,
  receiveLabResultUpload,
} from '../../ducks/labResults';
import {
  apiDeleteJson,
  apiGetJson,
  apiPatchJson,
  apiPostJson,
  apiPostRaw,
  getMultiFiltersString,
} from '../../core/dapi';
import { DAPI_CACTUS_HOST, DAPI_HOST } from '../../config';
import { setRuntimeVariable } from '../../actions/runtime';
import { receiveLabResultConfigDefaults, receiveLabResultsConfig } from '../../actions/settings';
import { isEmptyArray } from '../../core/util/array';

export const SAGA_FETCH_IMPORT_HISTORY = 'labResultSaga/FETCH_IMPORT_HISTORY';
export const SAGA_FETCH_LAB_RESULTS = 'labResultSaga/FETCH_LAB_RESULTS';
export const SAGA_UPLOAD_LAB_RESULT = 'labResultSaga/UPLOAD_LAB_RESULT';
export const SAGA_UPLOAD_LAB_RESULTS = 'labResultSaga/UPLOAD_LAB_RESULTS';
export const SAGA_SEND_LAB_RESULT_SMS = 'labResultSaga/SEND_LAB_RESULT_SMS';
export const SAGA_FETCH_LAB_RESULTS_CONFIG = 'labResultSaga/FETCH_LAB_CONFIG';
export const SAGA_CREATE_LAB_RESULTS_CONFIG = 'labResultSaga/CREATE_LAB_ADD_CONFIG';
export const SAGA_FETCH_LAB_RESULT_CONFIG_DEFAULTS =
  'labResultSaga/FETCH_LAB_RESULT_CONFIG_DEFAULTS';
export const SAGA_UPDATE_LAB_RESULTS_CONFIG = 'labResultSaga/UPDATE_LAB_ADD_CONFIG';

const DEFAULT_ORDER_BY = 'created_date DESC';

const dapi = (
  url,
  host = DAPI_HOST,
  expandEntities = false,
  limit = 20,
  page = 1,
  filters,
  orderBy = DEFAULT_ORDER_BY
) => {
  const filterParamString = getMultiFiltersString(filters);
  return (
    `${host}${url}` +
    `?expand_entities=${expandEntities}` +
    `&filters=${filterParamString}` +
    `&limit=${limit}` +
    `&page=${page}` +
    `&order_by=${orderBy}`
  );
};

function* fetchLabBasedResults({
  page = 1,
  filters,
  url,
  host = DAPI_HOST,
  limit = 20,
  action,
  orderBy = DEFAULT_ORDER_BY,
}) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResults',
        value: true,
      })
    );
    const { data } = yield call(apiGetJson, dapi(url, host, true, limit, page, filters, orderBy));
    yield put(action(data));
  } catch (e) {
    console.error('error fetching lab result for', url, e); // eslint-disable-line no-console
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResults',
        value: false,
      })
    );
  }
}

function* fetchImportHistory({ ...dapiArgs }) {
  yield fetchLabBasedResults({
    url: '/v1/lab-result-uploads',
    action: receiveImportHistory,
    ...dapiArgs,
  });
}

function* fetchLabResults({ ...dapiArgs }) {
  yield fetchLabBasedResults({
    url: '/v1/lab-results',
    host: DAPI_CACTUS_HOST,
    action: receiveLabResults,
    ...dapiArgs,
  });
}

function* uploadLabResultsCsv({ url, action, file }) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResultUploads',
        value: true,
      })
    );

    const postData = new FormData();
    postData.set('file_b64', file);

    const { data } = yield call(apiPostJson, dapi(url), postData);
    yield put(action(data));
  } catch (e) {
    console.error('error during uploadLabResultsCsv'); // eslint-disable-line no-console
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResultUploads',
        value: false,
      })
    );
  }
}

function* uploadLabResultData({ url, action, params, onSuccess, onError, onComplete }) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isUploadingLabResults',
        value: true,
      })
    );
    const postData = new FormData();
    const {
      booking_id: bookingId,
      service,
      test_type: testType,
      result,
      link_1_text: link1Text,
      link_1_url: link1Url,
      link_2_url: link2Url,
      link_2_text: link2Text,
    } = params;

    postData.set('booking_id', bookingId);
    postData.set('service', service);
    postData.set('test_type', testType);
    postData.set('result', result);
    link1Text && postData.set('link_1_text', link1Text);
    link1Url && postData.set('link_1_url', link1Url);
    link2Text && postData.set('link_2_text', link2Text);
    link2Url && postData.set('link_2_url', link2Url);

    const { data } = yield call(apiPostJson, dapi(url), postData);

    yield put(action(data));
    if (onSuccess) onSuccess(data);
  } catch (e) {
    if (onError) onError(e);
    console.error('error during uploadLabResultsCsv'); // eslint-disable-line no-console
  } finally {
    if (onComplete) onComplete();

    yield put(
      setRuntimeVariable({
        name: 'isUploadingLabResults',
        value: false,
      })
    );
  }
}

function* uploadLabResult({ ...args }) {
  yield uploadLabResultData({
    url: '/v1/lab-result-uploads',
    action: receiveLabResultUpload,
    ...args,
  });
}

function* uploadLabResults({ file }) {
  yield uploadLabResultsCsv({
    url: '/v1/lab-result-uploads',
    action: receiveLabResultUpload,
    file,
  });
}

function* sendLabResultSms({ labResultUploadId, onSuccess, onError, onComplete }) {
  try {
    yield put(setRuntimeVariable({ name: 'isSendingLabResultsSms', value: true }));
    const postData = new FormData();
    postData.set('lab_result_upload_id', labResultUploadId);

    yield call(apiPostRaw, dapi('/v1/lab-results/send-sms'), postData);
    if (onSuccess) onSuccess();
  } catch (e) {
    if (onError) onError(e);
    console.error('error during sendLabResultSms', e); // eslint-disable-line no-console
  } finally {
    yield put(setRuntimeVariable({ name: 'isSendingLabResultsSms', value: false }));
    if (onComplete) onComplete();
  }
}

function* fetchLabResultsConfig({ page = 1, filters }) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResultsConfig',
        value: true,
      })
    );
    const { data } = yield call(
      apiGetJson,
      dapi('/v1/lab-result-configs', DAPI_HOST, false, 1, page, filters)
    );
    yield put(receiveLabResultsConfig(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error fetching lab result for', '/v1/lab-result-configs', e);
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'isFetchingLabResultsConfig',
        value: false,
      })
    );
  }
}

function* fetchLabResultConfigDefaults({ serviceId }) {
  try {
    const { data } = yield call(
      apiGetJson,
      `${DAPI_HOST}/v1/lab-result-config-defaults/service/${serviceId}`
    );
    yield put(receiveLabResultConfigDefaults(data, serviceId));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      'error fetching defaults for service id',
      `/v1/lab-result-config-defaults/service/${serviceId}`,
      e
    );
  }
}

function* addLabResultsConfig({ locationId, locationServiceId, copy }) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isUpdatingLabResultsConfig',
        value: true,
      })
    );

    const postData = {
      location_id: locationId,
      location_service_id: locationServiceId,
      copy,
    };

    const { data } = yield call(apiPostJson, `${DAPI_HOST}/v1/lab-result-configs`, postData);
    yield put(receiveLabResultsConfig({ results: [data] }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error posting lab result config for', '/v1/lab-result-configs', e);
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'isUpdatingLabResultsConfig',
        value: false,
      })
    );
  }
}

function* updateLabResultsConfig({ labResultConfigId, locationId, locationServiceId, copy }) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'isUpdatingLabResultsConfig',
        value: true,
      })
    );
    const patchData = {
      location_id: locationId,
      location_service_id: locationServiceId,
      copy: JSON.stringify(copy),
    };

    const { data } = yield call(
      !isEmptyArray(copy) ? apiPatchJson : apiDeleteJson,
      `${DAPI_HOST}/v1/lab-result-configs/${labResultConfigId}`,
      patchData
    );
    yield put(receiveLabResultsConfig({ results: [data] }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error updating lab result config for', '/v1/lab-result-configs', e);
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'isUpdatingLabResultsConfig',
        value: false,
      })
    );
  }
}

export default function* rootSaga() {
  yield takeEvery(SAGA_FETCH_IMPORT_HISTORY, fetchImportHistory);
  yield takeEvery(SAGA_FETCH_LAB_RESULTS, fetchLabResults);
  yield takeEvery(SAGA_UPLOAD_LAB_RESULT, uploadLabResult);
  yield takeEvery(SAGA_UPLOAD_LAB_RESULTS, uploadLabResults);
  yield takeEvery(SAGA_SEND_LAB_RESULT_SMS, sendLabResultSms);
  yield takeEvery(SAGA_FETCH_LAB_RESULTS_CONFIG, fetchLabResultsConfig);
  yield takeEvery(SAGA_CREATE_LAB_RESULTS_CONFIG, addLabResultsConfig);
  yield takeEvery(SAGA_UPDATE_LAB_RESULTS_CONFIG, updateLabResultsConfig);
  yield takeEvery(SAGA_FETCH_LAB_RESULT_CONFIG_DEFAULTS, fetchLabResultConfigDefaults);
}
