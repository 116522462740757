import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

const StyledFormItem = styled(FormItem)`
  label {
    text-transform: capitalize;
    color: ${colors.greyBae};
    font-size: 14px;
  }

  .ant-radio-button-wrapper-checked {
    color: ${colors.primaryBlue};
  }
`;

class RadioGroupFormField extends PureComponent {
  render = () => (
    <StyledFormItem label={this.props.label} colon={false}>
      {this.props.form.getFieldDecorator(this.props.fieldName, {
        initialValue: this.props.initialValue,
        rules: [{ required: this.props.required, message: this.props.requiredMessage }],
      })(
        <RadioGroup disabled={this.props.disabled} onChange={this.props.onChange}>
          {this.props.options.map(option => (
            <RadioButton
              key={option.value}
              value={option.value}
              data-testid={`status-${option.value}`}
            >
              {option.name}
            </RadioButton>
          ))}
        </RadioGroup>
      )}
    </StyledFormItem>
  );
}

RadioGroupFormField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default RadioGroupFormField;
