import { isEmptyObject } from './object';
import { isEmptyArray } from './array';
import { isEmptyString } from './string';

const isEmpty = (val: unknown): boolean => {
  if (typeof val === 'string') return isEmptyString(val);
  if (typeof val === 'number') return isNaN(val); // i love js
  if (typeof val === 'symbol') return false;
  if (typeof val === 'boolean') return false;
  if (typeof val === 'function') return false;
  if (val instanceof Array) return isEmptyArray(val);
  if (val instanceof Object) return isEmptyObject(val);
  return true;
};

export { isEmpty };
