import { EMPTY_ARRAY } from '../core/util/array';

export class CategoriesActionTypes {
  static CATEGORIES_RECEIVED = 'CATEGORIES_RECEIVED';
  static CATEGORIES_ERROR = 'CATEGORIES_ERROR';
}

export const categoriesRecieved = value => ({
  type: CategoriesActionTypes.CATEGORIES_RECEIVED,
  payload: { value },
});

export const categoriesError = value => ({
  type: CategoriesActionTypes.CATEGORIES_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case CategoriesActionTypes.CATEGORIES_RECEIVED:
      return action.payload.value.data.results;
    case CategoriesActionTypes.CATEGORIES_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
