import { call, put, takeEvery } from 'redux-saga/effects';
import { setRuntimeVariable } from '../../actions/runtime';
import { RuntimeAttributes } from '../../reducers/runtime';
import { getLocationCategoriesUrl } from '../../core/dapi/location';
import { apiGetJson } from '../../core/dapi';
import { categoriesError, categoriesRecieved } from '../../ducks/categories';

export class CategoriesSagas {
  static FETCHING_CATEGORIES = 'SAGA/FETCH_CATEGORIES';
}

export function* fetchCategories() {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_CATEGORIES, value: true }));

  const response = yield call(apiGetJson, getLocationCategoriesUrl());

  if (response.errors) {
    yield put(categoriesError(response.errors));
  } else {
    yield put(categoriesRecieved(response));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_CATEGORIES, value: false }));
}

export default function* rootSaga() {
  yield takeEvery(CategoriesSagas.FETCHING_CATEGORIES, fetchCategories);
}
