import { coreAnalyticsTrackEvent } from '../core/analytics/coreAnalyticsTrackEvent';
import { APP_API_FAILURE } from '../core/analytics/events';

const DIRECTORY_LOCATIONS_RECEIVED = 'directory/DIRECTORY_LOCATIONS_RECEIVED';
const LOCATIONS_FAILED = 'directory/DIRECTORY_LOCATIONS_FAILED';

const setDirectoryLocations = (value) => ({
  type: DIRECTORY_LOCATIONS_RECEIVED,
  payload: { value: value.results },
});

function locationsError(value) {
  coreAnalyticsTrackEvent(APP_API_FAILURE);
  return {
    type: LOCATIONS_FAILED,
    payload: { value },
  };
}

export default (state = {}, action) => {
  switch (action.type) {
    case DIRECTORY_LOCATIONS_RECEIVED:
      return {
        ...state,
        officeLocations: action.payload.value,
      };
    case LOCATIONS_FAILED:
      return {
        error: action.payload.value,
      };

    default:
      return state;
  }
};

export { setDirectoryLocations, locationsError };
