import React from 'react';
import { Col, Input } from 'antd';
import { array, func } from 'prop-types';

const Search = Input.Search;

const SearchBar = ({ getLabel, options, setCheckboxOptions, setSearchKeyword }) => {
  const filterOptions = (e) => {
    const filteredOptions = options.filter((item) =>
      getLabel(item).toLowerCase().includes(e.target.value.toLowerCase())
    );

    setCheckboxOptions(filteredOptions);
    setSearchKeyword(e.target.value);
  };

  return (
    <Col span={8}>
      <Search onKeyUp={filterOptions} placeholder="Search" data-testid={'search-bar'} />
    </Col>
  );
};

SearchBar.propTypes = {
  getLabel: func,
  options: array,
  setCheckboxOptions: func.isRequired,
  setSearchKeyword: func.isRequired,
};

export default SearchBar;
