import Cookies from 'js-cookie';
import { isLocalHost } from '../../config';

const setCookie = (
  cookieName: string,
  data: any,
  exDays: number,
  exDatetime: Date | null | undefined = null,
  secure = true
) => {
  let d;
  if (exDatetime) {
    d = exDatetime;
  } else {
    d = new Date();
    d.setTime(d.getTime() + (exDays || 1) * 24 * 60 * 60 * 1000);
  }

  d = new Date(d);

  Cookies.set(cookieName, typeof data === 'string' ? data : JSON.stringify(data), {
    expires: d,
    secure: isLocalHost() ? false : secure,
  });
};

const getCookie = (cookieName: string) => {
  const result = Cookies.get(cookieName);
  if (!result) {
    return result;
  }
  try {
    return JSON.parse(result);
  } catch (ex) {
    return result;
  }
};

const removeCookie = (cookieName: string) => {
  Cookies.remove(cookieName);
};

export { setCookie, getCookie, removeCookie };
