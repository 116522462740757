export const getUrlParameterByNameForUrlString = (urlStr, name) => {
  if (typeof urlStr !== 'string') {
    return null;
  }

  const cleanedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cleanedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlStr);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getUrlParameterByName = (name) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const url = window.location.href;
  return getUrlParameterByNameForUrlString(url, name);
};

export const urlIsValid = (url) => {
  if (!(url && typeof url === 'string')) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
