import {
  BOOKINGS_ERROR,
  BOOKINGS_SUMMARY_RECEIVED,
  BOOKINGS_SUMMARY_ERROR,
  BOOKING_CLEAR_SEARCH_RESULTS,
  PARTIAL_UPDATE_EXISTING_BOOKING,
  UPDATE_BOOKING_SUBMITTING,
} from '../constants';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

const partialUpdateExistingBooking = (value) => (dispatch, getState) => {
  const selectedLocationIds = getState().queue.selectedLocationIds;
  dispatch({
    type: PARTIAL_UPDATE_EXISTING_BOOKING,
    payload: { value, selectedLocationIds },
  });
};

const bookingsError =
  (queueTab, { clearQueue = false } = {}) =>
  (errorString) => {
    analyticsTrackEvent(APP_API_FAILURE);
    return {
      type: BOOKINGS_ERROR,
      payload: { value: errorString, queueTab, clearQueue: !!clearQueue },
    };
  };

const receiveSummary = (payload) => ({
  type: BOOKINGS_SUMMARY_RECEIVED,
  payload,
});

const summaryError = (payload) => ({
  type: BOOKINGS_SUMMARY_ERROR,
  payload,
});

const clearBookingsSearchResults = () => ({
  type: BOOKING_CLEAR_SEARCH_RESULTS,
});

const setSubmittingBookingUpdate = (payload) => ({
  type: UPDATE_BOOKING_SUBMITTING,
  payload,
});

export {
  partialUpdateExistingBooking,
  bookingsError,
  receiveSummary,
  summaryError,
  clearBookingsSearchResults,
  setSubmittingBookingUpdate,
};
