import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

const StyledSectionHeader = styled.div`
  color: ${colors.notQuiteBlack};
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
  -webkit-font-smoothing: antialiased;
`;

class SectionHeader extends PureComponent {
  render = () => (
    <StyledSectionHeader onClick={this.props.onClick}>{this.props.children}</StyledSectionHeader>
  );
}

SectionHeader.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default SectionHeader;
