import { EMPTY_OBJECT } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export class SftpExportConfigActionTypes {
  static CREATED = 'sftpExportConfig/CREATED';
  static CREATING = 'sftpExportConfig/CREATING';
  static ERROR = 'sftpExportConfig/ERROR';
  static LOADING = 'sftpExportConfig/LOADING';
  static RECEIVED = 'sftpExportConfig/RECEIVED';
  static UPDATED = 'sftpExportConfig/UPDATED';
  static UPDATING = 'sftpExportConfig/UPDATING';
}

export const sftpExportConfigCreated = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.CREATED,
});

export const sftpExportConfigCreating = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.CREATING,
});

export const sftpExportConfigError = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.ERROR,
});

export const sftpExportConfigLoading = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.LOADING,
});

export const sftpExportConfigReceived = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.RECEIVED,
});

export const sftpExportConfigUpdated = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.UPDATED,
});

export const sftpExportConfigUpdating = (value) => ({
  payload: { value },
  type: SftpExportConfigActionTypes.UPDATING,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case SftpExportConfigActionTypes.CREATED: {
      const newResults = (state.results ?? EMPTY_ARRAY).slice();
      newResults.push(action.payload.value[0]);

      return {
        ...state,
        results: newResults,
      };
    }
    case SftpExportConfigActionTypes.CREATING:
      return {
        ...state,
        isCreating: action.payload.value,
      };
    case SftpExportConfigActionTypes.ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case SftpExportConfigActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case SftpExportConfigActionTypes.RECEIVED:
      return {
        ...state,
        error: null,
        results: action.payload.value,
      };
    case SftpExportConfigActionTypes.UPDATED: {
      const newResults = (state.results ?? EMPTY_ARRAY)
        .slice()
        .filter((sec) => sec.id !== action.payload.value.id);
      newResults.push(action.payload.value);

      return {
        ...state,
        error: null,
        results: newResults,
      };
    }
    case SftpExportConfigActionTypes.UPDATING: {
      return {
        ...state,
        isUpdating: action.payload.value,
      };
    }
    default:
      return state;
  }
};
