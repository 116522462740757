import {
  CLINIC_ACCOUNTS_FETCHING,
  CLINIC_ACCOUNTS_RECEIVED,
  CLINIC_ACCOUNTS_ERROR,
  CLINIC_ACCOUNTS_PATCHED,
  CLINIC_ACCOUNTS_PATCH_SUBMITTED,
  CLINIC_ACCOUNTS_PATCH_ERROR,
  CLINIC_ACCOUNTS_RESET_PATCH_STATE,
  CLINIC_ACCOUNTS_ADD_LOCATIONS,
  CLINIC_ACCOUNTS_DELETE_LOCATIONS,
  CLINIC_ACCOUNTS_ADD_GROUPS,
  CLINIC_ACCOUNTS_DELETE_GROUPS,
  CLINIC_ACCOUNTS_PASSWORD_CHANGED,
  LOGIN_LOGOUT,
  CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS,
  CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR,
  CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS,
} from '../constants';
import { isEmptyObject } from '../core/util/object';

const updateAccountsList = (existingList, updatedClinicAccount) => {
  const newList = existingList.slice();

  const existingClinicAccount = newList.find((account) => account.id === updatedClinicAccount.id);

  if (!isEmptyObject(existingClinicAccount)) {
    Object.assign(existingClinicAccount, updatedClinicAccount);
  } else {
    newList.push(updatedClinicAccount);
  }

  return newList;
};

const addLocationsToAccount = (existingList, clinicAccountId, addedLocations) => {
  const newList = existingList.slice();

  const existingClinicAccount = newList.find((account) => account.id === clinicAccountId);

  const existingLocations = existingClinicAccount.locations || [];

  existingClinicAccount.locations = existingLocations.concat(
    addedLocations.map((locationId) => ({ location_id: locationId }))
  );

  return newList;
};

const deleteLocationsFromAccount = (existingList, clinicAccountId, deletedLocations) => {
  const newList = existingList.slice();

  const existingClinicAccount = newList.find((account) => account.id === clinicAccountId);

  existingClinicAccount.locations = existingClinicAccount.locations.filter(
    (location) => !deletedLocations.includes(location.location_id)
  );

  return newList;
};

const addGroupsToAccount = (existingList, clinicAccountId, addedGroups) => {
  const newList = existingList.slice();

  const existingClinicAccount = newList.find((account) => account.id === clinicAccountId);

  const existingGroups = existingClinicAccount.groups || [];

  existingClinicAccount.groups = existingGroups.concat(addedGroups);

  return newList;
};

const deleteGroupsFromAccount = (existingList, clinicAccountId, deletedGroups) => {
  const newList = existingList.slice();

  const existingClinicAccount = newList.find((account) => account.id === clinicAccountId);

  const existingGroups = existingClinicAccount.groups || [];

  const deletedGroupIds = (groups) => groups.map((group) => group.group_id);

  existingClinicAccount.groups = existingGroups.filter(
    (group) => !deletedGroupIds(deletedGroups).includes(group.group_id)
  );

  return newList;
};

export default function clinicAccountsReducer(state = {}, action) {
  switch (action.type) {
    case CLINIC_ACCOUNTS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLINIC_ACCOUNTS_RECEIVED:
      return {
        ...state,
        fetching: false,
        accounts: action.payload.value.results,
        hasFetched: true,
      };
    case CLINIC_ACCOUNTS_ERROR:
      return {
        error: action.payload.value,
      };
    case CLINIC_ACCOUNTS_PATCH_SUBMITTED:
      return {
        ...state,
        patchSubmitting: true,
        patchError: null,
      };
    case CLINIC_ACCOUNTS_PATCH_ERROR:
      return {
        ...state,
        patchSubmitting: false,
        patchError: action.payload.value,
      };
    case CLINIC_ACCOUNTS_PATCHED:
      return {
        ...state,
        accounts: updateAccountsList(state.accounts, action.payload.value),
        patchSubmitting: false,
      };
    case CLINIC_ACCOUNTS_ADD_LOCATIONS:
      return {
        ...state,
        accounts: addLocationsToAccount(
          state.accounts,
          action.payload.value.id,
          action.payload.value.locations
        ),
        patchSubmitting: false,
      };
    case CLINIC_ACCOUNTS_DELETE_LOCATIONS:
      return {
        ...state,
        accounts: deleteLocationsFromAccount(
          state.accounts,
          action.payload.value.id,
          action.payload.value.locations
        ),
        patchSubmitting: false,
      };
    case CLINIC_ACCOUNTS_ADD_GROUPS:
      return {
        ...state,
        accounts: addGroupsToAccount(
          state.accounts,
          action.payload.value.id,
          action.payload.value.groups
        ),
        patchSubmitting: false,
      };
    case CLINIC_ACCOUNTS_DELETE_GROUPS:
      return {
        ...state,
        accounts: deleteGroupsFromAccount(
          state.accounts,
          action.payload.value.id,
          action.payload.value.groups
        ),
        patchSubmitting: false,
      };
    case CLINIC_ACCOUNTS_PASSWORD_CHANGED:
    case CLINIC_ACCOUNTS_RESET_PATCH_STATE:
      return {
        ...state,
        patchSubmitting: false,
        patchError: null,
      };
    case CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        addAllLocationsSuccess: true,
      };
    case CLINIC_ACCOUNTS_ADD_ALL_LOCATIONS_ERROR: {
      return {
        ...state,
        addAllLocationsSuccess: false,
        addAllLocationsError: true,
      };
    }

    case CLINIC_ACCOUNTS_RESET_ADD_ALL_LOCATIONS:
      return {
        ...state,
        addAllLocationsSuccess: null,
        addAllLocationsError: null,
      };
    case LOGIN_LOGOUT: {
      return {};
    }

    default:
      return state;
  }
}
