import { call, takeEvery, put, all } from 'redux-saga/effects';
import moment from 'moment';
import { getClinicAccountId } from '../core/auth';
import { apiGetJson, apiPostJson, apiPatchJson, apiGet, apiPatch, apiPost } from '../core/dapi';
import {
  sendMessageUrl,
  getConversationByIdUrl,
  updateLastViewedTimestampUrl,
  getMessagesByConversationIdUrl,
  getUpdatePatientSmsConsentStatusUrl,
  getConversationsByLocationIdUrl,
} from '../core/dapi/conversations';
import { getViewerParticipantId } from '../core/util/conversations';
import {
  receiveConversationsError,
  sendingMessage,
  sendMessageError,
  sendMessageSuccess,
  receiveConversation,
  receiveMessages,
  receiveMessagesError,
  setCurrentConversation,
  updatePatientConsentStatusError,
  updatePatienSmsConsentStatusSuccess,
  receiveConversations,
  isFetchingConversations,
  isFetchingMessages,
} from '../ducks/conversations';
import { apiGetAllPages } from './core/base';

export const SEND_FIRST_MESSAGE = 'SEND_FIRST_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const UPDATE_PATIENT_SMS_CONSENT_STATUS = 'UPDATE_PATIENT_SMS_CONSENT_STATUS';
export const FETCH_CONVERSATIONS_BY_LOCATION_ID = 'FETCH_CONVERSATIONS_BY_LOCATION_ID';
export const FETCH_MESSAGES_BY_CONVERSATION_ID = 'FETCH_MESSAGES_BY_CONVERSATION_ID';

function* updateLastViewedTimestamp({ conversationId, participantId, locationId }) {
  const patchLastViewedTimestampUrl = updateLastViewedTimestampUrl(conversationId, participantId);

  const lastViewedTimestamp = { last_viewed_timestamp: moment().toISOString() };
  const response = yield call(apiPatch, patchLastViewedTimestampUrl, lastViewedTimestamp);

  yield put(receiveConversation(response, { locationId }));
}

export function* sendFirstMessage({ message, locationId }) {
  yield put(sendingMessage());

  const newMessageResponse = yield call(apiPostJson, sendMessageUrl(), message);

  if (newMessageResponse.error) {
    yield put(sendMessageError(newMessageResponse.error));
    return;
  }

  if (newMessageResponse.data) {
    const conversationId = newMessageResponse.data.conversation_id;
    const getConversationUrl = getConversationByIdUrl(conversationId, locationId);

    const newConversation = yield call(apiGetJson, getConversationUrl);

    if (newConversation.error) {
      yield put(receiveConversationsError(newConversation.error));
      return;
    }

    if (newConversation.data) {
      yield put(setCurrentConversation(newConversation.data));

      const participantId = getViewerParticipantId(
        getClinicAccountId(),
        newConversation.data,
        locationId
      );

      yield call(updateLastViewedTimestamp, { conversationId, participantId, locationId });

      const conversationMessagesResponse = yield call(
        apiGetJson,
        getMessagesByConversationIdUrl(conversationId, locationId)
      );

      if (conversationMessagesResponse.data) {
        yield put(receiveMessages(conversationId)(conversationMessagesResponse.data));
      }

      if (conversationMessagesResponse.error) {
        yield put(receiveMessagesError(conversationMessagesResponse.error));
      }

      yield put(sendMessageSuccess(newMessageResponse.data, { locationId }));
    }
  }
}

function* sendMessage({ message, participantId, locationId }) {
  yield put(sendingMessage());
  try {
    const response = yield call(apiPost, sendMessageUrl(), message);
    const conversationId = response.conversation_id;
    yield all([
      call(updateLastViewedTimestamp, { conversationId, participantId, locationId }),
      put(sendMessageSuccess(response, { locationId })),
    ]);
  } catch (e) {
    yield put(sendMessageError(e));
  }
}

function* updatePatientSmsConsentStatus({ participantId, isConsentedPatient, locationId }) {
  try {
    const url = getUpdatePatientSmsConsentStatusUrl(participantId);
    const response = yield call(apiPatchJson, url, { is_consented_patient: isConsentedPatient });
    yield put(updatePatienSmsConsentStatusSuccess(response.data, { locationId }));
  } catch (e) {
    yield put(updatePatientConsentStatusError(e));
  }
}

function* fetchConversationsByLocationId({ locationId, page }) {
  yield put(isFetchingConversations());
  try {
    const url = getConversationsByLocationIdUrl(locationId, page);
    const response = yield call(apiGet, url);
    yield put(receiveConversations(response, { locationId }));
  } catch (e) {
    yield put(receiveConversationsError(e));
  }
}

function* fetchMessagesByConversationId({ conversationId, locationId, participantId, markRead }) {
  yield put(isFetchingMessages());
  try {
    const url = getMessagesByConversationIdUrl(conversationId, locationId);
    const response = yield call(apiGetAllPages, { url });

    const effects = [put(receiveMessages(conversationId)(response))];
    if (markRead) {
      effects.push(call(updateLastViewedTimestamp, { conversationId, participantId, locationId }));
    }

    yield all(effects);
  } catch (e) {
    yield put(receiveMessagesError(e));
  }
}

export default function* rootSaga() {
  yield takeEvery(SEND_FIRST_MESSAGE, sendFirstMessage);
  yield takeEvery(SEND_MESSAGE, sendMessage);
  yield takeEvery(UPDATE_PATIENT_SMS_CONSENT_STATUS, updatePatientSmsConsentStatus);
  yield takeEvery(FETCH_CONVERSATIONS_BY_LOCATION_ID, fetchConversationsByLocationId);
  yield takeEvery(FETCH_MESSAGES_BY_CONVERSATION_ID, fetchMessagesByConversationId);
}
