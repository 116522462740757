import { call, takeEvery } from 'redux-saga/effects';
import { apiPost } from '../../core/dapi';
import { createWebPushSubscriptionUrl } from '../../core/dapi/webPushSubscriptions';
import { emptyFunction } from '../../core/util/function';

export class WebPushSubscriptionsSagas {
  static CREATE = 'SAGA/CREATE_WEB_PUSH_SUBSCRIPTION';
}

export function* createWebPushSubscription({
  clinicAccountId,
  subscription,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  const webPushSubscriptionPostData = {
    auth_key: subscription.toJSON().keys.auth,
    clinic_account_id: clinicAccountId,
    endpoint: subscription.endpoint,
    p256dh_key: subscription.toJSON().keys.p256dh,
    raw_subscription: JSON.stringify(subscription),
  };

  const response = yield call(apiPost, createWebPushSubscriptionUrl(), webPushSubscriptionPostData);

  if (response.errors) {
    onError();
  } else {
    onSuccess();
  }
}

export default function* rootSaga() {
  yield takeEvery(WebPushSubscriptionsSagas.CREATE, createWebPushSubscription);
}
