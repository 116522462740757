import { takeEvery } from 'redux-saga/effects';
import {
  patchLocationSettingsGeneral,
  patchLocationSettings,
  fetchClinicPaymentAccountInformation,
  fetchServicesList,
  fetchPaymentAccounts,
  PATCH_LOCATION_SETTINGS_GENERAL,
  PATCH_LOCATION_SETTINGS_INTERNAL_ADMIN,
  FETCH_CLINIC_PAYMENT_ACCOUNT_INFORMATION,
  FETCH_PAYMENT_ACCOUNTS_NEW_SETTINGS,
  FETCH_SERVICES_LIST,
  FETCH_OPERATING_HOURS,
  fetchOperatingHours,
  SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES,
  searchLocationsForSolvEmployees,
  FETCH_SPECIAL_HOURS,
  fetchSpecialHours,
  CREATE_SPECIAL_HOURS,
  createSpecialHours,
  UPDATE_SPECIAL_HOURS,
  updateSpecialHours,
  DELETE_SPECIAL_HOURS,
  deleteSpecialHours,
  SETTINGS_CLEAR_LOCATIONS,
  clearLocations,
  FETCH_RESERVATION_HOURS,
  fetchReservationHours,
  FETCH_AVAILABILITY_RECOMMENDATIONS,
  fetchAvailabilityRecommendations,
  PATCH_RESERVATION_HOURS,
  updateReservationHours,
  UPDATE_LOCATION_SERVICE_PRICES,
  updateServicePrices,
  FETCH_GROUPS,
  fetchGroups,
  ADD_GROUPS_LOCATIONS,
  postLocationsGroups,
  DELETE_GROUPS_LOCATIONS,
  removeLocationsGroups,
  BULK_ADD_CLINIC_ACCOUNTS_LOCATIONS,
  bulkAddLocationToClinicAccounts,
  PATCH_LOCATION_UCL_BRANDS,
  patchLocationUclBrands,
} from '../components/Settings/NewSettings/NewSettingsSagas';

export default function* rootSaga() {
  yield takeEvery(SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES, searchLocationsForSolvEmployees);
  yield takeEvery(SETTINGS_CLEAR_LOCATIONS, clearLocations);

  yield takeEvery(PATCH_LOCATION_UCL_BRANDS, patchLocationUclBrands);
  yield takeEvery(PATCH_LOCATION_SETTINGS_GENERAL, patchLocationSettingsGeneral);
  yield takeEvery(PATCH_LOCATION_SETTINGS_INTERNAL_ADMIN, patchLocationSettings);

  yield takeEvery(FETCH_CLINIC_PAYMENT_ACCOUNT_INFORMATION, fetchClinicPaymentAccountInformation);

  yield takeEvery(FETCH_SERVICES_LIST, fetchServicesList);

  yield takeEvery(FETCH_PAYMENT_ACCOUNTS_NEW_SETTINGS, fetchPaymentAccounts);

  yield takeEvery(FETCH_OPERATING_HOURS, fetchOperatingHours);

  yield takeEvery(FETCH_SPECIAL_HOURS, fetchSpecialHours);
  yield takeEvery(CREATE_SPECIAL_HOURS, createSpecialHours);
  yield takeEvery(UPDATE_SPECIAL_HOURS, updateSpecialHours);
  yield takeEvery(DELETE_SPECIAL_HOURS, deleteSpecialHours);

  yield takeEvery(FETCH_RESERVATION_HOURS, fetchReservationHours);
  yield takeEvery(PATCH_RESERVATION_HOURS, updateReservationHours);

  yield takeEvery(UPDATE_LOCATION_SERVICE_PRICES, updateServicePrices);

  yield takeEvery(FETCH_AVAILABILITY_RECOMMENDATIONS, fetchAvailabilityRecommendations);

  yield takeEvery(FETCH_GROUPS, fetchGroups);

  yield takeEvery(ADD_GROUPS_LOCATIONS, postLocationsGroups);
  yield takeEvery(DELETE_GROUPS_LOCATIONS, removeLocationsGroups);

  yield takeEvery(BULK_ADD_CLINIC_ACCOUNTS_LOCATIONS, bulkAddLocationToClinicAccounts);
}
