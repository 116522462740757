import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setRuntimeVariable } from '../actions/runtime';
import { getV3BookingsReportsUrl, getClinicDownloadReportsUrl } from '../core/dapi/reports';
import { apiGet, apiPost } from '../core/dapi';
import {
  CREATE_CLINIC_DOWNLOAD_REPORT_REQUESTED,
  FETCH_CLINIC_DOWNLOAD_REPORTS_DATA,
  fetchClinicDownloadReportsData,
  BACKGROUND_REFRESH_CLINIC_DOWNLOAD_REPORTS,
  setClinicDownloadReports,
} from '../ducks/clinicDownloadReports';
import {
  REPORT_TYPE_CLINIC,
  REPORT_TYPE_FUTURE_PATIENT,
  REPORT_TYPE_PATIENT,
  REPORT_TYPE_PATIENT_REVIEWS_REPORT,
} from '../constants/downloadReports';
import {
  REPORTS_DOWNLOAD_AGGREGATE_PATIENT_REPORT,
  REPORTS_DOWNLOAD_CLINIC_REPORT,
  REPORTS_DOWNLOAD_FUTURE_PATIENT_REPORT,
  REPORTS_DOWNLOAD_PATIENT_REPORT,
  REPORTS_DOWNLOAD_PATIENT_REVIEWS_REPORT,
} from '../core/analytics/events';
import moment from 'moment-timezone';
import { analyticsTrackEvent } from '../core/analytics';
import { getTrackingProperties } from '../core/analytics/properties';
import { isManager } from '../core/session';
import { getCalendarDateRangeParam } from '../core/dapi/util';
import { getClinicAccountId } from '../core/auth';
import { log } from '../core/logger/log';
import { REPORT_TYPE_AGGREGATE_ARCHIVE_PATIENT } from '../constants/downloadReports';

const IS_GETTING_CLINIC_DOWNLOAD_REPORTS_DATA = 'isGettingClinicDownloadReportsData';
const GETTING_CLINIC_DOWNLOAD_REPORTS_ERROR = 'gettingClinicDownloadReportsError';

const CREATING_CLINIC_DOWNLOAD_REPORT_STATUS = 'creatingClinicDownloadReportStatus';
const CREATING_CLINIC_DOWNLOAD_REPORT_ERROR = 'creatingClinicDownloadReportError';

function* fetchClinicDownloadReports({}) {
  try {
    yield put(setRuntimeVariable({ name: IS_GETTING_CLINIC_DOWNLOAD_REPORTS_DATA, value: true }));
    yield put(setRuntimeVariable({ name: GETTING_CLINIC_DOWNLOAD_REPORTS_ERROR, value: null }));

    const response = yield call(apiGet, getClinicDownloadReportsUrl(50));

    yield put(setClinicDownloadReports(response));
    yield put(setRuntimeVariable({ name: IS_GETTING_CLINIC_DOWNLOAD_REPORTS_DATA, value: false }));
  } catch (e) {
    yield put(setRuntimeVariable({ name: IS_GETTING_CLINIC_DOWNLOAD_REPORTS_DATA, value: false }));
    yield put(setRuntimeVariable({ name: GETTING_CLINIC_DOWNLOAD_REPORTS_ERROR, value: e }));
  }
}

function* backgroundRefreshClinicDownloadReports({}) {
  try {
    const response = yield call(apiGet, getClinicDownloadReportsUrl(50));
    yield put(setClinicDownloadReports(response));
  } catch (e) {
    log.error(`Error while refreshing clinic download reports ${(e || '').toString()}`);
  }
}

function* createDownloadClinicReport(createDownloadClinicReportRequestionAction) {
  try {
    const login = yield select((state) => state.login);

    const { reportType, downloadFormat, type, locationIds } =
      createDownloadClinicReportRequestionAction.payload;
    let { to, from } = createDownloadClinicReportRequestionAction.payload;

    let trackingEvent;

    switch (reportType) {
      case REPORT_TYPE_PATIENT:
        trackingEvent = REPORTS_DOWNLOAD_PATIENT_REPORT;
        break;
      case REPORT_TYPE_CLINIC:
        trackingEvent = REPORTS_DOWNLOAD_CLINIC_REPORT;
        break;
      case REPORT_TYPE_AGGREGATE_ARCHIVE_PATIENT:
        trackingEvent = REPORTS_DOWNLOAD_AGGREGATE_PATIENT_REPORT;
        break;
      case REPORT_TYPE_PATIENT_REVIEWS_REPORT:
        trackingEvent = REPORTS_DOWNLOAD_PATIENT_REVIEWS_REPORT;
        break;
      case REPORT_TYPE_FUTURE_PATIENT:
        trackingEvent = REPORTS_DOWNLOAD_FUTURE_PATIENT_REPORT;
        from = moment();
        to = moment().add('1', 'year');
        break;
      default:
        break;
    }

    analyticsTrackEvent(trackingEvent, {
      ...getTrackingProperties({ login }),
      location_id: locationIds,
    });
    const postData = {
      location_ids: locationIds,
      from_: getCalendarDateRangeParam(from, 'from'),
      to_: getCalendarDateRangeParam(to, 'to'),
      type_: type,
      author: getClinicAccountId(),
      file_extension_: downloadFormat,
    };

    if (isManager(login)) {
      postData.role = 'manager';
    } else {
      postData.role = 'front_office';
    }

    yield put(
      setRuntimeVariable({ name: CREATING_CLINIC_DOWNLOAD_REPORT_STATUS, value: 'creating' })
    );
    yield put(setRuntimeVariable({ name: CREATING_CLINIC_DOWNLOAD_REPORT_ERROR, value: null }));

    yield apiPost(getV3BookingsReportsUrl(), postData);

    yield put(
      setRuntimeVariable({ name: CREATING_CLINIC_DOWNLOAD_REPORT_STATUS, value: 'created' })
    );
    yield put(fetchClinicDownloadReportsData());
  } catch (e) {
    yield put(setRuntimeVariable({ name: CREATING_CLINIC_DOWNLOAD_REPORT_STATUS, value: '' }));
    yield put(setRuntimeVariable({ name: CREATING_CLINIC_DOWNLOAD_REPORT_ERROR, value: e }));
  }
}

export default function* rootSaga() {
  yield takeEvery(CREATE_CLINIC_DOWNLOAD_REPORT_REQUESTED, createDownloadClinicReport);
  yield takeEvery(
    BACKGROUND_REFRESH_CLINIC_DOWNLOAD_REPORTS,
    backgroundRefreshClinicDownloadReports
  );
  yield takeEvery(FETCH_CLINIC_DOWNLOAD_REPORTS_DATA, fetchClinicDownloadReports);
}
