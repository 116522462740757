import { DAPI_HOST } from '../../config';

const createSftpExportConfigUrl = () => `${DAPI_HOST}/v1/sftp-export-config`;

const getSftpExportConfigUrl = (locationId: string) => {
  return `${DAPI_HOST}/v1/sftp-export-config/by-locations/${locationId}`;
};

const updateSftpExportConfigUrl = (sftpExportConfigId: string) =>
  `${DAPI_HOST}/v1/sftp-export-config/${sftpExportConfigId}`;

export { createSftpExportConfigUrl, getSftpExportConfigUrl, updateSftpExportConfigUrl };
