import { DAPI_HOST } from '../../config';

const getCreatePaperworkResponsesUrl = () => `${DAPI_HOST}/v1/paperwork-responses`;

const getPaperworkResponsesUrl = (bookingId) =>
  `${DAPI_HOST}/v2/paperwork-responses?booking_id=${bookingId}`;

const getPaperworkResponsesPdfUrl = (bookingId) =>
  `${DAPI_HOST}/v1/paperwork-responses.pdf` + `?booking_id=${bookingId}`;

export { getCreatePaperworkResponsesUrl, getPaperworkResponsesUrl, getPaperworkResponsesPdfUrl };
