import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, Col } from 'antd';
import styled from 'styled-components';
import { Row } from '../primitives';
import { toggleSolvPatientBoostModal } from '../../ducks/solvPatientBoost';
import { connect } from 'react-redux';
import { timeAgo } from '../../core/util/time';
import DateTime, { MONTH_NAME_DAY_ABBR, HOUR_MINUTE_PERIOD } from '../DateTime';
import { safeGet } from '../../core/util/object';
import { isEmpty } from '../../core/util/empty';
import moment from 'moment';
import {
  BOOKINGS_ATTRIBUTION_TYPE_SOLV,
  BOOKINGS_ATTRIBUTION_TYPE_PROFILE_OPTIMIZATION,
} from '../../constants';
import { GET_SOLV_PATIENT_BOOST_BOOKINGS } from '../../sagas/SolvPatientBoost';
import { getPatientName, getAttributionSource } from '../../core/util/booking';
import { TIME_NOW } from '../../core/dapi';
import { getCookie, setCookie } from '../../core/util/cookie';
import { LAST_BOOST_PATIENT_SEEN_COOKIE_NAME } from '../../config';
import { colors } from '../../constants/colors';
import { sortArrayOfObjectsByKey } from '../../core/util/array';

export const getLastBoostPatientSeen = () => getCookie(LAST_BOOST_PATIENT_SEEN_COOKIE_NAME);
export const setLastBoostPatientSeen = () =>
  setCookie(LAST_BOOST_PATIENT_SEEN_COOKIE_NAME, moment.utc(), 90);

const BOOKINGS_QUERY_RESULT_LIMIT = 100;
const BOOKINGS_QUERY_PAGE_RESULT_LIMIT = 1;
const SORT_SOLV_BOOKINGS_BY_APPOINTMENT_DATE = 'appointment_date';

const StyledRowContainer = styled(Row)`
  display: flex !important;
`;

const StyledInfoColumn = styled(Col)`
  margin-left: 10px;
  font-size: 16px;
`;

const StyledInfoRows = styled(Row)`
  padding: 0 !important;

  .boost-modal-name,
  .boost-modal-source-type {
    color: ${colors.black};
  }

  .boost-modal-date,
  .boost-modal-source {
    color: ${colors.lanaDelGrey};
  }

  .boost-modal-time {
    font-style: italic;
  }
`;

const StyledSolvLogo = styled.img`
  margin-left: 9px;
`;

const StyledSolvLettermark = styled.img`
  margin-top: 4px;
`;

const StyledTimeContainer = styled.span`
  display: flex;
`;

const StyledDateSpan = styled.span`
  margin: 0 5px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    overflow-y: auto;
    max-height: 470px;
  }
`;

class SolvPatientBoostModal extends PureComponent {
  componentDidMount = () => {
    this.props.getIsSolvPatientBoostBooking({
      locationId: this.props.locationId,
      limit: BOOKINGS_QUERY_RESULT_LIMIT,
      page: BOOKINGS_QUERY_PAGE_RESULT_LIMIT,
      filters: {
        attribution: [
          BOOKINGS_ATTRIBUTION_TYPE_SOLV,
          BOOKINGS_ATTRIBUTION_TYPE_PROFILE_OPTIMIZATION,
        ],
      },
      dateRange: {
        from: this.getDateFrom(),
        to: TIME_NOW,
      },
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.solvPatientBoost.patientBoostBookingsRecieved.length <
      this.props.solvPatientBoost.patientBoostBookingsRecieved.length
    ) {
      setLastBoostPatientSeen();
    }
  };

  getTitle = () => (
    <div>
      <img src="/images/logo/patient_boost_logo.svg" alt="Patient Boost Logo" />
      <StyledSolvLogo src="/images/logo/solv_new_patient_logo.svg" alt="Solv New Patient Logo" />
    </div>
  );

  getPatientData = (booking, iterator) => (
    <StyledRowContainer key={iterator}>
      <Col>
        <StyledSolvLettermark
          src="/images/icons/solv_lettermark_icon.svg"
          alt="Solv Lettermark Icon"
        />
      </Col>
      <StyledInfoColumn>
        <StyledInfoRows>
          <span className="boost-modal-name">{getPatientName(booking)}</span>
        </StyledInfoRows>
        <StyledInfoRows>
          <StyledTimeContainer className="boost-modal-date">
            <DateTime
              dateTime={booking.appointment_date}
              timeZone={booking.time_zone}
              format={MONTH_NAME_DAY_ABBR}
            />
            <StyledDateSpan>at</StyledDateSpan>
            <DateTime
              dateTime={booking.appointment_date}
              timeZone={booking.time_zone}
              format={HOUR_MINUTE_PERIOD}
            />
          </StyledTimeContainer>
        </StyledInfoRows>
        <StyledInfoRows>
          <span className="boost-modal-source">Booked Via</span>{' '}
          <span className="boost-modal-source-type">
            {getAttributionSource(booking.attribution)}
          </span>
        </StyledInfoRows>
        <StyledInfoRows>
          <span className="boost-modal-time">{timeAgo(booking.appointment_date)}</span>
        </StyledInfoRows>
      </StyledInfoColumn>
    </StyledRowContainer>
  );

  getEmptyState = () => "Hang tight. We're working hard to send you new patients.";

  getDateFrom = () => moment().subtract(1, 'months').format('YYYY-MM-DD');

  render = () => {
    if (this.props.isVisible) {
      return (
        <StyledModal
          visible
          title={this.getTitle()}
          footer={null}
          onCancel={this.props.handleExitSolvPatientBoostModal}
        >
          {!isEmpty(safeGet(this.props.solvPatientBoost)('patientBoostBookingsRecieved'))
            ? sortArrayOfObjectsByKey(
                this.props.solvPatientBoost.patientBoostBookingsRecieved,
                SORT_SOLV_BOOKINGS_BY_APPOINTMENT_DATE
              ).map((booking, iterator) => this.getPatientData(booking, iterator))
            : this.getEmptyState()}
        </StyledModal>
      );
    }

    return null;
  };
}

SolvPatientBoostModal.propTypes = {
  isVisible: PropTypes.bool,
  handleExitSolvPatientBoostModal: PropTypes.func,
  getIsSolvPatientBoostBooking: PropTypes.func,
  locationId: PropTypes.string.isRequired,
  solvPatientBoost: PropTypes.object,
};

SolvPatientBoostModal.defaultProps = {
  isVisible: false,
};

const mapStateToProps = (state) => ({ solvPatientBoost: state.solvPatientBoost });

const mapDispatchToProps = (dispatch) => ({
  handleExitSolvPatientBoostModal: () => {
    dispatch(toggleSolvPatientBoostModal(false));
  },

  getIsSolvPatientBoostBooking: ({ locationId, limit, page, filters, dateRange }) =>
    dispatch({
      type: GET_SOLV_PATIENT_BOOST_BOOKINGS,
      locationId,
      limit,
      page,
      filters,
      dateRange,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolvPatientBoostModal);
