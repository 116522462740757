import {
  RECEIVE_PAPERWORK_RESPONSES,
  RESET_PAPERWORK_RESPONSES,
  PAPERWORK_RESPONSES_ERROR,
} from '../constants';

const receivePaperworkResponse = value => ({
  type: RECEIVE_PAPERWORK_RESPONSES,
  payload: { value },
});

const resetPaperworkResponse = () => ({
  type: RESET_PAPERWORK_RESPONSES,
});

const paperworkResponseError = value => ({
  type: PAPERWORK_RESPONSES_ERROR,
  payload: { value },
});

export { paperworkResponseError, receivePaperworkResponse, resetPaperworkResponse };
