export const CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_SUCCESS =
  'clinicAccounts/BULK_ACCOUNT_CREATION_SUCCESS';
export const CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_ERROR =
  'clinicAccounts/BULK_ACCOUNT_CREATION_ERROR';
export const CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_CLEAR =
  'clinicAccounts/BULK_ACCOUNT_CREATION_CLEAR';
export const CLINIC_ACCOUNTS_CREATING_BULK_ACCOUNTS = 'clinicAccounts/CREATING_BULK_ACCOUNTS';

export const bulkAccountCreationSuccess = value => ({
  type: CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_SUCCESS,
  payload: { value },
});

export const bulkAccountCreationError = value => ({
  type: CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_ERROR,
  payload: { value },
});

export const clearBulkAccountCreation = () => ({
  type: CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_CLEAR,
});

export const isCreatingBulkAccounts = value => ({
  type: CLINIC_ACCOUNTS_CREATING_BULK_ACCOUNTS,
  payload: { value },
});

const getInitialState = () => ({
  successes: [],
  errors: [],
  isCreatingBulkAccounts: false,
});

export default function bulkClinicAccountActivationReducer(state = getInitialState(), action) {
  switch (action.type) {
    case CLINIC_ACCOUNTS_CREATING_BULK_ACCOUNTS:
      return {
        ...state,
        isCreatingBulkAccounts: action.payload.value,
      };
    case CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_SUCCESS:
      return {
        ...state,
        successes: action.payload.value,
      };
    case CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_ERROR:
      return {
        ...state,
        errors: action.payload.value,
      };
    case CLINIC_ACCOUNTS_BULK_ACCOUNT_CREATION_CLEAR:
      return getInitialState();

    default:
      return state;
  }
}
