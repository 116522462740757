import { LANG_CHANGE, LANG_ENG } from '../constants';

export default function languageReducer(state = LANG_ENG, action) {
  switch (action.type) {
    case LANG_CHANGE:
      return action.payload.value;
    default:
      return state;
  }
}
