import { DAPI_CACTUS_HOST, DAPI_HOST } from '../../config';
import { setRuntimeVariable } from '../../actions/runtime';
import { insuranceCheckSetRequestData } from '../../actions/insurance';
import {
  eligibilityCheckReceived,
  eligibilityCheckError,
  eligibilityFaceSheetReceived,
  eligibilityFaceSheetError,
  eligibilityCheckSubmitted,
} from '../../actions/eligibilityCheck';
import { sanitizeDateString } from '../../components/Form/BirthDateInput';
import { isEmptyObject } from '../util/object';
import { getFaceSheetUrl } from '../../core/dapi/eligibilityCheck';
import { apiGetDispatchable, apiPostJsonDispatchableWithCallbacks } from './index';
import { patchBookingDispatchable } from './bookings';
import { noOp } from '../../actions';

export const checkInsuranceCoverageUrl = () => `${DAPI_CACTUS_HOST}/v1/insurance-coverage`;

export const setBookingInsuranceUrl = () => `${DAPI_HOST}/v1/registration/insurance`;

export const checkInsuranceCoverage = (dispatch, insuranceInfo, bookingInfo) => {
  dispatch(setRuntimeVariable({ name: 'submittingEligibilityCheck', value: true }));
  dispatch(insuranceCheckSetRequestData(insuranceInfo));
  dispatch(eligibilityCheckSubmitted());

  let birthDate = !isEmptyObject(bookingInfo)
    ? bookingInfo.birth_date
    : insuranceInfo.dateOfBirth || null;

  if (birthDate) {
    birthDate = sanitizeDateString(birthDate);
  }

  const insuranceCoverageData = {
    member_code: insuranceInfo.memberCode,
    insurer_code: insuranceInfo.insurerCode,
    insurer_name: insuranceInfo.insurerName,
    plan_type: insuranceInfo.insurerType,
    first_name: insuranceInfo.firstName,
    last_name: insuranceInfo.lastName,
    birth_date: birthDate,
    service_type: insuranceInfo.serviceType,
  };

  // Pass also booking id if present
  if (!isEmptyObject(bookingInfo)) {
    insuranceCoverageData.booking_id = bookingInfo.id;
  }

  const setSubmittingEligibilityCheckFalse = () =>
    dispatch(setRuntimeVariable({ name: 'submittingEligibilityCheck', value: false }));

  const handleInsuranceCoverageSuccess = (response) => {
    setSubmittingEligibilityCheckFalse();

    dispatch(
      apiGetDispatchable(
        getFaceSheetUrl(response.eligibility_requests_id),
        eligibilityFaceSheetReceived,
        eligibilityFaceSheetError
      )
    );

    if (!isEmptyObject(bookingInfo)) {
      const patchData = { insurance_coverage_result_id: response.id };
      patchBookingDispatchable(dispatch, bookingInfo.id, patchData, noOp, noOp);
    }
  };

  dispatch(
    apiPostJsonDispatchableWithCallbacks(
      checkInsuranceCoverageUrl(),
      insuranceCoverageData,
      eligibilityCheckReceived,
      eligibilityCheckError,
      handleInsuranceCoverageSuccess,
      setSubmittingEligibilityCheckFalse
    )
  );
};
