import { SET_RUNTIME_VARIABLE } from '../constants';

const setRuntimeVariable = ({ name, value }) => ({
  type: SET_RUNTIME_VARIABLE,
  payload: {
    name,
    value,
  },
});

export { setRuntimeVariable };
