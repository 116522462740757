import { TYPE_HMO, TYPE_PPO, TYPE_SELF, TYPE_SELF_LABEL, TYPE_SKIP } from '../../constants';
import { isEmptyString, titleCase } from './string';
import { isEmptyObject } from './object';

const cardHash = (insurerCode, memberCode) => `i-${insurerCode}-m-${memberCode}`;

const usingInsurance = insurance =>
  !(
    insurance &&
    insurance.insurerType &&
    (insurance.insurerType === TYPE_SELF || insurance.insurerType === TYPE_SKIP)
  );

const insuranceEntered = insurance =>
  usingInsurance(insurance) &&
  !isEmptyString(insurance.insurerCode) &&
  !isEmptyString(insurance.memberCode);

const insuranceStatusToHumanReadable = status => (status === 'true' ? 'active' : 'inactive');
const sufficientInsuranceDataForEligibilityCheck = insuranceData =>
  insuranceData && insuranceData.insurerCode && insuranceData.memberCode;

const hasSufficientDataToDisplayInsuranceProfile = profile =>
  !isEmptyObject(profile) &&
  profile.member_code !== null &&
  profile.insurer_type !== null &&
  profile.insurer_type !== TYPE_SKIP &&
  profile.first_name !== null &&
  profile.last_name !== null;

const sufficientInsuranceDataForNPICheck = (newBooking, location) =>
  newBooking &&
  location &&
  newBooking.insurance.memberCode &&
  newBooking.profile.firstName &&
  newBooking.profile.lastName &&
  newBooking.profile.birthDate &&
  location.npi;

const getStoredNpiData = (newBooking, location, npi) => {
  if (!sufficientInsuranceDataForNPICheck(newBooking, location)) {
    return null;
  }

  const cardHashValue = cardHash(newBooking.insurance.insurerCode, newBooking.insurance.memberCode);
  if (!npi || !npi.result || !npi.result[cardHashValue]) {
    return null;
  }

  return npi.result[cardHashValue];
};

const getStoredEligibilityCheckData = (newBooking, location, eligibilityCheck) => {
  if (!sufficientInsuranceDataForEligibilityCheck(newBooking, location)) {
    return null;
  }

  const cardHashValue = cardHash(newBooking.insurance.insurerCode, newBooking.insurance.memberCode);
  if (!eligibilityCheck || !eligibilityCheck.result || !eligibilityCheck.result[cardHashValue]) {
    return null;
  }

  return eligibilityCheck.result[cardHashValue];
};

const isInNetwork = (newBooking, location, npi) => {
  const networkData = getStoredNpiData(newBooking, location, npi);
  if (networkData === null) {
    return false;
  }

  if (networkData.in_network && networkData.in_network.constructor === Array) {
    for (let i = 0; i < networkData.in_network.length; i++) {
      if (parseInt(networkData.in_network[i], 10) === parseInt(location.npi, 10)) {
        return true;
      }
    }
  }

  return false;
};

const getEligibilityId = (newBooking, location, npi, eligibilityCheck) => {
  const storedNpiData = getStoredNpiData(newBooking, location, npi);
  const storedEligibilityCheckData = getStoredEligibilityCheckData(
    newBooking,
    location,
    eligibilityCheck
  );

  if (storedNpiData !== null) {
    return storedNpiData.eligibility_requests_id;
  }

  if (storedEligibilityCheckData !== null) {
    return storedEligibilityCheckData.eligibility_requests_id;
  }

  return null;
};

const formatInsurerType = insurerType => {
  switch (insurerType) {
    case TYPE_PPO:
    case TYPE_HMO:
      return insurerType.toUpperCase();
    case TYPE_SELF:
      return TYPE_SELF_LABEL;
    default:
      return titleCase(insurerType);
  }
};

const getInsurerByInsurerCode = (code, insurers) => {
  const insurer = insurers.find(i => i.insurer_code === code);
  if (insurer) {
    return insurer;
  }

  return null;
};

const isEligibilityStatusGood = eligibilityStatus => eligibilityStatus === 'Active';

const isEligibilityStatusBad = eligibilityStatus =>
  ['Inactive', 'Not Found'].includes(eligibilityStatus);

const isThereAnyPokitdokError = eligibilityCheckData =>
  !isEmptyObject(eligibilityCheckData) &&
  !isEmptyObject(eligibilityCheckData.faceSheet) &&
  !isEmptyString(eligibilityCheckData.faceSheet.reject_reason) &&
  !isEmptyString(eligibilityCheckData.faceSheet.follow_up_action);

const isSelfPay = insurerType => insurerType === TYPE_SELF;

export {
  cardHash,
  usingInsurance,
  sufficientInsuranceDataForNPICheck,
  sufficientInsuranceDataForEligibilityCheck,
  isInNetwork,
  insuranceEntered,
  getEligibilityId,
  getInsurerByInsurerCode,
  formatInsurerType,
  insuranceStatusToHumanReadable,
  hasSufficientDataToDisplayInsuranceProfile,
  isEligibilityStatusGood,
  isEligibilityStatusBad,
  isThereAnyPokitdokError,
  isSelfPay,
};
