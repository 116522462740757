import { DAPI_HOST } from '../../config';

const getClinicAccountsUrl = (opts = {}) =>
  `${DAPI_HOST}/v1/clinic-accounts${opts.showInactive ? '?filter_active=false' : '?'}`;

const getClinicAccountUrl = (id) => `${DAPI_HOST}/v1/clinic-accounts/${id}`;

const getClinicAccountBySecretUrl = (encryptedValue, nonce, lastName) =>
  `${DAPI_HOST}/v1/clinic-accounts/verify?` +
  `token=${encryptedValue}${encodeURIComponent('+')}${nonce}&secret=${lastName}`;

const getClinicAccountPasswordUrl = (id) => `${DAPI_HOST}/v1/clinic-accounts/${id}/reset-password`;

const getClinicAccountExpiredPasswordUrl = (id) =>
  `${DAPI_HOST}/v1/clinic-accounts/${id}/reset-expired-password`;

const getClinicAccountsGroupsUrl = () => `${DAPI_HOST}/v1/clinic-accounts-groups`;

const getClinicAccountSendEmailUrl = (clinicAccountId, emailType) =>
  `${DAPI_HOST}/v1/clinic-accounts/${clinicAccountId}/send-email/${emailType}`;

const getResetClinicAccountPasswordUrl = () => `${DAPI_HOST}/v1/clinic-accounts/reset-password`;

const getClinicAccountBulkAddLocationUrl = () =>
  `${DAPI_HOST}/v1/clinic-accounts/bulk-add-location`;

const getPublicClinicAccountsUrl = () => `${DAPI_HOST}/v1/public-clinic-accounts`;

const getClinicAccountLocationLabelColorUrl = (id, locationId) =>
  `${DAPI_HOST}/v1/clinic-accounts/${id}/location/${locationId}/update-location-label-color`;

const getAddClinicAccountToAllLocationsUrl = () =>
  `${DAPI_HOST}/v1/clinic-accounts/add-all-locations`;

export {
  getClinicAccountsGroupsUrl,
  getClinicAccountsUrl,
  getClinicAccountUrl,
  getClinicAccountBySecretUrl,
  getClinicAccountPasswordUrl,
  getClinicAccountExpiredPasswordUrl,
  getClinicAccountSendEmailUrl,
  getResetClinicAccountPasswordUrl,
  getClinicAccountBulkAddLocationUrl,
  getPublicClinicAccountsUrl,
  getClinicAccountLocationLabelColorUrl,
  getAddClinicAccountToAllLocationsUrl,
};
