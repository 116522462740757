import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from '../../../core/util/device';
import { Popover } from 'antd';

const StyledInfoIcon = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

class InfoTooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.infoIconEl = null;

    this.setInfoIconElRef = element => {
      this.infoIconEl = element;
    };

    this.state = {
      imgSrc: '/images/reports/info-icon.svg',
    };
  }

  onMouseOver = e => {
    this.setState({ imgSrc: '/images/reports/info-icon-onhover.svg' });
    if (this.props.onMouseOver) {
      this.props.onMouseOver(e);
    }
  };

  onMouseOut = e => {
    this.setState({ imgSrc: '/images/reports/info-icon.svg' });
    if (this.props.onMouseOut) {
      this.props.onMouseOut(e);
    }
  };

  render = () => {
    const triggerType = isMobile() ? 'click' : 'hover';

    let position = 'top';
    if (this.infoIconEl) {
      if (this.infoIconEl.getBoundingClientRect().top < 180) {
        position = 'bottom';
      }
    }

    return (
      <Popover
        content={this.props.content}
        overlayStyle={this.props.overlayStyle}
        placement={position}
        trigger={triggerType}
      >
        <StyledInfoIcon>
          <img
            src={this.state.imgSrc}
            ref={this.setInfoIconElRef}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            alt={this.props.altText}
          />
        </StyledInfoIcon>
      </Popover>
    );
  };
}

InfoTooltip.propTypes = {
  altText: PropTypes.string,
  content: PropTypes.node,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  overlayStyle: PropTypes.object,
};

export default InfoTooltip;
