import { call, put, takeEvery } from 'redux-saga/effects';
import { setRuntimeVariable } from '../actions/runtime';
import { resultsReceived, resultsError } from '../actions/admin';
import { apiGet } from '../core/dapi';
import { getLocationsSearchUrl, getTelemedLocationSearchUrl } from '../core/dapi/location';
import { ORIGIN_BOOKING_WIDGET } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';
import { getDistanceBetweenPoints, parseLatLongString } from '../core/util/geo';

export class LocationSearchSaga {
  static SEARCH_LOCATIONS = 'sagas/SEARCH_LOCATIONS';
}

function* searchLocations({ searchParameters, shouldIncludeTelemed }) {
  try {
    let mergedResults = EMPTY_ARRAY;

    if (shouldIncludeTelemed && 'groupIds' in searchParameters) {
      const telemedSearchUrl = getTelemedLocationSearchUrl(searchParameters.groupIds);

      const telemedResults = yield call(apiGet, telemedSearchUrl);
      if (searchParameters.latitude && searchParameters.longitude) {
        /**
         * Sort telemed results by distance from the searched zip code
         * The reason we're doing this front end is because the location search limits to a 100 mile radius and for telemed
         * locations they could be out of state and/or not within 100 miles, but we still want to order them based on the
         * zipcode so it doesn't appear broken when they show up in the same order regardless of the zip code you search by.
         *
         * TODO: Ultimately we want to add a parameter to search by state for telemed locations
         *  */
        telemedResults.results.sort((locationA, locationB) => {
          const locationALatLong = parseLatLongString(locationA.lat_long ?? '');
          const distanceA = getDistanceBetweenPoints(locationALatLong, {
            latitude: searchParameters.latitude,
            longitude: searchParameters.longitude,
          });
          const locationBLatLong = parseLatLongString(locationB.lat_long ?? '');
          const distanceB = getDistanceBetweenPoints(locationBLatLong, {
            latitude: searchParameters.latitude,
            longitude: searchParameters.longitude,
          });
          return distanceA - distanceB;
        });
      }

      mergedResults = mergedResults.concat(telemedResults.results);
    }

    const searchUrl = getLocationsSearchUrl(searchParameters, undefined, ORIGIN_BOOKING_WIDGET);

    const locationsResults = yield call(apiGet, searchUrl);

    mergedResults = mergedResults.concat(locationsResults.results);

    yield put(resultsReceived({ results: mergedResults }));
  } catch (e) {
    resultsError(e);
  }

  yield put(setRuntimeVariable({ name: 'submittingZipCodeSearch', value: false }));
}

export default function* rootSaga() {
  yield takeEvery(LocationSearchSaga.SEARCH_LOCATIONS, searchLocations);
}
