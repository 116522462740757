import { DAPI_HOST } from '../../config';
import { apiPostJson } from './index';
import { IMAGE_SOURCES, IMAGE_UPLOAD_STATUSES } from '../../constants';
import { log } from '../logger/log';

const prefixS3PathForEntityType = (entityType, path) =>
  entityType !== 'location' ? `${entityType}s/${path}` : path;

export const getImageUploadFilename = () => `${DAPI_HOST}/v3/images`;

const awsImagesPostUploadStatus = (cardId) => `${DAPI_HOST}/v1/images/${cardId}/upload-status`;

const updateImageUploadStatus = (imageUploadStatus, cardId) => {
  apiPostJson(awsImagesPostUploadStatus(cardId), imageUploadStatus);
};

const awsImagePostUploadCompressedStatus = (cardId) =>
  `${DAPI_HOST}/v1/images/${cardId}/upload-status-compressed`;

const updateImageUploadCompressedStatus = (imageUploadStatus, cardId) => {
  apiPostJson(awsImagePostUploadCompressedStatus(cardId), imageUploadStatus);
};

const getS3SigningUrl = (name, type, source) => {
  const n = encodeURIComponent(name);
  const t = encodeURIComponent(type);
  const s = encodeURIComponent(source);
  return `/api/s3-files?file-name=${n}&file-type=${t}&source=${s}`;
};

export const getS3ImagesUrl = (entityId, entityType) =>
  `/api/s3-files/images/${entityType}/${entityId}`;

export const deleteS3ImageUrl = () => '/api/s3-files/images/delete';

const uploadToS3 = (image, source, isCompressed = false) => {
  const onSigningSuccess = (response) => {
    const uploadUrl = response.signedRequest;
    return fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': image.type,
        'x-amz-server-side-encryption': 'AES256',
        'Content-Disposition': image.name,
      },
      body: image.image,
    })
      .then((uploadResponse) => {
        if (source === IMAGE_SOURCES.insurance) {
          if (!uploadResponse.ok) {
            const imageUploadStatus = isCompressed
              ? IMAGE_UPLOAD_STATUSES.uploadFailedCompressed
              : IMAGE_UPLOAD_STATUSES.uploadFailed;
            const imageUploadFunction = isCompressed
              ? updateImageUploadCompressedStatus
              : updateImageUploadStatus;
            imageUploadFunction(imageUploadStatus, image.id);
          } else {
            const imageUploadStatus = isCompressed
              ? IMAGE_UPLOAD_STATUSES.uploadedCompressed
              : IMAGE_UPLOAD_STATUSES.uploaded;
            const imageUploadFunction = isCompressed
              ? updateImageUploadCompressedStatus
              : updateImageUploadStatus;
            imageUploadFunction(imageUploadStatus, image.id);
          }
        }
        return uploadResponse;
      })
      .catch((e) => log.error(e));
  };

  const onSigningFailure = (response) => {
    log.error(response);
  };

  return fetch(getS3SigningUrl(image.name, image.type, source), {
    method: 'GET',
    headers: { 'Content-Type': image.type },
  })
    .then((res) => res.json().then((json) => onSigningSuccess(json)))
    .catch(onSigningFailure);
};

export const uploadInsuranceImage = (
  image,
  imageType,
  insuranceProfileId,
  ocrResponse,
  onSuccess,
  onFailure,
  side,
  isCompressed,
  dapiRes
) => {
  const handleFilenameSuccess = (response) => {
    onSuccess(response, side);
    return uploadToS3(
      {
        ...image,
        name: isCompressed ? response.data.aws_path_compressed : response.data.aws_path,
        id: response.data.id,
      },
      IMAGE_SOURCES.insurance,
      isCompressed
    );
  };
  return handleFilenameSuccess(dapiRes).catch((e) => onFailure(e));
};

export const uploadImage = (entityId, entityType, image) => {
  const fileName = image.name.replace(/[^a-zA-Z0-9_.-]/g, '');
  const path = `${entityId}/${fileName}`;
  return uploadToS3({ ...image, image, name: prefixS3PathForEntityType(entityType, path) });
};
