export const LOG_LEVEL_DEBUG = 1;
export const LOG_LEVEL_INFO = 2;
export const LOG_LEVEL_WARN = 4;
export const LOG_LEVEL_ERROR = 8;
import cloneDeep from 'lodash/cloneDeep';
import * as Sentry from '@sentry/nextjs';

let logLevel = LOG_LEVEL_DEBUG;

const doLog = (message: any) => {
  if (typeof message === 'object') {
    console.dir(cloneDeep(message)); // eslint-disable-line no-console
  } else {
    console.log(message); // eslint-disable-line no-console
  }
};

const doError = (message: any) => {
  Sentry.captureException(message);
  if (typeof message === 'object') {
    console.error(cloneDeep(message)); // eslint-disable-line no-console
  } else {
    console.error(message); // eslint-disable-line no-console
  }
};

export const log = {
  debug: (message: any) => {
    if (logLevel <= LOG_LEVEL_DEBUG) {
      doLog(message);
    }
  },

  info: (message: any) => {
    if (logLevel <= LOG_LEVEL_INFO) {
      doLog(message);
    }
  },

  warn: (message: any) => {
    if (logLevel <= LOG_LEVEL_WARN) {
      doLog(message);
    }
  },

  error: (message: any) => {
    if (logLevel <= LOG_LEVEL_ERROR) {
      doError(message);
    }
  },

  setLevel: (level: number) => {
    logLevel = level;
  },
};
