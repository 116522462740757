import { call, takeEvery, put } from 'redux-saga/effects';
import { clearBookingError, updateBookingError, updateBookingSuccess } from '../actions/queue';
import { setRuntimeVariable } from '../actions/runtime';
import { apiGetJson, apiPatchJson } from '../core/dapi';
import {
  buildPatchAccountData,
  buildPatchBookingsData,
  buildPatchUserProfileData,
} from '../core/dapi/newBooking';
import { isEmptyString } from '../core/util/string';
import { patchUserProfile } from './core/userProfiles';
import { patchAccount } from './core/accounts';
import { isEmptyObject } from '../core/util/object';
import { getUserProfileUrl } from '../core/dapi/userProfiles';
import { getBookingByIdUrl } from '../core/dapi/bookings';
import { rescheduleBooking } from './core/bookings';
import { resetCurrentPatient } from '../actions/currentPatient';
import { resetPaperworkResponse } from '../actions/paperworkResponses';
import { eligibilityFaceSheetReset } from '../actions/eligibilityCheck';

export const SUBMIT_FACESHEET_PROFILE_FORM = 'SUBMIT_FACESHEET_PROFILE_FORM';

export function* submitFacesheetProfileForm({ formData, bookingId, userProfileId }) {
  if (isEmptyObject(formData)) {
    yield put(setRuntimeVariable({ name: 'showFacesheetModal', value: false }));
    return;
  }

  yield put(setRuntimeVariable({ name: 'submittingAddEditPatient', value: true }));

  const patchBookingData = buildPatchBookingsData(formData);
  const patchUserProfileData = buildPatchUserProfileData(formData);
  const patchAccountData = buildPatchAccountData(formData);

  yield call(patchUserProfile, userProfileId, { booking_id: bookingId, ...patchUserProfileData });

  const getUserProfileByIdUrl = getUserProfileUrl(userProfileId);

  const userProfile = yield call(apiGetJson, getUserProfileByIdUrl);
  const accountId = userProfile.data.account_id;

  if (!isEmptyString(accountId)) {
    yield call(patchAccount, { accountId, patchAccountData });
  }

  if ('appointmentDateAndTime' in formData) {
    yield call(rescheduleBooking, {
      bookingId,
      newAppointmentDate: formData.appointmentDateAndTime,
      onError: function* onRescheduleError(err) {
        yield put(updateBookingError(err));
      },
    });
  }

  const patchBookingUrl = getBookingByIdUrl(bookingId);
  const bookingResponse = yield call(apiPatchJson, patchBookingUrl, patchBookingData);

  if (bookingResponse.error) {
    yield put(updateBookingError(bookingResponse.error));
    return;
  }

  if (bookingResponse.data) {
    const updatedValue = bookingResponse.data;
    yield put(updateBookingSuccess(updatedValue));
  }

  yield put(setRuntimeVariable({ name: 'showFacesheetModal', value: false }));
  yield put(setRuntimeVariable({ name: 'submittingAddEditPatient', value: false }));

  yield put(resetCurrentPatient());
  yield put(resetPaperworkResponse());
  yield put(clearBookingError());
  yield put(eligibilityFaceSheetReset());
}

export default function* rootSaga() {
  yield takeEvery(SUBMIT_FACESHEET_PROFILE_FORM, submitFacesheetProfileForm);
}
