import { call, put } from 'redux-saga/effects';
import { updateUserProfileSuccess, updateUserProfileError } from '../../actions/queue';
import { apiPatchJson } from '../../core/dapi';
import { getUserProfileUrl } from '../../core/dapi/userProfiles';

export function* patchUserProfile(userProfileId, patchData) {
  const patchUserProfileUrl = getUserProfileUrl(userProfileId);

  const response = yield call(apiPatchJson, patchUserProfileUrl, patchData);

  if (response.error) {
    yield put(updateUserProfileError(response.error));
    return;
  }

  if (response.data) {
    yield put(updateUserProfileSuccess(response.data));
  }
}
