import { call, put, takeEvery } from 'redux-saga/effects';
import {
  clinicAccountAddLocation,
  clinicAccountsAddAllLocationsSuccess,
  clinicAccountsAddAllLocationsError,
} from '../actions/clinicAccounts';
import { setRuntimeVariable } from '../actions/runtime';
import { apiPost } from '../core/dapi';
import { getAddClinicAccountToAllLocationsUrl } from '../core/dapi/clinicAccounts';
import { diffArrays } from '../core/util/array';

export const ADD_ALL_LOCATIONS_TO_ACCOUNT = 'ADD_ALL_LOCATIONS_TO_ACCOUNT';
export const IS_ADDING_ALL_LOCATIONS = 'isAddingAllLocations';

export function* addAllLocationsToAccount({ account: { id: accountId, locations }, onSuccess }) {
  try {
    yield put(setRuntimeVariable({ name: IS_ADDING_ALL_LOCATIONS, value: true }));
    const postData = { clinic_account_id: accountId };
    const response = yield call(apiPost, getAddClinicAccountToAllLocationsUrl(), postData);
    if (response) {
      const prevLocations = locations.map((location) => location.location_id);
      const { addedElements } = diffArrays(prevLocations, response);
      yield put(clinicAccountAddLocation({ id: accountId, locations: addedElements }));
      yield put(clinicAccountsAddAllLocationsSuccess());
      onSuccess();
    }
  } catch (error) {
    yield put(clinicAccountsAddAllLocationsError(error));
  } finally {
    yield put(setRuntimeVariable({ name: IS_ADDING_ALL_LOCATIONS, value: false }));
  }
}

export default function* rootSaga() {
  yield takeEvery(ADD_ALL_LOCATIONS_TO_ACCOUNT, addAllLocationsToAccount);
}
