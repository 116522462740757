import {
  isCallCenterRep,
  isNextCareUser,
  isSolvEmployee,
  isSolvExpressLocation,
} from '../core/session';
import differenceBy from 'lodash/differenceBy';
import { EMPTY_ARRAY, EMPTY_ARRAY_NEVER } from '../core/util/array';
import { getClinicAccountPermissions } from '../core/util/clinicAccounts';
import { shouldShowFeature } from '../core/viewCustomization';
import { FeatureName } from '../core/viewCustomization/featureNames';
import { RootState } from '../types/RootState';

export const getLogin = (reduxState: RootState) => reduxState?.login ?? null;

export const getShouldShowFeature = (reduxState: RootState, featureName: FeatureName) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return shouldShowFeature(login, featureName);
};

export const getIsNextCareUser = (reduxState: RootState) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return isNextCareUser(login);
};

export const getIsSolvEmployee = (reduxState: RootState) => isSolvEmployee(getLogin(reduxState));

export const isSolvExpressLocationSelected = (reduxState: RootState, locationIds: string[]) => {
  const login = getLogin(reduxState);

  // We only treat the user as having a solv express location selected if *all* selected locations
  // are express.
  return locationIds.every((selectedLocId) => {
    const accountLocation = login.account.locations.find(
      (otherLocation) => selectedLocId === otherLocation.location_id
    );
    return accountLocation && isSolvExpressLocation(accountLocation);
  });
};

export const getLastCompletedNps = (reduxState: RootState): string | null => {
  return getLogin(reduxState)?.account?.last_completed_nps ?? null;
};

export const getIsCallCenterRep = (reduxState: RootState) => {
  const login = getLogin(reduxState);
  if (!login) return false;
  return isCallCenterRep(login);
};

export const getUserAccountId = (reduxState: RootState): string | null => {
  const login = getLogin(reduxState);
  return login?.account?.id ?? null;
};

export const getUserRoles = (reduxState: RootState): string[] => {
  const login = getLogin(reduxState);
  return login?.account?.roles ?? (EMPTY_ARRAY as never[]);
};

export const getUserPermissions = (reduxState: RootState) => {
  const login = getLogin(reduxState);
  const account = login?.account;
  if (!account) {
    return EMPTY_ARRAY_NEVER;
  }
  return getClinicAccountPermissions(account);
};

export const getUserGroups = (reduxState: RootState): string[] => {
  const login = getLogin(reduxState);
  return login?.account?.groups?.map((group) => group.group_id) ?? (EMPTY_ARRAY as never[]);
};
