import {
  QUEUE_TAB_ACTIVE,
  QUEUE_SET_TAB,
  UPDATE_BOOKING_SUCCESS,
  GET_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR,
  UPDATE_BOOKING_SUBMITTING,
  GET_BOOKING_ERROR,
  LOGIN_ACCOUNT_RECEIVED,
  LOGIN_LOGOUT,
  ACCOUNT_ROLE_CALL_CENTER,
  CALL_CENTER_MAKE,
  CLEAR_MOST_RECENT_BOOKING,
  CLEAR_BOOKING_ERROR,
  CREATE_BOOKING_ERROR,
  QUEUE_TOGGLE_NOTES_MODAL,
  QUEUE_SET_LOCATION_IDS,
  QUEUE_ADD_LOCATION_IDS,
} from '../constants';
import { mergeArrays } from '../core/util/array';

const getDefaultTab = (loginResponse) => {
  const roles = loginResponse.roles;

  if (roles.length === 1) {
    if (roles.includes(ACCOUNT_ROLE_CALL_CENTER)) {
      return CALL_CENTER_MAKE;
    }
  }

  return QUEUE_TAB_ACTIVE;
};

const getInitialState = () => ({
  selectedLocationIds: [],
});

export default function queueReducer(state = getInitialState(), action) {
  switch (action.type) {
    case QUEUE_SET_LOCATION_IDS: {
      return {
        ...state,
        selectedLocationIds: action.payload.value,
      };
    }

    case QUEUE_ADD_LOCATION_IDS:
      return {
        ...state,
        selectedLocationIds: mergeArrays(state.selectedLocationIds, action.payload.value),
      };

    case QUEUE_SET_TAB: {
      return {
        ...state,
        currentTab: action.payload.value,
      };
    }

    case LOGIN_LOGOUT:
      return getInitialState();

    case LOGIN_ACCOUNT_RECEIVED: {
      if (action.payload.value.locations.length > 0) {
        return {
          ...state,
          currentLocation: action.payload.value.locations[0].location_id,
          currentTab: getDefaultTab(action.payload.value),
        };
      }

      return state;
    }

    case UPDATE_BOOKING_ERROR:
    case CREATE_BOOKING_ERROR:
    case GET_BOOKING_ERROR: {
      return {
        ...state,
        error: action.payload.value,
        submitting: null,
        submittingPatchForBookingId: null,
      };
    }

    case CLEAR_BOOKING_ERROR:
      return {
        ...state,
        error: null,
      };

    case UPDATE_BOOKING_SUBMITTING:
      return {
        ...state,
        submittingPatchForBookingId: action.payload.value,
      };

    case UPDATE_BOOKING_SUCCESS:
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        submitting: null,
        submittingPatchForBookingId: null,
        mostRecentBooking: action.payload.value,
      };

    case CLEAR_MOST_RECENT_BOOKING:
      return {
        ...state,
        mostRecentBooking: {},
      };

    case QUEUE_TOGGLE_NOTES_MODAL:
      return {
        ...state,
        notesModalVisible: action.payload.value,
      };

    default:
      return state;
  }
}
