export const CLINIC_DOWNLOAD_REPORTS_RECEIVED = 'clinicDownloadReports/RECEIVED';
export const FETCH_CLINIC_DOWNLOAD_REPORTS_DATA = 'clinicDownloadReports/FETCH_DOWNLOAD_REPORTS';
export const BACKGROUND_REFRESH_CLINIC_DOWNLOAD_REPORTS =
  'clinicDownloadReports/BACKGROUND_REFRESH_DOWNLOAD_REPORTS';

export const CREATE_CLINIC_DOWNLOAD_REPORT_REQUESTED = 'clinicDownloadReports/CREATE_REQUEST';

export const setClinicDownloadReports = value => ({
  type: CLINIC_DOWNLOAD_REPORTS_RECEIVED,
  payload: { value: value.results },
});

export const createClinicDownloadReport = params => ({
  type: CREATE_CLINIC_DOWNLOAD_REPORT_REQUESTED,
  payload: params,
});

export const fetchClinicDownloadReportsData = () => ({
  type: FETCH_CLINIC_DOWNLOAD_REPORTS_DATA,
  payload: null,
});

export const backgroundRefreshClinicDownloadReports = () => ({
  type: BACKGROUND_REFRESH_CLINIC_DOWNLOAD_REPORTS,
  payload: null,
});

const defaultState = {
  data: null,
  createDownloadReportStatus: '', // '' | 'creating' | 'created' | 'error'
  createDownloadReportErrorText: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLINIC_DOWNLOAD_REPORTS_RECEIVED:
      return {
        ...state,
        data: action.payload.value,
      };
    default:
      return state;
  }
};
