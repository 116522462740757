import { DAPI_HOST } from '../../config';

const createContactUrl = () => `${DAPI_HOST}/v1/contacts`;

const getContactUrl = contactId => `${DAPI_HOST}/v1/contacts/${contactId}`;

const getContactsCollectionUrl = userProfileId =>
  `${DAPI_HOST}/v1/contacts/?user_profile_id=${userProfileId}`;

export { createContactUrl, getContactsCollectionUrl, getContactUrl };
