import { EMPTY_ARRAY } from '../core/util/array';

export class UclBrandsActionTypes {
  static UCL_BRANDS_RECEIVED = 'UCL_BRANDS_RECEIVED';
  static UCL_BRANDS_ERROR = 'UCL_BRANDS_ERROR';
}

export const uclBrandsReceived = value => ({
  type: UclBrandsActionTypes.UCL_BRANDS_RECEIVED,
  payload: { value },
});

export const uclBrandsError = value => ({
  type: UclBrandsActionTypes.UCL_BRANDS_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case UclBrandsActionTypes.UCL_BRANDS_RECEIVED:
      return action.payload.value.results;
    case UclBrandsActionTypes.UCL_BRANDS_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
