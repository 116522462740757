import {
  RECEIVE_ACCOUNTS_SEARCH,
  ACCOUNTS_SEARCH_ERROR,
  CLEAR_ACCOUNTS_DATA,
  UPDATE_ACCOUNT_SUBMITTING,
} from '../constants';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

const receiveAccountsSearch = value => ({
  type: RECEIVE_ACCOUNTS_SEARCH,
  payload: { value },
});

const accountsSearchError = value => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: ACCOUNTS_SEARCH_ERROR,
    payload: { value },
  };
};

const clearAccountsData = value => ({
  type: CLEAR_ACCOUNTS_DATA,
  payload: { value },
});

const updateAccountSubmitting = value => ({
  type: UPDATE_ACCOUNT_SUBMITTING,
  payload: { value },
});

export { accountsSearchError, receiveAccountsSearch, clearAccountsData, updateAccountSubmitting };
