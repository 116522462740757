import { useEffect } from 'react';
import { isLocalHost } from '../config';

export function useForceHttps() {
  useEffect(() => {
    const location = window.location;
    if (location.protocol === 'http:' && !isLocalHost()) {
      window.location.replace(window.location.href.replace('http://', 'https://'));
    }
  }, []);
}
