import { EMPTY_ARRAY } from '../core/util/array';

export class LocationImportToolFieldOptionsActionTypes {
  static LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_RECEIVED =
    'LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_RECEIVED';
  static LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_ERROR = 'LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_ERROR';
}

export const locationsImportToolFieldOptionsReceived = value => ({
  type: LocationImportToolFieldOptionsActionTypes.LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_RECEIVED,
  payload: { value },
});

export const locationsImportToolFieldOptionsError = value => ({
  type: LocationImportToolFieldOptionsActionTypes.LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case LocationImportToolFieldOptionsActionTypes.LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_RECEIVED:
      return action.payload.value;
    case LocationImportToolFieldOptionsActionTypes.LOCATIONS_IMPORT_TOOL_FIELD_OPTIONS_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
