import {
  RECEIVE_ACCOUNTS_SEARCH,
  ACCOUNTS_SEARCH_ERROR,
  CLEAR_ACCOUNTS_DATA,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUBMITTING,
  EMPTY_OBJECT,
} from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

const getInitialState = () => ({
  accounts: EMPTY_ARRAY,
  submitting: false,
  updateAccountSuccess: EMPTY_OBJECT,
  updateAccountError: EMPTY_OBJECT,
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RECEIVE_ACCOUNTS_SEARCH:
      return {
        ...state,
        accounts: action.payload.value.results,
      };

    case ACCOUNTS_SEARCH_ERROR:
      return {
        error: action.payload.value,
        accounts: EMPTY_ARRAY,
      };

    case CLEAR_ACCOUNTS_DATA:
      return {
        accounts: EMPTY_ARRAY,
      };
    case UPDATE_ACCOUNT_SUCCESS: {
      const updatedAccounts = [];
      state.accounts.forEach(account => {
        if (account.id === action.payload.value.id) {
          updatedAccounts.push(action.payload.value);
        } else {
          updatedAccounts.push(account);
        }
      });
      return {
        ...state,
        updateAccountSuccess: action.payload.value,
        accounts: updatedAccounts,
      };
    }
    case UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updateAccountError: action.payload.value,
      };
    case UPDATE_ACCOUNT_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
