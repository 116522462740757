import {
  RESET_REPORTS,
  REPORT_ERROR,
  SET_REPORT_DATES,
  SET_PERFORMANCE_PRICING_LOCATIONS_INFO,
  SET_REPORT_LOCATIONS,
  RECEIVE_REVIEWS_SUMMARY,
  RESET_REVIEWS_CURENT_PAGE,
  REVIEWS_SUMMARY_ERROR,
  RECEIVE_REVIEWS,
  REVIEWS_ERROR,
  RESET_REVIEWS,
  UPDATE_FILTERS,
  PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED,
  PAPERLESS_REPORTS_GRAPH_DATA_ERROR,
  RECEIVE_REVIEW,
  TELEMED_REPORTS_GRAPH_DATA_RECEIVED,
  SET_DOWNLOAD_REPORT_FILE_FORMAT,
  SET_PROVIDERS,
  SET_EARLIEST_LIVE_DATE,
} from '../constants';
import { getPaginationValues } from '../core/dapi';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

const resetReports = () => ({
  type: RESET_REPORTS,
});

const reportError = (value) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: REPORT_ERROR,
    payload: { value },
  };
};

const setDates = (value) => ({
  type: SET_REPORT_DATES,
  payload: value,
});

const receiveReviewsSummary = (value) => ({
  type: RECEIVE_REVIEWS_SUMMARY,
  payload: { value },
});

const reviewsSummaryError = (value) => ({
  type: REVIEWS_SUMMARY_ERROR,
  payload: { value },
});

const receiveReviews = (value) => {
  const { currentPage, hasMorePages } = getPaginationValues(value);

  return {
    type: RECEIVE_REVIEWS,
    payload: {
      currentPage,
      hasMorePages,
      value,
    },
  };
};

const receiveReview = (value) => ({
  type: RECEIVE_REVIEW,
  payload: { value },
});

const resetReviewsCurentPage = () => ({
  type: RESET_REVIEWS_CURENT_PAGE,
});

const reviewsError = (value) => ({
  type: REVIEWS_ERROR,
  payload: { value },
});

const resetReviews = () => ({ type: RESET_REVIEWS });

const updateFilters = (value) => ({
  type: UPDATE_FILTERS,
  payload: { value },
});

const setLocations = (value) => ({
  type: SET_REPORT_LOCATIONS,
  payload: { value },
});

const setPerformancePricingLocationInfo = ({
  performancePricingLocations,
  hasPerformancePricingLocations,
  locationIdsLiveMoreThan30Days,
}) => ({
  type: SET_PERFORMANCE_PRICING_LOCATIONS_INFO,
  payload: {
    performancePricingLocations,
    hasPerformancePricingLocations,
    locationIdsLiveMoreThan30Days,
  },
});

const setEarliestLiveDate = (momentObject) => ({
  type: SET_EARLIEST_LIVE_DATE,
  payload: { momentObject },
});

const setProviders = (value) => ({
  type: SET_PROVIDERS,
  payload: { value },
});

const receivePaperlessReportsGraphData = (type) => (value) => ({
  type: PAPERLESS_REPORTS_GRAPH_DATA_RECEIVED,
  payload: { value, type },
});

const paperlessReportsGraphDataError = (payload) => ({
  type: PAPERLESS_REPORTS_GRAPH_DATA_ERROR,
  payload,
});

const receiveTelemedReportsGraphData = (type, value) => ({
  type: TELEMED_REPORTS_GRAPH_DATA_RECEIVED,
  payload: { value, type },
});

const telemedReportsGraphDataError = (payload) => ({
  type: PAPERLESS_REPORTS_GRAPH_DATA_ERROR,
  payload,
});

const setDownloadReportFileFormat = (fileFormat) => ({
  type: SET_DOWNLOAD_REPORT_FILE_FORMAT,
  payload: fileFormat,
});

export {
  resetReports,
  reportError,
  setDates,
  receiveReviewsSummary,
  reviewsSummaryError,
  receiveReviews,
  receiveReview,
  reviewsError,
  resetReviews,
  resetReviewsCurentPage,
  updateFilters,
  setLocations,
  setPerformancePricingLocationInfo,
  setEarliestLiveDate,
  setProviders,
  receivePaperlessReportsGraphData,
  paperlessReportsGraphDataError,
  receiveTelemedReportsGraphData,
  telemedReportsGraphDataError,
  setDownloadReportFileFormat,
};
