/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* jscs:disable maximumLineLength */
import { DAPI_HOST } from '../../config';

const getRandomReviewToModerateUrl = () => `${DAPI_HOST}/v1/solv-ratings/random`;

const getPatchSolvRatingUrl = (solvRatingId, clinicAccountId) =>
  `${DAPI_HOST}/v1/solv-ratings/${solvRatingId}?clinic_account_id=${clinicAccountId}`;

const getSolvRatingUpdateIsContactedUrl = (solvRatingId, isContacted) =>
  `${DAPI_HOST}/v1/solv-ratings/${solvRatingId}/update-is-contacted?is_contacted=${isContacted}`;

const getReviewsByLocationUrl = (locationId, page = 1) =>
  `${DAPI_HOST}/v1/locations/${locationId}/solv-ratings?show_unfiltered=true&page=${page}`;

const getReviewsByIdUrl = (reviewId) => `${DAPI_HOST}/v1/solv-ratings/${reviewId}`;

const getTotalReviewsUrl = () => `${DAPI_HOST}/v1/solv-ratings/total-unmoderated`;

export {
  getReviewsByLocationUrl,
  getRandomReviewToModerateUrl,
  getPatchSolvRatingUrl,
  getReviewsByIdUrl,
  getSolvRatingUpdateIsContactedUrl,
  getTotalReviewsUrl,
};
