import { EMPTY_OBJECT } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export class ServiceLocationsActionTypes {
  static CREATED = 'serviceLocations/CREATED';
  static CREATING = 'serviceLocations/CREATING';
  static ERROR = 'serviceLocations/ERROR';
  static LOADING = 'serviceLocations/LOADING';
  static RECEIVED = 'serviceLocations/RECEIVED';
  static UPDATED = 'serviceLocations/UPDATED';
  static UPDATING = 'serviceLocations/UPDATING';
}
export const serviceLocationsCreating = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.CREATING,
});

export const serviceLocationsCreated = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.CREATED,
});

export const serviceLocationsError = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.ERROR,
});

export const serviceLocationsLoading = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.LOADING,
});

export const serviceLocationsReceived = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.RECEIVED,
});

export const serviceLocationUpdated = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.UPDATED,
});

export const serviceLocationUpdating = value => ({
  payload: { value },
  type: ServiceLocationsActionTypes.UPDATING,
});

export default (state = EMPTY_OBJECT, action) => {
  switch (action.type) {
    case ServiceLocationsActionTypes.CREATED: {
      const newResults = (state.results ?? EMPTY_ARRAY).slice();
      newResults.push(action.payload.value);

      return {
        ...state,
        error: null,
        results: newResults,
      };
    }
    case ServiceLocationsActionTypes.CREATING:
      return {
        ...state,
        isCreating: action.payload.value,
      };
    case ServiceLocationsActionTypes.ERROR:
      return {
        ...state,
        error: action.payload.value,
      };
    case ServiceLocationsActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ServiceLocationsActionTypes.RECEIVED:
      return {
        ...state,
        results: action.payload.value,
      };
    case ServiceLocationsActionTypes.UPDATED: {
      const newResults = (state.results ?? EMPTY_ARRAY)
        .slice()
        .filter(sl => sl.id !== action.payload.value.id);
      newResults.push(action.payload.value);

      return {
        ...state,
        error: null,
        results: newResults,
      };
    }
    case ServiceLocationsActionTypes.UPDATING: {
      return {
        ...state,
        isUpdating: action.payload.value,
      };
    }
    default:
      return state;
  }
};
