import { useInterval } from 'beautiful-react-hooks';
import { useEffect } from 'react';
import { useAppDispatch, useSelector } from '../types/Redux';
import { checkForNewVersion, setManageVersion } from '../ducks/newVersion';
import { isProd } from '../config';

export function useHeartBeat() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setManageVersion(process.env.NEXT_PUBLIC_MANAGE_VERSION));
    dispatch(checkForNewVersion());
  }, [dispatch]);

  useInterval(
    () => {
      dispatch(checkForNewVersion());
    },
    !isProd() ? 1000 * 15 : 1000 * 60
  );
}
