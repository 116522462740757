import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';
import { EMPTY_STRING } from '../../constants';

export const getAllPaperworkFieldsUrl = (filters, orderBy) => {
  const filterString = filters ? getFilterString('paperwork_fields', filters) : EMPTY_STRING;

  const orderString = orderBy ? `&order_by=${orderBy}` : EMPTY_STRING;

  return `${DAPI_HOST}/v1/paperwork-fields?${filterString}${orderString}`;
};

export const getPaperworkFieldsUrl = id => `${DAPI_HOST}/v1/paperwork-fields/${id}`;

export const getPaperworkFieldsV2Url = id => `${DAPI_HOST}/v2/paperwork-fields/${id}`;
