import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';

const getServicePreferencesUrl = (locationId, serviceId) => {
  return `${DAPI_HOST}/v1/service-preferences?location_id=${locationId}&service_id=${serviceId}`;
};

const createServicePreferencesUrl = () => `${DAPI_HOST}/v1/service-preferences/`;

const updateServicePreferencesUrl = (servicePreferenceId) =>
  `${DAPI_HOST}/v1/service-preferences/${servicePreferenceId}`;

export { getServicePreferencesUrl, createServicePreferencesUrl, updateServicePreferencesUrl };
