import { call, put } from 'redux-saga/effects';
import { VERSION_TWO, ORIGIN_QUEUE } from '../../constants';
import { loadingLocation, locationError, receiveLocation } from '../../actions/location';
import { getLocationUrl } from '../../core/dapi/location';
import { apiGetJson } from '../../core/dapi';

export function* fetchLocation({
  clearDataWhileLoading,
  locationId,
  origin = ORIGIN_QUEUE,
  version = VERSION_TWO,
}) {
  if (clearDataWhileLoading) {
    yield put(loadingLocation());
  }

  const url = `${getLocationUrl(locationId, origin, version)}`;

  const response = yield call(apiGetJson, url);

  if (response.errors) {
    yield put(locationError(response.errors));
  }

  if (response.data) {
    yield put(receiveLocation(response.data));
  }
}
