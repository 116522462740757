import { call, put, takeEvery } from 'redux-saga/effects';
import { setSubmittingBookingUpdate } from '../actions/bookings';
import { apiGetJson } from '../core/dapi';
import { getAllBookingsByLocationIdUrl } from '../core/dapi/bookings';
import {
  patientBoostBookingsRecieved,
  setLoadingPatientBoostData,
  patientBoostBookingsError,
  numberOfNewSolvBoostPatients,
} from '../ducks/solvPatientBoost';
import { getLastBoostPatientSeen } from '../components/SolvPatientBoostModal/SolvPatientBoostModal';
import moment from 'moment';

export const GET_SOLV_PATIENT_BOOST_BOOKINGS = 'GET_SOLV_PATIENT_BOOST_BOOKINGS';

export function* getSolvPatientBoostBookings({ locationId, limit, page, filters, dateRange }) {
  yield put(setLoadingPatientBoostData(true));

  const getBookingsUrl = getAllBookingsByLocationIdUrl(locationId, limit, page, filters, dateRange);

  const response = yield call(apiGetJson, getBookingsUrl);

  let numberOfNewPatients = 0;

  const lastSolvPatientBoostBookingSeen = getLastBoostPatientSeen();

  if (response.errors) {
    yield put(patientBoostBookingsError(response.errors));
    yield put(setSubmittingBookingUpdate(false));
  }

  if (response.data) {
    if (typeof lastSolvPatientBoostBookingSeen === 'undefined') {
      numberOfNewPatients = response.data.results.length;
    } else {
      response.data.results.forEach(solvPatient => {
        if (moment(solvPatient.created_date).isAfter(moment(lastSolvPatientBoostBookingSeen))) {
          numberOfNewPatients += 1;
        }
      });
    }

    yield put(patientBoostBookingsRecieved(response.data.results));

    if (numberOfNewPatients > 0) {
      yield put(numberOfNewSolvBoostPatients(numberOfNewPatients));
    }

    yield put(setSubmittingBookingUpdate(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_SOLV_PATIENT_BOOST_BOOKINGS, getSolvPatientBoostBookings);
}
