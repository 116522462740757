export const BUTTON_THEME_ANT_DANGER = 'danger';
export const BUTTON_THEME_ANT_DEFAULT = 'default';
export const BUTTON_THEME_ANT_PRIMARY = 'primary';
export const BUTTON_THEME_CRUNCHBERRY = 'crunchberry';
export const BUTTON_TYPE_BUTTON = 'button';
export const BUTTON_TYPE_RESET = 'reset';
export const BUTTON_TYPE_SUBMIT = 'submit';

export const ROW_VERTICAL_PADDING_NONE = 'nn';
export const ROW_VERTICAL_PADDING_SMALL = 'sm';
export const ROW_VERTICAL_PADDING_MEDIUM = 'md';
export const ROW_VERTICAL_PADDING_LARGE = 'lg';

export const ANTD_SIZE_SMALL = 'small';
export const ANTD_SIZE_DEFAULT = 'default';
export const ANTD_SIZE_LARGE = 'large';

export const breakpoints = {
  xs: 390,
  sm: 500,
  md: 700,
  tablet: 768,
  lg: 1200,
  xl: 1600,
};

export const tvBreakpoints = {
  sm: 852,
  md: 1280,
  lg: 1920,
};
export const addPatientFormAppointmentTimeTextCutoff = 645;
