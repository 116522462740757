import PropTypes from 'prop-types';
import React from 'react';
import { Form, Select } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { isEmptyArray } from '../../../core/util/array';
import { Label } from '../../primitives';

const FormItem = Form.Item;
const Option = Select.Option;

const StyledFormItem = styled(FormItem)`
  span {
    ${props => !props?.disabled && `color: ${colors.notQuiteBlack};`};
    font-size: 13px;
  }
`;

const StyledSelect = styled(Select)`
  margin-left: 3px !important;
  .ant-select-selection-selected-value {
    ${props => !props?.disabled && `background-color: ${colors.theWhiteAlbum};`};
    ${props => !props?.disabled && `color: ${colors.blueMonday};`};
    font-size: 15px;
    cursor: default;
    margin: 1px 0 0 3px;
    height: 25px;
  }

  .ant-select-selection__placeholder {
    font-size: 15px;
  }

  ${props =>
    props?.showSearch &&
    `
    .ant-select-search__field {
      padding: 0px; // Override existing input padding that could cause it to look offset from the dropdown options
    }
  `};
`;

const SelectFormField = ({
  allowClear,
  isSearchable,
  label,
  form,
  fieldName,
  initialValue,
  options,
  required,
  requiredMessage,
  disabled,
  placeholder,
  validator,
  fieldInfoTooltipDescription,
  onChange,
  getPopupContainer,
  location,
  showCopyIcon,
  showEditedIcon,
  dropdownMatchSelectWidth,
  showIsOptional,
}) => {
  const getOptions = () => {
    if (isEmptyArray(options)) return [];

    if (typeof options[0] === 'string') {
      return options.map(option => ({ name: option, value: option }));
    }

    return options;
  };

  const formValue = form.getFieldValue(fieldName);
  const copyValue = formValue ?? initialValue;

  return (
    <StyledFormItem disabled={disabled}>
      <Label
        label={label}
        showCopyIcon={showCopyIcon}
        location={location}
        fieldInfoTooltipDescription={fieldInfoTooltipDescription}
        copyValue={copyValue}
        showEditedIcon={showEditedIcon}
        showIsOptional={showIsOptional}
      />
      {form.getFieldDecorator(fieldName, {
        initialValue,
        rules: [{ required, message: requiredMessage }, { validator }],
      })(
        <StyledSelect
          allowClear={allowClear}
          showSearch={isSearchable}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          getPopupContainer={getPopupContainer}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        >
          {getOptions().map((option, i) => (
            <Option key={i} value={option.value} data-hj-suppress>
              {option.name}
            </Option>
          ))}
        </StyledSelect>
      )}
    </StyledFormItem>
  );
};

SelectFormField.propTypes = {
  allowClear: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validator: PropTypes.func,
  fieldInfoTooltipDescription: PropTypes.string,
  onChange: PropTypes.func,
  getPopupContainer: PropTypes.func,
  showCopyIcon: PropTypes.bool,
  login: PropTypes.object,
  location: PropTypes.object,
  showEditedIcon: PropTypes.bool,
  dropdownMatchSelectWidth: PropTypes.bool,
  showIsOptional: PropTypes.bool,
};

export default SelectFormField;
