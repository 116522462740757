import { takeLatest } from 'redux-saga/effects';
import {
  fetchInsuranceSummary,
  submitInsuranceForm,
  fetchInsurersList,
  fetchCards,
  fetchCharge,
  postCharge,
  setChargeSubmitting,
  setChargeError,
  deleteCard,
} from '../components/FacesheetModal/Payments/POS/POSSagas';

export default function* rootSaga() {
  yield takeLatest('FETCH_INSURANCE_SUMMARY', fetchInsuranceSummary);
  yield takeLatest('SUBMIT_INSURANCE_SUMMARY', submitInsuranceForm);
  yield takeLatest('FETCH_INSURERS_LIST', fetchInsurersList);
  yield takeLatest('FETCH_PAYMENT_CHARGE', fetchCharge);
  yield takeLatest('FETCH_PAYMENT_CARDS', fetchCards);
  yield takeLatest('POST_CHARGE', postCharge);
  yield takeLatest('DELETE_CARD', deleteCard);
  yield takeLatest('SET_CHARGE_SUBMITTING', setChargeSubmitting);
  yield takeLatest('SET_CHARGE_ERROR', setChargeError);
}
