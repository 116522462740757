import { message } from 'antd';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { TRIGGER_TO_EVENT_NAME_MAP } from '../../../../core/analytics/events';
import { getTrackingProperties } from '../../../../core/analytics/properties';

export const showCopySuccessMessage = (label, login, location) => {
  message.config({ top: 75 });
  message.success(`${label} copied`, 2);

  analyticsTrackEvent(
    TRIGGER_TO_EVENT_NAME_MAP.get(`Copy ${label}`),
    getTrackingProperties({ login, location })
  );
};
