import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { receiveReview } from '../actions/reports';
import { receiveReviewsData, reviewsDataError } from '../actions/reviews';
import { getLastReviewSeen } from '../components/ReviewsModal/ReviewsModal';
import { apiGet } from '../core/dapi';
import { getReviewsUrl } from '../core/dapi/review';
import { selectors as locationsSelectors } from '../ducks/locations';
import { ReviewsGoogleGmbSagas } from './core/reviewsGoogleGmb';
import { SolvRatingsSagas } from './core/solvRatings';

export class ReviewsSagas {
  static UPDATE_REVIEW = 'sagas/UPDATE_REVIEW';
  static UPDATE_REVIEW_IS_CONTACTED = 'sagas/UPDATE_REVIEW_IS_CONTACTED';
  static FETCH_REVIEW_DATA_FOR_LOCATION = 'saga/FETCH_REVIEW_DATA_FOR_LOCATION';
}

function* updateReview({ solvRatingId, reviewGoogleGmbId, clinicAccountId, patchData }) {
  if (solvRatingId) {
    yield put({
      type: SolvRatingsSagas.UPDATE_SOLV_RATING,
      solvRatingId,
      clinicAccountId,
      patchData,
    });
  } else if (reviewGoogleGmbId) {
    yield put({
      type: ReviewsGoogleGmbSagas.UPDATE_REVIEW_GOOGLE_GMB,
      reviewGoogleGmbId,
      patchData,
    });
  }
}

function* updateReviewIsContacted({ solvRatingId, reviewGoogleGmbId, isContacted, review }) {
  // this is to quickly update it in app state while we
  // wait for the API response so it feels snappier
  const updatedReview = { ...review, is_contacted: isContacted };
  yield put(receiveReview(updatedReview));

  if (solvRatingId) {
    yield put({
      type: SolvRatingsSagas.UPDATE_SOLV_RATING_IS_CONTACTED,
      solvRatingId,
      isContacted,
    });
  } else if (reviewGoogleGmbId) {
    yield put({
      type: ReviewsGoogleGmbSagas.UPDATE_REVIEW_GOOGLE_GMB_IS_CONTACTED,
      reviewGoogleGmbId,
      isContacted,
    });
  }
}

function* fetchReviewDataForLocation({ locationId }) {
  const reviewsDataForLocationIsStale = yield select(state =>
    locationsSelectors.getReviewsDataForLocationIsStale(state, locationId)
  );

  if (!reviewsDataForLocationIsStale) {
    return;
  }

  let response;
  try {
    const url = getReviewsUrl(locationId);
    response = yield call(apiGet, url);
    let numberOfNewReviews = 0;
    const lastReviewSeen = getLastReviewSeen();

    if (lastReviewSeen == null) {
      numberOfNewReviews = response?.results?.length ?? 0;
    } else if (Array.isArray(response?.results)) {
      const lastReviewSeenMoment = moment(lastReviewSeen);
      response.results.forEach(rating => {
        if (moment(rating.rating_date).isAfter(lastReviewSeenMoment)) {
          numberOfNewReviews += 1;
        }
      });
    }
    yield put(receiveReviewsData(numberOfNewReviews, response, { locationId }));
  } catch (err) {
    yield put(reviewsDataError(err, { locationId }));
  }
}

export default function* rootSaga() {
  yield takeEvery(ReviewsSagas.UPDATE_REVIEW, updateReview);
  yield takeEvery(ReviewsSagas.UPDATE_REVIEW_IS_CONTACTED, updateReviewIsContacted);
  yield takeEvery(ReviewsSagas.FETCH_REVIEW_DATA_FOR_LOCATION, fetchReviewDataForLocation);
}
