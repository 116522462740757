import React from 'react';
import styled from 'styled-components';
import { isMobile } from '../../../../core/util/device';
import { isEmptyArray } from '../../../../core/util/array';
import { array, bool, number } from 'prop-types';

const Limit = styled.span`
  opacity: 50%;
  margin-left: 10px;
`;

const LimitTop = styled.div`
  margin-left: 10px;
`;

const LimitBottom = styled.div`
  opacity: 50%;
  margin-left: 10px;
  font-size: 14px;
`;

const SelectedCountLabel = props => {
  const { limit, selectedOptions, shouldShowLimitText, totalOptionCount } = props;

  const getSelectedCount = () => (!isEmptyArray(totalOptionCount) ? totalOptionCount.length : 0);

  if (!shouldShowLimitText) {
    return `${getSelectedCount()} selected`;
  }

  if (isMobile()) {
    return (
      <div>
        <LimitTop>{`${getSelectedCount()} selected`}</LimitTop>
        <LimitBottom>{selectedOptions.length >= limit ? '(Maxed)' : `(Max ${limit})`}</LimitBottom>
      </div>
    );
  }

  return (
    <>
      {`${getSelectedCount()} selected`}
      <Limit>
        (Max {limit}
        {selectedOptions.length >= limit && ' reached!'})
      </Limit>
    </>
  );
};

SelectedCountLabel.propTypes = {
  limit: number,
  selectedOptions: array,
  shouldShowLimitText: bool,
  totalOptionCount: array,
};

export default SelectedCountLabel;
