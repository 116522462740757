import format from 'dateformat';
import moment from 'moment';
import { EARLIEST_YEAR_ALLOWED } from '../../config';
import { isNumberBetween } from '../../core/util/number';

/**
 * Format date
 * @param {number} date Unix timestamp
 * @param {String} formatString
 */
const dateFormat = (date, formatString) => format(new Date(date), formatString);

/**
 * Because Safari is still terrible
 * @param dateTimeString
 * @return {number}
 */
const parseSqlDateTime = (dateTimeString) => {
  // 2016-08-01 21:00:00
  const parseRegex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;

  // see above
  let year = null;
  let month = null;
  let day = null;
  let hour = null;
  let minute = null;
  let second = null;

  dateTimeString.replace(parseRegex, (wholeMatch, $1, $2, $3, $4, $5, $6) => {
    year = $1;
    month = $2;
    day = $3;
    hour = $4;
    minute = $5;
    second = $6;
  });
  return new Date(year, month - 1, day, hour, minute, second).getTime();
};

/**
 * Because Still
 * @param dateString
 * @return {number}
 */
const parseSqlDate = (dateString) => {
  // 2016-08-01
  const parseRegex = /(\d{4})-(\d{2})-(\d{2})/;

  // see above
  let year = null;
  let month = null;
  let day = null;

  dateString.replace(parseRegex, (wholeMatch, $1, $2, $3) => {
    year = $1;
    month = $2;
    day = $3;
  });
  return new Date(year, month - 1, day).getTime();
};

/**
 * Convert a date string to a relative name, such as "today" or "tomorrow"
 * @param date {number} Unix timestamp
 * @return {string}
 */
const getRelativeDayName = (date) => {
  const today = Date.now();
  if (dateFormat(today, 'isoDate') === dateFormat(date, 'isoDate')) {
    return 'Today';
  }

  const tomorrow = today + 24 * 60 * 60 * 1000;
  if (dateFormat(tomorrow, 'isoDate') === dateFormat(date, 'isoDate')) {
    return 'Tomorrow';
  }

  // e.g. "Thursday"
  return dateFormat(date, 'dddd');
};

/**
 * Get an array representing the months of the year.
 * @param y {number, string}
 * @returns {array}
 */
const getMonthsOfTheYearArray = (y) => {
  let year = y;
  if (!year) {
    return [...Array(12).keys()];
  }

  if (typeof year === 'string') {
    if (year.length === 0) {
      return [...Array(12).keys()];
    }

    year = parseInt(year, 10);
  }

  return moment().year() === year ? [...Array(moment().month() + 1).keys()] : [...Array(12).keys()];
};

/**
 * Get an array representing the days of the month.
 * Add additional element to the end and remove the first because day of the month is 1-indexed in
 * momentjs.
 * @param m {number, string}
 * @param y {number, string}
 * @returns daysOfTheMonth {array}
 */
const getDaysOfTheMonthArray = (m, y) => {
  let month = m;
  let year = y;

  if (!month) {
    month = 0;
  }

  if (!year) {
    year = 0;
  }

  if (typeof month === 'string') {
    if (month.length === 0) {
      month = 0;
    } else {
      month = parseInt(month, 10);
    }
  }

  if (typeof year === 'string') {
    if (year.length === 0) {
      year = 0;
    } else {
      year = parseInt(year, 10);
    }
  }

  let daysOfTheMonth = null;
  if (moment().month() === month && moment().year() === year) {
    daysOfTheMonth = [...Array(moment().date() + 1).keys()];
    daysOfTheMonth.shift();
    return daysOfTheMonth;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  daysOfTheMonth = [...Array(monthLength[month] + 1).keys()];
  daysOfTheMonth.shift();
  return daysOfTheMonth;
};

const thisYear = () => moment().year();

const isValidYear = (year) => isNumberBetween(year, EARLIEST_YEAR_ALLOWED, thisYear());

// Moment zero-indexes months
const isValidMonth = (month) => isNumberBetween(month, 0, 11);

const isValidDay = (day) => isNumberBetween(day, 1, 31);

const standardizeYear = (year) => {
  const yearString = typeof year === 'number' ? String(year) : year;

  let yearNumber = parseInt(yearString, 10);

  if (yearString.length === 2 && yearNumber === 0) {
    yearNumber = 2000;
  } else if (yearNumber > 0 && yearNumber < 100) {
    if (yearNumber <= thisYear() - 2000) {
      yearNumber += 2000;
    } else {
      yearNumber += 1900;
    }
  }

  return yearNumber;
};

const standardizeDate = (birthDate) => {
  const ymd = birthDate.split('-');
  return `${standardizeYear(ymd[0])}-${ymd[1].padStart(2, '0')}-${ymd[2].padStart(2, '0')}`;
};

const getAge = (birthDate) => {
  if (!birthDate) {
    return null;
  }

  if (moment().diff(birthDate, 'years') < 1) {
    return `${moment().diff(birthDate, 'months')}mo`;
  }

  return moment().diff(birthDate, 'years');
};

const getAgeOnDate = (birthDate, evaluationDate) => {
  if (!birthDate) {
    return null;
  }

  let dateToCompare = moment();

  if (evaluationDate) {
    dateToCompare = moment(evaluationDate);
  }

  if (dateToCompare.diff(birthDate, 'years') < 1) {
    return `${dateToCompare.diff(birthDate, 'months')}mo`;
  }

  return dateToCompare.diff(birthDate, 'years');
};

const getDaysSince = (someDate) => moment(someDate).diff(moment(), 'days');

const ensureMomentObject = (date) => (typeof date === 'string' ? moment(date) : date);

export {
  dateFormat,
  ensureMomentObject,
  getAge,
  getAgeOnDate,
  getDaysOfTheMonthArray,
  getDaysSince,
  getMonthsOfTheYearArray,
  getRelativeDayName,
  isValidYear,
  isValidMonth,
  isValidDay,
  parseSqlDate,
  parseSqlDateTime,
  standardizeYear,
  standardizeDate,
  thisYear,
};
