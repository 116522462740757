import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';
import { CopyToClipboard } from '../../primitives';
import { colors } from '../../../constants/colors';
import { isEmpty } from '../../../core/util/empty';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const StyledFormItem = styled(FormItem)`
  span {
    text-transform: capitalize;
    color: ${colors.notQuiteBlack};
    font-size: 13px;
    margin-left: 8px;
  }

  input,
  textarea {
    background-color: ${colors.theWhiteAlbum};
    color: ${colors.blueMonday};
    font-size: 15px;
    cursor: default;
    margin-left: 3px;
    height: 32px;
    padding: 6px 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

class TextAreaFormField extends PureComponent {
  shouldShowCopyIcon = () => this.props.showCopyIcon && !isEmpty(this.props.initialValue);

  render = () => {
    if (this.shouldShowCopyIcon()) {
      return (
        <StyledFormItem label={null} colon={false}>
          <CopyToClipboard
            copiableTextValue={this.props.form.getFieldValue(this.props.fieldName)}
            label={this.props.label}
            location={this.props.location}
            login={this.props.login}
          />
          {this.props.form.getFieldDecorator(this.props.fieldName, {
            initialValue: this.props.initialValue,
            rules: [
              {
                required: this.props.required,
                message: this.props.requiredMessage,
              },
              { validator: this.props.validator },
            ],
          })(
            <TextArea
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              rows={this.props.rows}
              data-hj-suppress
            />
          )}
        </StyledFormItem>
      );
    }

    return (
      <StyledFormItem label={this.props.label} colon={false}>
        {this.props.form.getFieldDecorator(this.props.fieldName, {
          initialValue: this.props.initialValue,
          rules: [
            {
              required: this.props.required,
              message: this.props.requiredMessage,
            },
            { validator: this.props.validator },
          ],
        })(
          <TextArea
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            rows={this.props.rows}
            data-hj-suppress
          />
        )}
      </StyledFormItem>
    );
  };
}

TextAreaFormField.defaultProps = {
  rows: 2,
  showCopyIcon: true,
  showFieldBorderOnHover: true,
};

TextAreaFormField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validator: PropTypes.func,
  rows: PropTypes.number,
  showCopyIcon: PropTypes.bool,
  login: PropTypes.object,
};

export default TextAreaFormField;
