import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetJson, apiPatchJson } from '../core/dapi';
import {
  getUnclaimedPlatformUsageAlertsUrl,
  getPlatformAlertsPatchUrl,
  getClaimedPlatformUsageAlertsUrl,
  getClearAllUnclaimedPlatformAlertsUrl,
  getClaimedTabFiltersUrl,
} from '../core/dapi/platformUsageAlerts';
import {
  receiveUnclaimedPlatformUsageAlerts,
  receiveClaimedPlatformUsageAlerts,
  platformUsageAlertsError,
  fetchingAlerts,
  receiveClaimedTabFilters,
} from '../ducks/platformUsageAlerts';
import { apiGetAllPages } from './core/base';

export class PlatformUsageAlertsSagas {
  static FETCH_UNCLAIMED_ALERTS = 'FETCH_UNCLAIMED_ALERTS';
  static FETCH_CLAIMED_ALERTS = 'FETCH_CLAIMED_ALERTS';
  static CLEAR_ALL_UNCLAIMED_ALERTS = 'CLEAR_ALL_UNCLAIMED_ALERTS';
  static CLEAR_ALERT = 'CLEAR_ALERT';
  static FETCH_CLAIMED_TAB_FILTERS = 'FETCH_CLAIMED_TAB_FILTERS';
}

export function* fetchUnclaimedAlerts() {
  yield put(fetchingAlerts(true));

  const response = yield call(apiGetAllPages, { url: getUnclaimedPlatformUsageAlertsUrl() });

  if (response.errors) {
    yield put(platformUsageAlertsError(response.errors));
  }

  if (response) {
    yield put(receiveUnclaimedPlatformUsageAlerts(response));
  }

  yield put(fetchingAlerts(false));
}

export function* fetchClaimedAlerts({ page = 1, filters = {} }) {
  yield put(fetchingAlerts(true));

  const response = yield call(apiGetJson, getClaimedPlatformUsageAlertsUrl(page, filters));

  if (response.errors) {
    yield put(platformUsageAlertsError(response.errors));
  }

  if (response.data) {
    yield put(receiveClaimedPlatformUsageAlerts(response.data));
  }

  yield put(fetchingAlerts(false));
}

export function* fetchClaimedTabFilters() {
  yield put(fetchingAlerts(true));

  const response = yield call(apiGetJson, getClaimedTabFiltersUrl());

  if (response.errors) {
    yield put(platformUsageAlertsError(response.errors));
  }

  if (response.data) {
    yield put(receiveClaimedTabFilters(response.data));
  }

  yield put(fetchingAlerts(false));
}

export function* clearAlert({ alert }) {
  const patchAlertsUrl = getPlatformAlertsPatchUrl(alert.id);

  const patchData = { clinic_account_username: alert.username };

  const response = yield call(apiPatchJson, patchAlertsUrl, patchData);

  if (response.errors) {
    yield put(platformUsageAlertsError(response.errors));
  }

  if (response.data) {
    yield put(fetchingAlerts(true));

    const alertsResponse = yield call(apiGetAllPages, {
      url: getUnclaimedPlatformUsageAlertsUrl(),
    });

    if (alertsResponse.errors) {
      yield put(platformUsageAlertsError(alertsResponse.errors));
    }

    if (alertsResponse) {
      yield put(receiveUnclaimedPlatformUsageAlerts(alertsResponse));
    }

    yield put(fetchingAlerts(false));
  }
}

export function* clearAllUnclaimedAlerts({ clinicAccount }) {
  const clearAllAlertsUrl = getClearAllUnclaimedPlatformAlertsUrl();

  const patchData = { clinic_account_username: clinicAccount.username };

  const response = yield call(apiPatchJson, clearAllAlertsUrl, patchData);

  if (response.errors) {
    yield put(platformUsageAlertsError(response.errors));
  }

  if (response.data) {
    yield put(fetchingAlerts(true));

    const alertsResponse = yield call(apiGetAllPages, {
      url: getUnclaimedPlatformUsageAlertsUrl(),
    });

    if (alertsResponse.errors) {
      yield put(platformUsageAlertsError(alertsResponse.errors));
    }

    if (alertsResponse) {
      yield put(receiveUnclaimedPlatformUsageAlerts(alertsResponse));
    }

    yield put(fetchingAlerts(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(PlatformUsageAlertsSagas.FETCH_UNCLAIMED_ALERTS, fetchUnclaimedAlerts);
  yield takeEvery(PlatformUsageAlertsSagas.FETCH_CLAIMED_ALERTS, fetchClaimedAlerts);
  yield takeEvery(PlatformUsageAlertsSagas.CLEAR_ALERT, clearAlert);
  yield takeEvery(PlatformUsageAlertsSagas.CLEAR_ALL_UNCLAIMED_ALERTS, clearAllUnclaimedAlerts);
  yield takeEvery(PlatformUsageAlertsSagas.FETCH_CLAIMED_TAB_FILTERS, fetchClaimedTabFilters);
}
