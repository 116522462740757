import React, { useState, useEffect, Component } from 'react';
import styled from 'styled-components';
import { array, bool, func, number, string } from 'prop-types';
import { Row } from 'antd';
import { diffArrays, EMPTY_ARRAY, isEmptyArray } from '../../../core/util/array';
import { emptyFunction } from '../../../core/util/function';
import { isEmptyString } from '../../../core/util/string';
import { EMPTY_STRING } from '../../../constants';
import Checkboxes from './components/Checkboxes';
import Header from './components/Header';
import { useDeepDiff } from '../../../hooks/useDeepDiff';

const Root = styled(Row)`
  width: 700px;
  max-width: 100%;

  .ant-popover-container {
    padding: 10px 20px;
  }

  .ant-input-suffix {
    display: none;
  }

  .ant-checkbox + span {
    position: relative;
    top: 2px;
  }

  .ant-input-affix-wrapper {
    height: 35px;
  }

  .ant-input {
    font-size: 14px;
  }
`;

const Message = styled.div`
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const FilterableCheckboxGroup = (props) => {
  const {
    disabledValues,
    emptyStateText,
    getLabel,
    getValue,
    isMultipleAllowed,
    limit,
    loading,
    onChange,
    onHasValueCallback,
    onePerLine,
    options,
    sortBy,
    value,
    visible,
    onButtonSubmit,
    shouldDisableButton,
    shouldShowLimitText,
    appliedOptions,
    selectedOptions,
    setSelectedOptions,
    clinicAccountsResetAddAllLocations,
    onBlur,
    showSelectAll,
  } = props;

  const [checkboxOptions, setCheckboxOptions] = useState(EMPTY_ARRAY);
  const [searchKeyword, setSearchKeyword] = useState(EMPTY_STRING);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [hasSetSelectedOptions, setHasSetSelectedOptions] = useState(false);

  useEffect(() => {
    const initialize = () => {
      if (!isEmptyArray(value)) onHasValueCallback(value);
      if (isEmptyArray(options) && isEmptyString(searchKeyword)) setCheckboxOptions(options);
    };

    if (!hasInitialized) {
      initialize();
      setHasInitialized(true);
    }
  }, [hasInitialized, onHasValueCallback, options, searchKeyword, value]);

  useEffect(() => {
    setCheckboxOptions(options);
  }, [options]);

  useDeepDiff(selectedOptions, (options) => {
    if (hasInitialized) {
      onChange(options);
    }
  });

  const shouldSetSelectedOptions =
    visible && !hasSetSelectedOptions && isEmptyArray(selectedOptions) && !isEmptyArray(value);

  useEffect(() => {
    if (shouldSetSelectedOptions) {
      setSelectedOptions(value);
      setHasSetSelectedOptions(true);
    }
  }, [
    hasSetSelectedOptions,
    selectedOptions,
    setSelectedOptions,
    shouldSetSelectedOptions,
    value,
    visible,
  ]);

  useEffect(() => {
    if (!visible) {
      setSelectedOptions(EMPTY_ARRAY);
      setSearchKeyword(EMPTY_STRING);
      setHasSetSelectedOptions(false);
      setHasInitialized(false);
    }
  }, [visible, setSelectedOptions]);

  const addOptionToSelectedOptions = (addedSelection) =>
    setSelectedOptions((prevSelectedOptions) => [...(prevSelectedOptions || []), addedSelection]);

  const removeOptionFromSelectedOptions = (removedSelection) => {
    const existingSelectedOptions = [...selectedOptions];
    existingSelectedOptions.splice(existingSelectedOptions.indexOf(removedSelection), 1);
    setSelectedOptions(existingSelectedOptions);
  };

  const handleChange = (updatedOption) => {
    clinicAccountsResetAddAllLocations();
    const shouldUnselectAllOptions = () =>
      Array.isArray(updatedOption) && isEmptyArray(updatedOption);
    const shouldSelectAllOptions = () =>
      Array.isArray(updatedOption) && checkboxOptions.length === updatedOption.length;
    const shouldSetSelectedOptionsToValue = () =>
      shouldSelectAllOptions() || shouldUnselectAllOptions();

    if (!isMultipleAllowed) {
      if (selectedOptions.includes(updatedOption)) return setSelectedOptions([]);
      return setSelectedOptions([updatedOption]);
    }

    if (shouldSetSelectedOptionsToValue()) return setSelectedOptions(updatedOption);

    if (!selectedOptions.includes(updatedOption)) {
      if (selectedOptions.length >= limit) return null;
      return addOptionToSelectedOptions(updatedOption);
    }

    return removeOptionFromSelectedOptions(updatedOption);
  };

  const getDisabledOptions = () => {
    if (selectedOptions.length < limit) return disabledValues;
    const unSelectedOptions = diffArrays(
      checkboxOptions.map(getValue),
      selectedOptions
    ).removedElements;
    return unSelectedOptions.concat(disabledValues);
  };

  const disabledOptions = getDisabledOptions();

  const handleUnselectAll = () => {
    setSelectedOptions([]);
  };

  return (
    <Root
      data-testid={'filterable-checkbox-group'}
      onBlur={() => {
        onBlur?.();
      }}
    >
      <Header
        disabledOptions={disabledOptions}
        getLabel={getLabel}
        getValue={getValue}
        handleChange={handleChange}
        handleUnselectAll={handleUnselectAll}
        isMultipleAllowed={isMultipleAllowed}
        limit={limit}
        options={options}
        onButtonSubmit={onButtonSubmit}
        selectedOptions={selectedOptions}
        setCheckboxOptions={setCheckboxOptions}
        setSearchKeyword={setSearchKeyword}
        shouldDisableButton={shouldDisableButton}
        shouldShowLimitText={shouldShowLimitText}
        showSelectAll={showSelectAll}
      />
      {checkboxOptions.length === 0 && (
        <Message>No results found, please refine your search.</Message>
      )}
      <Checkboxes
        checkboxOptions={checkboxOptions}
        emptyStateText={emptyStateText}
        getLabel={getLabel}
        getValue={getValue}
        handleChange={handleChange}
        loading={loading}
        onePerLine={onePerLine}
        options={options}
        selectedOptions={selectedOptions}
        disabledOptions={disabledOptions}
        appliedOptions={appliedOptions}
        sortBy={sortBy}
      />
    </Root>
  );
};

FilterableCheckboxGroup.defaultProps = {
  clinicAccountsResetAddAllLocations: emptyFunction,
  disabledValues: EMPTY_ARRAY,
  isMultipleAllowed: true,
  loading: false,
  onHasValueCallback: emptyFunction,
  emptyStateText: EMPTY_STRING,
  limit: Infinity,
  onePerLine: false,
  visible: true,
  shouldShowLimitText: false,
  selectedOptions: EMPTY_ARRAY,
  setSelectedOptions: emptyFunction,
  showSelectAll: true,
};

FilterableCheckboxGroup.propTypes = {
  appliedOptions: array,
  clinicAccountsResetAddAllLocations: func,
  disabledValues: array,
  emptyStateText: string,
  getLabel: func.isRequired,
  getValue: func.isRequired,
  isMultipleAllowed: bool,
  limit: number,
  loading: bool,
  onButtonSubmit: func,
  onChange: func,
  onHasValueCallback: func,
  onePerLine: bool,
  options: array,
  selectedOptions: array,
  setSelectedOptions: func,
  shouldDisableButton: bool,
  shouldShowLimitText: bool,
  sortBy: func,
  value: array,
  visible: bool,
  onBlur: func,
  showSelectAll: bool,
};

// From https://github.com/react-component/form/issues/287#issue-437622052
// eslint-disable-next-line react/no-multi-comp,react/prefer-stateless-function
export default class FilterableCheckboxGroupWrapper extends Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { children, ...props } = this.props;
    return <FilterableCheckboxGroup {...props}>{children}</FilterableCheckboxGroup>;
  }
}
