import { EMPTY_OBJECT } from '../core/util/object';
import { EMPTY_ARRAY, isObjectInArray } from '../core/util/array';
import { GET_BOOKING_SUCCESS } from '../constants';
import { isBoostPatient } from '../core/util/booking';

const TOGGLE_SOLV_PATIENT_BOOST_MODAL = 'TOGGLE_SOLV_PATIENT_BOOST_MODAL';
const PATIENT_BOOST_BOOKINGS_RECIEVED = 'PATIENT_BOOST_BOOKINGS_RECIEVED';
const PATIENT_BOOST_BOOKINGS_ERROR = 'PATIENT_BOOST_BOOKINGS_ERROR';
const SET_LOADING_PATIENT_BOOST_DATA = 'SET_LOADING_PATIENT_BOOST_DATA';
const NUMBER_OF_NEW_SOLV_BOOST_PATIENTS = 'NUMBER_OF_NEW_SOLV_BOOST_PATIENTS';

const getInitialState = () => ({
  toggleSolvPatientBoostModal: false,
  loadingBoostPatients: true,
  patientBoostBookingsRecieved: EMPTY_ARRAY,
  hasRecievedNewPatientBoost: false,
  numberOfNewSolvBoostPatients: 0,
});

const toggleSolvPatientBoostModal = value => ({
  type: TOGGLE_SOLV_PATIENT_BOOST_MODAL,
  payload: { value },
});

const patientBoostBookingsRecieved = value => ({
  type: PATIENT_BOOST_BOOKINGS_RECIEVED,
  payload: { value },
});

const patientBoostBookingsError = value => ({
  type: PATIENT_BOOST_BOOKINGS_ERROR,
  payload: { value },
});

const setLoadingPatientBoostData = value => ({
  type: SET_LOADING_PATIENT_BOOST_DATA,
  payload: { value },
});

const numberOfNewSolvBoostPatients = value => ({
  type: NUMBER_OF_NEW_SOLV_BOOST_PATIENTS,
  payload: { value },
});

const reducer = (state = getInitialState(), action = EMPTY_OBJECT) => {
  switch (action.type) {
    case TOGGLE_SOLV_PATIENT_BOOST_MODAL:
      return {
        ...state,
        toggleSolvPatientBoostModal: action.payload.value,
        hasRecievedNewPatientBoost: false,
        numberOfNewSolvBoostPatients: 0,
      };

    case PATIENT_BOOST_BOOKINGS_RECIEVED:
      return {
        ...state,
        patientBoostBookingsRecieved: action.payload.value,
      };
    case PATIENT_BOOST_BOOKINGS_ERROR:
      return {
        ...state,
        patientBoostBookingsError: action.payload.value,
      };
    case SET_LOADING_PATIENT_BOOST_DATA:
      return {
        ...state,
        loadingBoostPatients: action.payload.value,
      };
    case GET_BOOKING_SUCCESS: {
      if (
        isBoostPatient(action.payload.value) &&
        !isObjectInArray(
          action.payload.value,
          state.patientBoostBookingsRecieved,
          booking => booking.id
        )
      ) {
        return {
          ...state,
          patientBoostBookingsRecieved: [
            ...state.patientBoostBookingsRecieved,
            action.payload.value,
          ],
          numberOfNewSolvBoostPatients: state.numberOfNewSolvBoostPatients + 1,
          hasRecievedNewPatientBoost: true,
        };
      }

      return state;
    }
    case NUMBER_OF_NEW_SOLV_BOOST_PATIENTS:
      return {
        ...state,
        numberOfNewSolvBoostPatients: action.payload.value,
        hasRecievedNewPatientBoost: true,
      };
    default:
      return state;
  }
};

export {
  reducer as default,
  getInitialState,
  toggleSolvPatientBoostModal,
  patientBoostBookingsRecieved,
  patientBoostBookingsError,
  setLoadingPatientBoostData,
  numberOfNewSolvBoostPatients,
};
