export type DapiResults<T> = {
  results: T[];
  page: {
    limit: number;
    links: Array<{
      href: string;
      rel: string;
    }>;
    page: number;
    results_count?: number;
  };
} & DapiErrors;

export type DapiDataResults<T> = {
  data: DapiResults<T>;
  meta?: DapiMeta;
};

export type DapiDataV1Results<T> = {
  data: T[];
  meta: DapiMeta;
};

export function EmptyDapiDataResults<T>(): DapiDataResults<T> {
  return {
    data: {
      results: [],
      page: {
        limit: 0,
        links: [],
        page: 0,
        results_count: 0,
      },
    },
    meta: {
      api_version: 0,
      clinic_account_id: '',
      env: '',
      is_authenticated: false,
      is_clinic: false,
      processing_time: 0,
      roles: [],
    },
  };
}

export function EmptyDapiSingleResult<T>(data: T): DapiSingleResult<T> {
  return {
    data: data,
    meta: {
      api_version: 0,
      clinic_account_id: '',
      env: '',
      is_authenticated: false,
      is_clinic: false,
      processing_time: 0,
      roles: [],
    },
  };
}

export type DapiSingleResult<T> = {
  data: T;
  meta: DapiMeta;
} & DapiErrors;

export type DapiErrors = { errors?: DapiError[] };

export function EmptyDapiResults<T>(): DapiResults<T> {
  return {
    results: [],
    page: {
      limit: 0,
      links: [],
      page: 0,
      results_count: 0,
    },
  };
}

export function assertNoError(error: DapiErrors) {
  if (error?.errors) {
    const entry = error.errors[0];
    throw new Error(entry.message ?? entry.description);
  }
}

export type DapiError = {
  code: number;
  description?: string;
  status: string;
  message?: string;
};

export type DapiMeta = {
  api_version: number;
  clinic_account_id: string;
  env: string;
  is_authenticated: boolean;
  is_clinic: boolean;
  processing_time: number;
  roles: string[];
};
