import {
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR,
  UPDATE_BOOKING_SUBMITTING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  BOOKINGS_LOADING,
  QUEUE_SET_TAB,
  SET_RUNTIME_VARIABLE,
  CLEAR_BOOKING_ERROR,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  CLEAR_MOST_RECENT_BOOKING,
  QUEUE_TOGGLE_NOTES_MODAL,
  QUEUE_SET_LOCATION_IDS,
  QUEUE_ADD_LOCATION_IDS,
} from '../constants';
import { PubNubEvent, PubNubListeners, PubNubSource } from '../components/Pubnub/usePubNub';

const getUserProfileSuccess = (value) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: { value },
});

const getUserProfileError = (value) => ({
  type: GET_USER_PROFILE_ERROR,
  payload: { value },
});

const getBookingSuccess = (value) => (dispatch, getState) => {
  const selectedLocationIds = getState().queue.selectedLocationIds;
  dispatch({
    type: GET_BOOKING_SUCCESS,
    payload: { value, selectedLocationIds },
  });
};

const getBookingError = (value) => ({
  type: GET_BOOKING_ERROR,
  payload: { value },
});

const updateBookingSubmitting = (value) => ({
  type: UPDATE_BOOKING_SUBMITTING,
  payload: { value },
});

const updateBookingSuccess = (value) => (dispatch, getState) => {
  const selectedLocationIds = getState().queue.selectedLocationIds;
  PubNubListeners.dispatch({
    type: PubNubEvent.BookingUpdated,
    payload: value,
    source: PubNubSource.Local,
  });
  dispatch({
    type: UPDATE_BOOKING_SUCCESS,
    payload: { value, selectedLocationIds },
  });
};

const updateUserProfileSuccess = (value) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: { value },
});

const updateUserProfileError = (value) => ({
  type: UPDATE_USER_PROFILE_ERROR,
  payload: { value },
});

const updateAccountSuccess = (value) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: { value },
});

const updateAccountError = (value) => ({
  type: UPDATE_ACCOUNT_ERROR,
  payload: { value },
});

const updateBookingError = (value) => ({
  type: UPDATE_BOOKING_ERROR,
  payload: { value },
});

const queueSetLocationIds = (value) => ({
  type: QUEUE_SET_LOCATION_IDS,
  payload: { value },
});

const queueAddLocationIds = (value) => ({
  type: QUEUE_ADD_LOCATION_IDS,
  payload: { value },
});

const setLoadingBookings = (value, queueTab) => ({
  type: BOOKINGS_LOADING,
  payload: { value, queueTab },
});

const setTab = (value) => ({
  type: QUEUE_SET_TAB,
  payload: { value },
});

const setSearchKeyword = (value) => ({
  type: SET_RUNTIME_VARIABLE,
  payload: { name: 'queueSearchKeyword', value },
});

const clearSearchKeyword = () => ({
  type: SET_RUNTIME_VARIABLE,
  payload: { name: 'queueSearchKeyword', value: null },
});

const createBookingSuccess = (value) => ({
  type: CREATE_BOOKING_SUCCESS,
  payload: { value },
});

const createBookingError = (value) => ({
  type: CREATE_BOOKING_ERROR,
  payload: { value },
});

const clearBookingError = () => ({
  type: CLEAR_BOOKING_ERROR,
});

const clearMostRecentBooking = () => ({
  type: CLEAR_MOST_RECENT_BOOKING,
});

const toggleNotesModal = (value) => ({
  type: QUEUE_TOGGLE_NOTES_MODAL,
  payload: { value },
});

export {
  queueSetLocationIds,
  queueAddLocationIds,
  getBookingSuccess,
  getBookingError,
  updateBookingSuccess,
  updateBookingSubmitting,
  updateBookingError,
  setLoadingBookings,
  setTab,
  setSearchKeyword,
  clearBookingError,
  clearMostRecentBooking,
  clearSearchKeyword,
  createBookingSuccess,
  createBookingError,
  updateUserProfileSuccess,
  updateUserProfileError,
  updateAccountSuccess,
  updateAccountError,
  getUserProfileSuccess,
  getUserProfileError,
  toggleNotesModal,
};
