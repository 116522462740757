import { AccountLocation } from '../../types/RootState';

export const LocationFormatters = {
  formatAccountLocations: (locations: AccountLocation[]): AccountLocation[] => {
    return locations.map((l) => {
      l.$type = 'account.location';
      return l;
    });
  },
};
