import {
  LOOK_UP_SET_TYPE,
  LOOK_UP_TYPE_PHONE,
  LOOK_UP_SET_SUBMITTING,
  LOOK_UP_BOOKINGS_RECEIVED,
  LOOK_UP_BOOKINGS_ERROR,
  LOOK_UP_USER_PROFILES_RECEIVED,
  LOOK_UP_USER_PROFILES_ERROR,
} from '../constants';

const createInitialState = () => ({
  lookUpType: LOOK_UP_TYPE_PHONE,
});

export default function lookUpReducer(state = createInitialState(), action) {
  switch (action.type) {
    case LOOK_UP_SET_TYPE:
      return {
        ...state,
        lookUpType: action.payload.value,
      };
    case LOOK_UP_SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    case LOOK_UP_BOOKINGS_RECEIVED:
      return {
        ...state,
        bookings: action.payload.value.results,
      };
    case LOOK_UP_BOOKINGS_ERROR:
      return {
        ...state,
        bookings: { error: action.payload.value },
      };
    case LOOK_UP_USER_PROFILES_RECEIVED:
      return {
        ...state,
        userProfiles: action.payload.value.results,
      };
    case LOOK_UP_USER_PROFILES_ERROR:
      return {
        ...state,
        userProfiles: { error: action.payload.value },
      };
    default:
      return state;
  }
}
