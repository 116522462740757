import { AccountLocation, Location } from './RootState';

export function isAccountLocation(
  location: Location | AccountLocation
): location is AccountLocation {
  return location != null && '$type' in location && location.$type === 'account.location';
}

export function isLocation(location: Location | AccountLocation): location is Location {
  return location != null && '$type' in location && location.$type === 'location';
}
