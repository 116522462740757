import {
  CURRENT_PATIENT_ELIGIBLIITY_REQUEST_ID_RECEIVED,
  CURRENT_PATIENT_PAYMENTS_INSURANCE_ERROR,
  CURRENT_PATIENT_PAYMENTS_INSURANCE_RECEIVED,
  GET_BOOKING_SUCCESS,
  GET_CURRENT_PATIENT_ACCOUNT_SUMMARY,
  GET_CURRENT_PATIENT_ACCOUNT_SUMMARY_ERROR,
  GET_CURRENT_PATIENT_BOOKING_DETAILS,
  GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS,
  GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS,
  RESET_CURRENT_PATIENT,
  SET_CURRENT_PATIENT_BOOKING_SUCCESS,
  SET_CURRENT_PATIENT_BOOKING_ERROR,
  CURRENT_PATIENT_INSURANCE_SUMMARY_CLEAR,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  CURRENT_PATIENT_CONSENT_FORM_SIGNATURES_ERROR,
  CURRENT_PATIENT_RECEIVE_CONSENT_FORM_SIGNATURES,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_URL,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_URL,
  CURRENT_PATIENT_PHOTO_ID_URL_ERROR,
  PARTIAL_UPDATE_EXISTING_BOOKING,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_METADATA,
  CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_METADATA,
} from '../constants';

export default function addPatientReducer(state = {}, action) {
  switch (action.type) {
    case CURRENT_PATIENT_ELIGIBLIITY_REQUEST_ID_RECEIVED: {
      return {
        ...state,
        eligibility: {
          id: action.payload.value,
        },
      };
    }

    case CURRENT_PATIENT_PAYMENTS_INSURANCE_RECEIVED: {
      return {
        ...state,
        insuranceSummary: action.payload.value,
      };
    }

    case CURRENT_PATIENT_INSURANCE_SUMMARY_CLEAR:
      return {
        ...state,
        insuranceSummary: {},
        insuranceProfile: {},
      };

    case CURRENT_PATIENT_PAYMENTS_INSURANCE_ERROR:
      return {
        ...state,
        insuranceSummary: {
          error: action.payload.value,
        },
      };

    case RESET_CURRENT_PATIENT:
      return {
        ...{},
      };
    case SET_CURRENT_PATIENT_BOOKING_SUCCESS:
      return {
        ...state,
        booking: { ...action.payload.value },
      };
    case SET_CURRENT_PATIENT_BOOKING_ERROR:
      return {
        ...state,
        booking: { error: action.payload.value },
      };
    case GET_CURRENT_PATIENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: { ...action.payload.value },
      };
    case GET_CURRENT_PATIENT_INSURANCE_PROFILE_SUCCESS:
      return {
        ...state,
        insuranceProfile: { ...action.payload.value },
        booking: {
          ...state.booking,
          insurance_profile_id: action.payload.value.id,
        },
      };
    case GET_CURRENT_PATIENT_DENTAL_INSURANCE_PROFILE_SUCCESS:
      return {
        ...state,
        dentalInsuranceProfile: { ...action.payload.value },
      };
    case GET_CURRENT_PATIENT_INSURANCE_FRONT_CARD_IMAGE_SUCCESS:
      return {
        ...state,
        insuranceProfile: {
          ...state.insuranceProfile,
          front_image_url: action.payload.value.image_url,
        },
      };
    case GET_CURRENT_PATIENT_DENTAL_INSURANCE_FRONT_CARD_IMAGE_SUCCESS:
      return {
        ...state,
        dentalInsuranceProfile: {
          ...state.dentalInsuranceProfile,
          front_image_url: action.payload.value.image_url,
        },
      };
    case GET_CURRENT_PATIENT_DENTAL_INSURANCE_BACK_CARD_IMAGE_SUCCESS:
      return {
        ...state,
        dentalInsuranceProfile: {
          ...state.dentalInsuranceProfile,
          back_image_url: action.payload.value.image_url,
        },
      };
    case GET_CURRENT_PATIENT_INSURANCE_BACK_CARD_IMAGE_SUCCESS:
      return {
        ...state,
        insuranceProfile: {
          ...state.insuranceProfile,
          back_image_url: action.payload.value.image_url,
        },
      };
    case PARTIAL_UPDATE_EXISTING_BOOKING:
    case GET_BOOKING_SUCCESS:
      if (state.booking && state.booking.id === action.payload.value.id) {
        return {
          ...state,
          booking: action.payload.value,
        };
      }

      return state;
    case GET_CURRENT_PATIENT_ACCOUNT_SUMMARY:
      return {
        ...state,
        summary: action.payload.value,
      };
    case GET_CURRENT_PATIENT_ACCOUNT_SUMMARY_ERROR:
      return {
        ...state,
        summary: {
          error: action.payload.value,
        },
      };
    case GET_CURRENT_PATIENT_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: action.payload.value,
      };
    case CURRENT_PATIENT_RECEIVE_CONSENT_FORM_SIGNATURES:
      return {
        ...state,
        consentForms: action.payload.value.results,
      };
    case CURRENT_PATIENT_CONSENT_FORM_SIGNATURES_ERROR:
      return {
        ...state,
        consentForms: {
          error: action.payload.value,
        },
      };
    case CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_URL:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          photo_id_front_url: action.payload.value,
        },
      };
    case CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_URL:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          photo_id_back_url: action.payload.value,
        },
      };
    case CURRENT_PATIENT_RECEIVE_PHOTO_ID_FRONT_METADATA:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          photo_id_front_metadata: action.payload.value,
        },
      };
    case CURRENT_PATIENT_RECEIVE_PHOTO_ID_BACK_METADATA:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          photo_id_back_metadata: action.payload.value,
        },
      };
    case CURRENT_PATIENT_PHOTO_ID_URL_ERROR:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          photo_id_url_error: action.payload.value,
        },
      };
    default:
      return state;
  }
}
