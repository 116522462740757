import { useForceHttps } from '../hooks/useForceHttps';
import { useHeartBeat } from '../hooks/useHeartBeat';
import { useEffect } from 'react';
import { analyticsTrackEvent, analyticsTrackPage } from '../core/analytics';
import { MANAGE_LOADED } from '../core/analytics/events';
import { useRouter } from 'next/router';

export default function Global() {
  useForceHttps();
  useHeartBeat();
  usePageTracking();

  useEffect(() => {
    analyticsTrackEvent(MANAGE_LOADED, {});
  }, []);

  return null;
}

function usePageTracking() {
  const router = useRouter();

  useEffect(() => {
    analyticsTrackPage(undefined, undefined, {
      path: router.pathname,
      asPath: router.asPath,
    });
  }, [router.asPath, router.pathname]);
}
