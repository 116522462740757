import {
  LOCATION_RESPONSE_RECEIVED,
  LOCATION_FAILED,
  LOADING_LOCATION,
  LOGIN_LOGOUT,
  LOCATION_PATCH_SUCCESS,
  LOCATION_PATCH_STATUS_CLEAR,
  LOCATION_WAIT_LIST_COUNT_RECEIVED,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_PRACTICES,
  LOCATION_RECEIVE_SPECIAL_OPERATING_HOURS,
  LOCATION_RECEIVE_OPERATING_HOURS,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS,
  LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
  RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR,
  LOADING_LOCATION_SERVICE_PREFERENCES,
} from '../constants';

export default function locationReducer(state = {}, action) {
  switch (action.type) {
    case LOCATION_RESPONSE_RECEIVED:
      return action.payload.value;
    case LOCATION_FAILED:
      return {
        ...state,
        error: action.payload.value,
      };
    case LOADING_LOCATION:
      return {
        loading: true,
      };
    case LOCATION_PATCH_SUCCESS:
      return {
        ...state,
        success: action.payload.value,
      };
    case LOCATION_PATCH_STATUS_CLEAR:
      return {
        ...state,
        success: null,
        error: null,
      };
    case LOCATION_WAIT_LIST_COUNT_RECEIVED:
      return {
        ...state,
        waitListCount: action.payload.value,
      };
    case LOADING_LOCATION_SERVICE_PREFERENCES:
      return {
        ...state,
        servicePreferences: {
          ...state.servicePreferences,
          isLoading: true,
        },
      };
    case LOCATION_RECEIVE_SERVICE_PREFERENCES_PRACTICES: {
      const newPractices = action.payload.value.service_preferences.practices;
      const oldPractices = state.servicePreferences?.practices;
      return {
        ...state,
        servicePreferences: {
          ...state.servicePreferences,
          // Try to keep practices null or an object
          practices:
            typeof newPractices === 'object' ||
            newPractices === null ||
            typeof oldPractices === 'undefined'
              ? newPractices
              : oldPractices,
          isLoading: false,
        },
      };
    }
    case LOCATION_RECEIVE_SERVICE_PREFERENCES_APPOINTMENTS:
      return {
        ...state,
        servicePreferences: {
          ...state.servicePreferences,
          appointments: action.payload.value.service_preferences.appointments,
        },
      };
    case LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR:
      return {
        ...state,
        servicePreferences: {
          ...state.servicePreferences,
          error: action.payload.value,
        },
      };
    case RESET_LOCATION_RECEIVE_SERVICE_PREFERENCES_ERROR:
      return {
        ...state,
        servicePreferences: {
          ...state.servicePreferences,
          error: null,
        },
      };
    case LOCATION_RECEIVE_SPECIAL_OPERATING_HOURS:
      return {
        ...state,
        specialOperatingHours: action.payload.value.results,
      };
    case LOCATION_RECEIVE_OPERATING_HOURS:
      return {
        ...state,
        hours: action.payload.value.hours,
      };
    case LOGIN_LOGOUT:
      return {};
    default:
      return state;
  }
}
