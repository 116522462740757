import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';

const getAccountsUrl = filters =>
  `${DAPI_HOST}/v1/accounts?${getFilterString('accounts', filters)}`;

const getAccountByIdUrl = accountId => `${DAPI_HOST}/v1/accounts/${accountId}`;

const getAccountByPhoneUrl = phone => `${DAPI_HOST}/v1/accounts/phone-number/${phone}`;

const getAccountDeidentificationByIdUrl = accountId =>
  `${DAPI_HOST}/v1/accounts/${accountId}/de-identify`;

export {
  getAccountsUrl,
  getAccountByIdUrl,
  getAccountByPhoneUrl,
  getAccountDeidentificationByIdUrl,
};
