import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSolvEmployee } from '../../core/session';
import { getLocationsFromIds } from '../../core/util/location';
import LocationsSelector from '../Form/LocationsSelector';
import { getQueueSelectedLocationIds } from '../../selectors/queue';
import { getLogin } from '../../selectors/login';
import { selectors as locationsSelectors } from '../../ducks/locations';

const ModalLocationPicker = (props) => {
  const {
    login,
    selectedLocationIds,
    handleSelectLocation,
    currentModalLocationId,
    showAllLocations,
    className,
    getLocationConversations,
    showConversationCounts,
  } = props;

  /** @type {object[]} */
  const allLocations = login.account.locations;

  const allLocationsWithoutFacades = useMemo(
    () => allLocations?.filter((loc) => loc.facade_type == null) ?? [],
    [allLocations]
  );

  const locationOptions = showAllLocations
    ? allLocationsWithoutFacades
    : getLocationsFromIds(allLocationsWithoutFacades, selectedLocationIds);
  const locationOptionsWithMessageCount = locationOptions.map((location) => ({
    ...location,
    unreadCount: getLocationConversations(location.location_id)?.unreadCount || 0,
  }));

  const sortBy = showConversationCounts
    ? (a, b) => {
        const aUnreads = a.unreadCount || 0;
        const bUnreads = b.unreadCount || 0;
        return bUnreads - aUnreads;
      }
    : null;

  return (
    <LocationsSelector
      defaultSelectedLocation={currentModalLocationId}
      isSolvEmployee={isSolvEmployee(login)}
      locationOptions={locationOptionsWithMessageCount}
      shouldShowTitle={false}
      onLocationSelectValueChange={handleSelectLocation}
      showConversationCounts={showConversationCounts}
      className={className}
      sortBy={sortBy}
    />
  );
};

ModalLocationPicker.propTypes = {
  login: PropTypes.object,
  selectedLocationIds: PropTypes.array,
  handleSelectLocation: PropTypes.func,
  currentModalLocationId: PropTypes.string,
  showAllLocations: PropTypes.bool,
  showConversationCounts: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  login: getLogin(state),
  selectedLocationIds: Array.isArray(ownProps.selectedLocationIds)
    ? ownProps.selectedLocationIds
    : getQueueSelectedLocationIds(state),
  getLocationConversations: (locationId) =>
    locationsSelectors.getLocationConversations(state, locationId),
});

export default connect(mapStateToProps)(ModalLocationPicker);
