import { EMPTY_OBJECT } from '../core/util/object';

const SHOW_VIDEO_VISIT_MODAL = 'videoVisitModal/show';
const HIDE_VIDEO_VISIT_MODAL = 'videoVisitModal/hide';
const SET_VIDEO_VISIT_BOOKING = 'videoVisitModal/setBooking';
const RESET_VIDEO_VISIT_BOOKING = 'videoVisitModal/resetBooking';

const reducer = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  switch (action.type) {
    case SHOW_VIDEO_VISIT_MODAL:
      return {
        ...state,
        modalVisible: true,
      };
    case HIDE_VIDEO_VISIT_MODAL:
      return {
        ...state,
        modalVisible: false,
      };
    case SET_VIDEO_VISIT_BOOKING:
      return {
        ...state,
        booking: action.payload.value,
      };
    case RESET_VIDEO_VISIT_BOOKING:
      return {
        ...state,
        booking: EMPTY_OBJECT,
      };
    default:
      return state;
  }
};

const videoVisitModalShow = () => ({ type: SHOW_VIDEO_VISIT_MODAL });

const videoVisitModalHide = () => ({ type: HIDE_VIDEO_VISIT_MODAL });

const videoVisitModalSetBooking = value => ({
  type: SET_VIDEO_VISIT_BOOKING,
  payload: { value },
});

const videoVisitModalResetBooking = () => ({ type: RESET_VIDEO_VISIT_BOOKING });

export {
  reducer as default,
  videoVisitModalShow,
  videoVisitModalHide,
  videoVisitModalSetBooking,
  videoVisitModalResetBooking,
};
