import React, { PureComponent } from 'react';
import Notifications from './Notifications';
import NotificationsContext from './NotificationsContext';

type Props = {
  swRegistration: Promise<any>;
  applicationServerKey: string;
  children: any;
};

type State = {
  notificationsSupported: boolean;
  notificationsSubscription: any;
  notifications: any;
};

export default class NotificationsProvider extends PureComponent<Props, State> {
  private removeListener: (() => void) | null;
  private _isMounted: boolean = false;

  constructor(props: any) {
    super(props);

    this.removeListener = null;

    this.state = {
      notificationsSupported: false,
      notificationsSubscription: null,
      notifications: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setup();
  }

  componentDidUpdate(prevProps: Props) {
    const { swRegistration, applicationServerKey } = this.props;

    if (
      swRegistration !== prevProps.swRegistration ||
      applicationServerKey !== prevProps.applicationServerKey
    ) {
      this.setup();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.removeListener) {
      this.removeListener();
    }
  }

  setup() {
    const { swRegistration, applicationServerKey } = this.props;

    if (swRegistration) {
      swRegistration
        .then(async (swReg) => {
          if (!this._isMounted) {
            return;
          }

          if (this.removeListener) {
            this.removeListener();
            this.removeListener = null;
          }

          const notifications = new Notifications(swReg.pushManager, applicationServerKey);
          notifications.listen();
          this.removeListener = notifications.onSubscriptionChange((newSubscription: any) => {
            this.setState({
              ...this.state,
              notificationsSubscription: newSubscription,
            });
          });

          const subscription = await notifications.getSubscription();

          this.setState({
            ...this.state,
            notificationsSupported: true,
            notificationsSubscription: subscription,
            notifications,
          });
        })
        .catch(() => {}); // silently fail in browsers that don't use service-workers
    }
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Readonly<any>' is not assignable to type 'nu... Remove this comment to see the full error message
      <NotificationsContext.Provider value={this.state}>
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}
