import { DAPI_CACTUS_HOST, DAPI_HOST } from '../../config';

const getInvoicesUrl = () => `${DAPI_CACTUS_HOST}/v1/invoices`;

const getInvoiceUrl = invoiceId => `${DAPI_HOST}/v1/invoices/${invoiceId}`;

const getInvoiceResendUrl = invoiceId => `${DAPI_HOST}/v1/invoices/${invoiceId}/resend`;

const getPaymentsSummaryUrl = () => `${DAPI_CACTUS_HOST}/v1/invoices/summary`;

const getStubBookingUrl = () => `${DAPI_HOST}/v1/invoice-stub-bookings`;

const getPaymentCardUrl = accountId => `${DAPI_HOST}/v1/payment-customers/cards/${accountId}`;

const deletePaymentCardUrl = accountId => `${DAPI_HOST}/v1/payment-customers/cards/${accountId}`;

export {
  getInvoiceUrl,
  getInvoicesUrl,
  getInvoiceResendUrl,
  getPaymentCardUrl,
  getPaymentsSummaryUrl,
  getStubBookingUrl,
  deletePaymentCardUrl,
};
