import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useDebouncedCallback } from 'use-debounce';

/**
 * Does a deep comparison against 'value' and calls onChange if it changes between updates.
 * Please avoid using this with large complex objects, the deep equals check is expensive.
 * @param value
 * @param onChange
 * @param opts
 */
export function useDeepDiff<T>(
  value: T,
  onChange: (value: T, prev: T) => void,
  opts?: { debounce: number }
) {
  const ref = useRef<T | undefined>();
  const first = useRef(true);
  const callbackRef = useRef(onChange);

  const debounceOnChange = useDebouncedCallback((value: T, prev: T) => {
    callbackRef.current(value, prev);
  }, opts?.debounce ?? 0);

  useEffect(() => {
    callbackRef.current = onChange;
  });

  useEffect(() => {
    if (first.current) {
      debounceOnChange(value, ref.current as T);
      ref.current = value;
      first.current = false;
      return;
    }
    if (!isEqual(value, ref.current)) {
      debounceOnChange(value, ref.current as T);
      ref.current = value;
    }
  }, [debounceOnChange, value]);
}
