import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetAllPages } from './core/base';
import { setRuntimeVariable } from '../actions/runtime';
import { RuntimeAttributes } from '../reducers/runtime';
import { getUclBrandsUrl } from '../core/dapi/uclBrands';
import { uclBrandsReceived, uclBrandsError } from '../ducks/uclBrands';

export class UclBrandsSagas {
  static FETCH_UCL_BRANDS = 'SAGA/FETCH_UCL_BRANDS';
}

export function* fetchUclBrands() {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_UCL_BRANDS, value: true }));

  const response = yield call(apiGetAllPages, { url: getUclBrandsUrl() });

  if (response.errors) {
    yield put(uclBrandsError(response.errors));
  } else {
    yield put(uclBrandsReceived(response));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_UCL_BRANDS, value: false }));
}

export default function* rootSaga() {
  yield takeEvery(UclBrandsSagas.FETCH_UCL_BRANDS, fetchUclBrands);
}
