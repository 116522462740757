import { EMPTY_ARRAY } from '../core/util/array';

export class SpecialtiesActionTypes {
  static SPECIALTIES_RECEIVED = 'SPECIALTIES_RECEIVED';
  static SPECIALTIES_ERROR = 'SPECIALTIES_ERROR';
}

export const specialtiesReceived = value => ({
  type: SpecialtiesActionTypes.SPECIALTIES_RECEIVED,
  payload: { value },
});

export const specialtiesError = value => ({
  type: SpecialtiesActionTypes.SPECIALTIES_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case SpecialtiesActionTypes.SPECIALTIES_RECEIVED:
      return action.payload.value.results;
    case SpecialtiesActionTypes.SPECIALTIES_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
