import { Middleware } from 'redux';
import { QUEUE_SET_LOCATION_IDS } from '../../constants';

export const localStorageMiddleware: Middleware = store => next => action => {
// Let the action pass through as normal
  const result = next(action);

// After the action has been handled by the reducers
  if (action.type === QUEUE_SET_LOCATION_IDS) {
    // Store the location IDs in local storage
    localStorage.setItem('selectedLocationIds', JSON.stringify(action.payload.value));
  }

  return result;
};