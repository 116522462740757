/* eslint-disable max-len */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Select, Col, Row, Input } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import {
  getMonthsOfTheYearArray,
  getDaysOfTheMonthArray,
  isValidYear,
  isValidMonth,
  isValidDay,
  thisYear,
  standardizeYear,
} from '../../core/util/date';
import { isNumberBetween } from '../../core/util/number';
import { EARLIEST_YEAR_ALLOWED } from '../../config';
import { isEmptyString, stripNonnumeric } from '../../core/util/string';
import { isEmptyObject } from '../../core/util/object';
import { EMPTY_STRING } from '../../constants';

const StyledYearInputHolder = styled(Col)`
  line-height: 49px;
  .ant-input.birth-date-year-input {
    width: auto;
  }
`;

const MONTHS = [
  { key: 0, label: 'January' },
  { key: 1, label: 'February' },
  { key: 2, label: 'March' },
  { key: 3, label: 'April' },
  { key: 4, label: 'May' },
  { key: 5, label: 'June' },
  { key: 6, label: 'July' },
  { key: 7, label: 'August' },
  { key: 8, label: 'September' },
  { key: 9, label: 'October' },
  { key: 10, label: 'November' },
  { key: 11, label: 'December' },
];

const Option = Select.Option;

const dateDelimiter = '-';

const stringDateToObject = date => {
  const splitDate = date.split(dateDelimiter);

  const year = parseInt(splitDate[0], 10);
  const month = parseInt(splitDate[1], 10) - 1; // Moment zero-indexes months
  const day = parseInt(splitDate[2], 10);

  return { year, month, day };
};

const dateObjectToString = date => {
  let month = date.month;
  if (month || month === 0) {
    month = parseInt(month, 10) + 1; // Moment zero-indexes months
  }

  return `${date.year}-${month}-${date.day}`;
};

export const sanitizeDateString = date => {
  const splitDate = date.split(dateDelimiter);

  const year = standardizeYear(splitDate[0]);
  const month = splitDate[1].length === 2 ? splitDate[1] : `0${splitDate[1]}`;
  const day = splitDate[2].length === 2 ? splitDate[2] : `0${splitDate[2]}`;

  return [year, month, day].join(dateDelimiter);
};

export const sanitizeMaskedBirthDateString = (date = EMPTY_STRING) =>
  `${date.substr(4)}-${date.substr(0, 2)}-${date.substr(2, 2)}`;

// Keeping this logic in here
export const dateValidator = (required = true) => (_, value, callback) => {
  if (required && !value) {
    callback('Please enter valid date');
    return;
  }

  if (required || value) {
    const { year, month, day } = stringDateToObject(value);
    const invalidYearMessage = `Please input a year between ${EARLIEST_YEAR_ALLOWED}
      and ${thisYear()} (two-digit years such as '14' are allowed)`;

    // When creating a moment from a string, months are 1-indexed,
    // while otherwise they are 0-indexed
    const standardizedMonth = month + 1;

    if (!year && !standardizedMonth && !day) {
      callback('Please enter valid date');
      return;
    }

    if (!standardizedMonth) {
      callback('Please input a month');
      return;
    }

    if (!day) {
      callback('Please input a day');
      return;
    }

    if (!year) {
      callback('Please input a year');
      return;
    }

    if (!(String(year).length === 2 || String(year).length === 4)) {
      callback(invalidYearMessage);
      return;
    }

    const standardizedYear = standardizeYear(year);

    if (!isValidYear(standardizedYear)) {
      callback(invalidYearMessage);
      return;
    }

    const formattedDate = `${standardizedYear}-${standardizedMonth}-${day}`;
    const date = moment(formattedDate, 'YYYY-M-D');
    if (!date.isValid()) {
      callback('Please input a valid date');
      return;
    }
  }

  callback();
};

export default class BirthDateInput extends Component {
  constructor(props) {
    super(props);
    if (this.props.value) {
      this.state = {};
      this.setStateFromProps(this.props);
    } else {
      this.state = {
        month: null,
        day: null,
        year: null,
      };
    }

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleYearOnBlur = this.handleYearOnBlur.bind(this);
  }

  setStateFromProps(props) {
    if (isEmptyString(props.value)) {
      return;
    }

    const stateUpdate = {};
    const { year, month, day } = stringDateToObject(props.value);

    if (isValidYear(year)) {
      stateUpdate.year = year;
    }

    if (isValidMonth(month)) {
      stateUpdate.month = month;
    }

    if (isValidDay(day)) {
      stateUpdate.day = day;
    }

    if (!isEmptyObject(stateUpdate)) {
      this.setState(stateUpdate);
    }
  }

  getMonthOptions() {
    return MONTHS.map(month => (
      <Option key={month.key} value={String(month.key)}>
        {month.label}
      </Option>
    ));
  }

  getDayOptions() {
    const days = getDaysOfTheMonthArray(this.state.month, this.state.year);
    return days.map(day => (
      <Option key={day} value={String(day)}>
        {day}
      </Option>
    ));
  }

  getDay = () => (this.state.day ? String(this.state.day) : undefined);

  getMonth = () => {
    if (isNumberBetween(this.state.month, 0, 11)) {
      return MONTHS[this.state.month];
    }

    return { key: '', label: '' };
  };

  getYear = () => (this.state.year ? String(this.state.year) : undefined);

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      this.setStateFromProps(nextProps);
    }
  }

  selectFocus = () => {
    if (!document || !document.getElementsByClassName) {
      return;
    }

    /* hack to auto-open select menu on focus */
    const focusedSelectMenus = document.getElementsByClassName('ant-select-focused');
    const numMenus = focusedSelectMenus.length;
    if (numMenus) {
      focusedSelectMenus[numMenus - 1].click();
    }
  };

  handleDayChange(d) {
    const day = typeof d === 'undefined' ? null : parseInt(d, 10);

    this.setState({ day });

    this.triggerChange({ day });
  }

  handleYearChange(e) {
    let year = e.target.value;
    year = typeof year === 'undefined' ? '' : stripNonnumeric(year).slice(0, 4);

    this.setState({ year });

    this.triggerChange({ year });
  }

  handleYearOnBlur(e) {
    let year = e.target.value;

    if (isEmptyString(year)) {
      return;
    }

    year = standardizeYear(year);

    const months = getMonthsOfTheYearArray(year);
    if (months.indexOf(parseInt(this.state.month, 10)) === -1) {
      this.setState({ month: null });
      this.triggerChange({ month: null, year: String(year) });
      return;
    }

    const days = getDaysOfTheMonthArray(this.state.month, year);
    if (days.indexOf(parseInt(this.state.day, 10)) === -1) {
      this.setState({ day: null });
      this.triggerChange({ day: null, year: String(year) });
    }
  }

  handleMonthChange(m) {
    if (!m) {
      return;
    }

    let matches = [];
    const searchString = m.toLowerCase();
    if (isNumberBetween(searchString, 1, 12)) {
      const monthNumberHuman = parseInt(searchString, 10);
      matches = [MONTHS[monthNumberHuman - 1]];
    } else {
      matches = MONTHS.filter(month => month.label.toLowerCase().indexOf(searchString) !== -1);
    }

    if (matches.length) {
      const month = matches[0].key;
      this.setState({ month });
      this.triggerChange({ month });
    }
  }

  triggerChange(changedValue) {
    const updatedDateObject = Object.assign({}, this.state, changedValue);
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(dateObjectToString(updatedDateObject));
    }
  }

  filterOption = (input, option) => {
    if (isNumberBetween(input, 1, 12)) {
      const monthNumberHuman = parseInt(input, 10);
      const monthIndex = monthNumberHuman - 1;
      return option.key === String(monthIndex);
    }

    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  handleSelectMonth = monthObject => {
    const month = monthObject.key;
    this.setState({ month });
    this.triggerChange({ month });
  };

  handleSelectDay = day => {
    this.setState({ day });
    this.triggerChange({ day });
  };

  render() {
    return (
      <Col className={this.props.className} span={this.props.span}>
        <Row gutter={16}>
          <Col span={12}>
            <Select
              allowClear={this.props.allowClear && !isEmptyString(this.state.month)}
              defaultActiveFirstOption
              disabled={this.props.disabled}
              dropdownClassName={`${this.props.dropdownClassName}-month`}
              dropdownStyle={this.props.dropdownStyle}
              optionFilterProp="children"
              onSearch={this.handleMonthChange}
              onSelect={this.handleSelectMonth}
              placeholder="Month"
              showSearch={!this.props.disableKeyboard}
              value={this.getMonth()}
              filterOption={this.filterOption}
              labelInValue
              onFocus={this.selectFocus}
            >
              {this.getMonthOptions()}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              allowClear={this.props.allowClear && !isEmptyString(this.state.day)}
              defaultActiveFirstOption
              disabled={this.props.disabled}
              dropdownClassName={`${this.props.dropdownClassName}-day`}
              dropdownStyle={this.props.dropdownStyle}
              onChange={this.handleDayChange}
              onSelect={this.handleSelectDay}
              optionFilterProp="children"
              placeholder="Day"
              showSearch={!this.props.disableKeyboard}
              value={this.getDay()}
              onFocus={this.selectFocus}
            >
              {this.getDayOptions()}
            </Select>
          </Col>
          <StyledYearInputHolder span={6} className="birth-date-year-input-wrapper">
            <Input
              className="birth-date-year-input"
              disabled={this.props.disabled}
              onBlur={this.handleYearOnBlur}
              onChange={this.handleYearChange}
              max={moment().year()}
              min={0}
              placeholder="Year"
              value={this.getYear()}
              type="number"
            />
          </StyledYearInputHolder>
        </Row>
      </Col>
    );
  }
}

BirthDateInput.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableKeyboard: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  dropdownStyle: PropTypes.object,
  onChange: PropTypes.func,
  span: PropTypes.number.isRequired,
  value: PropTypes.string,
};

BirthDateInput.defaultProps = {
  allowClear: true,
  disableKeyboard: false,
};
