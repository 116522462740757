import omit from 'lodash/omit';
import { call, put, all } from 'redux-saga/effects';
import { setRuntimeVariable } from '../../../actions/runtime';
import {
  getPatchLocationDataUrl,
  getLocationOperatingHoursUrl,
  getLocationSpecialOperatingHoursUrl,
  getLocationReservationHoursUrl,
  getLocationAvailabilityRecommendationsUrl,
  getLocationUrl,
  getInvalidateCloudfrontCacheUrl,
  getPatchLocationUclBrandsAssignmentUrl,
} from '../../../core/dapi/location';
import { apiPatchJson, apiGetJson, apiPostJson, apiDeleteJson } from '../../../core/dapi';
import {
  locationPatchSuccess,
  locationError,
  receiveLocation,
  updateLocationSpecialOperatingHours,
  updateLocationOperatingHours,
} from '../../../actions/location';
import { isEmpty } from '../../../core/util/empty';
import { getPaymentAccountsUrl, getCreatePaymentAccountUrl } from '../../../core/dapi/payments';
import {
  fetchSolvpayStatus,
  updatePaymentsEnabled,
  updatePaymentsError,
  updatePaymentsSuccess,
  fetchServicesListSuccess,
  fetchPaymentAccountsSuccess,
  createEmployeeSearchEmpty,
  receiveLocations,
  fetchOperatingHoursSuccess,
  fetchPaymentAccountsError,
  fetchServicesListError,
  receiveUpcomingSpecialOperatingHours,
  fetchOperatingHoursError,
  createSpecialOperatingHoursError,
  clearLocationsData,
  createSpecialOperatingHoursSuccess,
  updateSpecialOperatingHoursSuccess,
  receiveReservationHoursError,
  receiveReservationHoursSuccess,
  createReservationHoursError,
  setFetchingReservationHours,
  setFetchingAvailabilityRecommendations,
  setSubmittingReservationHours,
  setSubmitting,
  receiveAvailabilityRecommendationsSuccess,
  receiveAvailabilityRecommendationsError,
  updatePosEnabled,
  updateAutoResendEnabled,
  updatePaymentsDuringBookingEnabled,
  updatePaymentsDuringBookingRequired,
} from '../../../actions/settings';
import { getGroupsUrl, getLocationsGroupsUrl } from '../../../core/dapi/groups';
import {
  receiveGroups,
  fetchingGroups,
  groupsError,
  groupsDeleteLocations,
} from '../../../actions/groups';
import { getServicesUrl } from '../../../core/dapi/services';
import { emptyFunction } from '../../../core/util/function';
import { EMPTY_OBJECT, isEmptyObject, safeGet } from '../../../core/util/object';
import { apiGetAllPages } from '../../../sagas/core/base';
import { getClinicAccountBulkAddLocationUrl } from '../../../core/dapi/clinicAccounts';
import { clinicAccountsError } from '../../../actions/clinicAccounts';
import { RuntimeAttributes } from '../../../reducers/runtime';

export const SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES = 'SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES';
export const SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES_BY_STATE =
  'SEARCH_LOCATIONS_FOR_SOLV_EMPLOYEES_BY_STATE';
export const PATCH_LOCATION_UCL_BRANDS = 'PATCH_LOCATION_UCL_BRANDS';
export const PATCH_LOCATION_SETTINGS_GENERAL = 'PATCH_LOCATION_SETTINGS_GENERAL';
export const PATCH_LOCATION_SETTINGS_INTERNAL_ADMIN = 'PATCH_LOCATION_SETTINGS_INTERNAL_ADMIN';
export const FETCH_CLINIC_PAYMENT_ACCOUNT_INFORMATION = 'FETCH_CLINIC_PAYMENT_ACCOUNT_INFORMATION';
export const FETCH_PAYMENT_ACCOUNTS_NEW_SETTINGS = 'FETCH_PAYMENT_ACCOUNTS_NEW_SETTINGS';
export const FETCH_SERVICES_LIST = 'FETCH_SERVICES_LIST';
export const FETCH_RESERVATION_HOURS = 'FETCH_RESERVATION_HOURS';
export const FETCH_AVAILABILITY_RECOMMENDATIONS = 'FETCH_AVAILABILITY_RECOMMENDATIONS';
export const PATCH_RESERVATION_HOURS = 'PATCH_RESERVATION_HOURS';
export const POST_RESERVATION_HOURS = 'POST_RESERVATION_HOURS';
export const FETCH_OPERATING_HOURS = 'FETCH_OPERATING_HOURS';
export const PATCH_OPERATING_HOURS = 'PATCH_OPERATING_HOURS';
export const FETCH_SPECIAL_HOURS = 'FETCH_SPECIAL_HOURS';
export const CREATE_SPECIAL_HOURS = 'CREATE_SPECIAL_HOURS';
export const UPDATE_SPECIAL_HOURS = 'UPDATE_SPECIAL_HOURS';
export const DELETE_SPECIAL_HOURS = 'DELETE_SPECIAL_HOURS';
export const SETTINGS_CLEAR_LOCATIONS = 'SETTINGS_CLEAR_LOCATIONS';
export const UPDATE_LOCATION_SERVICE_PRICES = 'UPDATE_LOCATION_SERVICE_PRICES';
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const ADD_GROUPS_LOCATIONS = 'ADD_GROUPS_LOCATIONS';
export const DELETE_GROUPS_LOCATIONS = 'DELETE_GROUPS_LOCATIONS';
export const BULK_ADD_CLINIC_ACCOUNTS_LOCATIONS = 'BULK_ADD_CLINIC_ACCOUNTS_LOCATIONS';

export function* getPaginated(url, limitResultsPerPage = 100) {
  const getPaginatedResponseUrl = `${url}&page=1&limit=${limitResultsPerPage}`;
  const results = [];

  const response = yield call(apiGetJson, getPaginatedResponseUrl);

  const newResults = safeGet(response)('data.results');
  if (newResults) {
    results.push(response.data.results);
    const totalResultsCount = response.data.page.results_count;
    if (totalResultsCount !== 0 && response.data.results.length < totalResultsCount) {
      const nNeededPages = Math.ceil(totalResultsCount / limitResultsPerPage);
      const paginated = [];
      for (let page = 2; page <= nNeededPages; page++) {
        paginated.push(`${url}&page=${page}&limit=${limitResultsPerPage}`);
      }

      const responses = yield all(paginated.map((limitUrl) => call(apiGetJson, limitUrl)));

      if (!isEmpty(responses)) {
        responses.map((res) => results.push(res.data.results));
      }
    }
  }

  return results;
}

export function* searchLocationsForSolvEmployees({ url }) {
  const response = yield call(getPaginated, url);

  let locationSearchResult = [];
  if (!isEmpty(response)) {
    response.forEach((responseArray) => {
      locationSearchResult = [...locationSearchResult, ...responseArray];
    });
  }

  if (isEmpty(locationSearchResult)) {
    yield put(createEmployeeSearchEmpty(true));
  }

  yield put(receiveLocations({ results: locationSearchResult }));
}

export function* clearLocations() {
  yield put(clearLocationsData());
}

export function* patchLocationUclBrands({
  locationId,
  patchData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmitting(true));

  const patchLocationUrl = getPatchLocationUclBrandsAssignmentUrl(locationId);
  const response = yield call(apiPatchJson, patchLocationUrl, patchData);
  yield put(setSubmitting(false));

  if (response.error) {
    yield put(locationError(response.error));
    onError();
    yield put(locationPatchSuccess(false));
  } else if (response.data) {
    yield put(receiveLocation(response.data));
    yield put(locationPatchSuccess(true));
    onSuccess();
  }
}

export function* postLocationsGroups({ postData }) {
  yield put(setSubmitting(true));

  const postLocationsGroupsUrl = getLocationsGroupsUrl();

  const response = yield call(apiPostJson, postLocationsGroupsUrl, postData);

  if (response.error) {
    yield put(groupsError(response.error));
  } else {
    const locationUrl = getLocationUrl(postData.location_ids);
    const locationResponse = yield call(apiGetJson, locationUrl);

    if (locationResponse.error) {
      yield put(locationError(locationResponse.error));
    } else {
      yield put(receiveLocation(locationResponse.data));
    }
  }
}

export function* removeLocationsGroups({ deleteData }) {
  yield put(setSubmitting(true));

  const deleteLocationsGroupsUrl = getLocationsGroupsUrl();

  const response = yield call(apiDeleteJson, deleteLocationsGroupsUrl, deleteData);

  if (response.error) {
    yield put(groupsError(response.error));
  } else {
    yield put(groupsDeleteLocations(response.data));
  }
}

export function* patchLocationSettings({
  locationId,
  paymentAccountId,
  patchData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmitting(true));

  let patchPaymentAccountParams = null;
  if ('is_payments_enabled' in patchData) {
    patchPaymentAccountParams = {
      is_payments_enabled: patchData.is_payments_enabled,
      is_pos_enabled: patchData.is_payments_enabled,
      is_auto_resend_enabled: patchData.is_auto_resend_enabled,
      is_payment_during_booking_enabled: patchData.is_payment_during_booking_enabled,
      is_payment_during_booking_required: patchData.is_payment_during_booking_required,
    };

    const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

    const response = yield call(apiPatchJson, patchPaymentAccountsUrl, patchPaymentAccountParams);

    if (response.errors) {
      yield put(updatePaymentsError(response.errors));
      return;
    }

    if (response.data) {
      yield put(updatePaymentsEnabled(response.data.is_payments_enabled));
      yield put(updatePaymentsSuccess(response.data));
    }
  }

  const patchLocationUrl = getPatchLocationDataUrl(locationId);

  const response = yield call(apiPatchJson, patchLocationUrl, patchData);

  if (response.errors) {
    yield put(locationError(response.errors));
    onError();
  }

  if (response.data) {
    yield put(receiveLocation(response.data));
    yield put(locationPatchSuccess(true));
    onSuccess();
  }

  yield put(setSubmitting(false));
}

export function* patchLocationSettingsGeneral({
  locationId,
  patchData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield call(patchLocationSettings, {
    locationId,
    paymentAccountId: null,
    patchData: omit(patchData, 'is_payments_enabled'),
    onSuccess,
    onError,
  });
}

export function* fetchClinicPaymentAccountInformation({ paymentAccountId }) {
  const fetchPaymentAccountUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(apiGetJson, fetchPaymentAccountUrl);

  if (response.errors) {
    yield put(fetchSolvpayStatus(response.errors));
  }

  if (response.data) {
    yield put(updatePaymentsEnabled(response.data.is_payments_enabled));
    yield put(updatePosEnabled(response.data.is_pos_enabled));
    yield put(updateAutoResendEnabled(response.data.is_auto_resend_enabled));
    yield put(updatePaymentsDuringBookingEnabled(response.data.is_payment_during_booking_enabled));
    yield put(
      updatePaymentsDuringBookingRequired(response.data.is_payment_during_booking_required)
    );
  }
}

export function* fetchServicesList({ specialtiesFilters }) {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_SERVICES, value: true }));
  const fetchServicesUrl = getServicesUrl(specialtiesFilters);

  const response = yield call(apiGetAllPages, { url: fetchServicesUrl });

  if (response.errors) {
    yield put(fetchServicesListError(response.errors));
  } else {
    yield put(fetchServicesListSuccess(response.results));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_SERVICES, value: false }));
}

export function* fetchPaymentAccounts() {
  const fetchPaymentAccountsUrl = getCreatePaymentAccountUrl();

  const response = yield call(apiGetJson, fetchPaymentAccountsUrl);

  if (response.errors) {
    yield put(fetchPaymentAccountsError(response.errors));
  }

  if (response.data) {
    yield put(fetchPaymentAccountsSuccess(response.data.results));
  }
}

export function* fetchOperatingHours({ locationId }) {
  const fetchOperatingHoursUrl = getLocationOperatingHoursUrl(locationId);

  const response = yield call(apiGetJson, fetchOperatingHoursUrl);

  if (response.errors) {
    yield put(fetchOperatingHoursError(response.errors));
  }

  if (response.data) {
    yield put(fetchOperatingHoursSuccess(response.data));
    yield put(updateLocationOperatingHours(response.data));
  }
}

export function* fetchSpecialHours({ locationId }) {
  const fetchSpecialHoursUrl = getLocationSpecialOperatingHoursUrl(locationId);

  const response = yield call(apiGetJson, fetchSpecialHoursUrl);

  if (response.errors) {
    yield put(createSpecialOperatingHoursError(response.errors));
  }

  if (response.data) {
    yield put(receiveUpcomingSpecialOperatingHours(response.data, locationId));
    yield put(updateLocationSpecialOperatingHours(response.data));
  }
}

export function* createSpecialHours({ locationId, postData, callback }) {
  yield put(setRuntimeVariable({ name: 'isModifyingSpecialHours', value: true }));

  const response = yield call(
    apiPostJson,
    getLocationSpecialOperatingHoursUrl(locationId),
    postData
  );

  if (response.errors) {
    yield put(createSpecialOperatingHoursError(response.errors));
    return;
  }

  yield put(createSpecialOperatingHoursSuccess(response));

  yield call(fetchSpecialHours, { locationId });
  callback();
}

export function* updateSpecialHours({ locationId, specialHoursId, patchData, callback }) {
  yield put(setRuntimeVariable({ name: 'isModifyingSpecialHours', value: true }));

  const response = yield call(
    apiPatchJson,
    getLocationSpecialOperatingHoursUrl(locationId, specialHoursId),
    patchData
  );

  if (response.errors) {
    yield put(createSpecialOperatingHoursError(response.errors));
  }

  yield put(updateSpecialOperatingHoursSuccess(response));

  yield call(fetchSpecialHours, { locationId });
  callback();
}

export function* deleteSpecialHours({ locationId, specialHoursId }) {
  yield put(setRuntimeVariable({ name: 'isModifyingSpecialHours', value: true }));

  const response = yield call(
    apiDeleteJson,
    getLocationSpecialOperatingHoursUrl(locationId, specialHoursId),
    EMPTY_OBJECT
  );

  if (response.errors) {
    yield put(createSpecialOperatingHoursError(response.errors));
    return;
  }

  yield put(updateSpecialOperatingHoursSuccess(response));

  yield call(fetchSpecialHours, { locationId });
}

export function* fetchReservationHours({ locationId }) {
  yield put(setFetchingReservationHours(true));
  const response = yield call(apiGetJson, getLocationReservationHoursUrl(locationId));

  if (response.errors) {
    yield put(receiveReservationHoursError(response.errors));
  } else {
    yield put(receiveReservationHoursSuccess(response.data));
  }

  yield put(setFetchingReservationHours(false));
}

export function* fetchAvailabilityRecommendations({ locationId }) {
  yield put(setFetchingAvailabilityRecommendations(true));
  const response = yield call(apiGetJson, getLocationAvailabilityRecommendationsUrl(locationId));

  if (response.errors) {
    yield put(receiveAvailabilityRecommendationsError(response.errors));
  } else {
    yield put(receiveAvailabilityRecommendationsSuccess(response.data));
  }

  yield put(setFetchingAvailabilityRecommendations(false));
}

const getExistingReservationHoursId = (existingReservationHours, newReservationHours) => {
  let matchingId = null;
  existingReservationHours.forEach((hours) => {
    if (hours.week_day === newReservationHours.week_day) {
      matchingId = hours.reservation_hours_id;
    }
  });

  return matchingId;
};

export function* updateReservationHours({
  locationId,
  updates,
  existingReservationHours,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmittingReservationHours(true));

  const responses = yield all(
    Object.entries(updates).map(([key, value]) => {
      const body = {
        week_day: key,
      };

      if (value.fromTime) {
        body.from_time = value.fromTime;
      }

      if (value.toTime) {
        body.to_time = value.toTime;
      }

      const existingReservationHoursId = getExistingReservationHoursId(
        existingReservationHours,
        body
      );

      if (existingReservationHoursId) {
        return call(
          apiPatchJson,
          getLocationReservationHoursUrl(locationId, existingReservationHoursId),
          body
        );
      }

      return call(apiPostJson, getLocationReservationHoursUrl(locationId), body);
    })
  );

  yield put(setSubmittingReservationHours(false));

  const withError = responses.find((response) => !!response.errors);

  if (withError) {
    onError();
    yield put(createReservationHoursError(withError.errors));
  } else {
    onSuccess();
    yield call(fetchReservationHours, { locationId });
  }
}

export function* updateServicePrices({
  locationId,
  patchData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmitting(true));

  let response;

  if (!isEmptyObject(patchData)) {
    const patchLocationUrl = getPatchLocationDataUrl(locationId);
    response = yield call(apiPatchJson, patchLocationUrl, patchData);
  }

  if (isEmptyObject(response) || 'errors' in response) {
    yield put(locationError(response.errors));
    yield put(locationPatchSuccess(false));
    onError();
  } else {
    yield put(receiveLocation(response.data));
    yield put(locationPatchSuccess(true));
    onSuccess();
  }

  yield put(setSubmitting(false));
}

export function* fetchGroups() {
  yield put(fetchingGroups());
  const response = yield call(apiGetAllPages, { url: getGroupsUrl() });

  if (response) {
    yield put(receiveGroups(response));
  }

  if (response.error) {
    yield put(groupsError(response.error));
  }
}

export function* bulkAddLocationToClinicAccounts({
  postData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(setSubmitting(true));
  const response = yield call(apiPostJson, getClinicAccountBulkAddLocationUrl(), postData);

  if (response.error) {
    yield put(setSubmitting(false));
    yield put(clinicAccountsError(response.error));
    onError();
  } else {
    yield put(setSubmitting(false));
    onSuccess();
  }
}
