import { call, put, takeEvery } from 'redux-saga/effects';
import { updateAccountSuccess, updateAccountError } from '../../actions/queue';
import { apiPatchJson, apiPostJson } from '../../core/dapi';
import { getAccountByIdUrl, getAccountDeidentificationByIdUrl } from '../../core/dapi/accounts';
import { clearAccountsData, updateAccountSubmitting } from '../../actions/accounts';
import { emptyFunction } from '../../core/util/function';

export const PATCH_ACCOUNT = 'PATCH_ACCOUNT';
export const DE_IDENTIFY_ACCOUNT = 'DE_IDENTIFY_ACCOUNT';

export function* patchAccount({
  accountId,
  patchAccountData,
  onSuccess = emptyFunction,
  onError = emptyFunction,
}) {
  yield put(updateAccountSubmitting(true));

  const patchAccountUrl = getAccountByIdUrl(accountId);

  const response = yield call(apiPatchJson, patchAccountUrl, patchAccountData);
  if (response.errors) {
    yield put(updateAccountError(response.errors));
    onError();
  }

  if (response.data) {
    yield put(updateAccountSuccess(response.data));
    onSuccess();
  }

  yield put(updateAccountSubmitting(false));
}

export function* deIdentifyAccount({ accountId, postData, onSuccess, onError }) {
  const response = yield call(apiPostJson, getAccountDeidentificationByIdUrl(accountId), postData);
  if (response.data) {
    onSuccess();
    yield put(clearAccountsData());
  }
  if (response.errors) onError();
}

export default function* rootSaga() {
  yield takeEvery(PATCH_ACCOUNT, patchAccount);
  yield takeEvery(DE_IDENTIFY_ACCOUNT, deIdentifyAccount);
}
