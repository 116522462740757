import {
  ELIGIBILITY_CHECK_RECEIVED,
  ELIGIBILITY_CHECK_ERROR,
  ELIGIBILITY_CHECK_SUBMITTED,
  ELIGIBILITY_FACESHEET_RECEIVED,
  INSURANCE_CHECK_SET_REQUEST,
  ELIGIBILITY_FACESHEET_RESET,
  ELIGIBILITY_FEEDBACK_SUCCESS,
  ELIGIBILITY_FEEDBACK_FAILURE,
  ELIGIBILITY_FEEDBACK_SUBMIT,
} from '../constants';

const getInitialState = () => ({
  eligibilityFeedbackFailure: false,
  eligibilityFeedbackSubmitting: false,
  eligibilityFeedbackSuccess: false,
  error: null,
  faceSheet: {},
  result: {},
  submitting: false,
});

export default function eligibilityCheckReducer(state = getInitialState(), action) {
  switch (action.type) {
    case ELIGIBILITY_CHECK_SUBMITTED:
      return {
        ...state,
        error: null,
        submitting: true,
      };
    case ELIGIBILITY_CHECK_RECEIVED:
      return {
        ...state,
        result: {
          ...action.payload,
        },
        error: null,
        submitting: false,
      };
    case ELIGIBILITY_CHECK_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    case ELIGIBILITY_FACESHEET_RECEIVED:
      return {
        ...state,
        faceSheet: {
          ...action.payload,
        },
      };
    case ELIGIBILITY_FACESHEET_RESET:
      return getInitialState();
    case INSURANCE_CHECK_SET_REQUEST:
      return {
        ...state,
        result: {},
        error: null,
      };
    case ELIGIBILITY_FEEDBACK_SUCCESS:
      return {
        ...state,
        eligibilityFeedbackSuccess: true,
        eligibilityFeedbackFailure: false,
        eligibilityFeedbackSubmitting: false,
      };
    case ELIGIBILITY_FEEDBACK_FAILURE:
      return {
        ...state,
        eligibilityFeedbackSuccess: false,
        eligibilityFeedbackFailure: true,
        eligibilityFeedbackSubmitting: false,
      };
    case ELIGIBILITY_FEEDBACK_SUBMIT:
      return {
        ...state,
        eligibilityFeedbackSuccess: false,
        eligibilityFeedbackFailure: false,
        eligibilityFeedbackSubmitting: true,
      };
    default:
      return state;
  }
}
