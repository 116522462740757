import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FlagsState {
  flags: Record<string, any>;
}

const initialState: FlagsState = {
  flags: {},
};

export const flagsSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setFlags: (state, payload: PayloadAction<Record<string, any>>) => {
      state.flags = payload.payload;
    },
  },
});

export const { setFlags } = flagsSlice.actions;
