import {
  GROUPS_FETCHING,
  GROUPS_RECEIVED,
  GROUPS_ERROR,
  GROUPS_DELETE_LOCATIONS,
  GROUPS_PATCH_GROUP,
  GROUPS_PATCH_SUBMITTED,
  GROUPS_PATCH_ERROR,
  GROUP_RECEIVED,
} from '../constants';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';

const fetchingGroups = () => ({
  type: GROUPS_FETCHING,
});

const receiveGroup = (value) => ({
  payload: { value },
  type: GROUP_RECEIVED,
});

const receiveGroups = (value) => ({
  type: GROUPS_RECEIVED,
  payload: { value },
});

const groupsError = (value) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: GROUPS_ERROR,
    payload: { value },
  };
};

const groupsPatchSubmitted = () => ({
  type: GROUPS_PATCH_SUBMITTED,
});

const groupsPatchError = (value) => ({
  type: GROUPS_PATCH_ERROR,
  payload: { value },
});

const groupsDeleteLocations = (value) => ({
  type: GROUPS_DELETE_LOCATIONS,
  payload: { value },
});

const groupsPatchGroup = (group) => ({
  type: GROUPS_PATCH_GROUP,
  payload: { group },
});

export {
  fetchingGroups,
  receiveGroup,
  receiveGroups,
  groupsError,
  groupsPatchSubmitted,
  groupsPatchGroup,
  groupsPatchError,
  groupsDeleteLocations,
};
