import { DAPI_HOST } from '../../config';
import { getMultiFiltersString } from './index';

export const getProviderUrl = (providerId) => `${DAPI_HOST}/practitioners/${providerId}`;

export const getProvidersUrl = (searchParams) => {
  const filters = { providers: {} };

  Object.entries(searchParams).forEach(([key, value]) => {
    if (value) filters.providers[key] = value;
  });

  return `${DAPI_HOST}/practitioners?filters=${getMultiFiltersString(filters)}`;
};

export const getProvidersByLocationUrl = (locationIdParams) => {
  const locationIds = locationIdParams.join(',');
  return `${DAPI_HOST}/practitioners?limit=100&location_ids=${locationIds}`;
};
