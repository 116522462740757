import { WAIT_TIME_RECEIVED, WAIT_TIME_ERROR, LOADING_WAIT_TIME } from '../constants';

const createInitialState = () => ({
  waitTime: null,
});

export default function waitTime(state = createInitialState(), action) {
  switch (action.type) {
    case WAIT_TIME_RECEIVED:
      return {
        waitTime: action.payload.value.duration_minutes,
      };
    case WAIT_TIME_ERROR:
      return {
        error: action.payload.value,
      };
    case LOADING_WAIT_TIME:
      return {
        loading: true,
      };
    default:
      return state;
  }
}
