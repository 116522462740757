import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

export default class BirthDateMaskedInput extends Component {
  constructor(props) {
    super(props);
    this.state = { birthDate: this.props.value.birthDate || null };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      const birthDate = nextProps.value;
      this.setState(birthDate);
    }
  }
  cleanValue = (e) => {
    const rawValue = e.target.value;
    const birthDate = rawValue.replace(/[^0-9]/g, '');
    const changedValue = { birthDate };
    this.setState(changedValue);
    return changedValue;
  };

  handleChange = (e) => {
    const changedValue = this.cleanValue(e);
    if (this.props.onChange) {
      this.props.onChange(Object.assign({}, this.state, changedValue));
    }
  };

  handleOnBlur = (e) => {
    const changedValue = this.cleanValue(e);
    if (this.props.onBlur) {
      this.props.onBlur(Object.assign({}, this.state, changedValue));
    }
  };

  renderMaskedInput = () => (
    <MaskedInput
      mask={[/[01]?\d/, /\d/, '/', /[0-3]?/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
      guide
      placeholderChar={'_'}
      disabled={this.props.disabled}
      className="ant-input"
      onChange={this.handleChange}
      autoFocus={this.props.autoFocus}
      onBlur={this.handleOnBlur}
      type="tel"
      value={this.state.birthDate}
      placeholder="MM/DD/YYYY"
      data-testid="addPatientDob"
    />
  );

  render() {
    return this.props.enableMask ? this.renderMaskedInput() : this.renderUnMaskedInput();
  }
}

BirthDateMaskedInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  enableMask: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BirthDateMaskedInput.defaultProps = {
  enableMask: false,
};
