import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

const StyledSectionDivider = styled.div`
  opacity: 0.5;
  height: 2px;
  background-color: ${colors.greyish};
  margin-bottom: 20px;
`;

class SectionDivider extends PureComponent {
  render = () => (
    <StyledSectionDivider onClick={this.props.onClick}>{this.props.children}</StyledSectionDivider>
  );
}

SectionDivider.propTypes = {
  children: PropTypes.string,
  width: PropTypes.string,
};

export default SectionDivider;
