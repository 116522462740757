import styled from 'styled-components';
import { Spin } from 'antd';
import React from 'react';

const FullPageSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export function FullPageSpinner() {
  return (
    <FullPageSpinnerContainer>
      <Spin size={'large'} />
    </FullPageSpinnerContainer>
  );
}
