import { select, take } from 'redux-saga/effects';
import { RootState } from '../../store/configureStore';

export function* waitFor(selector: (state: RootState) => any, takeSelector = '*') {
  // @ts-ignore
  if (yield select(selector)) return;

  while (true) {
    console.debug('waiting for redux saga selector', takeSelector);

    // Yields until any sort of redux store dispatch is done.
    yield take(takeSelector); // (1a)

    // @ts-ignore
    // When there was a redux store dispatch, we can assume state has changed, so re-check the selector
    if (yield select(selector)) return; // (1b)
  }
}
