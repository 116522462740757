import { DAPI_CACTUS_HOST } from '../../config';
import { CHARGE_STATUS_SUCCEEDED } from '../../constants';
import { queryStringFromObject } from '../util/string';

const getChargesByBookingUrl = (bookingId) => {
  const params = {
    booking_id: bookingId,
    status: CHARGE_STATUS_SUCCEEDED,
  };

  const queryString = queryStringFromObject(params);

  return `${DAPI_CACTUS_HOST}/v1/charges?${queryString}`;
};

const getAllChargesByBookingUrl = (bookingId) => {
  const params = {
    booking_id: bookingId,
  };

  const queryString = queryStringFromObject(params);

  return `${DAPI_CACTUS_HOST}/v1/charges?${queryString}`;
};

const getChargesPOSUrl = () => `${DAPI_CACTUS_HOST}/v1/charges/pos`;
const getV2ChargesPOSUrl = () => `${DAPI_CACTUS_HOST}/v2/charges/pos`;

export { getChargesPOSUrl, getV2ChargesPOSUrl, getChargesByBookingUrl, getAllChargesByBookingUrl };
