import moment from 'moment';
import { HOUR_MINUTE_SECOND } from '../../components/DateTime';
import { isEmptyArray } from './array';
import { leftPad } from './string';

const getHoursThatHavePassed = dateTime => {
  if (dateTime.isSameOrAfter(moment().add(1, 'day'), 'day')) {
    return [];
  }

  const hoursOfTheDay = [...new Array(24).keys()].map(key => `${key}:00:00`);
  const timeFormat = 'H:mm:ss';
  const pastHours = hoursOfTheDay.filter(hour =>
    moment(hour, timeFormat).isBefore(moment(), 'hour')
  );
  return pastHours.map(hour => Number(hour.split(':')[0]));
};

const fromTimeToTime = (from, to) => moment.utc(moment(from).diff(moment(to)));

const elapsedMinutes = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return Math.floor(duration.asMinutes());
};

const sumDurations = durationsArray => {
  let sum = 0;
  durationsArray.forEach(duration => {
    sum += parseInt(duration, 10);
  });
  return sum;
};

const timeAgo = time =>
  `${moment
    .duration(moment().diff(moment(time)))
    .locale('en')
    .humanize()} ago`;

const minutesToHoursMinutes = (minutes, timeStyle = null) => {
  if (minutes >= 0 && minutes < 60) {
    if (timeStyle === 'abbreviated') {
      return `0:${leftPad('0', 2, minutes)}`;
    }

    return `${minutes} min`;
  }

  const hours = Math.floor(Math.abs(minutes) / 60);
  if (hours >= 1000) {
    // mainly for test data styling help
    return `${hours} hrs`;
  }

  const remainderMinutes = leftPad('0', 2, Math.abs(minutes) - hours * 60);
  if (timeStyle === 'abbreviated') {
    return `${hours} h ${remainderMinutes} min`;
  }

  return `${hours} hrs ${remainderMinutes} mins`;
};

const isDatabaseBirthDate = time => /^\d{4}-\d{2}-\d{2}$/.test(time);

const formatTime = time => {
  const timeObject = moment(time);
  if (timeObject.minute() > 0) {
    return timeObject.format('h:mma');
  }

  return timeObject.format('ha');
};

const openHoursString = hours => {
  const dateString = moment().format('YYYY-MM-DD');

  const formattedHours = hours.map(interval => {
    const startTime = formatTime(`${dateString} ${interval.fromTime || interval.from_time}`);
    const endTime = formatTime(`${dateString} ${interval.toTime || interval.to_time}`);
    return `${startTime} - ${endTime}`;
  });

  return formattedHours.join(', ');
};

const dateIsToday = date => moment(date).isSame(moment(), 'day');

const LAST_SECOND_OF_DAY = '23:59:59';
const LAST_MINUTE_OF_DAY = '23:59:00';
const TIME_MIDNIGHT = '00:00:00';
const DEFAULT_OPENING_TIME = '08:00:00';
const DEFAULT_CLOSING_TIME = '20:00:00';

const isTimeMidnight = time => time === TIME_MIDNIGHT;

const isTimeLastMinuteOrSecondOfDay = time =>
  [LAST_SECOND_OF_DAY, LAST_MINUTE_OF_DAY].includes(time);

const timeStringToMoment = (string, format = HOUR_MINUTE_SECOND) => moment(string, format);

const getHoursFromTimeString = string => {
  const timeParts = string.split(':');
  return !isEmptyArray(timeParts) ? timeParts[0] : '';
};

const getTimeRangeFromTime = range => range.fromTime;

export {
  getHoursThatHavePassed,
  isDatabaseBirthDate,
  fromTimeToTime,
  elapsedMinutes,
  sumDurations,
  timeAgo,
  minutesToHoursMinutes,
  formatTime,
  openHoursString,
  dateIsToday,
  LAST_MINUTE_OF_DAY,
  LAST_SECOND_OF_DAY,
  TIME_MIDNIGHT,
  DEFAULT_OPENING_TIME,
  DEFAULT_CLOSING_TIME,
  isTimeMidnight,
  isTimeLastMinuteOrSecondOfDay,
  timeStringToMoment,
  getHoursFromTimeString,
  getTimeRangeFromTime,
};
