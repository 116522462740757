import { call, put } from 'redux-saga/effects';
import {
  getEligibilityByInsuranceProfileId,
  getInsuranceDetailsUrl,
} from '../../../../core/dapi/eligibilityCheck';
import {
  eligibilityRequestIdReceived,
  paymentsInsuranceReceived,
} from '../../../../actions/currentPatient';
import { setRuntimeVariable } from '../../../../actions/runtime';
import { isEmptyArray } from '../../../../core/util/array';
import { apiGet, apiPost, apiPatch, apiDeleteJson } from '../../../../core/dapi/index';
import { insurersError, insurersReceived } from '../../../../actions/insurers';
import { getFullInsurersListUrl } from '../../../../core/dapi/insurers';
import { createInsuranceProfileUrl } from '../../../../core/dapi/insuranceProfiles';
import { getBookingByIdUrl } from '../../../../core/dapi/bookings';
import { getBookingSuccess } from '../../../../actions/queue';
import {
  checkInsuranceCoverageUrl,
  setBookingInsuranceUrl,
} from '../../../../core/dapi/insuranceCoverage';
import { getChargesByBookingUrl, getChargesPOSUrl } from '../../../../core/dapi/charges';
import { getPaymentCardUrl, deletePaymentCardUrl } from '../../../../core/dapi/invoices';
import { chargesReceived, chargesError, chargesSubmitting } from '../../../../actions/charge';
import { cardsError, cardsReceived } from '../../../../ducks/paymentCustomers';
import { PubNubEvent, PubNubListeners, PubNubSource } from '../../../Pubnub/usePubNub';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { FACESHEET_MODAL_UPLOAD_INSURANCE } from '../../../../core/analytics/events';
import { queryGeneralConfigs } from '../../../../hooks/useGeneralConfigs';

export const FETCHING_ELIGIBILITY = 'fetchingEligibility';
export const FETCHING_PAYMENT_CARDS = 'fetchingPaymentCards';
export const FETCHING_PAYMENT_CHARGE = 'fetchingPaymentCharge';
export const DELETING_PAYMENT_CARD = 'deletingPaymentCard';

export function* submitInsuranceForm(postAndBookingData) {
  try {
    const { postData, booking } = postAndBookingData;
    const insuranceProfileUrl = createInsuranceProfileUrl();
    const bookingUrl = getBookingByIdUrl(booking.id);
    const insuranceCoverageUrl = checkInsuranceCoverageUrl();
    const bookingInsuranceUrl = setBookingInsuranceUrl();

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: true }));

    const createInsuranceProfileResponse = yield call(apiPost, insuranceProfileUrl, {
      ...postData,
      insurer_type: postData.plan_type,
    });

    const patchBooking = yield call(apiPatch, bookingUrl, {
      insurance_profile_id: createInsuranceProfileResponse.id,
    });

    yield put(getBookingSuccess(patchBooking));
    const generalConfigsData = yield call(queryGeneralConfigs, {
      locationId: booking.location_id,
      fields: ['isDataValidatorEnabled'],
      inheritFromGroup: true,
    });
    const isDataValidatorEnabled = !!generalConfigsData.values.isDataValidatorEnabled;
    analyticsTrackEvent(FACESHEET_MODAL_UPLOAD_INSURANCE, {
      hadInsuranceOnFile: !!booking.insurance_profile_id,
      isDataValidatorEnabled,
      cardFrontUploaded: !!postData.card_front_id,
      cardBackUploaded: !!postData.card_back_id,
    });

    const blueCrossBlueShieldRegex = /\b(?:blue\s?cross|blue\s?shield|bcbs)\b/i;
    const isBCBS = blueCrossBlueShieldRegex.test(postData.insurer_name);
    const todayDay = new Date().getDay();
    /**
     * Blue Cross Blue Shield has 24/6 coverage and will not return values for an eligibility check run on Sunday
     * Therefore, we should not bother running the check for bookings made on Sundays.
     * The dapi-tasks task XYZ will capture these insurances and run the eligibility check on Monday
     */
    let shouldRunEligibilityCheck = isDataValidatorEnabled;
    if (isBCBS) {
      shouldRunEligibilityCheck = shouldRunEligibilityCheck && todayDay !== 0;
    }

    if (!shouldRunEligibilityCheck) {
      yield put(paymentsInsuranceReceived({ active: null }));
    } else {
      const postInsuranceCoverageParams = {
        ...postData,
        insurance_profile_id: createInsuranceProfileResponse.id,
      };
      const insuranceCoverageResult = yield call(
        apiPost,
        insuranceCoverageUrl,
        postInsuranceCoverageParams
      );
      yield put(eligibilityRequestIdReceived(insuranceCoverageResult.eligibility_requests_id));
      const insuranceCoverageParams = {
        eligibility_request_id: insuranceCoverageResult.eligibility_requests_id,
        booking_id: booking.id,
      };
      yield call(apiPost, bookingInsuranceUrl, insuranceCoverageParams);
      const eligibilityByInsuranceProfileIdAndBookingIdUrl = getEligibilityByInsuranceProfileId(
        createInsuranceProfileResponse.id
      );

      const response = yield call(apiGet, eligibilityByInsuranceProfileIdAndBookingIdUrl);
      const insuranceDetailsUrl = getInsuranceDetailsUrl(
        response.results[0].id,
        createInsuranceProfileResponse.id,
        'UC'
      );
      const detailsResponse = yield call(apiGet, insuranceDetailsUrl);

      yield put(paymentsInsuranceReceived(detailsResponse));
      /**
       * Update booking on queue so the Active/Inactive indicator color shows the latest state
       * (updated date and potentially updated color)
       */
      PubNubListeners.dispatch({
        type: PubNubEvent.PartialBookingUpdate,
        payload: {
          booking_id: booking.id,
        },
        source: PubNubSource.Local,
      });
    }

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  } catch (e) {
    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  }
}

export function* fetchInsurersList() {
  try {
    const insurersListUrl = getFullInsurersListUrl();

    const response = yield call(apiGet, insurersListUrl);
    yield put(insurersReceived(response));
  } catch (e) {
    yield put(insurersError(e));
  }
}

export function* fetchInsuranceSummary({ insuranceProfileId, serviceCode }) {
  try {
    const insuranceProfileAndBookingIdUrl = getEligibilityByInsuranceProfileId(insuranceProfileId);

    let response;

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: true }));

    if (!!insuranceProfileId) {
      response = yield call(apiGet, insuranceProfileAndBookingIdUrl);
      if (!isEmptyArray(response.results)) {
        yield put(eligibilityRequestIdReceived(response.results[0].id));
      }
    }

    if (!isEmptyArray(response.results)) {
      const insuranceDetailsUrl = getInsuranceDetailsUrl(
        response.results[0].id,
        insuranceProfileId,
        serviceCode || 'UC'
      );

      response = yield call(apiGet, insuranceDetailsUrl);
      yield put(paymentsInsuranceReceived(response));
    }

    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  } catch (e) {
    yield put(setRuntimeVariable({ name: FETCHING_ELIGIBILITY, value: false }));
  }
}

export function* fetchCharge({ bookingId }) {
  try {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CHARGE, value: true }));
    const response = yield call(apiGet, getChargesByBookingUrl(bookingId));
    if (response && response.results && response.results.length > 0) {
      yield put(chargesReceived(response.results[0]));
    } else {
      yield put(chargesReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CHARGE, value: false }));
  }
}

export function* fetchCards({ accountId }) {
  try {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CARDS, value: true }));
    const response = yield call(apiGet, getPaymentCardUrl(accountId));
    if (response && response.results && response.results.length > 0) {
      yield put(cardsReceived(response.results));
    } else {
      yield put(cardsReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: FETCHING_PAYMENT_CARDS, value: false }));
  }
}

export function* postCharge({ token, savedCard, booking, amount, reason }) {
  try {
    yield put(chargesSubmitting());
    const chargePostData = {
      token,
      saved_card: savedCard,
      booking_id: booking.id,
      amount,
      reason,
    };

    const response = yield call(apiPost, getChargesPOSUrl(), chargePostData);
    yield put(chargesReceived(response));
  } catch (e) {
    yield put(chargesError(e));
  }
}

export function* deleteCard({ accountId, cardId, vendorId }) {
  try {
    yield put(setRuntimeVariable({ name: DELETING_PAYMENT_CARD, value: true }));
    const deleteCardData = {
      card_id: cardId,
      vendor_id: vendorId,
    };
    const response = yield call(apiDeleteJson, deletePaymentCardUrl(accountId), deleteCardData);
    if (response && response?.data?.deleted) {
      yield put(cardsReceived(null));
    }
  } catch (e) {
    yield put(cardsError(e));
  } finally {
    yield put(setRuntimeVariable({ name: DELETING_PAYMENT_CARD, value: false }));
  }
}

export function* setChargeSubmitting() {
  yield put(chargesSubmitting());
}

export function* setChargeError({ error }) {
  yield put(chargesError(error || 'An error occurred.'));
}
