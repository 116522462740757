import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Checkbox, Form } from 'antd';
import { colors } from '../../../constants/colors';

const FormItem = Form.Item;

const StyledFormItem = styled(FormItem)`
  label {
    text-transform: capitalize;
    color: ${colors.notQuiteBlack};
    font-size: 13px;
  }
`;

class CheckboxFormField extends PureComponent {
  render = () => (
    <StyledFormItem label={null} colon={false}>
      {this.props.form.getFieldDecorator(this.props.fieldName, {
        initialValue: this.props.initialValue,
        valuePropName: 'checked',
      })(<Checkbox disabled={this.props.disabled}>{this.props.label}</Checkbox>)}
    </StyledFormItem>
  );
}

CheckboxFormField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CheckboxFormField;
