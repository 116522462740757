import { RECEIVE_PAST_BOOKINGS, PAST_BOOKING_ERROR } from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export default (state = { pastBookings: EMPTY_ARRAY }, action) => {
  switch (action.type) {
    case RECEIVE_PAST_BOOKINGS:
      return {
        pastBookings: action.payload.value.results,
      };
    case PAST_BOOKING_ERROR:
      return {
        error: action.payload.value,
      };
    default:
      return state;
  }
};
