import {
  RECEIVE_BOOKING_HISTORY,
  BOOKING_HISTORY_ERROR,
  CLEAR_BOOKING_HISTORY,
} from '../constants';
import { EMPTY_ARRAY } from '../core/util/array';

export default (state = { bookingHistory: EMPTY_ARRAY }, action) => {
  switch (action.type) {
    case RECEIVE_BOOKING_HISTORY:
      return {
        bookingHistory: action.payload.value,
      };
    case BOOKING_HISTORY_ERROR:
      return {
        error: action.payload.value,
      };
    case CLEAR_BOOKING_HISTORY:
      return {
        bookingHistory: EMPTY_ARRAY,
      };
    default:
      return state;
  }
};
