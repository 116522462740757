import { put, takeEvery } from 'redux-saga/effects';
import { setRuntimeVariable } from '../../actions/runtime';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export function* toggleModal({ modalName, visible }) {
  yield put(setRuntimeVariable({ name: modalName, value: visible }));
}

export default function* rootSaga() {
  yield takeEvery(TOGGLE_MODAL, toggleModal);
}
