export const BULK_UPLOAD_ROW_ERROR = 'payments/rowError';
export const BULK_UPLOAD_SOFT_MATCH = 'payments/softMatch';
export const BULK_UPLOAD_HAS_INVOICE = 'payments/hasInvoice';
export const BULK_UPLOAD_INVOICE_CREATED = 'payments/invoiceCreated';
export const BULK_UPLOAD_DEFAULT_STATE = 'payments/defaultState';

export const CHECK_BOOKINGS_BY_FILTER_PARAMS = 'payments/checkBookingsByFilterParams';

const getDefaultState = () => ({
  bulkBills: {
    rowError: [],
    softMatch: [],
    hasInvoice: [],
    invoiceCreated: [],
  },
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case BULK_UPLOAD_ROW_ERROR:
      return {
        ...state,
        bulkBills: {
          ...state.bulkBills,
          rowError: [...state.bulkBills.rowError, action.payload.value],
        },
      };
    case BULK_UPLOAD_HAS_INVOICE:
      return {
        ...state,
        bulkBills: {
          ...state.bulkBills,
          hasInvoice: [...state.bulkBills.hasInvoice, action.payload.value],
        },
      };
    case BULK_UPLOAD_INVOICE_CREATED:
      return {
        ...state,
        bulkBills: {
          ...state.bulkBills,
          invoiceCreated: [...state.bulkBills.invoiceCreated, action.payload.value],
        },
      };
    case BULK_UPLOAD_SOFT_MATCH:
      return {
        ...state,
        bulkBills: {
          ...state.bulkBills,
          softMatch: [...state.bulkBills.softMatch, action.payload.value],
        },
      };
    case BULK_UPLOAD_DEFAULT_STATE:
      return {
        ...state,
        bulkBills: {
          rowError: [],
          softMatch: [],
          hasInvoice: [],
          invoiceCreated: [],
        },
      };
    default:
      return state;
  }
};

const rowError = value => ({
  type: BULK_UPLOAD_ROW_ERROR,
  payload: { value },
});

const hasInvoice = value => ({
  type: BULK_UPLOAD_HAS_INVOICE,
  payload: { value },
});

const invoiceCreated = value => ({
  type: BULK_UPLOAD_INVOICE_CREATED,
  payload: { value },
});

const softMatch = value => ({
  type: BULK_UPLOAD_SOFT_MATCH,
  payload: { value },
});

const defaultState = value => ({
  type: BULK_UPLOAD_DEFAULT_STATE,
  payload: { value },
});

export {
  reducer as default,
  rowError,
  hasInvoice as bulkHasInvoice,
  invoiceCreated,
  softMatch,
  defaultState,
};
