import { call, put } from 'redux-saga/effects';
import { getAccountByPhoneUrl, getAccountsUrl } from '../../../../core/dapi/accounts';
import { getUserProfilesForAccountUrl } from '../../../../core/dapi/userProfiles';
import { apiPostJson, apiGetJson } from '../../../../core/dapi/index';
import { isEmpty } from '../../../../core/util/empty';
import { getStubBookingUrl, getInvoicesUrl } from '../../../../core/dapi/invoices';
import { standardizeDate } from '../../../../core/util/date';
import { rowError, invoiceCreated, bulkHasInvoice, softMatch } from '../../../../ducks/BulkUpload';
import { queryStringFromObject } from '../../../../core/util/string';
import {
  handleGenerateErrorCsvRow,
  hasANameMismatch,
  handleGenerateSoftMatchCsvRow,
} from './utils';
import { INVOICE_STATUS_PENDING, AUTHOR_TYPE_CLINIC_ACCOUNT } from '../../../../constants/index';

export function* createInvoice(csvData, bookingId) {
  const invoicesUrl = getInvoicesUrl();
  const createInvoiceParams = {
    ...csvData,
    balance: csvData.amount,
    status: INVOICE_STATUS_PENDING,
    stub_booking_id: bookingId,
    author_type: AUTHOR_TYPE_CLINIC_ACCOUNT,
  };

  const response = yield call(apiPostJson, invoicesUrl, createInvoiceParams);

  if (response.errors) {
    const generateCsvRow = handleGenerateErrorCsvRow(
      response.errors,
      invoicesUrl,
      createInvoiceParams,
      csvData
    );
    yield put(rowError(generateCsvRow));
    return;
  }

  if (response.data) {
    yield put(invoiceCreated(csvData));
  }
}

export function* createStubBooking(csvData, accountId) {
  const stubBookingUrl = getStubBookingUrl();
  const stubBookingParams = {
    ...csvData,
    account_id: accountId,
  };

  const response = yield call(apiPostJson, stubBookingUrl, stubBookingParams);

  if (response.errors) {
    const generateCsvRow = handleGenerateErrorCsvRow(
      response.errors,
      stubBookingUrl,
      stubBookingParams,
      csvData
    );
    yield put(rowError(generateCsvRow));
    return;
  }

  if (response.data) {
    const bookingId = response.data.id;
    yield call(createInvoice, csvData, bookingId);
  }
}

export function* createAccount(csvData) {
  const accountsUrl = getAccountsUrl();
  const createAccountParams = {
    ...csvData,
    birth_date: standardizeDate(csvData.birth_date),
    appointment_date: standardizeDate(csvData.appointment_date),
  };

  const response = yield call(apiPostJson, accountsUrl, createAccountParams);

  if (response.errors) {
    const generateCsvRow = handleGenerateErrorCsvRow(
      response.errors,
      accountsUrl,
      createAccountParams,
      csvData
    );
    yield put(rowError(generateCsvRow));
    return;
  }

  yield call(createStubBooking, csvData, response.data.id);
}

export function* lookupAccountByPhone(csvData) {
  const { phone } = csvData;
  const getAccountUrl = getAccountByPhoneUrl(phone);

  const response = yield call(apiGetJson, getAccountUrl);

  if (response.errors) {
    const generateCsvRow = handleGenerateErrorCsvRow(
      response.errors,
      getAccountUrl,
      phone,
      csvData
    );
    yield put(rowError(generateCsvRow));
    return;
  }

  if (isEmpty(response.data.results)) {
    yield call(createAccount, csvData);
  } else {
    const accountData = response.data.results[0];
    const accountId = accountData.id;

    // if name from csv doesn't match name on account, check for match among user profiles associated with account
    // if still no match, reject and do not create stub booking
    if (hasANameMismatch(accountData, csvData)) {
      const accountUserProfilesUrl = getUserProfilesForAccountUrl(accountId);
      const response = yield call(apiGetJson, accountUserProfilesUrl);
      const accountUserProfiles = response.data;

      const isMatchWithProfile = !!accountUserProfiles.find((up) => !hasANameMismatch(up, csvData));

      if (!isMatchWithProfile) {
        const generateCsvRow = handleGenerateSoftMatchCsvRow(accountData, csvData);
        yield put(softMatch(generateCsvRow));
        return;
      }
    }

    yield call(createStubBooking, csvData, accountId);
  }
}

export function* searchForBookings(filters) {
  const { filterParams } = filters;
  const {
    first_name: firstName,
    last_name: lastName,
    location_id: locationId,
    birth_date: birthDate,
    appointment_date: appointmentDate,
  } = filterParams;
  const searchParams = {
    first_name: firstName,
    last_name: lastName,
    location_id: locationId,
    birth_date: birthDate,
    appointment_date: appointmentDate,
    should_fuzzy_match: true,
  };

  const searchBookingAndInvoicesUrl = `${getInvoicesUrl()}?${queryStringFromObject(searchParams)}`;
  const response = yield call(apiGetJson, searchBookingAndInvoicesUrl);

  // if there is an error from dapi
  if (response.errors) {
    const generateCsvRow = handleGenerateErrorCsvRow(
      response.errors,
      searchBookingAndInvoicesUrl,
      `?${queryStringFromObject(searchParams)}`,
      filterParams
    );
    yield put(rowError(generateCsvRow));
    return;
  }

  // if there is already an existing booking
  if (!isEmpty(response.data.results)) {
    const { results } = response.data;

    // flag if name from csv doesn't match name on existing booking
    if (hasANameMismatch(results[0], filterParams)) {
      const generateCsvRow = handleGenerateSoftMatchCsvRow(results, filterParams);

      yield put(softMatch(generateCsvRow));
      return;
    }

    yield put(bulkHasInvoice(filterParams));
    return;
  }

  // if the server responded with no results
  if (isEmpty(response.data.results)) {
    yield call(lookupAccountByPhone, filterParams);
  }
}
