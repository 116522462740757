import difference from 'lodash/difference';
import {
  RESET_REVIEW_COUNTER,
  REVIEWS_DATA_ERROR,
  REVIEWS_DATA_RECEIVED,
  REVIEWS_NEW_REVIEWS_RECEIVED,
  STALE_REVIEWS_DATA,
} from '../constants';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';
import { ReviewsSagas } from '../sagas/Reviews';
import { getQueueSelectedLocationIds } from '../selectors/queue';

export const staleReviewsData = ({ locationId } = {}) => ({
  type: STALE_REVIEWS_DATA,
  payload: { locationId },
});

export const fetchReviewsDataForLocation = (locationId) => ({
  type: ReviewsSagas.FETCH_REVIEW_DATA_FOR_LOCATION,
  locationId,
});

export const receiveReviewsData = (numberOfNewReviews, value, { locationId = null } = {}) => ({
  type: REVIEWS_DATA_RECEIVED,
  payload: { numberOfNewReviews, value, locationId },
});

export const receiveNewReviews = (value, { locationId = null } = {}) => ({
  type: REVIEWS_NEW_REVIEWS_RECEIVED,
  payload: { value, locationId },
});

export const reviewsDataError = (value, { locationId = null } = {}) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: REVIEWS_DATA_ERROR,
    payload: { value, locationId },
  };
};

export const resetReviewCounter = ({ locationId = null }) => ({
  type: RESET_REVIEW_COUNTER,
  payload: { locationId },
});

export const resetReviewCounterOfAllSelectedLocations = () => (dispatch, getState) => {
  const selectedLocationIds = getQueueSelectedLocationIds(getState()) ?? [];

  selectedLocationIds.forEach((locationId) => {
    dispatch(resetReviewCounter({ locationId }));
  });
};

export const staleReviewsDataOfUnselectedLocationIds =
  (newSelectedLocationIds = []) =>
  (dispatch, getState) => {
    const oldSelectedLocationIds = getQueueSelectedLocationIds(getState());
    const thoseInOldButNotInNew = difference(oldSelectedLocationIds, newSelectedLocationIds);
    thoseInOldButNotInNew.forEach((locationId) => {
      dispatch(staleReviewsData({ locationId }));
    });
  };

export const staleAllReviewsData = () => staleReviewsDataOfUnselectedLocationIds([]);
