const PAYMENTS_INVOICES_CREATED = 'payments/INVOICES_CREATED';
const PAYMENTS_INVOICES_RECEIVED = 'payments/INVOICES_RECEIVED';
const PAYMENTS_INVOICES_ERROR = 'payments/INVOICES_ERROR';
const PAYMENTS_INVOICE_RECEIVED = 'payments/INVOICE_RECEIVED';
const PAYMENTS_INVOICE_ERROR = 'payments/INVOICE_ERROR';
const PAYMENTS_SEARCH_FILTERS = 'payments/SEARCH_FILTERS';
const PAYMENTS_SUMMARY_RECEIVED = 'payments/SUMMARY_RECEIVED';
const PAYMENTS_SUMMARY_ERROR = 'payments/SUMMARY_ERROR';

const invoiceCreated = value => ({
  type: PAYMENTS_INVOICES_CREATED,
  payload: { value },
});

const invoicesReceived = value => ({
  type: PAYMENTS_INVOICES_RECEIVED,
  payload: { value },
});

const invoicesError = value => ({
  type: PAYMENTS_INVOICES_ERROR,
  payload: { value },
});

const invoiceReceived = value => ({
  type: PAYMENTS_INVOICE_RECEIVED,
  payload: { value },
});

const invoiceError = value => ({
  type: PAYMENTS_INVOICE_ERROR,
  payload: { value },
});

const setSearchFilters = value => ({
  type: PAYMENTS_SEARCH_FILTERS,
  payload: { value },
});

const summaryReceived = value => ({
  type: PAYMENTS_SUMMARY_RECEIVED,
  payload: { value },
});

const summaryError = value => ({
  type: PAYMENTS_SUMMARY_ERROR,
  payload: { value },
});

const getDefaultState = () => ({
  invoices: {
    results: [],
  },
  searchFilters: {},
});

const reducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case PAYMENTS_INVOICES_RECEIVED:
      return {
        ...state,
        invoices: {
          results: action.payload.value.data.results,
          resultsPage: action.payload.value.data.page,
          error: null,
        },
      };
    case PAYMENTS_INVOICE_RECEIVED:
    case PAYMENTS_INVOICES_CREATED: {
      const bookingId = action.payload.value.booking_id;
      const invoicesCopy = getDefaultState();
      invoicesCopy.invoices.results = [...state.invoices.results];
      for (let i = 0; i < invoicesCopy.invoices.results.length; i++) {
        if (invoicesCopy.invoices.results[i].booking_id === bookingId) {
          invoicesCopy.invoices.results[i] = action.payload.value;
        }
      }

      invoicesCopy.summary = state.summary;
      invoicesCopy.invoice = action.payload.value;

      return invoicesCopy;
    }

    case PAYMENTS_INVOICES_ERROR:
      return {
        invoices: {
          ...state,
          results: [],
          error: action.payload.value,
        },
      };
    case PAYMENTS_INVOICE_ERROR:
      return {
        ...state,
        invoice: null,
        invoiceError: action.payload.value,
      };
    case PAYMENTS_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload.value,
      };
    case PAYMENTS_SUMMARY_RECEIVED:
      return {
        ...state,
        summary: action.payload.value,
        summaryError: null,
      };
    case PAYMENTS_SUMMARY_ERROR:
      return {
        ...state,
        summary: null,
        summaryError: action.payload.value,
      };
    default:
      return state;
  }
};

export {
  reducer as default,
  invoiceCreated,
  invoicesReceived,
  invoicesError,
  invoiceReceived,
  invoiceError,
  setSearchFilters,
  summaryReceived,
  summaryError,
};
