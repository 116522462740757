import { PartialRecord } from '../util/generics';
import moment from 'moment-timezone';
import { EARLIEST_YEAR_ALLOWED } from '../../config';

export type FieldType =
  | 'ZipCode'
  | 'DateOfBirth'
  | 'DateOfBirthRequired'
  | 'DateRequired'
  | 'DateMayBeFuture';
type DateValidatorOptions = {
  isRequired?: boolean;
  required?: boolean;
  isBirthdate?: boolean;
  mayBeFuture?: boolean;
};
type ValidationCallback = (value?: string) => void;

const basicDateValidation = (
  opts: DateValidatorOptions,
  callback: ValidationCallback,
  date: string,
  isBirthdate: boolean
) => {
  const dateSentenceStart = isBirthdate ? 'Birth date' : 'Date';
  const dateInline = isBirthdate ? 'birth date' : 'date';

  if (!opts.isRequired && !opts.required && (!date || !date.length)) {
    callback();
    return false;
  }

  if (!date) {
    callback(`Please enter a ${dateInline}`);
    return false;
  }

  if (date.length !== 8) {
    callback(`${dateSentenceStart} must be 8 digits long`);
    return false;
  }

  const parsedDate = moment(date, 'MMDDYYYY');
  if (!parsedDate.isValid()) {
    callback('Invalid date');
    return false;
  }

  if (!opts.mayBeFuture && parsedDate.isAfter(moment(), 'day')) {
    callback(`${dateSentenceStart} cannot be in the future`);
    return false;
  }

  return true;
};

const birthDateValidator =
  (opts: DateValidatorOptions = {}) =>
  (rule: string, value: any, callback: ValidationCallback) => {
    const date = value.birthDate;

    const passesBasicValidation = basicDateValidation(opts, callback, date, true);
    if (!passesBasicValidation) {
      return;
    }

    const parsedDate = moment(date, 'MMDDYYYY');
    if (parsedDate.isSame(moment(), 'day')) {
      callback('Birth date cannot be today');
      return;
    }

    if (parsedDate.isBefore(moment().year(EARLIEST_YEAR_ALLOWED), 'day')) {
      callback('Please enter a valid date');
      return;
    }

    callback();
  };

const dateValidator =
  (opts: DateValidatorOptions = {}) =>
  (rule: string, value: any, callback: ValidationCallback) => {
    const date = value.birthDate;

    basicDateValidation(opts, callback, date, false);
    callback();
  };

// Maps to https://3x.ant.design/components/form/#Validation-Rules
export const BASE_FIELD_VALIDATIONS: PartialRecord<FieldType, Array<PartialRecord<string, any>>> = {
  ZipCode: [
    {
      validator: async (_: any, value: any) => {
        if (value == null || value === '') {
          return Promise.resolve();
        }
        if (value.length !== 5) {
          return Promise.reject('Please enter a valid 5 digit zip code.');
        }
        if (isNaN(parseFloat(value))) {
          return Promise.reject('Zip code must be a number.');
        }
      },
    },
  ],
  DateOfBirth: [{ validator: birthDateValidator() }],
  DateOfBirthRequired: [{ validator: birthDateValidator({ required: true }) }],
  DateRequired: [{ validator: dateValidator({ required: true }) }],
  DateMayBeFuture: [{ validator: dateValidator({ mayBeFuture: true }) }],
};
