import { call, put } from 'redux-saga/effects';
import { apiPatchJson, apiGetJson, apiPostJson } from '../../core/dapi';
import { getPaymentAccountsUrl, getCreatePaymentAccountUrl } from '../../core/dapi/payments';
import {
  updatePaymentsEnabled,
  updatePosEnabled,
  updatePaymentsSuccess,
  updatePaymentsError,
  updatePosError,
  updatePosSuccess,
  updateAutoResendEnabled,
  updateAutoResendSuccess,
  fetchSolvpayStatus,
  createNewPaymentAccountError,
  resetNewPaymentAccountState,
  createNewPaymentAccountFetchError,
  updateEnablePaymentsDuringBookingSuccess,
  updateEnablePaymentsDuringBookingError,
  updatePaymentsDuringBookingEnabled,
  updateRequirePaymentsDuringBookingSuccess,
  updateRequirePaymentsDuringBookingError,
  updatePaymentsDuringBookingRequired,
} from '../../actions/settings';
import { fetchLocation } from './locations';

export function* enablePayments(accountData) {
  const { paymentAccountId } = accountData;
  const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(
    apiPatchJson,
    patchPaymentAccountsUrl,
    accountData.enablePaymentsData
  );

  if (response.errors) {
    yield put(updatePaymentsError(response.errors));
    return;
  }

  if (response.data) {
    yield put(updatePaymentsEnabled(response.data.is_payments_enabled));
    yield put(updatePaymentsSuccess(response.data));
  }
}

export function* enableAutoResend(accountData) {
  const { paymentAccountId } = accountData;
  const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(
    apiPatchJson,
    patchPaymentAccountsUrl,
    accountData.enableAutoResendData
  );

  if (response.errors) {
    yield put(updatePaymentsError(response.errors));
    return;
  }

  if (response.data) {
    yield put(updateAutoResendEnabled(response.data.is_auto_resend_enabled));
    yield put(updateAutoResendSuccess(response.data));
  }
}

export function* enablePos(accountData) {
  const { paymentAccountId } = accountData;
  const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(apiPatchJson, patchPaymentAccountsUrl, accountData.enablePosData);

  if (response.errors) {
    yield put(updatePosError(response.errors));
    return;
  }

  if (response.data) {
    yield put(updatePosEnabled(response.data.is_pos_enabled));
    yield put(updatePosSuccess(response.data));
  }
}

export function* enablePaymentsDuringBooking(accountData) {
  const { paymentAccountId } = accountData;
  const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(
    apiPatchJson,
    patchPaymentAccountsUrl,
    accountData.enablePaymentsDuringBookingData
  );

  if (response.errors) {
    yield put(updateEnablePaymentsDuringBookingError(response.errors));
    yield put(updateEnablePaymentsDuringBookingSuccess(response.data));
    return;
  }

  if (response.data) {
    yield put(updatePaymentsDuringBookingEnabled(response.data.is_payment_during_booking_enabled));
    yield put(updateEnablePaymentsDuringBookingSuccess(response.data));
  }
}
export function* requirePaymentsDuringBooking(accountData) {
  const { paymentAccountId } = accountData;
  const patchPaymentAccountsUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(
    apiPatchJson,
    patchPaymentAccountsUrl,
    accountData.requirePaymentsDuringBookingData
  );

  if (response.errors) {
    yield put(updateRequirePaymentsDuringBookingError(response.errors));
    yield put(updateRequirePaymentsDuringBookingSuccess(response.data));
    return;
  }

  if (response.data) {
    yield put(
      updatePaymentsDuringBookingRequired(response.data.is_payment_during_booking_required)
    );
    yield put(updateRequirePaymentsDuringBookingSuccess(response.data));
  }
}

export function* fetchClinicPaymentInfo(accountData) {
  const { paymentAccountId } = accountData;
  const fetchPaymentAccountUrl = getPaymentAccountsUrl(paymentAccountId);

  const response = yield call(apiGetJson, fetchPaymentAccountUrl);

  if (response.errors) {
    yield put(fetchSolvpayStatus(response.errors));
    return;
  }

  if (response.data) {
    yield put(updatePaymentsEnabled(response.data.is_payments_enabled));
    yield put(updatePosEnabled(response.data.is_pos_enabled));
    yield put(updateAutoResendEnabled(response.data.is_auto_resend_enabled));
    yield put(updatePaymentsDuringBookingEnabled(response.data.is_payment_during_booking_enabled));
    yield put(
      updatePaymentsDuringBookingRequired(response.data.is_payment_during_booking_required)
    );
  }
}

export function* createNewPaymentsAccount(accountData) {
  const { paymentAccountId, locationId, vendorType, status, successCallback } = accountData;
  const fetchPaymentAccountUrl = getPaymentAccountsUrl(paymentAccountId);
  const createPaymentAccountUrl = getCreatePaymentAccountUrl();

  const response = yield call(apiGetJson, fetchPaymentAccountUrl);

  if (response.errors) {
    yield put(createNewPaymentAccountFetchError(response.errors));
    return;
  }

  if (response.data) {
    const vendorId = response.data.vendor_id;

    const createPaymentAccountData = {
      location_id: locationId,
      vendor_type: vendorType,
      vendor_id: vendorId,
      status,
    };

    const createPaymentAccountResponse = yield call(
      apiPostJson,
      createPaymentAccountUrl,
      createPaymentAccountData
    );

    if (createPaymentAccountResponse.errors) {
      yield put(createNewPaymentAccountError(createPaymentAccountResponse.errors));
      return;
    }

    if (createPaymentAccountResponse.data) {
      yield call(fetchLocation, { locationId });
      successCallback();
    }
  }
}

export function* resetNewPaymentState() {
  yield put(resetNewPaymentAccountState());
}
