import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import s from '../primitives.module.scss';
import { showCopySuccessMessage } from './util';
import withStyles from '../../../core/isomorphic-style-loader/withStyles';

const StyledCopyIcon = styled.img`
  margin: 1px 0 0 3px;
  cursor: pointer;
`;

/**
 * Supply wrapper component for the React CopyToClipboard component.
 */
class CopyToClipboard extends PureComponent {
  render = () => {
    let label = (
      <span>
        {this.props.label}
        {!this.props.shouldHideCopyIcon && (
          <StyledCopyIcon src="/images/icons/copy.svg" alt="copy" />
        )}
      </span>
    );
    if (this.props.customLabel) {
      label = this.props.customLabel;
    }

    return (
      <ReactCopyToClipboard
        text={this.props.copiableTextValue}
        onCopy={() => {
          showCopySuccessMessage(this.props.label, this.props.login, this.props.location);
          this.props.onCopy && this.props.onCopy();
        }}
      >
        {label}
      </ReactCopyToClipboard>
    );
  };
}

CopyToClipboard.propTypes = {
  copiableTextValue: PropTypes.string,
  customLabel: PropTypes.element,
  label: PropTypes.string,
  location: PropTypes.object,
  login: PropTypes.object,
  shouldHideCopyIcon: PropTypes.bool,
  onCopy: PropTypes.function,
};

export default withStyles(s)(CopyToClipboard);
