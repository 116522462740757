import { REPORT_REQUEST_RECEIVED_OLD, REPORT_REQUEST_REPORT_ERROR_OLD } from '../constants';

export default function reportReducer(state = {}, action) {
  switch (action.type) {
    case REPORT_REQUEST_RECEIVED_OLD:
      return {
        ...state,
        data: action.payload.value.results,
        dateSelection: action.payload.dateSelection,
      };
    case REPORT_REQUEST_REPORT_ERROR_OLD:
      return {
        ...state,
        error: action.payload.value,
      };

    default:
      return state;
  }
}
