import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet, apiPatch, apiPost } from '../../core/dapi';
import {
  createSftpExportConfigUrl,
  getSftpExportConfigUrl,
  updateSftpExportConfigUrl,
} from '../../core/dapi/sftpExportConfig';
import {
  sftpExportConfigCreated,
  sftpExportConfigCreating,
  sftpExportConfigError,
  sftpExportConfigLoading,
  sftpExportConfigReceived,
  sftpExportConfigUpdated,
  sftpExportConfigUpdating,
} from '../../ducks/sftpExportConfig';

export class SftpExportConfigSagas {
  static CREATE_SFTP_EXPORT_CONFIG = 'saga/CREATE_SFTP_EXPORT_CONFIG';
  static FETCH_SFTP_EXPORT_CONFIG = 'saga/FETCH_SFTP_EXPORT_CONFIG';
  static UPDATE_SFTP_EXPORT_CONFIG = 'saga/UPDATE_SFTP_EXPORT_CONFIG';
}

export function* createSftpExportConfig({ onSuccess, postData }) {
  yield put(sftpExportConfigCreating(true));

  try {
    const response = yield call(apiPost, createSftpExportConfigUrl(), postData);
    yield put(sftpExportConfigCreated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(sftpExportConfigError(e));
  } finally {
    yield put(sftpExportConfigCreating(false));
  }
}

export function* fetchSftpExportConfig({ locationId }) {
  yield put(sftpExportConfigLoading(true));

  try {
    const response = yield call(apiGet, getSftpExportConfigUrl(locationId));
    yield put(sftpExportConfigReceived(response));
  } catch (e) {
    yield put(sftpExportConfigError(e));
  } finally {
    yield put(sftpExportConfigLoading(false));
  }
}

export function* updateSftpExportConfig({ onSuccess, patchData, sftpExportConfigId }) {
  yield put(sftpExportConfigUpdating(true));

  try {
    const response = yield call(apiPatch, updateSftpExportConfigUrl(sftpExportConfigId), patchData);
    yield put(sftpExportConfigUpdated(response));
    if (typeof onSuccess === 'function') onSuccess();
  } catch (e) {
    yield put(sftpExportConfigError(e));
  } finally {
    yield put(sftpExportConfigUpdating(false));
  }
}

export default function* rootSaga() {
  yield takeEvery(SftpExportConfigSagas.CREATE_SFTP_EXPORT_CONFIG, createSftpExportConfig);
  yield takeEvery(SftpExportConfigSagas.FETCH_SFTP_EXPORT_CONFIG, fetchSftpExportConfig);
  yield takeEvery(SftpExportConfigSagas.UPDATE_SFTP_EXPORT_CONFIG, updateSftpExportConfig);
}
