import {
  BOOKINGS_GRAPH_DATA_RECEIVED,
  BOOKINGS_GRAPH_DATA_ERROR,
  SET_GRAPH_LOCATIONS,
} from '../constants';

const receiveGraphData = type => value => ({
  type: BOOKINGS_GRAPH_DATA_RECEIVED,
  payload: { value, type },
});

const graphDataError = payload => ({
  type: BOOKINGS_GRAPH_DATA_ERROR,
  payload,
});

const setGraphLocations = value => ({
  type: SET_GRAPH_LOCATIONS,
  payload: value,
});

export { receiveGraphData, graphDataError, setGraphLocations };
