import { EMPTY_ARRAY } from '../core/util/array';

export class MarketingPackagesActionTypes {
  static PACKAGES_RECEIVED = 'PACKAGES_RECEIVED';
  static PACKAGES_ERROR = 'PACKAGES_ERROR';
}

export const packagesReceived = value => ({
  type: MarketingPackagesActionTypes.PACKAGES_RECEIVED,
  payload: { value },
});

export const packagesError = value => ({
  type: MarketingPackagesActionTypes.PACKAGES_ERROR,
  payload: { value },
});

export default (state = EMPTY_ARRAY, action) => {
  switch (action.type) {
    case MarketingPackagesActionTypes.PACKAGES_RECEIVED:
      return action.payload.value.results;
    case MarketingPackagesActionTypes.PACKAGES_ERROR:
      return EMPTY_ARRAY;
    default:
      return state;
  }
};
