import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetAllPages } from './base';
import { getSpecialtiesUrl } from '../../core/dapi/specialties';
import { specialtiesError, specialtiesReceived } from '../../ducks/specialties';
import { setRuntimeVariable } from '../../actions/runtime';
import { RuntimeAttributes } from '../../reducers/runtime';

export class SpecialtiesSagas {
  static FETCH_SPECIALTIES = 'SAGA/FETCH_SPECIALTIES';
}

export function* fetchSpecialties() {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_SPECIALTIES, value: true }));

  const response = yield call(apiGetAllPages, { url: getSpecialtiesUrl() });

  if (response.errors) {
    yield put(specialtiesError(response.errors));
  } else {
    yield put(specialtiesReceived(response));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_SPECIALTIES, value: false }));
}

export default function* rootSaga() {
  yield takeEvery(SpecialtiesSagas.FETCH_SPECIALTIES, fetchSpecialties);
}
