import { DAPI_HOST } from '../../config';
import { getFilterString } from './index';

const createServiceLocationUrl = () => `${DAPI_HOST}/v1/service-locations`;

const getServiceLocationsUrl = locationId => {
  const filters = getFilterString('service_locations', { location_id: locationId });

  return `${DAPI_HOST}/v1/service-locations?${filters}`;
};

const updateServiceLocationUrl = serviceLocationId =>
  `${DAPI_HOST}/v1/service-locations/${serviceLocationId}`;

export { createServiceLocationUrl, getServiceLocationsUrl, updateServiceLocationUrl };
