import { store } from '../../store/configureStore';
import type { RootState } from '../../types/RootState';
import { GenericObject } from '../util/generics';
import {
  coreAnalyticsTrackEvent,
  isAnalyticsEnabled,
  trackOptions as options,
} from './coreAnalyticsTrackEvent';
import { getTrackingProperties } from './properties';
import { analyticsReset } from './reset';

export const analyticsTrackPage = (
  category: string | undefined,
  name: string | undefined,
  properties: GenericObject
) => {
  if (isAnalyticsEnabled()) {
    const props = {
      environment: process.env.NEXT_PUBLIC_SOLV_ENV || 'Unknown',
      repo: 'manage-dev',
      ...properties,
      manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      title: '',
    };
    window.analytics.page(category, name, props, options);
  }
};

export const analyticsTrackEvent = (event: string, properties: GenericObject = {}) => {
  if (
    !properties ||
    ['clinic_account_id', 'is_solv_employee', 'roles', 'permissions', 'group_id'].some(
      (prop) => !properties[prop]
    )
  ) {
    const state: RootState | undefined = store.getState() as any;
    const login = state?.login;
    if (login) {
      properties = {
        ...getTrackingProperties({ login }),
        ...properties,
      };
    }
  }

  coreAnalyticsTrackEvent(event, properties);
};

export const analyticsTrackFormErrors = (event: string, errors: GenericObject) => {
  if (isAnalyticsEnabled()) {
    const properties = {
      ...errors,
      manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      fields: Object.keys(errors).join(','),
    };
    analyticsTrackEvent(event, properties);
  }
};

export const analyticsIdentify = (accountId: string, bookingPostData?: GenericObject) => {
  if (isAnalyticsEnabled()) {
    analyticsReset();
    window.analytics.identify(
      accountId,
      {
        ...(bookingPostData && {
          email: bookingPostData.account.email,
          first_name: bookingPostData.account.first_name,
          last_name: bookingPostData.account.last_name,
          phone: bookingPostData.account.phone,
          origin: bookingPostData.booking.origin,
          reason: bookingPostData.booking.reason_for_visit,
          insurer_type: bookingPostData.insurance_profile
            ? bookingPostData.insurance_profile.insurer_type
            : null,
          insurer_code: bookingPostData.insurance_profile
            ? bookingPostData.insurance_profile.insurer_code
            : null,
        }),
        manageVersion: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'Unknown',
      },
      options
    );
  }
};

export { getTrackingProperties } from './properties';
