import { call, put, takeEvery } from 'redux-saga/effects';
import { getMarketplaceInsurersUrl } from '../../core/dapi/marketplaceInsurers';
import { insurersError, insurersReceived } from '../../ducks/marketplaceInsurers';
import { setRuntimeVariable } from '../../actions/runtime';
import { RuntimeAttributes } from '../../reducers/runtime';
import { apiGetAllPages } from './base';

export class MarketplaceInsurersSagas {
  static FETCH_INSURERS = 'SAGA/FETCH_INSURERS';
}

export function* fetchMarketplaceInsurers({ filters }) {
  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_INSURERS, value: true }));

  const response = yield call(apiGetAllPages, { url: getMarketplaceInsurersUrl(filters) });

  if (response.errors) {
    yield put(insurersError(response.errors));
  } else {
    yield put(insurersReceived(response));
  }

  yield put(setRuntimeVariable({ name: RuntimeAttributes.IS_FETCHING_INSURERS, value: false }));
}

export default function* rootSaga() {
  yield takeEvery(MarketplaceInsurersSagas.FETCH_INSURERS, fetchMarketplaceInsurers);
}
