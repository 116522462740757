import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledMobileModal = styled(Modal)`
  height: 100% !important;

  .ant-modal {
    width: 100% !important;
    top: 0 !important;
    margin: 0 !important;

    @media (max-width: 768px) {
      margin: 0;
      width: 100% !important;
      height: 100vh;
    }
  }

  .ant-modal-content {
    border-radius: 0;
    min-height: 100vh;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    right: 10px;
    top: 12px;
  }
`;

class MobileModal extends PureComponent {
  render = () => (
    <StyledMobileModal
      closable={false}
      footer={this.props.footer}
      onCancel={this.props.onCancel}
      title={this.props.title}
      visible={this.props.visible}
      wrapClassName="mobile-modal-wrapper"
    >
      {this.props.children}
    </StyledMobileModal>
  );
}

MobileModal.propTypes = {
  children: PropTypes.element.isRequired,
  closable: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  footer: PropTypes.string,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

export default MobileModal;
