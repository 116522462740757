import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { EMPTY_STRING } from '../../../constants';
import { titleCase } from '../../../core/util/string';
import withStyles from '../../../core/isomorphic-style-loader/withStyles';
import s from '../primitives.module.scss';
import {
  BUTTON_THEME_ANT_DANGER,
  BUTTON_THEME_ANT_DEFAULT,
  BUTTON_THEME_ANT_PRIMARY,
  BUTTON_THEME_CRUNCHBERRY,
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_RESET,
  BUTTON_TYPE_SUBMIT,
} from '../constants';

const antThemes = [BUTTON_THEME_ANT_DANGER, BUTTON_THEME_ANT_DEFAULT, BUTTON_THEME_ANT_PRIMARY];

class Button extends PureComponent {
  getClassName = () => {
    if (antThemes.includes(this.props.theme)) {
      return EMPTY_STRING;
    }

    const classNames = [s.primitivesButton];

    classNames.push(s[`primitivesButton${titleCase(this.props.theme)}`]);

    if (this.props.isWidthOfParent) {
      classNames.push(s.primitivesButtonFullWidth);
    }

    return classNames.join(' ');
  };

  getType = () => {
    if (antThemes.includes(this.props.theme)) {
      return this.props.theme;
    }

    return EMPTY_STRING;
  };

  render = () => (
    <AntButton
      className={this.getClassName()}
      disabled={this.props.disabled}
      htmlType={this.props.htmlType}
      loading={this.props.loading}
      onClick={this.props.onClick}
      type={this.getType()}
      shape={this.props.shape}
      icon={this.props.icon}
      size={this.props.size}
    >
      {this.props.children}
    </AntButton>
  );
}

Button.defaultProps = {
  htmlType: BUTTON_TYPE_BUTTON,
  theme: BUTTON_THEME_CRUNCHBERRY,
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  htmlType: PropTypes.oneOf([BUTTON_TYPE_SUBMIT, BUTTON_TYPE_RESET, BUTTON_TYPE_BUTTON]).isRequired,
  isWidthOfParent: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.string,
  shape: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
};

export default withStyles(s)(Button);
