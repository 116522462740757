import { METERS_PER_MILE } from '../../constants';
import haversine from 'haversine';
import { isEmptyString } from './string';

/**
 * Convert meters to miles
 * @param meters
 * @return {*}
 */
const getMilesFromMeters = (meters) => {
  let distanceInMiles;
  if (meters > 0) {
    distanceInMiles = (meters / METERS_PER_MILE).toFixed(2);
  } else {
    distanceInMiles = null;
  }

  return distanceInMiles;
};

/**
 * Parse out lat/long from API string value
 * @param latLongString {string}
 * @return {{latitude: float, longitude: float}}
 */
const parseLatLongString = (latLongString) => {
  // (37.59514,-122.37353)
  const parseRegex = /\((-?[0-9.]+),(-?[0-9.]+)/;
  let latitude = null;
  let longitude = null;
  latLongString.replace(parseRegex, (wholeMatch, $1, $2) => {
    latitude = $1;
    longitude = $2;
  });
  return { latitude, longitude };
};

/**
 * Returns true if x is within range of any point in ys
 * @param x {{latitude: float, longitude: float}}
 * @param range {float}
 * @param ys {[{latitude: float, longitude: float}]}
 * @return {boolean}
 */

const xIsWithinRangeOfY = (x, range, ys) =>
  ys.some((serviceLocation) => haversine(x, serviceLocation, { unit: 'mile' }) <= range);

/**
 * Returns the distance between two points
 * @param p1 {{latitude: float, longitude: float}}
 * @param p2 {{latitude: float, longitude: float}}
 * @return {float}
 */
const getDistanceBetweenPoints = (p1, p2) => haversine(p1, p2);

const convertPostgresLatLongToObject = (latLongString) => {
  const withoutParentheses = latLongString.replace(/[()]/g, '');
  return {
    latitude: parseFloat(withoutParentheses.split(',')[0]),
    longitude: parseFloat(withoutParentheses.split(',')[1]),
  };
};

const usZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const isValidZipCode = (zipCode) => !isEmptyString(zipCode) && usZipCodeRegex.test(zipCode);

const latLongRegex = /[0-9.-]+,[0-9.-]+/;
const isValidLatLong = (latLong) => !isEmptyString(latLong) && latLongRegex.test(latLong);

export {
  convertPostgresLatLongToObject,
  getMilesFromMeters,
  isValidLatLong,
  isValidZipCode,
  parseLatLongString,
  usZipCodeRegex,
  xIsWithinRangeOfY,
  getDistanceBetweenPoints,
};
