import { takeEvery, call } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getLocationBookingCodesUrl } from '../core/dapi/location';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { MAPP_HOST } from '../config';
import { log } from '../core/logger/log';

export class SingleUseLinksSagas {
  static GENERATE_CODES = 'saga/GENERATE_CODES';
}

const buildAndDownloadCsv = (codes, locationId) => {
  const worksheetData = codes.map((code) => [
    `${MAPP_HOST}/book-online/${locationId}?bc=${encodeURIComponent(code)}`,
  ]);
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const csvWorksheet = XLSX.utils.sheet_to_csv(worksheet);

  FileSaver.saveAs(
    new Blob([csvWorksheet], { type: 'text/csv;charset=utf=8' }),
    `single_use_booking_links_${Date.now()}.csv`
  );
};

function* generateCodes({ locationId }) {
  try {
    const response = yield call(apiPost, getLocationBookingCodesUrl(locationId), {
      num_codes: 1000,
    });
    buildAndDownloadCsv(response, locationId);
  } catch (e) {
    log.error(e);
  }
}

export default function* rootSaga() {
  yield takeEvery(SingleUseLinksSagas.GENERATE_CODES, generateCodes);
}
